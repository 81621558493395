import React, { useReducer,useEffect } from 'react'
import authReducer from './authReducer'
import AuthContext from './authContext'
import setAuthToken from '../../utils/setAuthToken'
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
  LOAD_SUBJECT_DATA,
  LOAD_THEORY_CHECK,
  LOAD_THEORY_DATA,
} from '../types'
import axiosInstance from '../../utils/axios'
import { API_URL } from '../../utils/constants'
import checkall  from './SubjectData/checkall';
import theorycheck from './SubjectData/theorycheck';
import mathData from './SubjectData/math';
import physicsData from './SubjectData/physics';
import physicalchemistoryData from './SubjectData/physicalchemistry';
import organicchemistoryData from './SubjectData/organicchemistry';
import inorganicchemistoryData from './SubjectData/inorganicchemistry';



const initialize = () => {
  if (localStorage.stateData) {
    const tempStateData = JSON.parse(localStorage.stateData)
    return ({
      ...tempStateData,
      error: null,
      subjectData: { "Math": [], "Physics": [], "Chemistry": [], "Inorganic Chemistry": [], "Physical Chemistry": [], "Organic Chemistry": [] },
    })
  }
  else {
    return {
      token: localStorage.getItem('token'),
      isAuthencated: null,
      loading: true,
      user: null,
      username: null,
      error: null,
      subjectData: { "Math": [], "Physics": [], "Chemistry": [], "Inorganic Chemistry": [], "Physical Chemistry": [], "Organic Chemistry": [] },
      checkSubject: {},
    }
  }
}

const AuthState = (props) => {
  // const intialState = {
  //   token: localStorage.getItem('token'),
  //   isAuthencated: null,
  //   loading: true,
  //   user: null,
  //   error: null
  // }

  const intialState = initialize()

  const [state, dispatch] = useReducer(authReducer, intialState)

  // Load User
  const loadUser = async () => {
    //console.log('loadeduser', localStorage.getItem('user'));
    if (localStorage.user) {
      const config = {
        headers: {
          "Content-Type": "application/json"
        },
      };

      try {
        const res = await axiosInstance.post(
          `${API_URL}/user/details`,
          { userid: localStorage.getItem("user"), },
          config
        )

        //console.log("loading res ",res.data," ",SubKeys[i])

        //subjects[SubKeys[i]]= res.data
        dispatch({
          type: USER_LOADED,
          payload: res.data
        })
      }
      catch (err) {
        console.log("error fetching user")
      }
    } else {
      dispatch({
        type: AUTH_ERROR,
      })
    }
  }
  // const loadUser = async () => {
  //   // if (localStorage.token) {
  //   //   setAuthToken(localStorage.token)
  //   // }
  //   try {
  //     const config = {
  //       header: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //     const data={userid:localStorage.user}
  //     const res = await axiosInstance.post('/user/me',data,config)
  //     console.log('loadUser',res.data);
  //     dispatch({
  //       type: USER_LOADED,
  //       payload: res.data.user
  //     })
  //   } catch (err) {
  //     dispatch({
  //       type: AUTH_ERROR,
  //     })
  //   }
  // }

  //Register User
  const register = async formData => {
    const config = {
      header: {
        'Content-Type': 'application/json'
      }
    }
    try {
      const res = await axiosInstance.post("/user/register", formData, config)

      console.log(res)

      // dispatch({
      //   type: REGISTER_SUCCESS,
      //   payload: res.data
      // })
      // loadUser()

      if (res.data.isSuccessful) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data
        })
        loadUser()
      }
      else {
        dispatch({
          type: REGISTER_FAIL,
          payload: res.data.message
        })

      }

    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        // payload: err.response.data.error
      })
    }
  }

  //login user

  const login = async formData => {
    const config = {
      header: {
        'Content-Type': 'application/json'
      }
    }
    try {
      var res;
      console.log("googledata", formData);

      if (formData.username && formData.password)
        res = await axiosInstance.post('/user/login', formData, config)
      else if (formData.googleToken) {
        res = await axiosInstance.post('/user/login/google', formData, config)

      }
      else if (formData.facebookToken) {
        res = await axiosInstance.post('/user/login/facebook', formData, config)
      }
      
      // console.log('user data', res.data);
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: res.data
      // })
      // loadUser()

      if (res.data['isSuccessful']) {

        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        })
        loadUser()
        loadSubData()
        loadTheoryData()
      }
      else {
        handleLoginError();
        dispatch({
          type: LOGIN_FAIL,
          //  payload: err.response.data.msg
        })
      }

    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        // payload: err.response.data.msg
      })
    }
  }

  let handleLoginError;
  let  waitingListuser;
 

  const onLoginError = (funcToExecute) => {
    handleLoginError = funcToExecute;
  }



  const setError = (err) => {
    dispatch({
      type: REGISTER_FAIL,
      payload: [{ msg: err }]
    })
  }
  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  // useEffect(() => {
  //   logout()
  // }, []);


  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });


  const loadTheoryCheck = async () => {
    // const config = {
    //   headers: {
    //       "Content-Type": "application/json"
    //   },
    // };

    let res = checkall;
    //console.log("theory check response ",res)

    if (res && res.data) {
      dispatch({
        type: LOAD_THEORY_CHECK,
        payload: res.data,
      })
    }
  }

  const loadSubData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };

    const inorganic = inorganicchemistoryData;
    const organic = organicchemistoryData;
    const physical = physicalchemistoryData;
    const physics = physicsData;
    const math = mathData;
    //console.log("zzzz",inorganic);

    const subjects = { "Math": math, "Physics": physics, "Chemistry": {}, "Inorganic Chemistry": inorganic, "Physical Chemistry": physical, "Organic Chemistry": organic }

    // const SubKeys = Object.keys(subjects)

    // for(var i=0;i<SubKeys.length;i++){
    //   try{
    //     const res = await axiosInstance.post("https://content.acadza.in/theory/listSubConcepts/", {
    //           subject: SubKeys[i]
    //       }, config)

    //       //console.log("loading res ",res.data," ",SubKeys[i])

    //       subjects[SubKeys[i]]= res.data
    //   }
    //   catch(err){
    //     console.log("error fetching ",SubKeys[i])
    //   }
    // }


    dispatch({
      type: LOAD_SUBJECT_DATA,
      payload: subjects
    });
  }

  const loadTheoryData = async () => {
    let theory;


    try {
      const res = theorycheck;
      //console.log("loading res ",res.data," ",SubKeys[i])

      theory = res.theoryCheck
    }
    catch (err) {
      console.log("error fetching theorycheck")
    }

    //console.log("done fetching")


    dispatch({
      type: LOAD_THEORY_DATA,
      payload: theory
    });
  }
  return (
    <AuthContext.Provider value={{
      token: state.token,
      isAuthencated: state.isAuthencated,
      user: state.user,
      username: state.username,
      userdata: state.userdata,
      theorycheck: state.theorycheck,
      error: state.error,
      loading: state.loading,
      subjectData: state.subjectData,
      checkSubject: state.checkSubject,
      messg: state.messg,
      register,
      login,
      onLoginError,
      loadUser,
      logout,
      clearErrors,
      setError,
      loadSubData,
      loadTheoryCheck,
      loadTheoryData,
    }} >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState
