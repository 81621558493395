export default {
    "Classification and nomenclature of organic compounds": {
        "Classification and degree of unsaturaion": [
            "Classification based on structure",
            "Classification based on functional groups",
            "Classification based on saturation and unsaturation in a carbon chain",
            "Degree of unsaturation calculation"
        ],
        "Terminology of general IUPAC name": [
            "Introduction of secondary and primary prefix",
            "Introduction of the word root and suffix"
        ],
        "Rules for IUPAC nomenclature": [
            "Selection of principal chain",
            "Numbering of the selected parent chain"
        ],
        "IUPAC nomenclature of important compounds": [
            "Nomenclature of Hydrocarbons",
            "Nomenclature of Oxygen and nitrogen derivatives",
            "Nomenclature of Halogen derivatives",
            "Nomenclature of Poly functional and aromatic compounds",
            "Nomenclature of Bicyclo compounds"
        ]
    },
    "General Organic Chemistry": {
        "Bond Cleavage": [
            "Homolytic and Heterolytic Cleavage",
            "All Reaction Intermediates"
        ],
        "Inductive effect": [
            "Definition, Types of I effect",
            "Effect on acidic and basic strength",
            "Stability of all reaction intermediates"
        ],
        "Resonance": [
            "Definition, Conditions for resonance",
            "Rules for writing resonating structures and equivalent resonance",
            "Types of conjugations",
            "Mesomeric effect",
            "Steric Inhibition of Resonance [SIR effect]",
            "Stability comparison of resonating structures"
        ],
        "Heat of hydrogenation and combustion": [
            "Definition and Variation and Application"
        ],
        "Aromaticity": [
            "Aromatic, Anti-aromatic, Non-aromatic compounds and Huckel's rule",
            "Quasi-aromatic compounds and Annulenes",
            "Applications of aromaticity"
        ],
        "Resonance energy": [
            "Definition and comparison of resonance energy"
        ],
        "Hyperconjugation effect [+H effect]": [
            "Definition and Conditions for hyperconjugation",
            "Application of hyperconjugation"
        ],
        "Reverse hyperconjugation[-H effect]": [
            "Definition and Conditions for -H effect and Application"
        ],
        "Electromeric effect": [
            "Definition and applications"
        ],
        "Acid-base theory": [
            "General comparison of acidic and basic strength",
            "Tests based on acidic strength"
        ],
        "Acidic strength comparisons": [
            "In organic acids",
            "In inorganic acids"
        ],
        "Basic strength comparisons": [
            "Aliphatic amines in gaseous and aqueous medium",
            "Aromatic amines",
            "Ortho effect"
        ]
    },
    "Isomerism of organic compounds": {
        "Structural Isomerism": [
            "Definition and types of isomerism, Discussion of chain and position isomerism",
            "Functional, ring chain isomerism and metamerism",
            "Introduction, Mechanism and condition of tautomerism",
            "% enol content and types of tautomers"
        ],
        "2-D projections of molecules": [
            "Sawhorse and newman projection and their interconversions"
        ],
        "Conformational analysis of open chain compounds": [
            "Conformational analysis of alkanes(Ethane, Propane, Butane)",
            "Gauche effect"
        ],
        "Geometrical isomerism": [
            "Introduction, condition and structural units of G.I",
            "cis-trans and syn-anti classifications",
            "E-Z classification and CIP sequence",
            "Calculation of total number of GI in organic compounds",
            "Comparison of properties of geometrical isomers"
        ],
        "Concept of strain": [
            "Definition, strain energy and types of strain"
        ],
        "Concept of symmetry": [
            "Introduction of symmetry and types of symmetry(AOS and COS)",
            "Plane of symmetry and Alternative axis of symmetry"
        ],
        "Fischer projections": [
            "Fischer projections and Interconversion from other projections"
        ],
        "Optical isomerism": [
            "R and S classification",
            "Optically active and inactive compounds and specific rotation",
            "Enantiomers and Diastereomers",
            "Meso compounds",
            "Optical activity in compounds without a chiral center"
        ],
        "Conformational analysis of closed chain compounds": [
            "Conformational analysis of cyclobutane and cyclohexane",
            "Substituted cyclohexane's and their stability"
        ],
        "Classification of optical isomers": [
            "Erythro and threo classification and D, L classifications"
        ],
        "Racemic mixture": [
            "Racemization, racemic mixture and their properties"
        ],
        "Concept of chirality": [
            "Introduction and chiriality of molecules and chiriality of centre"
        ],
        "Calculation of number of optical isomers": [
            "In symmetrical and unsymmetrical molecules",
            "Total number of stereoisomers"
        ],
        "Amine inversion": [
            "Amine inversion and applications"
        ],
        "Resolution": [
            "Definition and resolution schemes in stereoisomers"
        ]
    },
    "Reaction Mechanism": {
        "Kinetics of organic reaction": [
            "Order, molecularity, energy barrier of chemical reaction"
        ],
        "Thermodynamics of organic reactions": [
            "Free energy diagram, thermodynamically and kinetically controlled products"
        ],
        "Organic Reagents": [
            "Nucleophiles and Electrophlies"
        ],
        "Leaving Groups in organic reactions": [
            "Introduction and strength of leaving groups"
        ],
        "Rearrangement": [
            "Rearrangement reactions",
            "Structure and stability order of carbocation and carbanion",
            "Ring expansion and ring contraction in carbocation",
            "Nitrene(Preparation, structure, types and reactions)",
            "Carbenes(Preparation, structure, types and reactions)",
            "Structure and preparation of benzyne and reaction of benzyne"
        ],
        "Substitution Reaction": [
            "Nucleophilic Substitution reaction 1 (SN1)",
            "Nucleophilic Substitution reaction 2 (SN2)",
            "Intramolecular Substitution reaction SNi",
            "Stereochemistry of SN1 and SN2",
            "Comparion between SN1 and SN2"
        ],
        "Elimination Reaction": [
            "Elimination 1 (E1) and Saytzeff's Rule",
            "Elimination 2 (E2) and Hoffman's Elimination",
            "E1CB Reaction and other types of elimination reaction",
            "Stereochemistry of E1 and E2",
            "Comparion between E1 and E2"
        ],
        "Comparison between Substitution and Elimination": [
            "Elimination vs Substitution"
        ],
        "Addition Reaction": [
            "Addition of acid in unsaturated hydrocarbons and Markovnikov rule",
            "Anti Markovnikov rule",
            "Hydroboration, Oxymercuration Demercuration and Hydration of alkenes and alkynes"
        ]
    },
    "Alkanes, Alkenes and Alkynes (Hydrocarbons)": {
        "Introduction: Alkanes, Alkynes and Alkenes": [
            "Structure, Bonding, IUPAC, Isomerism"
        ],
        "Preparations of Alkane": [
            "From Alkenes and Alkynes",
            "Preparation from halides",
            "Preparation from oxygen derivatives",
            "Kolbe's electrolysis (Alkane, Alkene, Alkyne)",
            "Hydrolysis of metal carbide"
        ],
        "Physical properties of Alkane": [
            "General physical properties, octane and cetane numbers"
        ],
        "Chemical properties of Alkane": [
            "Combustion, Cracking, Nitration, Sulphonation reaction",
            "Halogenation, Aromatization and Isomerisation of alkanes",
            "Aromatization and Isomerisation of Alkanes and Miscellaneous reactions"
        ],
        "Preparation of Alkenes": [
            "From controlled Hydrogenation",
            "From Halogen Derivatives",
            "From Alcohols and cracking of Alkanes"
        ],
        "Physical properties of Alkenes": [
            "General Physical properties, Heat of Hydrogenation (Stability)"
        ],
        "Electrophilic Addition Reaction": [
            "Addition of Hydrogen Halides",
            "Hydroboration - Oxidation",
            "Addition of carbene and dihalo carbene",
            "Oxymercuration and Demercuration and Hydration of Alkenes",
            "Epoxidation, Hydroxylation, Anti-Hydroxylation and Addition of HOX"
        ],
        "Oxidation reaction": [
            "Ozonolysis",
            "Oxidation with Bayer's reagent"
        ],
        "Diels-Alder reaction": [
            "Mechanism, Regioselectivity and Stereochemistry, Cyclic reaction"
        ],
        "Miscellaneous reactions of Alkenes": [
            "N.B.S, Dimerization, Polymerisation and Addition to conjugated dienes"
        ],
        "Preparation of Alkynes": [
            "From Dihaloalkanes"
        ],
        "Physical properties of Alkynes": [
            "General Physical properties and Chemical reactivity as compared to Alkenes"
        ],
        "Chemical properties of Alkynes": [
            "Electrophilic addition reactions",
            "Reactions of terminal Alkynes",
            "Reduction and Oxidation of Alkynes and Polymerization/Dimerization reaction"
        ],
        "Miscellaneous reactions of Alkynes": [
            "Addition reaction in Alkadienes (1,2 addition and 1,4 addition)"
        ]
    },
    "Aromatic hydrocarbons": {
        "Preparations": [
            "All Preparation reactions"
        ],
        "Physical properties": [
            "General physical properties, Addition and Substitution"
        ],
        "Electrophilic aromatic substitution [ArSE or EAS]": [
            "Mechanism and kinetics",
            "Activating and deactivating groups, reactivity comparison",
            "ArSE in naphthalene and heteroaromatic compounds",
            "Halogenation",
            "Nitration and Nitrosation reaction",
            "Sulphonation reaction",
            "Friedel-Crafts alkylation",
            "Friedel-Crafts acylation reaction",
            "Ipso substitution"
        ],
        "Nucleophilic substitution Reaction": [
            "Benzyne Mechanism",
            "Haloarenes"
        ],
        "Miscellaneous": [
            "Addition reaction, Photolytic halogenation, Hydrogenation and Oxidation reaction"
        ]
    },
    "Alkyl and aryl halides or halogen derivatives": {
        "Preparation of haloalkanes": [
            "From hydrocarbons, Finkelstein and Swarts reaction",
            "From alcohols",
            "Borodine Hunsdiecker reaction"
        ],
        "Physical and chemical properties of haloalkanes": [
            "Physical properties of haloalkanes, Wurtz reaction",
            "Elimination reactions of haloalkanes",
            "Nucleophilic substitution of haloalkanes",
            "Williamson's reaction",
            "Ammonolysis, Reduction and oxidation of halides"
        ],
        "Grignard reagent": [
            "Preparation and Properties"
        ],
        "Haloform": [
            "Haloform reaction and Iodoform test",
            "Physical and chemical properties, Uses"
        ],
        "Aryl halides": [
            "Introduction and preparations"
        ],
        "Reaction of aryl halides": [
            "ArSE of aryl halides and reaction with metals"
        ],
        "Nucleophilic substitution of aryl halides": [
            "ArSN2 mechanism and kinetics"
        ]
    },
    "Alcohols": {
        "Alcohols: Introduction": [
            "Classification and nomenclature"
        ],
        "Preparation of alcohols": [
            "From hydrocarbons",
            "From halides and ethers",
            "By reducing carbonyl compounds",
            "By reducing carboxylic acids and derivatives",
            "By hydrolysis of esters",
            "From amines",
            "Allylic oxidation and Fermentation of Carbohydrates"
        ],
        "Properties of alcohol": [
            "Physical Properties of Alcohol",
            "Reactions due to O-H bond cleavage",
            "Reactions due to C-O bond cleavage",
            "Esterification with organic acids and derivatives",
            "Esterification with inorganic acids and derivatives",
            "Oxidation by mild oxidising agents",
            "Oxidation by strong oxidising agents",
            "Distinction tests of alcohol"
        ],
        "geminal diols": [
            "Preration from  Alkenes and geminal dihalides & imp. properties"
        ],
        "Preparation and properties of vicinal diols": [
            "Prepration From alkenes and vicinal dihalides",
            "Preparation From carbonyl compounds",
            "Physical properties and Reaction with metals",
            "Halogenation and Dehydration",
            "Esterification, Redox reaction and polymerisation reaction",
            "Pinacol-pinacolone rearrangement reaction",
            "Malaprade oxidation"
        ],
        "Preparation and properties of glycerol": [
            "Preparation and properties of glycerol"
        ],
        "Chemical properties": [
            "Halogenation, Dehydration and Esterification reaction",
            "Redox reaction and Reaction with oxalic acid"
        ]
    },
    "Phenols": {
        "Preparation and physical properties of phenols": [
            "Preparation and physical properties of phenols"
        ],
        "Chemical properties of phenol": [
            "Reaction due to O-H bond cleavage",
            "Reactions due to C-O bond cleavage",
            "Electrophilic aromatic substitutions",
            "Name reactions",
            "Polymerization reactions",
            "Distinction tests",
            "Oxidation reactions"
        ]
    },
    "Ethers": {
        "Preparation and properties of ethers": [
            "From alkene",
            "From halides",
            "From alcohols",
            "Physical properties"
        ],
        "Chemical properties of aliphatic ethers": [
            "Halogenation and Dehydration",
            "Reduction and Oxidation reactions",
            "Acid and base catalysed ring-opening of epoxides",
            "Nomenclature and properties of crown ethers"
        ]
    },
    "Carbonyl compounds (Aldehydes and ketones)": {
        "Introduction to aldehydes and ketones": [
            "Structure"
        ],
        "Preparations": [
            "From hydrocarbons and halides",
            "Carbonylation of aromatic compounds and Etard oxidation reaction",
            "By heating allyl-vinyl ethers",
            "By oxidation of alcohols",
            "Friedel-Crafts acylation",
            "Fries rearrangement reaction",
            "Stephen's aldehyde synthesis",
            "Nef's and Rosenmund’s reaction",
            "From nitriles",
            "From carboxylic acids and derivatives"
        ],
        "Properties of carbonyl compounds- 1": [
            "Physical properties and General Nucleophilic addition",
            "Nucleophilic addition of water, alcohols",
            "Cyanohydrin formation",
            "Addition of sodium bisulphite",
            "Addition of organometallic compounds",
            "Michael addition reaction",
            "Wittig reaction",
            "Addition elimination with ammonia and derivatives",
            "Enamine formation with secondary amines",
            "Beckmann rearrangement reaction 2",
            "Reduction reactions",
            "Oxidation reactions",
            "Distinction tests of aldehydes and ketones"
        ],
        "Properties of carbonyl compounds- 2": [
            "Reaction due to alpha-H",
            "Aldol condensation reaction",
            "Claisen-Schmidt reaction",
            "Knoevenagel condensation reaction",
            "Perkin condensation reaction 2",
            "Reformatsky rearrangement reaction",
            "Benzoin reaction",
            "Mannich reaction",
            "Cannizzaro reaction",
            "Benzil-benzilic acid rearrangement reaction",
            "Tischenko reaction 2",
            "Favorskii rearrangement reaction",
            "Polymerization reactions"
        ]
    },
    "Carboxylic acid and its derivatives": {
        "Introduction to carboxylic acids and its derivatives": [
            "Structures and resonance stabilization"
        ],
        "Preparation of carboxylic acids": [
            "Oxidation of hydrocarbons",
            "By Grignard reagents and from halides",
            "Oxidation of alcohols",
            "Oxidation of aldehydes and ketones",
            "Oxidation of aromatic compounds",
            "Hydrolysis of carboxylic acid derivatives",
            "Hydrolysis of nitriles"
        ],
        "Properties": [
            "Physical properties",
            "Reactions due to O-H bond cleavage",
            "Reactions due to C-O bond cleavage",
            "Decarboxylation",
            "Heating effect on carboxylic acids",
            "Oxidation and Reduction reactions",
            "Reaction with organometallic compounds",
            "Borodine Hunsdiecker reaction",
            "Hell-Volhard-Zelinsky reaction",
            "Schmidt reaction",
            "Arndt-Eistert reaction",
            "Acyl nucleophilic substitution"
        ],
        "Preparation and properties of Acyl halides": [
            "Preparation and properties of Acyl halides"
        ],
        "Chemical properties of acyl halides": [
            "Hydrolysis, Esterification and Redox reaction",
            "SNAc and Curtius rearrangement reaction",
            "Reaction with organometallic compounds"
        ],
        "Preparation and properties of anhydrides": [
            "Preparation and properties of anhydrides"
        ],
        "Chemical properties of acid anhydride": [
            "Hydrolysis, redox reaction and reaction with organometallic compounds",
            "Perkin condensation reaction 1"
        ],
        "Preparation of esters": [
            "From carboxylic acids and derivatives",
            "Baeyer-Villiger oxidation",
            "Tischenko reaction 1"
        ],
        "Properties of esters": [
            "Physical properties of esters, Hydrolysis and Redox reactions",
            "Claisen condensation of esters",
            "With organometallic compounds",
            "Acyloin reaction"
        ],
        "Preparation of amides": [
            "SNAc of carboxylic acids and derivatives",
            "Partial hydrolysis of nitriles",
            "Beckmann rearrangement reaction 1"
        ],
        "Properties of amides": [
            "Physical properties, Hydrolysis and Dehydration",
            "Halogenation and redox reactions.",
            "Acyl nucleophilic substitution",
            "Hoffman bromamide degradation reaction"
        ]
    },
    "Nitrogen derivatives": {
        "Introduction to amines": [
            "Structure and nomenclature of amines"
        ],
        "Preparation of amines": [
            "From halides and alcohols",
            "From carbonyl compounds",
            "Gabriel phthalimide reaction",
            "Reduction of oximes, imines, azides and nitro compounds",
            "Reduction of amides, nitriles and isonitriles",
            "Lossen rearrangement reaction"
        ],
        "Properties of amines": [
            "Physical properties and Basic nature of amines",
            "Hofmann elimination",
            "Acylation and Schotten-Baumann reaction",
            "Condensation with carbonyl compounds"
        ],
        "Distinction tests": [
            "With HNO₂",
            "Hoffman mustard oil test",
            "With Hinsberg’s reagent",
            "Carbylamine test",
            "Reaction with COCl₂"
        ],
        "Oxidation of amines": [
            "Oxidation by KMnO₄ and peroxy acid",
            "Oxidation of aromatic amines"
        ],
        "Preparation and properties of aniline": [
            "Reduction of nitrobenzene by Zn/HCl or Sn/HCl",
            "From phenol and chlorobenzene",
            "Electrophilic aromatic substitution"
        ],
        "Preparation and properties and urea": [
            "Wohler synthesis and Heating effect on urea",
            "Reaction with oxalic acid, diethyl malonate and HNO₂"
        ]
    },
    "Biomolecules": {
        "Carbohydrates": [
            "Definition, classification and examples of saccharides"
        ],
        "Monosaccharides": [
            "Aldoses and Ketoses",
            "D, L classification of monosaccharides",
            "Stereoisomerism, Haworth and Fischer projection of monosaccharides",
            "Ring chain tautomerism in sugars",
            "Mutarotation",
            "Glycosides",
            "Lobry de Vruyn-van Ekenstein transformation",
            "Oxidation and Reduction of sugar",
            "Formation of Osazones",
            "Kiliani Fischer synthesis and Ruff degradation synthesis"
        ],
        "Sucrose": [
            "Structure and Properties of Sucrose"
        ],
        "Maltose": [
            "Structure and Properties of Maltose"
        ],
        "Polysaccharides": [
            "Starch",
            "Amylose and Amylopectin"
        ],
        "Structure and classification of amino acids": [
            "Classification of Amino acids",
            "Zwitter ion character and Isoelectric point"
        ],
        "Synthesis of amino acids": [
            "Ammonolysis of alpha-amino acids, Gabriel phthalimide and Strecker synthesis"
        ],
        "Polypeptides": [
            "Peptide bonds their preparation",
            "Representation of polypeptides and Ninhydrin test",
            "Hydrolysis of polypeptides and Sanger N- terminal analysis"
        ]
    },
    "Synthetic and natural polymers": {
        "Polymers-Introduction": [
            "Polymers, macromolecules, Homopolymers and Copolymers"
        ],
        "Classification of polymers": [
            "Classification based on source",
            "Classification based on structure"
        ],
        "Classification based on synthesis": [
            "Addition polymers and Condensation polymers"
        ],
        "Classification based on the mode of polymerization": [
            "Chain growth and step growth polymers",
            "Free radical and Vinyl polymerization"
        ],
        "Classification based on molecular forces": [
            "Elastomers and fibres",
            "Thermoplastics and Thermosetting polymers"
        ],
        "Polymerization": [
            "Conjugate diene polymerization",
            "Cationic addition polymerization",
            "Anionic addition polymerization",
            "Co polymerization"
        ],
        "Commercially important polymers": [
            "Polyethylene, Polypropylene and Polystyrene",
            "Polyacrylonitrile (PAN), Polyvinyl chlorides (PVC) and Teflon"
        ],
        "Homopolymers": [
            "Synthetic rubber",
            "Natural rubber (all-cis rubber)",
            "Gutta-percha (all-trans rubber)"
        ],
        "Copolymers": [
            "BUNA-S and BUNA-N"
        ],
        "Condensation polymers": [
            "Terylene or dacron"
        ],
        "Polyamides": [
            "Types of nylon"
        ],
        "Formaldehyde resin": [
            "Resins with formaldehyde, Melamine and Urea"
        ]
    },
    "Theoretical and environmental chemistry": {
        "Introduction to environmental chemistry": [
            "Regions of the atmosphere and Air pollution reactions",
            "Air pollution and its effects",
            "Greenhouse Effect Global Warming and Ozone layer depletion",
            "Water pollution"
        ]
    },
    "Chemistry in everyday life": {
        "Introduction to drugs and chemotherapy": [
            "Chemotherapy",
            "Classification of drugs and important drugs",
            "Drugs according to their action",
            "Neurologically active drugs and Antimicrobials"
        ],
        "Types of chemical messengers": [
            "Types of Hormones and Neurotransmitters"
        ],
        "Chemicals in food": [
            "Artificial sweeteners, Food preservatives and Antioxidants"
        ],
        "Cleansing agents": [
            "Soaps and detergents"
        ],
        "Rocket propellant": [
            "Types of rocket propellants"
        ],
        "Dyes": [
            "Chromophores and important dyes"
        ]
    }
}