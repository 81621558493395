import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  LOAD_SUBJECT_DATA,
  LOAD_THEORY_CHECK,
  LOAD_THEORY_DATA,
} from '../types'

const backup = (state) =>{
  localStorage.setItem('stateData',JSON.stringify(state))
  return state
}


export default (state, { type, payload }) => {
  switch (type) {
    case USER_LOADED:
      return backup({
        ...state,
        isAuthencated: true,
        user: payload._id,
        userdata: payload,
        loading: false,
        error: null
      })
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      console.log('LOGIN SUCCESS');
      localStorage.setItem('token', payload.auth_token)
      localStorage.setItem('user', payload.user._id)
      localStorage.setItem('username', payload.user.name)
      return backup({
        ...state,
        ...payload,
        username: payload.user.username,
        userdata: payload.user,
        isAuthencated: true,
        loading: false,
        error: null,
        messg:true,
      })
    case REGISTER_FAIL:
      
    case LOGIN_FAIL:
      return backup({
        messg:false
      })
    case AUTH_ERROR:
    case LOGOUT:
      console.log("logout pressed")
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      return backup({
        ...state,
        token: null,
        isAuthencated: null,
        user: null,
        username: null,
        userdata: null,
        loading: false,
        error: payload,
        messg:false,
      })
    case CLEAR_ERRORS:
      return backup({
        ...state,
        error: null
      })

    case LOAD_SUBJECT_DATA:
      return backup({
        ...state,
        subjectData:payload,
      })
    
    case LOAD_THEORY_CHECK:
      return backup({
        ...state,
        checkSubject:payload,
      })
    case LOAD_THEORY_DATA:
      return backup({
        ...state,
        theorycheck:payload,
      })
    default:
      return backup(state)
  }
}