export default {
    "isSuccessful": true,
    "message": "Theories checked!!!",
    "theoryCheck": {
        "Mathematics in physics": true,
        "Units and dimensions": false,
        "Vectors": true,
        "Motion in one dimension": true,
        "Motion in two dimensions": true,
        "Newton's law of motion": true,
        "Work, power and energy": true,
        "Circular Motion": true,
        "Centre of mass and momentum conservation (collision)": true,
        "Rotational Motion": true,
        "Gravitation": true,
        "Oscillations (SHM)": true,
        "Properties of matter and fluid mechanics": true,
        "Waves": true,
        //"Wave on string": false,
        //"Sound waves": false,
        "Temperature and thermal properties of matter": true,
        "Kinetic theory of gases": true,
        "Laws of thermodynamics": true,
        "Heat transfer": true,
        "Electrostatics": true,
        "Current electricity": true,
        "Magnetism": false,
        "Electromagnetic induction and alternating current": false,
        "Geometrical optics and optical instruments": true,
        "Wave optics": true,
        "Experimental physics": false,
        "Photoelectric effect and matter waves": false,
        "Atomic structure": false,
        "Nuclear physics and radioactivity": false,
        "Semiconductos and communication system": false,
        "Trignometric Ratios, Functions & Identities": true,
        "Equation and Inequation": true,
        "Quadratic Equations": true,
        "Sequences and Series (Progressions)": true,
        "Trigonometric Equations": true,
        "Binomial Theorem": true,
        "Permutation and Combination": true,
        "Probability": true,
        "Point and Straight Line": true,
        "Circle": true,
        "Parabola": true,
        "Ellipse": true,
        "Hyperbola": true,
        "Complex Numbers": false,
        "Properties of Triangle": false,
        "Functions": true,
        "Inverse Trignometric Functions": false,
        "Limits": true,
        "Continuity and Differentiability": true,
        "Method of Differentiation": true,
        "Application of Derivatives": false,
        "Indefinite Integration": false,
        "Definite Integration": false,
        "Area Under Curves": false,
        "Differential Equation": false,
        "Matrices and Determinants": false,
        "Vector": false,
        "Three Dimensional Geometrical System": false,
        "Set Theory and Relations": false,
        "Mathematical Induction": false,
        "Statistics": false,
        "Mathematical Reasoning": false,
        "Chemical bonding and molecular structure": true,
        "Hydrogen and its compounds": false,
        "S-block elements (Alkali and alkaline earth metals) and Hydrogen": false,
        "Alkaline earth metals": false,
        "P Block elements": false,
        "Carbon family": false,
        "Nitrogen family": false,
        "Oxygen family": false,
        "Group 17 (Halogens)": false,
        "Group 18 (noble gases)": false,
        "Coordination Compounds": false,
        "D Block elements": false,
        "F block elements": false,
        "General principles and processes of isolation of metals (Metallurgy)": false,
        "Qualitative inorganic analysis": false,
        "Classification and nomenclature of organic compounds": true,
        "General Organic Chemistry": true,
        "Isomerism of organic compounds": true,
        "Reaction Mechanism": true,
        "Alkanes, Alkenes and Alkynes (Hydrocarbons)": true,
        "Aromatic hydrocarbons": true,
        "Alkyl and aryl halides or halogen derivatives": true,
        "Alcohols": false,
        "Phenols": false,
        "Ethers": false,
        "Carbonyl compounds (Aldehydes and ketones)": false,
        "Carboxylic acid and its derivatives": false,
        "Nitrogen derivatives": false,
        "Biomolecules": false,
        "Synthetic and natural polymers": false,
        "Theoretical and environmental chemistry": false,
        "Chemistry in everyday life": false,
        "Basic concepts of chemistry (Mole concept)": true,
        "Gaseous state": true,
        "Volumetric analysis": true,
        "Thermodynamics and Thermochemistry": true,
        "Chemical equilibrium": true,
        "Ionic equilibrium": true,
        "Solid state": false,
        "Chemical kinetics": true,
        "Radioactivity": true,
        "Nuclear Chemistry": true,
        "Electrochemistry": true,
        "Liquid solutions": false,
        "Surface chemistry": false,
        "Classification of elements and periodicity in properties": true
    }
}
