import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AuthContext from './context/authContext/authContext';
import AuthState from './context/authContext/AuthState';
import './flickity.css';
/* import QuestionSave from "./questionSave";
import TheorySave from "./theorySave"; */
const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './components/pages/Home'));
const AboutUs = React.lazy(() => import(/* webpackChunkName: "AboutUs" */ './components/pages/Aboutus/AboutUs'));
const PrivateRoute = React.lazy(() =>
    import(/* webpackChunkName: "PrivateRoute" */ './components/routing/PrivateRoute')
);
const LandingRoute = React.lazy(() =>
    import(/* webpackChunkName: "LandingRoute" */ './components/routing/LandingRoute')
);

const QRMCQ = React.lazy(() =>
    import(/* webpackChunkName: "QRMCQ" */ './components/StudentModule/BacklogRemoverTool/QRMCQ')
);
const LMMCQ = React.lazy(() =>
    import(/* webpackChunkName: "LMMCQ" */ './components/StudentModule/LastMinutePreprationTool/LMMCQ')
);
const QMMCQ = React.lazy(() =>
    import(/* webpackChunkName: "QMMCQ" */ './components/StudentModule/LastMinutePreprationTool/QMMCQ')
);
const PTMCQ = React.lazy(() => import(/* webpackChunkName: "PTMCQ" */ './components/StudentModule/PracticeTool/PTMCQ'));
const TCQ = React.lazy(() => import(/* webpackChunkName: "TCQ" */ './components/TCQ'));
const StudentDashboard = React.lazy(() =>
    import(/* webpackChunkName: "StudentDashboard" */ './components/StudentModule/StudentDashboard/StudentDashboard')
);
const LastMinutePreprationTool = React.lazy(() =>
    import(
        /* webpackChunkName: "LastMinutePreprationTool" */ './components/StudentModule/LastMinutePreprationTool/LastMinutePreprationTool'
    )
);
const QuickModeFormulaSheet = React.lazy(() =>
    import(
        /* webpackChunkName: "QuickModeFormulaSheet" */ './components/StudentModule/LastMinutePreprationTool/QuickModeFormulaSheet'
    )
);
const AttackMode = React.lazy(() =>
    import(/* webpackChunkName: "AttackMode" */ './components/StudentModule/LastMinutePreprationTool/AttackMode')
);
const PrTMCQ = React.lazy(() => import(/* webpackChunkName: "PrTMCQ" */ './components/StudentModule/PromoTest/PrTMCQ'));

const FormulaSheetDisplay = React.lazy(() =>
    import(
        /* webpackChunkName: "FormulaSheetDisplay" */ './components/StudentModule/FormulaCreator/FormulaSheetDisplay'
    )
);
const FormulaView = React.lazy(() =>
    import(/* webpackChunkName: "FormulaView" */ './components/StudentModule/FormulaCreator/formulaview')
);
const BacklogRemoverTool = React.lazy(() =>
    import(
        /* webpackChunkName: "BacklogRemoverTool" */ './components/StudentModule/BacklogRemoverTool/BacklogRemoverTool'
    )
);
const PracticeTool = React.lazy(() =>
    import(/* webpackChunkName: "PracticeTool" */ './components/StudentModule/PracticeTool/PracticeTool')
);
const BacklogMaterial = React.lazy(() =>
    import(/* webpackChunkName: "BacklogMaterial" */ './components/StudentModule/BacklogRemoverTool/BacklogMaterial')
);
const Sidebar = React.lazy(() => import(/* webpackChunkName: "Sidebar" */ './components/StudentModule/Sidebar'));
const VideoQues = React.lazy(() => import(/* webpackChunkName: "VideoQues" */ './components/Admin/videoQues'));
const TheoryPreview = React.lazy(() => import(/* webpackChunkName: "TheoryPreview" */ './components/theoryPreview'));
const BookmarkComponent = React.lazy(() => import(/* webpackChunkName: "BookmarkComponent" */ './components/Bookmark'));
const Purchase = React.lazy(() => import(/* webpackChunkName: "Purchase" */ './components/Purchase/Purchase'));
const Donation = React.lazy(() => import(/* webpackChunkName: "Donation" */ './components/Donation/Donation'));
const MathProgram = React.lazy(() =>
    import(/* webpackChunkName: "MathProgram" */ './components/Donation/MathProgram/MathProgram')
);
const GenericPayment = React.lazy(() =>
    import(/* webpackChunkName: "MathProgram" */ './components/Donation/GenericPayment/GenericPayment')
);
const ChemistryNightClass = React.lazy(() =>
    import(/* webpackChunkName: "MathProgram" */ './components/Donation/ChemistryNightClass/ChemistryNightClass')
);
const AmSir = React.lazy(() => import(/* webpackChunkName: "AmSir" */ './components/Donation/AmSir/AmSir'));
const PlanPurchase = React.lazy(() =>
    import(/* webpackChunkName: "AmSir" */ './components/Donation/PlanPurchase/PlanPurchase')
);
const ClassEleventhBacklog = React.lazy(() =>
    import(/* webpackChunkName: "AmSir" */ './components/Donation/ClassEleventhBacklog/ClassEleventhBacklog')
);
const Success = React.lazy(() => import(/* webpackChunkName: "Success" */ './components/Purchase/Success'));
const Privacy = React.lazy(() => import(/* webpackChunkName: "Privacy" */ './components/pages/Privacy'));
const FAQ = React.lazy(() => import(/* webpackChunkName: "FAQ" */ './components/pages/FAQ'));
const Terms = React.lazy(() => import(/* webpackChunkName: "Terms" */ './components/pages/Terms'));
const Disclaimer = React.lazy(() => import(/* webpackChunkName: "Disclaimer" */ './components/pages/Disclaimer'));
const ContactUs = React.lazy(() => import(/* webpackChunkName: "ContactUs" */ './components/pages/ContactUs'));
const FormulaIntiatorTool = React.lazy(() =>
    import(/* webpackChunkName: "FormulaIntiatorTool" */ './components/StudentModule/FormulaCreator/FormulaIntiatorToo')
);
const FormulaPreview = React.lazy(() => import(/* webpackChunkName: "FormulaPreview" */ './components/formulaPreview'));
const ConceptBasket = React.lazy(() =>
    import(/* webpackChunkName: "ConceptBasket" */ './components/StudentModule/BacklogRemoverTool/conceptBasket')
);
const SelfStudy = React.lazy(() =>
    import(/* webpackChunkName: "SelfStudy" */ './components/StudentModule/selfStudy/selfStudy')
);
const Playlist = React.lazy(() =>
    import(/* webpackChunkName: "Playlist" */ './components/StudentModule/PlaylistTool/PlaylistTool')
);
const Payment = React.lazy(() => import(/* webpackChunkName: "Payment" */ './components/payment'));

const ViewQuestionId = React.lazy(() => import(/* webpackChunkName: "ViewQuestionId" */ './components/ViewQuestionId'));
const livestreamHome = React.lazy(() => import(/* webpackChunkName: "LiveStreamHome" */ './components/zoom/home'));
const livestreamRecordings = React.lazy(() =>
    import(/* webpackChunkName: "LiveStreamRecording" */ './components/zoom/livestream/recordings')
);
const Livestream = React.lazy(() =>
    import(/* webpackChunkName: "LiveStream" */ './components/zoom/livestream/screen1')
);
const LivestreamPurchased = React.lazy(() =>
    import(/* webpackChunkName: "Screen2" */ './components/zoom/livestream/screen2')
);
const LivestreamMeetings = React.lazy(() =>
    import(/* webpackChunkName: "Meetings" */ './components/zoom/livestream/meetings')
);
const FeedbackList = React.lazy(() =>
    import(/* webpackChunkName: "Meetings" */ './components/StudentModule/ReuseableComponent/FeedbackList')
);

const FeedbackListSecret = React.lazy(() =>
    import(/* webpackChunkName: "Meetings" */ './components/StudentModule/ReuseableComponent/FeedbackListSecret')
);

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

function App() {
    return (
        <AuthState>
            <CoverApp />
        </AuthState>
    );
}

function CoverApp() {
    const [loadinggg, setLoadinggg] = useState(true);
    const { loadSubData, loadTheoryCheck, subjectData, loadTheoryData } = useContext(AuthContext);

    useEffect(() => {
        const temp = async () => {
            await loadSubData();
            setLoadinggg(false);
        };
        loadTheoryCheck();
        loadTheoryData();
        temp();
    }, []);

    //  console.log("=-----====== ", subjectData);

    return (
        <div className="app">
            {!loadinggg ? (
                <Suspense
                    fallback={
                        <div class="home">
                            <div class="containr">
                                <div class="come">A</div>
                                <div class="come">C</div>
                                <div class="come">A</div>
                                <div class="come">D</div>
                                <div class="come">Z</div>
                                <div class="come">A</div>
                            </div>
                        </div>
                    }
                >
                    <Router>
                        <Route
                            exact
                            path="/student/formula-sheet-tool/"
                            render={(props) => <Sidebar {...props} isFormulaCreator={true} />}
                        />
                        <Route
                            exact
                            path="/student/formula-sheet-tool/detail"
                            render={(props) => <Sidebar {...props} isFormulaCreator={true} />}
                        />
                        <Route
                            exact
                            path="/student/backlog-remover-tool/"
                            render={(props) => <Sidebar {...props} isBacklogRemover={true} />}
                        />
                        <Route
                            exact
                            path="/student/backlog-remover-tool/material"
                            render={(props) => <Sidebar {...props} isBacklogRemover={true} />}
                        />
                        <Route
                            exact
                            path="/student/practice-tool/"
                            render={(props) => <Sidebar {...props} isPracticeTool={true} />}
                        />
                        <Route
                            exact
                            path="/student/revision-tool/"
                            render={(props) => <Sidebar {...props} isLastMinute={true} />}
                        />
                        <Route
                            exact
                            path="/student/revision-tool/quickmode"
                            render={(props) => <Sidebar {...props} isLastMinute={true} />}
                        />
                        <Route
                            exact
                            path="/student/revision-tool/attackmode"
                            render={(props) => <Sidebar {...props} isLastMinute={true} />}
                        />
                        <Route
                            exact
                            path="/student/self-study-tool/"
                            render={(props) => <Sidebar {...props} isSelfStudy={true} />}
                        />
                        <Route
                            exact
                            path="/student/playlist-tool"
                            render={(props) => <Sidebar {...props} isPlaylist={true} />}
                        />
                        <Switch>
                            <Route exact path="/about" component={AboutUs} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route exact path="/faq" component={FAQ} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/contact" component={ContactUs} />
                            <Route exact path="/disclaimer" component={Disclaimer} />
                            <Route exact path="/feedbacklist" component={FeedbackList} />
                            <Route exact path="/secret" component={FeedbackListSecret} />
                            <PrivateRoute exact path="/promo/:chapter_id" component={PrTMCQ} />
                            {/* <PrivateRoute exact path="/" component={Home} /> */}
                            <LandingRoute exact path="/" component={Home} />
                            {/* <Route exact path="/" component={Landing} /> */}

                            {/* <Route
                            exact
                            path="/theory/save"
                            component={TheorySave}
                        /> */}

                            {/* <Route
                            exact
                            path="/question/save"
                            component={QuestionSave}
                        /> */}

                            <PrivateRoute exact path="/preview/theory/:theory_id" component={TheoryPreview} />

                            <PrivateRoute exact path="/purchase" component={Purchase} />

                            <Route exact path="/payment/scholarshipcode/anshulsirbatch" component={AmSir} />

                            <Route exact path="/payment/scholarshipcode/generic/:coupon" component={GenericPayment} />

                            <Route
                                exact
                                path="/payment/chemistry-assistance-programme"
                                component={ChemistryNightClass}
                            />

                            <Route exact path="/payment/scholarshipcode/:coupon" component={Donation} />

                            <Route exact path="/payment/anshulsirbatch/scholarshipcode" component={AmSir} />

                            <Route exact path="/payment/class-11th-backlog" component={ClassEleventhBacklog} />

                            <Route exact path="/payment/plan" component={PlanPurchase} />

                            <Route exact path="/payment/maths-assistance-programme" component={MathProgram} />

                            <PrivateRoute exact path="/success" component={Success} />

                            <PrivateRoute exact path="/preview/formula/:formula_id" component={FormulaPreview} />

                            <PrivateRoute exact path="/bookmarks" component={BookmarkComponent} />

                            <PrivateRoute exact path="/payment" component={Payment} />

                            <Route exact path="/admin/video" component={VideoQues} />
                            <PrivateRoute exact path="/student/dashboard" component={StudentDashboard} />
                            <PrivateRoute exact path="/student/self-study-tool" component={SelfStudy} />
                            <PrivateRoute
                                exact
                                path="/student/revision-tool/quickmode"
                                component={QuickModeFormulaSheet}
                            />
                            <PrivateRoute exact path="/student/revision-tool/attackmode" component={AttackMode} />
                            <PrivateRoute exact path="/student/revision-tool" component={LastMinutePreprationTool} />
                            <PrivateRoute exact path="/lmmcq" component={LMMCQ} />
                            <PrivateRoute exact path="/qmmcq" component={QMMCQ} />
                            <PrivateRoute exact path="/ptmcq" component={PTMCQ} />
                            <PrivateRoute exact path="/lastminute/:id" component={LMMCQ} />
                            <PrivateRoute exact path="/qrmcq" component={QRMCQ} />
                            <PrivateRoute exact path="/tcq" component={TCQ} />
                            <PrivateRoute exact path="/student/backlog-remover-tool" component={BacklogRemoverTool} />
                            <PrivateRoute exact path="/student/practice-tool" component={PracticeTool} />
                            <PrivateRoute exact path="/student/playlist-tool" component={Playlist} />
                            <PrivateRoute
                                exact
                                path="/student/backlog-remover-tool/material"
                                component={BacklogMaterial}
                            />
                            <PrivateRoute exact path="/student/fc/01" component={FormulaSheetDisplay} />
                            <PrivateRoute exact path="/student/formula-sheet-tool" component={FormulaIntiatorTool} />
                            <PrivateRoute exact path="/basket/:concept/:sub_concept" component={ConceptBasket} />
                            <PrivateRoute exact path="/student/formula-sheet-tool/detail" component={FormulaView} />
                            <PrivateRoute exact path="/viewQuestion/:id" component={ViewQuestionId} />
                            <PrivateRoute exact path="/livestream/home" component={livestreamHome} />
                            <PrivateRoute exact path="/livestream/recordings" component={livestreamRecordings} />
                            <PrivateRoute exact path="/livestream" component={Livestream} />
                            <PrivateRoute exact path="/livestream/purchased" component={LivestreamPurchased} />
                            <PrivateRoute exact path="/livestream/meetings" component={LivestreamMeetings} />
                        </Switch>
                    </Router>
                </Suspense>
            ) : (
                // "loading"
                <div class="home">
                    <div class="container">
                        <div class="come">A</div>
                        <div class="come">C</div>
                        <div class="come">A</div>
                        <div class="come">D</div>
                        <div class="come">Z</div>
                        <div class="come">A</div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default App;
