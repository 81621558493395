export default {
    "Chemical bonding and molecular structure": {
        "Cause of chemical combination": [
            "Limitations of lewis thoery"
        ],
        "Lewis dot formula": [
            "Writing Lewis structure and examples"
        ],
        "Chemical bonds and their type": [
            "Definition and different type of chemical bonds"
        ],
        "Ionic or electrovalent bonds": [
            "Ionic Bond definition",
            "Favourable factors for cation & anion formation",
            "Born haber cycle"
        ],
        "Fajan's rule": [
            "definition ,Polarizability of anion  and cation "
        ],
        "Application of Fajan's rule": [
            "Covalent and ionic character",
            "Melting point of ionic compounds",
            "Solubility of compounds of p and d block",
            "Acidic nature of oxides"
        ],
        "Water solubility of compounds of S block": [
            "Condition for water solubility",
            "Important orders water solubility"
        ],
        "Covlent bond": [
            "Definition and different types of covalent bonds"
        ],
        "Valence bond theory-1": [
            "Bonding by overlapping of atomic orbitals(VBT)",
            "Sigma and pi bond and their relative strength",
            "Demerits of atomic orbital approach",
            "Hybridization and Mathematical relationship with bond angle",
            "Atomic orbital participation in different hybrid orbitals and Electronic geometry associated",
            "Method to determine hybridization",
            "Valence shell electron pair repulsion theory",
            "Effect of lone pair in different geometries",
            "Bent's rule",
            "Variation of bond angle"
        ],
        "Back bonding": [
            "Definition and conditions for back bonding",
            "Applications of back bonding"
        ],
        "Bridge bonding": [
            "Bonding of diboranes",
            "Bridge bonding in other compounds"
        ],
        "Molecular orbital (MO)": [
            "Types of molecular orbitals and LCAO (Linear combination of atomic orbitals) method",
            "Rules for combination of atomic orbitals",
            "Combination of atomic orbitals to give MO by LCAO and Energy order of Molecular orbitals",
            "Calculation of bond order, variation of bond length and bond energy",
            "MO configuration of different molecules"
        ],
        "Polarity": [
            "Polarity of bonds",
            "Dipole moment",
            "Variation of dipole moment",
            "Comparison of dipole in important examples",
            "Dipole moment in geometrical isomers"
        ],
        "Metallic bond": [
            "Definition, Factors affecting strength of metallic bonds",
            "Effect on conductivity and metallic lustre"
        ],
        "Intermolecular forces": [
            "Dipole dipole attractions and Dipole induced dipole attraction",
            "Van Der Waals forces and Factors affecting strength of van der Waals attractions"
        ],
        "Hydrogen bonding": [
            "Definition, types of H bonding",
            "Strength of H bond,condition for intramolecular H bonding",
            "Applications of H bonding on B.P, M.P and water solubility",
            "Effect of H bond on acidic strength",
            "Effect of H bond on basic strength",
            "Effect of H bond on other properties"
        ]
    },
    "Hydrogen and its compounds": {
        "Introduction": [
            "Electronic configuration, Occurence and isotopes of hydrogen",
            "Resemblance with alkali metals and differences",
            "Resemblance with halogens and differences"
        ],
        "Types of hydrogen": [
            "Atomic hydrogen, nascent hydrogen",
            "Ortho and para hydrogen, Comparison of properties"
        ],
        "Preparation and physical properties of hydrogen": [
            "Laboratory preparation of hydrogen",
            "Commercial preparation of hydrogen and its physical properties"
        ],
        "Chemical properties": [
            "Reaction with metals and non-metals",
            "Reducing properties and Uses of hydrogen"
        ],
        "Hydrides": [
            "Ionic hydrides and their properties",
            "Covalent hydrides and their properties",
            "Metallic or non stoichiometric hydrides and Polymeric hydrides"
        ],
        "Water": [
            "Physical properties of water and heavy water"
        ],
        "Chemical properties of water": [
            "Reaction with metals and non-metals",
            "Reaction with compounds"
        ],
        "Hard water": [
            "Hardness of water and causes",
            "Removal of temporary hardness",
            "Removal of permanent hardness"
        ],
        "Heavy water": [
            "Preparation and properties of heavy water and uses of heavy water"
        ],
        "Hydrogen peroxide": [
            "Preparation",
            "Physical properties and structure"
        ],
        "Chemical properties of hydrogen peroxie": [
            "Acidic nature and reactions as an acid",
            "As an oxidizing agent, bleaching action",
            "As a reducing agent",
            "Detection of H₂O₂"
        ]
    },
    "S-block elements (Alkali and alkaline earth metals) and Hydrogen": {
        "Alkali metals": [
            "General electronic configuration and group members"
        ],
        "General properties-1": [
            "General physical properties",
            "Bond type"
        ],
        "General properties-2": [
            "Oxidation state, Photoelectric emission and Hydration energies",
            "Reducing ability in gaseous and aqueous state",
            "Ionic mobility and flame colours"
        ],
        "Chemical chemical properties-1": [
            "Action of air and water"
        ],
        "Chemical chemical properties-2": [
            "Peroxides and superoxides of alkali metals"
        ],
        "Chemical chemical properties-3": [
            "Ionic hydrides and carbides"
        ],
        "Chemical chemical properties-4": [
            "Sulphides and polysulphides"
        ],
        "Chemical chemical properties-5": [
            "Solution in liquid ammonia"
        ],
        "Chemical chemical properties-6": [
            "Abnormalities of lithium"
        ],
        "Salts of alkali metals": [
            "Carbonates,Bicarbonates and nitrates",
            "Halides of alkali metals"
        ],
        "Preparation of NaOH": [
            "Preparation of NaOH"
        ],
        "Properties of NaOH": [
            "Physical properties and action of air",
            "Reaction with protic acids, acidic and amphoteric oxides",
            "Reaction with metals",
            "Reaction with non metals",
            "Reaction with ammonium salts and with CO",
            "Reaction with salts"
        ],
        "Preparations of Na₂CO₃": [
            "Le Blanc process",
            "Solvay’s process"
        ],
        "Properties": [
            "Physical properties and formation of bicarbonate",
            "Action of protic acids and acidic oxides",
            "Reaction with salts",
            "Reaction with sulphur and sulphur dioxide, uses of sodium carbonate"
        ],
        "Preparations of KI": [
            "Laboratory and other preparations"
        ],
        "Properties of KI": [
            "Physical properties",
            "Chemical properties"
        ]
    },
    "Alkaline earth metals": {
        "Alkaline earth metals": [
            "General electronic configuration and group members"
        ],
        "General properties": [
            "General physical properties",
            "Flame colours",
            "General chemical properties"
        ],
        "Compounds of alkaline earth metals": [
            "Hydrides, nitrides, oxides and halides",
            "Hydroxides and oxo salts"
        ],
        "General trends": [
            "Solubility and thermal stability"
        ],
        "Abnormalities of beryllium": [
            "Abnormal physical and chemical properties",
            "Diagonal relationship with Al"
        ],
        "Important compounds": [
            "Comopunds of magnesium and calcium"
        ]
    },
    "P Block elements": {
        "Boron family": [
            "General electronic configuration and group members",
            "Metallic and non metallic nature"
        ],
        "General properties": [
            "Allotropy",
            "General physical properties",
            "General chemical properties",
            "Compounds of gp 13 elements",
            "Abnormalities of boron"
        ],
        "Boron": [
            "Ores and extraction of boron",
            "Properties of boron"
        ],
        "Boron trioxide": [
            "Preparation of boron trioxide",
            "Physical and chemical properties"
        ],
        "Preparation and properties of boric acid": [
            "Preparation of boric acid",
            "Properties of boric acid"
        ],
        "Preparation and properties of borax": [
            "Preparation of borax",
            "Properties of borax"
        ],
        "Preparation and properties of boron trihalides": [
            "Preparation",
            "Properties"
        ],
        "Boron hydrides": [
            "Classification in Nido and arachno boranes",
            "Preparation of diborane",
            "Properties of diborane"
        ],
        "Preparation and properties of AlCl₃": [
            "Preparation of AlCl₃",
            "Properties of AlCl₃"
        ]
    },
    "Carbon family": {
        "Carbon family": [
            "General electronic configuration",
            "Group members, metallic and non metallic nature"
        ],
        "General properties": [
            "General physical properties",
            "Allotropy of carbon and tin",
            "General chemical properties"
        ],
        "Compounds of group- 14": [
            "Hydrides",
            "Halides and Oxides"
        ],
        "Preparation and properties of carbon monoxide": [
            "Preparation of carbon monoxide",
            "Properties of carbon monoxide"
        ],
        "Preparation and properties of CO₂": [
            "Preparation of CO₂",
            "Properties of CO₂"
        ],
        "Carbides": [
            "Type of Carbides",
            "Chemical reactions of Carbides"
        ],
        "Preparation and properties of silica": [
            "Preparation of silica",
            "Properties of silica"
        ],
        "Silicones and silicates": [
            "Preparation and properties of silicone",
            "Type of silicates"
        ],
        "Preparation and properties of SnCI₂": [
            "Preparation of SnCI₂",
            "Properties of SnCI₂"
        ],
        "Preparation and properties of stannic chloride": [
            "Preparation of stannic chloride",
            "Properties of stannic chloride"
        ],
        "Preparation and properties of lead dioxide": [
            "Preparation of lead dioxide",
            "Properties of lead dioxide"
        ]
    },
    "Nitrogen family": {
        "Group members": [
            "General electronic configuration, Metallic and non metallic nature of group members"
        ],
        "General properties": [
            "General physical properties",
            "Allotropy",
            "General chemical properties"
        ],
        "Compounds of group": [
            "Hydrides",
            "Halides",
            "Oxides and oxyacids"
        ],
        "Preparation and properties of nitrogen": [
            "Preparation of nitrogen",
            "Properties of nitrogen"
        ],
        "Preparation and properties of ammonia": [
            "Preparation of ammonia",
            "Physical properties of ammonia",
            "Chemical properties of ammonia",
            "Uses of ammonia",
            "Qualitative estimation of ammonia"
        ],
        "Oxides of nitrigen": [
            "Preparation and properties of nitrous oxide",
            "Preparation and properties of nitric oxide",
            "Preparation and properties of nitrogen dioxide",
            "Preparation and properties of dinitrogen pentoxide"
        ],
        "Oxyacids of nitrogen": [
            "Preparation and properties of nitrous acid",
            "Preparation of nitric acid",
            "Physical properties of nitric acid and reactions as an acid",
            "Nitric acid as an oxidizing agent with compounds",
            "Action of nitric acid on metals, non metals and metalloids"
        ],
        "Preparation and properties of phosphorus": [
            "Allotropy of phosphorus",
            "Preparation of phosphorus",
            "Properties of phosphorus"
        ],
        "Preparation and properties of phosphine": [
            "Preparation of phosphine",
            "Properties of phosphine"
        ],
        "Oxides of phosphorus": [
            "Preparation and properties of phosphorus trioxides",
            "Preparation and properties of phosphorus pentoxide"
        ],
        "Oxyacids": [
            "Structure of oxyacids",
            "Preparation and properties of phosphorus acid",
            "Preparation of phosphoric acid",
            "Properties of phosphoric acid"
        ],
        "Fertilizers": [
            "Types and characteristics of fertilizers"
        ]
    },
    "Oxygen family": {
        "Group members (Group 16)": [
            "General electronic configuration",
            "Metallic and non metallic nature"
        ],
        "General properties": [
            "General physical properties",
            "Allotropy",
            "General chemical properties"
        ],
        "Compounds of oxygen family": [
            "Hydrides",
            "Halides",
            "Oxides and oxyacids"
        ],
        "Preparation and properties of oxygen": [
            "Preparation of oxygen",
            "Properties of oxygen"
        ],
        "Preparation and properties of ozone": [
            "Preparation of ozone and its physical properties",
            "Ozone as an oxidizing agent with compounds",
            "Ozone as an oxidizing agent with metals and non metals"
        ],
        "Preparation and properties of compounds": [
            "Preparations of H₂S and its physical properties",
            "Chemical reactions as an acid",
            "Chemical reactions as a precipitating agent",
            "Chemical reactions as a reducing agent"
        ],
        "Preparation and properties of sulphur dioxide": [
            "Preparation of sulphur dioxide",
            "Physical and chemical properties of sulphur dioxide"
        ],
        "Properties and prparation of sulphur trioxide": [
            "Preparation of sulphur trioxide",
            "Physical and chemical properties"
        ],
        "Preparation and properties sulphuric acid": [
            "Preparation of sulphuric acid",
            "Physical properties of sulphuric acid",
            "Reactions of sulphuric acid as an acid",
            "Reactions of sulphuric acid with metals and non metals",
            "Reactions of sulphuric acid as an oxidizing agent"
        ],
        "Preparation and properties of sodium thiosulphate": [
            "Preparation of sodium thiosulphate",
            "Physical properties of sodium thiosulphate",
            "Chemical properties of sodium thiosulphate"
        ]
    },
    "Group 17 (Halogens)": {
        "Preparation and properties of sodium thiosulphate": [
            "Group members and General electronic configuration"
        ],
        "Properties of group": [
            "Physical properties",
            "Chemical properties"
        ],
        "Preparation and propeties of flourine": [
            "Preparation of fluorine",
            "Properties of fluorine"
        ],
        "Preparation and propeties of chlorine": [
            "Preparation of chlorine",
            "Properties of chlorine"
        ],
        "Preparation and properties of bromine": [
            "Preparation of bromine",
            "Properties of bromine"
        ],
        "Prepration and properties of iodine": [
            "Preparation of iodine",
            "Properties of iodine"
        ],
        "Preparation and properties of halocids": [
            "Preparation of haloacids",
            "Properties of haloacids"
        ],
        "Oxides of CI and Br": [
            "Mono oxides of chlorine and bromine",
            "Chlorine dioxide",
            "Chlorine heptoxide",
            "Dichloro hexo oxide"
        ],
        "Oxyacids of halogens": [
            "Preparation and properties of hypohalous acids",
            "Preparation and properties of halic acids",
            "Preparation and properties of perhalic acids"
        ],
        "Bleaching powder": [
            "Preparation of bleaching powder",
            "Properties of bleaching Powder"
        ],
        "Interhalogen compounds": [
            "Preparation of interhalogen compounds",
            "Properties of interhalogen compounds"
        ],
        "Pseudohalides": [
            "List of pseudohalides",
            "Similarities with halogens",
            "Preparation and properties of cyanogen"
        ],
        "Polyhalides": [
            "Preparation",
            "Properties"
        ]
    },
    "Group 18 (noble gases)": {
        "Group members": [
            "General electronic configuration",
            "Physical properties",
            "Chemical properties"
        ],
        "Xenon diflouride": [
            "Preparation",
            "Properties"
        ],
        "Xenon tetraflouride": [
            "Preparation",
            "Properties"
        ],
        "Xenon hexaflouride": [
            "Preparation",
            "Properties"
        ]
    },
    "Coordination Compounds": {
        "Addition compound": [
            "Definition and Double salts"
        ],
        "Coordination or complex compounds": [
            "Definition of Complex compounds, Nature of central metal atom/ion",
            "Definition and Denticity of ligands",
            "Coordination and ionization sphere",
            "Primary/ionic and secondary valency(coordination number)"
        ],
        "Classification": [
            "Classification of complexes",
            "Classification of ligands based on denticity",
            "Classification of ligands based on bonding"
        ],
        "Metal carbonyls": [
            "Bonding in metal carbonyls ( synergic bonding )",
            "Effect of back bonding on different bonds",
            "Classification of carbonyls",
            "Important carbonyls and their structure"
        ],
        "Zeise salt": [
            "Structure",
            "Properties"
        ],
        "Effective atomic numner (EAN)": [
            "Effective atomic number and method of calculation",
            "Sidgwick EAN rule, application and limitations"
        ],
        "Nomenclature of complex compounds": [
            "Rules",
            "Examples"
        ],
        "Werner theory": [
            "Primary and Secondary valency",
            "Shape based on secondary valency and Limitations of Werner theory"
        ],
        "Structural isomerism in complexes": [
            "Ionisation isomerism and Hydrate/solvate isomerism",
            "Linkage isomerism and Ligand isomerism",
            "Coordination isomerism, Position coordination isomerism and Coordination polymerization isomerism"
        ],
        "Stereoisomerism": [
            "Tetrahedral complexes",
            "Square planar complexes",
            "Stereoisomerism in octahedral complexes with monodentate ligands",
            "Stereoisomerism in octahedral complexes with bidentate ligands"
        ],
        "Valence bond theory (VBT)": [
            "Postulates and Hybridization states",
            "Inner, outer orbital complexes and Limitations of VBT"
        ],
        "Crystal field theory": [
            "Shape of d orbitals and Postulates",
            "Weak and strong field ligands, Spectrochemical series",
            "Low and high spin complexes",
            "Crystal field splitting in octahedral complexes",
            "Pairing energy and Conditions for pairing",
            "Crystal field stabilization energy (CFSE)",
            "Calculation of CFSE in octahedral complexes",
            "Factors affecting delta",
            "Splitting in tetrahedral complexes and CFSE calculation",
            "Splitting in square planar complexes"
        ],
        "Jahn teller distortion theorem": [
            "Tetragonal distortion",
            "Application of Jahn teller distortion theorem"
        ],
        "Color analysis in ocmplexes": [
            "D-D transition",
            "Ligand to metal charge transfer (LMCT)",
            "Metal to ligand charge transfer (MLCT)",
            "Metal to metal charge transfer(MMCT)"
        ],
        "Stability of complexes": [
            "Formation constant and Factors affecting stability of complexes"
        ],
        "Application of complexes": [
            "In qualitative and quantitative analysis",
            "In metallurgy and photography"
        ]
    },
    "D Block elements": {
        "Electronic configurations of d-block elements": [
            "Series wise distribution and abnormal electronic configurations"
        ],
        "General characterictics- 1": [
            "Metallic bonding in transition metal lattices",
            "Atomic and ionic radii of transition elements",
            "Variable oxidation state and relative stability of various oxidation states",
            "Complexation and reactivity of metals",
            "Density, melting and boiling points"
        ],
        "General characterictics- 2": [
            "Ionisation energies and lattice structure",
            "Standard electrode potential",
            "Nature of oxides, color and polarisation",
            "Incompletely filled d or f subshell",
            "Magnetic properties and Catalytic properties",
            "Electrical conductivity and metallic lustre",
            "Nonstoichiometric compounds",
            "Alloy formation and Abundance"
        ],
        "Potassium dichromate": [
            "Preparation",
            "Properties"
        ],
        "Potassium permanganate": [
            "Preparation",
            "Properties"
        ]
    },
    "F block elements": {
        "Classification of f-block elements": [
            "Lanthanoid",
            "Actinoids"
        ],
        "General characteristics": [
            "Electronic configuration, Physical state and Oxidation states",
            "Magnetic properties and Complexation",
            "Atomic and ionic radii",
            "Lanthanoid contraction",
            "Basic strength of hydroxides"
        ],
        "Comparison b/w 3d and 4f block elements": [
            "Magnetic moment",
            "Oxidation state"
        ],
        "General characteristics of actinoid": [
            "Electronic configuration, Oxidation states and Electropositive character",
            "Atomic and ionic radii",
            "Magnetic behaviour",
            "Ionisation energies and Reducing action",
            "Radioactivity and Colour of ions",
            "Similarities with lanthanoids"
        ]
    },
    "General principles and processes of isolation of metals (Metallurgy)": {
        "Minerals & Ores": [
            "Definitions and Type of ores"
        ],
        "Concentration of ores by physical separation": [
            "Gravimetric separation or levigation",
            "Magnetic separation",
            "Froth floatation"
        ],
        "Concentration of ores by chemical separation": [
            "Leaching of alumina, gold ores and argentite"
        ],
        "Conversion of concentrated ore into its oxide": [
            "Calcination",
            "Roasting"
        ],
        "Reduction of oxide to crude metal": [
            "Carbon reduction",
            "Hydrogen reduction",
            "Thermite reduction/aluminothermy and Hydrometallurgy",
            "Electrolytic reduction and Thermal decomposition",
            "Electrolytic vs carbon reduction"
        ],
        "Refining or purification of crude metal": [
            "Thermal refining and Fractional distillation",
            "Liquation and Zone refining",
            "Vapour phase refining and Electro-refining"
        ],
        "Thermodynamics of reduction process": [
            "Ellingham diagram",
            "Explanation based on free energy change and curve for carbon"
        ],
        "Alloys and amalgam": [
            "Definition, classification and preparation"
        ],
        "Types of furnaces": [
            "Blast and reverberatory furnace",
            "Muffle and Electric furnace"
        ],
        "Extraction of silver": [
            "Ores and extraction of silver"
        ],
        "Extraction of gold": [
            "Ores and extraction of gold"
        ],
        "Extraction of tin": [
            "Ores of tin and concentration",
            "Carbon reduction and Electro-refining"
        ],
        "Extraction of magnesium": [
            "Ores of Mg and preparation of MgCl₂hydrated and anhydrous"
        ],
        "Extraction of aluminium": [
            "Ores of aluminium, concentration and role of cryolite",
            "Electrolytic reduction and Electro refining of Al"
        ],
        "Extraction of lead": [
            "Ores of lead, concentration",
            "Carbon reduction, Self reduction and electrolytic refining"
        ],
        "Extraction of copper": [
            "Ores of copper, concentration",
            "Smelting, Bessemerization and refining"
        ],
        "Extraction of zinc": [
            "Ores, concentration",
            "Carbon reduction and Electrorefining"
        ],
        "Extraction of iron": [
            "Ores of iron, concentration",
            "Refining and types of iron"
        ],
        "Steel formation": [
            "Types of steel, preparation, annealing and quenching"
        ]
    },
    "Qualitative inorganic analysis": {
        "Classification of acid radicals": [
            "Dilute H₂SO₄/ dil HCl group radicals and reactions involved",
            "Conc H₂SO₄ group radicals and reactions involved",
            "Independent group"
        ],
        "Carbonate and bicarbonate radicals": [
            "Action of dil H₂SO₄ carbonate/ bicarbonate and confirmatory tests"
        ],
        "Sulphite radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Thiosulphate radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Sulphide radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Nitrite radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Acetate radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Formate radical": [
            "Action of dil  H₂SO₄ or dil HCl and confirmatory tests"
        ],
        "Chloride radical": [
            "Action of conc H₂SO₄, test with precipitating agents",
            "Test with MnO₂ and conc H₂SO₄ ,chromyl chloride test"
        ],
        "Bromide radical": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n"
        ],
        "Iodide radical": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n"
        ],
        "Flourides": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n"
        ],
        "Nitrate radical": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n",
            "Brown ring test"
        ],
        "Borate radical": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n"
        ],
        "Oxalate radical": [
            "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n"
        ],
        "Sulphate radical": [
            "Confirmatory tests and reactions"
        ],
        "Phosphate radical": [
            "Confirmatory tests and reactions"
        ],
        "Heating effects": [
            "On carbonates and bicarbonates",
            "On nitrates",
            "On ammonium salts",
            "On sulphates",
            "On hydrated chlorides"
        ],
        "Flame test": [
            "Alkali and alkaline metals"
        ],
        "Borax bead test": [
            "Procedure and colors observed"
        ],
        "Charcoal cavity test": [
            "Procedure, Metals detected and limitations"
        ],
        "Cobalt nitrate test": [
            "Procedure and Cations detected"
        ],
        "Classification of basic radicals": [
            "Classification, group members and colour and composition of precipitates",
            "Explanation for group reagents"
        ],
        "Group 1": [
            "Tests of lead cation",
            "Tests of mercurous cation",
            "Tests of silver cation"
        ],
        "Group 2": [
            "Distinction between group 2A and 2B",
            "Tests of mercuric cation",
            "Tests of bismuth cation",
            "Tests of cupric cation",
            "Tests of lead cation",
            "Tests of cadmium cation",
            "Tests of arsenic cation",
            "Tests of antimony cation",
            "Tests of stannous and stannic cation"
        ],
        "Group 3": [
            "Tests of ferric ion",
            "Tests of aluminium cation",
            "Tests of chromium cation"
        ],
        "Group 4": [
            "Tests of Cobalt cation",
            "Tests of nickel cation",
            "Tests of Manganese cation",
            "Tests of zinc cation"
        ],
        "Group 5": [
            "Tests of barium, strontium and calcium cation"
        ],
        "Group 6": [
            "Tests of magnesium cation"
        ]
    }
}