export default {
    "Basic concepts of chemistry (Mole concept)": {
        "BASIC MOLE": [
            "Classification of matter and units of measurement",
            "mole definition , Avogadro's number",
            "mole - number relation",
            "Atomic mass unit , RAM ,GAM",
            "Percentage abundance of isotopes,Average atomic mass",
            "RMM  ,GMM, MOLAR MASS",
            "MASS - MOLE RELATION",
            "Density, specific gravity and specific volume",
            "VOLUME - MOLE RELATION",
            "INTERCONVERSION OF MASS , MOLE VOLUME, NUMBER",
            "SUBENTITIES"
        ],
        "BASIC STOICHIOMETRY": [
            "Empiricial formula, definition and determination",
            "Molecular formula, definition and determination",
            "Principle of Atomic Conservation , BALANCING OF REACTION",
            "Equation-based calculations",
            "Limiting reagent"
        ],
        "ADVANCED STOICHIOMETRY": [
            "Reaction yield",
            "POAC METHOD",
            "SEQUENTIAL AND PARALLEL REACTIONS",
            "MIXTURE PROBLEMS"
        ],
        "Concentration TERMS": [
            "Mole percent and mole fraction",
            "Mass percent and mass fraction",
            "Molarity ",
            "MOLALITY",
            "PPM,PPB",
            "INTERCONVERSION OF CONCENTRATION TERMS",
            "STOICHIOMETRY OF REACTIONS IN SOLUTION"
        ]
    },
    "Atomic structure": {
        "EARLY ATOMIC MODELS": [
            "CRT experiment",
            "thomson's model",
            "gold foil experiment",
            "rutherford's model"
        ],
        "Useful concept of Physics": [
            " Wave and Wave theory of light"
        ],
        "useful concept of physics": [
            "basic terms of physics"
        ],
        "DUAL NATURE OF LIGHT": [
            "Planck's Quantum theory",
            "Electromagnetic radiations"
        ],
        "BOHR'S THEORY": [
            "Bohr theory ",
            "derivation of r,v,E",
            "time period, frequency"
        ],
        "ATOMIC SPECTRUM": [
            "Emission spectrum,Absorption spectrum",
            "Hydrogen spectrum",
            "Rydberg's equation"
        ],
        "Wave- particle duality": [
            "drawback of bohr , Sommerfeld model",
            "De Broglie’s wavelength",
            "Heisenberg’s uncertainty principle"
        ],
        "Photoelectric effect": [
            "Photoelectric emission, observations and conclusions,Black body radiation,",
            "Einstein's photoelectric equation",
            "photocurrent ,stopping volatge"
        ],
        "Schrodinger equation": [
            "Electronic configuration of ions",
            "Orbital angular momentum and spin angular momentum",
            "FINDING MAX PROBABILITY DISTANCE",
            "Nodes",
            "Graphs of the radial distribution function",
            "Angular wave function",
            "Radial wave function",
            "Wave function",
            "Orbitals and shape of orbitals",
            "SHRODINGER EQUATION ",
            "Exceptional configurations",
            "Extra stable configurations",
            "EXCHANGE PAIRS AND SPIN PAIRS",
            "Electronic configuration of atoms",
            "Hund's rule",
            "Pauli's exclusion principle",
            "Aufbau rule",
            "QUANTUM NUMBERS"
        ]
    },
    "Gaseous state": {
        "Introduction": [
            "Gas pressure and atmospheric pressure"
        ],
        "Measurement of gas pressure": [
            "Barometer and manometer",
            "Pressure exerted by liquid columns"
        ],
        "Gas laws": [
            "Boyle's law",
            "Charles' law",
            "Avogadro's law"
        ],
        "Ideal gas equation": [
            "Formulation of law",
            "Gas constant",
            "The physical significance of molar gas constant"
        ],
        "Dalton's law": [
            "Partial pressure and partial volume",
            "Mole fraction",
            "Vapour density",
            "Average molar mass",
            "Measurement of gas density"
        ],
        "Kinetic theory of gases": [
            "Postulates",
            "Properties of gases",
            "The kinetic energy of gases",
            "RMS, Average and most probable speed",
            "Plots of Maxwell distribution of speeds"
        ],
        "Graham's law": [
            "Diffusion, effusion and their rates",
            "Graham's law"
        ],
        "Eudiometry": [
            "Methods of collecting gases",
            "Methods of drying gases"
        ],
        "Non-ideal gases": [
            "Limitations of the ideal gas equation",
            "Correction in volume and Intermolecular attraction",
            "Limiting condition for ideality",
            "Real isotherms"
        ],
        "Critical phenomena": [
            "Critical point",
            "Properties near-critical point",
            "Compressibility factor"
        ],
        "Van der Waals equation": [
            "Corrections in van der waals equations",
            "Van der Waals isotherm",
            "Liquification and Van der Waals equation",
            "Critical constants for Van der Waals gas"
        ],
        "Other equations of state": [
            "Virial equation of state and coefficients",
            "Conversion of van der Waals equation into virial",
            "Other equations of state"
        ]
    },
    "Volumetric analysis": {
        "Redox Reactions": [
            "Oxidation number/state",
            "Introduction and Types of redox reaction",
            "Balancing of redox reactions"
        ],
        "Equivalent mass calculation": [
            "Calculation of n factor in acid, base and salt",
            "Calculation of n factor in redox reactions",
            "Equivalent mass",
            "Law of equivalents",
            "Normality and its application"
        ],
        "Advanced stoichiometry": [
            "Acid-base titration",
            "Back titration",
            "Double titrations",
            "Titration of carbonate mixtures with using indicators",
            "Redox titration",
            "Iodometric and Iodimetric titration",
            "Volume strength of H₂O₂",
            "Percentage Labelling of Oleum",
            "Miscellaneous titrations"
        ]
    },
    "Thermodynamics and Thermochemistry": {
        "Basic terms used in thermodynamics": [
            "Thermodynamic systems and properties",
            "Thermodynamic functions, process and state"
        ],
        "Heat": [
            "Thermal equilibrium and Zeroth law of thermodynamics",
            "Heat transfer, mode of heat transfer and sign convention"
        ],
        "Work": [
            "Definition, sign convention and difference in mechanical and thermodynamic work",
            "P-V work done in reversible and inrreversible process"
        ],
        "Internal Energy": [
            "Definition and characteristics of Internal Energy",
            "Degree of freedom",
            "Heat capacity of matter",
            "Comparison between Cp and Cv"
        ],
        "Calorimetry": [
            "Bomb calorimeter and Dulong-Petit law",
            "Definition and basic derivation of Enthalpy"
        ],
        "First law of thermodynamics": [
            "Statement and its basic applications"
        ],
        "Application of First law of thermodynamics": [
            "Isothermal Process",
            "Isobaric Process",
            "Cyclic Process",
            "Adiabatic Reversible Process",
            "Adiabatic Irreversible Process",
            "Polytropic Process and its Application",
            "Area under the curve and Graph",
            "Isochoric Procses"
        ],
        "Basic law of thermochemistry": [
            "Exothermic, Endothermic process and Laplace and Lavoisier law",
            "Relationship between Enthalpy and Internal Energy"
        ],
        "Measurement of Enthalpy change": [
            "Enthaply of Chemical Reaction Or Heat of Reaction",
            "Enthalpy of Combustion",
            "Enthalpy of fusion, vapourization and sublimation",
            "Enthalpy of atomisation,Bond Energy and Bond Dissociation Energy",
            "Hess Law and Born Haber Cycle and its application",
            "Enthaply of Formation"
        ],
        "Enthalpy change involving solutions": [
            "Enthalpy of neutralization",
            "Enthalpy of Solvation and Enthalpy of Hydration"
        ],
        "Second law of Thermodynamics": [
            "Limitations of First law of thermodynamics and statement of second law of thermodynamics",
            "Heat Engine and Carnot Cycle",
            "Entropy and basic calculation of entropy",
            "Change in Entropy in physical process",
            "Entropy calculation in different thermodynamic process",
            "Change in Entropy calculation during Mixing of Gases and Phase Change",
            "Gibbs Free Energy and Helmholtz Equation"
        ],
        "Kirchhoff equation": [
            "Temperature dependence of ΔH",
            "Temperature dependence of ΔS",
            "Temperature dependence of ΔG"
        ],
        "Gibbs Free Energy": [
            "Relationship between free energy and equilibrium constant",
            "Temperature Derivative Of Gibbs Free Energy"
        ],
        "Third Law Of Thermodynamics": [
            "Statement and its basic applications"
        ]
    },
    "Chemical equilibrium": {
        "Types of chemical reaction": [
            "Reversible and Irreversible reaction"
        ],
        "Kinetics and equilibrium": [
            "Rate of chemical reaction, Introduction and condition of equilibrium"
        ],
        "Equilibrium": [
            "Active mass, Law of mass action, Reaction Quotient and Equilibrium constant"
        ],
        "Types of equilibrium": [
            "Kp, Kc, Kx and its unit",
            "Homogenous and Heterogeneous equilibrium",
            "Simultaneous equilibrium"
        ],
        "Relation between degree of dissociation and vapour density": [
            "Calculation of degree of dissociation from density measurement"
        ],
        "Le Chatelier's principle": [
            "Le chatelier's principle and Effect of addition of reactant or product at equilibrium state",
            "Effect of volume and pressure and Effect of addition of inert gas",
            "Van't Hoff Equation",
            "Effect of change in temperature and Effect of Catalyst",
            "Application in heterogeneous equilibrium"
        ],
        "Application of equilibrium constant": [
            "Relation between equilibrium constant and Gibbs free energy"
        ],
        "Calculation of equilibrium constant": [
            "Mathematical approach for equilibrium constant"
        ]
    },
    "Ionic equilibrium": {
        "Introduction of Electrolytes": [
            "Definition and Classification of electrolytes"
        ],
        "Classification of acids and bases": [
            "All theories of acids and bases"
        ],
        "Ionisation of water": [
            "Ionic product of water"
        ],
        "pH scale": [
            "Introduction of pH and pOH in aqueous solution"
        ],
        "Calculation of pH": [
            "Aqueous solution of strong acid or strong base and its combination",
            "pH calculation in case of dilution and mixing of aqueous solution",
            "Ostwald dilution law for weak acid and weak base",
            "Common ion effect",
            "Polyprotic acid and base"
        ],
        "Salt hydrolysis": [
            "Introduction and salt of weak acid and strong base",
            "Salt of strong acid and weak base",
            "Salt of weak acid and weak base",
            "Amphiprotic salt"
        ],
        "Buffer solution": [
            "Introduction of buffer solution and acidic buffer",
            "Basic buffer",
            "Buffer action and buffer capacity"
        ],
        "Indicators": [
            "Acidic and basic indicators and its function"
        ],
        "Acid-base titrations": [
            "Titration curves"
        ],
        "Solubility product": [
            "Introduction and Calculation of Ksp",
            "Effect of adding common ion"
        ],
        "Precipitation": [
            "Simple precipitation and condition of precipitation",
            "Application in qualitative analysis"
        ],
        "Complex Equilibrium": [
            "Formation of complex ion and formation constant"
        ],
        "Miscellaneous Problems": [
            "Solubility Vs Buffer and Solubility Vs Hydrolysis"
        ]
    },
    "Solid state": {
        "Solid state": [
            "Definition and Properties of solids",
            "Cohesion and cohesive energy",
            "Anisotropy and Types of solids"
        ],
        "Crystals and crystallography": [
            "Geometric crystallography",
            "Bravais lattice",
            "Coordination number",
            "Types of unit cell",
            "Interfacial angles"
        ],
        "Symmetry": [
            "Symmetry operations and Elements of symmetry"
        ],
        "Crystal systems": [
            "2D Lattice and 3D Cubic systems",
            "Simple cubic unit cell",
            "Body-centred unit cell",
            "Face-centred unit cell",
            "Packing efficiency and Ideal density of crystal",
            "Polymorphism",
            "Tetrahedral and Octahedral voids"
        ],
        "Structure of ionic solids": [
            "Rock salt structure",
            "CsCl structure",
            "ZnS structure",
            "Antifluorite structure",
            "Rutile structures and Spinels"
        ],
        "Radius ratio rule": [
            "Calculations of radius ratio",
            "Relation of radius ratio with coordination number",
            "Significance of coordination number"
        ],
        "Miller indices": [
            "Determination of Miller indices",
            "Lattice planes in a cubic system and Interplanar distances"
        ],
        "Crystal defects": [
            "Point defects",
            "Schottky defect and Extrinsic Schottky defect",
            "Frenkel defect and Atom exchange defect",
            "Interstitial defect and F-centres",
            "Defects and non-stoichiometry"
        ],
        "Properties of crystals": [
            "Electrical, magnetic and dielectric properties"
        ]
    },
    "Chemical kinetics": {
        "Rate of reaction": [
            "Differential and instantaneous rate",
            "Rate law and order of chemical reaction"
        ],
        "Integrated rate law": [
            "Zero order reaction",
            "First order reaction",
            "Molecularity and Pseudo first-order reaction"
        ],
        "Analysis of some important first order reaction": [
            "First order kinetics in terms of pressure and volume",
            "First order kinetics in terms of titration and optical rotation"
        ],
        "Integrated rate law of higher order": [
            "Second order and Third order reactions",
            "nth order reaction and ostwald's equation",
            "Graphs for different order chemical reaction"
        ],
        "Complex kinetics (First order reaction)": [
            "Parallel kinetics",
            "Sequential kinetics",
            "Reversible kinetics"
        ],
        "Effect of temperature on rate of reaction": [
            "Arrhenius concept of chemical reactions",
            "Arrhenius equation and temperature coefficient",
            "Effect of Catalyst"
        ],
        "Mechanism of chemical reaction": [
            "Elementary and Non-elementary reaction and its order and molecularity"
        ]
    },
    "Radioactivity": {
        "Radioactivity": [
            "Radioactivity and its discovery",
            "Theory of Radioactive Disintegration"
        ],
        "Radioactivity Displacement": [
            "Group displacement law",
            "Radioactive disintegration series (Alpha(α), Beta(β) and Gamma(ℽ) radiations)"
        ],
        "Radioactivity Disintegration": [
            "Rate of disintegration and half life period",
            "Radioactive decay as a first order process",
            "Half life and Average life",
            "Parallel path decay and Radioactive equilibrium"
        ],
        "Advanced Radioactivity": [
            "Nuclear fission and fusion",
            "Artificial Radioactivity, Atomic bomb and Hydrogen bomb"
        ]
    },
    "Nuclear Chemistry": {
        "Nucleus": [
            "Discovery of nucleus, Nucleons, Isotopes, Isobars and Isotones",
            "Reasons of nuclear stability, Mass defect and Binding energy",
            "Magic numbers and cause of Radioactivity",
            "Stable Isotopes and Isotones",
            "Alpha(α) decay and Beta(β) decay",
            "Nuclear reaction (apart from fission & fusion)"
        ]
    },
    "Electrochemistry": {
        "Introduction and cells": [
            "Introduction and Types of cells"
        ],
        "Electrochemical cells": [
            "Electrode potential, SOP and SRP",
            "Working of Electrochemical cells and Electrochemical Series",
            "Half cells, full cells and feasibility of cells",
            "Nernst Equation and applications",
            "Standard Hydrogen cell and Calomel Electrode",
            "Concentration Cell, type and define spontaneity of cell",
            "Relation between Standard Electrode Potential and Equilibrium Constant"
        ],
        "Commercial Cells": [
            "Primary, secondary cell & fuel cell"
        ],
        "Electrolytic cell": [
            "Electrolytic cell and Electrolysis",
            "Selective Electrolysis",
            "Faraday's law of Electrolysis"
        ],
        "Conductance": [
            "Conductance and Specific Conductance",
            "Molar and Equivalent Conductance",
            "Effect of dilution, Kohlrausch law and Ionic Mobility",
            "Application of Kohlrausch law and Conductometric Titration"
        ]
    },
    "Liquid solutions": {
        "Solutions": [
            "Solubility and Concentration terms",
            "Henry's law and its application"
        ],
        "Raoult's Law": [
            "Introduction to vapour pressure of solution, Boiling point and Melting Point",
            "Raoult's law for volatile solute",
            "Ideal and Non-ideal solution"
        ],
        "Colligative property": [
            "Introduction and Raoult's law for non-volatile solute",
            "Van't Hoff factor and Calculation of Van't Hoff factor",
            "Relationship between RLVP and Molar mass of solute",
            "Elevation of boiling point",
            "Depression of freezing point (Cryoscopy)",
            "Osmosis and Osmotic pressure",
            "Methods of measuring Osmotic Pressure and Reverse Osmosis"
        ]
    },
    "Surface chemistry": {
        "Adsorption": [
            "Introduction, definition and difference from absorption",
            "Adsorbate and Adsorbent",
            "Desorption and Sorption"
        ],
        "Driving forces for adsorption": [
            "Surface forces and Adsorption of gases on solids"
        ],
        "Types of adsorption": [
            "Physical and Chemical adsorption",
            "Sorption",
            "Factors affecting adsorption of gases on solids"
        ],
        "Adsorption isotherms": [
            "Freundlich and Langmuir isotherm",
            "Adsorption from solutions"
        ],
        "Applications of adsorption": [
            "Adsorption from gases and from solutions"
        ],
        "Colloidal state": [
            "Introduction, crystalloids and colloids"
        ],
        "Types of colloidal systems": [
            "Dispersed phase and Dispersion medium",
            "Multimolecular and Macromolecular colloids",
            "Association colloids or micelles"
        ],
        "Classification of colloidal systems": [
            "Lyophilic and Lyophobic sols"
        ],
        "Preparation of sols": [
            "Preparation of lyophilic sols",
            "Condensation methods and Dispersion methods"
        ],
        "Purification of sols": [
            "Ultrafiltration, Dialysis and Electrodialysis"
        ],
        "Properties of sols": [
            "Heterogeneity, Filtration and Sedimentation",
            "Brownian motion and Tyndall effect",
            "Colour and Colligative properties of sols"
        ],
        "Electrical properties of sols": [
            "Electrophoresis and Electro-osmosis"
        ],
        "Stability of sols": [
            "Repulsive forces between similarly charged particles",
            "Particle solvent interaction",
            "Coagulation or flocculation of sols",
            "Protective colloids and Mechanism of sol protection",
            "Gold number"
        ],
        "Emulsions": [
            "Oil in water and water in oil emulsions",
            "Preparation of emulsions",
            "Structure and Nature of emulsifiers",
            "Identification of emulsion types and Properties of emulsions",
            "Demulsification",
            "Application of emulsions and colloids"
        ]
    },
    "Classification of elements and periodicity in properties": {
        "Classification of elements": [
            "Older models of periodic table"
        ],
        "Mendeleev's classification": [
            "Salient features, merits and demerits"
        ],
        "Long form/modern periodic table": [
            "Moseley's periodic law and Modern periodic table",
            "Classification of periods and groups",
            "Nomenclature of recently discovered elements"
        ],
        "Classification in blocks and properties": [
            "S block elements and properties",
            "P block elements and properties",
            "D and F block elements and properties"
        ],
        "Diagonal relationship": [
            "Definition, reason for diagonal relationship",
            "Different pairs exhibiting diagonal relationship"
        ],
        "Screening or shielding effect": [
            "Definition, Effective Atomic number (Zeff.) and its variation",
            "Screening ability of electrons in different subshells and application of screening effect"
        ],
        "Inert pair effect": [
            "Definition, explanation of inert pair effect",
            "Application of inert pair effect in group 13,14 and 15",
            "Application of inert pair effect in other groups"
        ],
        "Atomic radii": [
            "Definition, types of atomic radius",
            "Factors affecting atomic radius"
        ],
        "Variation of atomic radius": [
            "In groups and periods",
            "In D and F block elements"
        ],
        "Ionic radii": [
            "Cationic radii, Anionic radii and factors affecting ionic radii"
        ],
        "Trends of ionic radii": [
            "Among isoelectronic species and in periods and groups"
        ],
        "Ianthaniod contraction": [
            "Definitions and applications"
        ],
        "Ionization energy": [
            "Definition and successive ionization energies",
            "Factors affecting ionization energy",
            "Variation of ionization energy in periodic table"
        ],
        "Applications of ionization energy": [
            "Electropositive character and reducing ability",
            "Ionic character of compounds"
        ],
        "Electron gain enthalpy/electron affinity (EA)": [
            "Definition and successive electron gain enthalpies",
            "Factors affecting electron affinity",
            "Variation of electron affinity in groups and periods",
            "Application of electron affinity"
        ],
        "Electronegativity": [
            "Definition and scales of electronegativity",
            "Factors affecting electronegativity"
        ],
        "Applications of electronegativity": [
            "Polarity and strength of bonds",
            "Acidic and basic character"
        ],
        "Hydration energy": [
            "Definition and Variation",
            "Applications of hydration energy"
        ],
        "Lattice energy": [
            "Definition, Variation of lattice energy",
            "Application of Lattice energy"
        ],
        "Oxidation Number": [
            "Oxidation number of S-Block, P-Block, D-Block, & F-Block Elements"
        ]
    }
}