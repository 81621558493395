export const API_URL = "https://api.acadza.in";
export const drawerWidth = "18vw";

export const PRODUCTION_URL = "https://api.acadza.in/";

// export const API_URL = "http://localhost:1234";
// export const API_URL = "https://testapi.acadza.in";

export const DOMAIN_URL = "https://acadza.com";
// export const DOMAIN_URL = "http://localhost:1234"

// TEMPORARAY CLIENT ID FOR TESTING
// TODO: create a google auth app project in gcp and generate a proper client id and secret to replace this with
export const GOOGLE_CLIENT_ID =
    "98962733511-l1fgro1rsp4guu04i6e3rdob2e9ovfju.apps.googleusercontent.com"; //"715684193477-rfcu34hdga3q4gg3g4dqr9gje07aa6gs.apps.googleusercontent.com"; 

export const FB_CLIENT_ID = "471752963861963"; //"1117331388728762";
export const INSTAGRAM_CLIENT_ID = "1306451126354739";

// export const RAZORPAY_KEY = "rzp_test_8hDAkQSEJhG4tf";
export const RAZORPAY_KEY = "rzp_live_PoKEOpYgjAiGfL";
