export default {
    "isSuccessful": true,
    "message": "successfull",
    "data": {
        "Math": {
            "count": 383,
            "content": {
                "Trignometric Ratios, Functions & Identities": {
                    "count": 47,
                    "content": {
                        "Fundamentals of Trigo": {
                            "count": 3,
                            "content": {
                                "Degree, Radians, Minutes and Seconds": {
                                    "count": 1
                                },
                                "Definition of Sin, Cos, Tan": {
                                    "count": 1
                                },
                                "Definition of Cosec, Sec, Cot": {
                                    "count": 1
                                }
                            }
                        },
                        "Domain, Range, Graph of Trigo": {
                            "count": 3,
                            "content": {
                                "Domain, Range, Graph Sin,cos": {
                                    "count": 1
                                },
                                "Domain, Range, Graph Tan,cot": {
                                    "count": 1
                                },
                                "Domain, Range, Graph Cosec, sec": {
                                    "count": 1
                                }
                            }
                        },
                        "Signs of Ratios in Quadrants": {
                            "count": 1,
                            "content": {
                                "Unit circle definition, ASTC (All Silver Tea Cups)": {
                                    "count": 1
                                }
                            }
                        },
                        "Allied Angles": {
                            "count": 5,
                            "content": {
                                "180°± A": {
                                    "count": 1
                                },
                                "360° ± A": {
                                    "count": 1
                                },
                                "90 ± A": {
                                    "count": 1
                                },
                                "270° ± A": {
                                    "count": 1
                                },
                                "n(90°) ± A method": {
                                    "count": 1
                                }
                            }
                        },
                        "Basic Trigo Identities": {
                            "count": 3,
                            "content": {
                                "Sin and Cos": {
                                    "count": 1
                                },
                                "Tan and Sec": {
                                    "count": 1
                                },
                                "Cot and Cosec": {
                                    "count": 1
                                }
                            }
                        },
                        "Standard Angles": {
                            "count": 1,
                            "content": {
                                "0°,30°, 45°, 60°, 90°": {
                                    "count": 1
                                }
                            }
                        },
                        "Compound Angles Formula": {
                            "count": 4,
                            "content": {
                                "sin(A+B) and sin(A-B)": {
                                    "count": 1
                                },
                                "cos(A+B) and cos (A-B)": {
                                    "count": 1
                                },
                                "tan(A+B) and tan(A-B)": {
                                    "count": 1
                                },
                                "Expansion of cot(A±B)": {
                                    "count": 1
                                }
                            }
                        },
                        "Factorisation, De Factorisation": {
                            "count": 4,
                            "content": {
                                "(sinC+sinD) and (sinC-sinD)": {
                                    "count": 1
                                },
                                "(cosC+cosD) and (cosC-cosD)": {
                                    "count": 1
                                },
                                "Formula of 2sinAcosB and 2cosAsinB": {
                                    "count": 1
                                },
                                "Formula of 2cosAcosB and 2sinAsinB": {
                                    "count": 1
                                }
                            }
                        },
                        "15°, 75°": {
                            "count": 1,
                            "content": {
                                "15°, 75°": {
                                    "count": 1
                                }
                            }
                        },
                        "Multiple angles Formula": {
                            "count": 2,
                            "content": {
                                "sin2A, cos2A, tan2A": {
                                    "count": 1
                                },
                                "sin3A, cos3A, tan3A": {
                                    "count": 1
                                }
                            }
                        },
                        "18°, 36°, 54°, 72°": {
                            "count": 2,
                            "content": {
                                "18°, 72°": {
                                    "count": 1
                                },
                                "36°, 54°": {
                                    "count": 1
                                }
                            }
                        },
                        "Half Angle Formula": {
                            "count": 4,
                            "content": {
                                "sin(A/2), cos(A/2) formula": {
                                    "count": 1
                                },
                                "tan(A/2) formula": {
                                    "count": 1
                                },
                                "sin(A/2)± cos(A/2)": {
                                    "count": 1
                                },
                                "cot(A/2)± tan(A/2)": {
                                    "count": 1
                                }
                            }
                        },
                        "22.5° , 67.5° and 7.5°": {
                            "count": 1,
                            "content": {
                                "22.5° , 67.5° and 7.5°": {
                                    "count": 1
                                }
                            }
                        },
                        "A, (60°+A), (60°-A)": {
                            "count": 3,
                            "content": {
                                "sinA.sin(60°-A).sin(60°+A)": {
                                    "count": 1
                                },
                                "cosA.cos(60°-A). cos(60°+A)": {
                                    "count": 1
                                },
                                "tanA.tan(60°-A).tan(60°+A)": {
                                    "count": 1
                                }
                            }
                        },
                        "A+B+C= 180°, In Triangle ABC": {
                            "count": 3,
                            "content": {
                                "∑tanA, ∑tan(A/2)tan(B/2)": {
                                    "count": 1
                                },
                                "∑sin2A, ∑cos2A": {
                                    "count": 1
                                },
                                "∑sinA, ∑cosA": {
                                    "count": 1
                                }
                            }
                        },
                        "Max and Min in Trigo": {
                            "count": 3,
                            "content": {
                                "Whole Square Method": {
                                    "count": 1
                                },
                                "Reciprocal Based (Acosx+ Bsecx)": {
                                    "count": 1
                                },
                                "asinx + bcosx": {
                                    "count": 1
                                }
                            }
                        },
                        "Sin and Cos of an AP": {
                            "count": 1,
                            "content": {
                                "Sin of an AP, Cos of an AP": {
                                    "count": 1
                                }
                            }
                        },
                        "Trignonometry and other chapters": {
                            "count": 3,
                            "content": {
                                "Series (telescoping)": {
                                    "count": 1
                                },
                                "Complex numbers": {
                                    "count": 1
                                },
                                "Theory of Equations": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Equation and Inequation": {
                    "count": 21,
                    "content": {
                        "Inequalities": {
                            "count": 6,
                            "content": {
                                "Introduction of >,<,>=,<=,Intervals,Union and Intersection of intervals": {
                                    "count": 1
                                },
                                "Transitive property (inequality), Property related addition, Subtraction and Multiplication, division": {
                                    "count": 1
                                },
                                "Properties related to powers and roots": {
                                    "count": 1
                                },
                                "Linear inequalities, system of Linear inequalities (one variable)": {
                                    "count": 1
                                },
                                "Wavy Curve Method": {
                                    "count": 1
                                },
                                "Irrational inequalities and equation": {
                                    "count": 1
                                }
                            }
                        },
                        "Modulus Function": {
                            "count": 5,
                            "content": {
                                "Introduction of modulus function": {
                                    "count": 1
                                },
                                "Graph of Modulus function (y = |x - a|, y = |x - a|+ |x - b| )": {
                                    "count": 1
                                },
                                "Properties of Modulus function": {
                                    "count": 1
                                },
                                "Triangle inequalities of Modulus function": {
                                    "count": 1
                                },
                                "Max f (x), g(x), Min f (x), g(x) Related Properties": {
                                    "count": 1
                                }
                            }
                        },
                        "Exponential Function": {
                            "count": 2,
                            "content": {
                                "Introduction and graph and equation of exponential function": {
                                    "count": 1
                                },
                                "Exponential Inequalities": {
                                    "count": 1
                                }
                            }
                        },
                        "Logarithm Function": {
                            "count": 8,
                            "content": {
                                "Law of Indices": {
                                    "count": 1
                                },
                                "Graph, Domain, Range of logarithm": {
                                    "count": 1
                                },
                                "Logarithm of number or base raised to any power": {
                                    "count": 1
                                },
                                "Change of base, Reciprocal of logarithm": {
                                    "count": 1
                                },
                                "Cancelling of base, a^(log(b,c)) = b^(log(a,c))": {
                                    "count": 1
                                },
                                "Logarithmic inequalities": {
                                    "count": 1
                                },
                                "Common log, Characteristic and mantissa and antilog": {
                                    "count": 1
                                },
                                "log ab / log a/b": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Quadratic Equations": {
                    "count": 34,
                    "content": {
                        "Introduction of quadratic": {
                            "count": 3,
                            "content": {
                                "Polynomial and quadratic expression": {
                                    "count": 1
                                },
                                "Difference between equation expression identity formula": {
                                    "count": 1
                                },
                                "Identical equations,Rational expression": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation and roots": {
                            "count": 4,
                            "content": {
                                "Factors & roots of Quadratic Equation": {
                                    "count": 1
                                },
                                "Quadratic formula": {
                                    "count": 1
                                },
                                "Sum,product and difference of roots": {
                                    "count": 1
                                },
                                "Formation of quadratic with given roots": {
                                    "count": 1
                                }
                            }
                        },
                        "Nature of Roots of quadratic": {
                            "count": 4,
                            "content": {
                                "Real and distinct,Real and equal": {
                                    "count": 1
                                },
                                "Imaginary vs Real": {
                                    "count": 1
                                },
                                "Rational/Irrational/Integral roots": {
                                    "count": 1
                                },
                                "Geometrical Interpretation of Nature of Roots": {
                                    "count": 1
                                }
                            }
                        },
                        "Descartes Rule of Sign": {
                            "count": 1,
                            "content": {
                                "In Quadratic & In equation of degree n": {
                                    "count": 1
                                }
                            }
                        },
                        "Transformation of equation": {
                            "count": 3,
                            "content": {
                                "kα ,kβ, -α ,-β": {
                                    "count": 1
                                },
                                "α±k , β±k": {
                                    "count": 1
                                },
                                "αⁿ , βⁿ ,1/α ,1/β": {
                                    "count": 1
                                }
                            }
                        },
                        "Common roots": {
                            "count": 3,
                            "content": {
                                "Condition for at least one common root": {
                                    "count": 1
                                },
                                "Condition for both common roots": {
                                    "count": 1
                                },
                                "Common roots in higher degree equations": {
                                    "count": 1
                                }
                            }
                        },
                        "Quadratic/Biquadratic": {
                            "count": 1,
                            "content": {
                                "Equation Reducible to Quadratic/Biquadratic": {
                                    "count": 1
                                }
                            }
                        },
                        "Quadratic function and its graph": {
                            "count": 2,
                            "content": {
                                "a>0, D>0 & a>0, D=0 & a>0, D<0": {
                                    "count": 1
                                },
                                "a<0, D>0 & a<0, D=0 & a<0, D<0": {
                                    "count": 1
                                }
                            }
                        },
                        "Maximum and minimum value of quadratic": {
                            "count": 2,
                            "content": {
                                "a>0 , a<0": {
                                    "count": 1
                                },
                                "Range of rational expression": {
                                    "count": 1
                                }
                            }
                        },
                        "Location of roots": {
                            "count": 6,
                            "content": {
                                "Both roots greater than a real number": {
                                    "count": 1
                                },
                                "Both roots less than a real number": {
                                    "count": 1
                                },
                                "A real number lie between the roots": {
                                    "count": 1
                                },
                                "Exactly one root lie in open interval": {
                                    "count": 1
                                },
                                "Both roots lie in open interval": {
                                    "count": 1
                                },
                                "One root is less than ‘p’ and other is greater than ‘q’": {
                                    "count": 1
                                }
                            }
                        },
                        "Polynomial expression": {
                            "count": 4,
                            "content": {
                                "Cubic Equation & Relation between roots and coefficient": {
                                    "count": 1
                                },
                                "Condition for 3 real roots,1 real and two imaginary roots": {
                                    "count": 1
                                },
                                "Graph of cubic in different conditions": {
                                    "count": 1
                                },
                                "Polynomial of degree n,Relation between roots and coefficients": {
                                    "count": 1
                                }
                            }
                        },
                        "Rolle’s theorem": {
                            "count": 1,
                            "content": {
                                "Statement of rolle’s theorem": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Sequences and Series (Progressions)": {
                    "count": 33,
                    "content": {
                        "Definition of Sequence and Series": {
                            "count": 1,
                            "content": {
                                "Def sequence and series & general term": {
                                    "count": 1
                                }
                            }
                        },
                        "Arithmetic Progressions (AP)": {
                            "count": 5,
                            "content": {
                                "Definition , General term": {
                                    "count": 1
                                },
                                "Sum of n terms": {
                                    "count": 1
                                },
                                "General term from sum of n terms": {
                                    "count": 1
                                },
                                "Properties of Arithmetic Progression": {
                                    "count": 1
                                },
                                "Arithmetic mean,Inserting n A.M. B/w 2 Numbers": {
                                    "count": 1
                                }
                            }
                        },
                        "Geometric Progressions(GP)": {
                            "count": 5,
                            "content": {
                                "Definition,General term": {
                                    "count": 1
                                },
                                "Sum of n term": {
                                    "count": 1
                                },
                                "Sum of infinite terms": {
                                    "count": 1
                                },
                                "Properties of geometric Progression": {
                                    "count": 1
                                },
                                "Geometric mean,Inserting n G.M. B/w 2 Numbers": {
                                    "count": 1
                                }
                            }
                        },
                        "Harmonic progression(HP)": {
                            "count": 3,
                            "content": {
                                "Definition,General term": {
                                    "count": 1
                                },
                                "Properties of Harmonic Progression": {
                                    "count": 1
                                },
                                "Harmonic mean,Inserting n H.M. B/w 2 Numbers": {
                                    "count": 1
                                }
                            }
                        },
                        "A.M. G.M and H.M": {
                            "count": 2,
                            "content": {
                                "A.M, G.M, H.M of n positive numbers": {
                                    "count": 1
                                },
                                "Relation Between A.M. G.M and H.M": {
                                    "count": 1
                                }
                            }
                        },
                        "Arithmetic-geometric progression ( AGP)": {
                            "count": 3,
                            "content": {
                                "Definition,General term": {
                                    "count": 1
                                },
                                "Sum of n term": {
                                    "count": 1
                                },
                                "Sum of infinite terms": {
                                    "count": 1
                                }
                            }
                        },
                        "Miscellaneous series": {
                            "count": 7,
                            "content": {
                                "Sum of squares of first n natural numbers": {
                                    "count": 1
                                },
                                "Sum of cubes of first n natural numbers": {
                                    "count": 1
                                },
                                "Difference of terms in AP": {
                                    "count": 1
                                },
                                "Difference of Difference of terms in AP": {
                                    "count": 1
                                },
                                "Difference of terms in GP": {
                                    "count": 1
                                },
                                "Difference of Difference of terms in GP": {
                                    "count": 1
                                },
                                "Vn Method in Progression": {
                                    "count": 1
                                }
                            }
                        },
                        "Various Inequalities": {
                            "count": 1,
                            "content": {
                                "Cauchy's Inequality": {
                                    "count": 1
                                }
                            }
                        },
                        "Special Concepts": {
                            "count": 6,
                            "content": {
                                "Recursion": {
                                    "count": 1
                                },
                                "Use of Rationalisation in Series": {
                                    "count": 1
                                },
                                "Weighted Mean": {
                                    "count": 1
                                },
                                "Use of Calculus in Series": {
                                    "count": 1
                                },
                                "Exponential Series": {
                                    "count": 1
                                },
                                "Logarithmic Series": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Trigonometric Equations": {
                    "count": 24,
                    "content": {
                        "Introduction of trigonometric equation": {
                            "count": 3,
                            "content": {
                                "Principal & general solution of T.E": {
                                    "count": 1
                                },
                                "Definition, solution or root of T.E": {
                                    "count": 2
                                }
                            }
                        },
                        "General solution of some elementary equations": {
                            "count": 3,
                            "content": {
                                "sinx=0,1,-1": {
                                    "count": 1
                                },
                                "cosx=0,1,-1": {
                                    "count": 1
                                },
                                "tanx=0, cotx=0": {
                                    "count": 1
                                }
                            }
                        },
                        "Important points to be taken care while solving equations": {
                            "count": 3,
                            "content": {
                                "Domain checking": {
                                    "count": 1
                                },
                                "Avoid  squaring": {
                                    "count": 1
                                },
                                "Avoid  cancelling terms from both side containing unknown": {
                                    "count": 1
                                }
                            }
                        },
                        "General solution of some standard equations": {
                            "count": 4,
                            "content": {
                                "sinθ=sinα": {
                                    "count": 1
                                },
                                "cosθ=cosα": {
                                    "count": 1
                                },
                                "tanθ=tanα": {
                                    "count": 1
                                },
                                "sin²θ=sin²α, cos²θ=cos²α, tan²θ=tan²α": {
                                    "count": 1
                                }
                            }
                        },
                        "Different strategies to solve T.E": {
                            "count": 6,
                            "content": {
                                "By factorisation": {
                                    "count": 1
                                },
                                "By transforming product into sum": {
                                    "count": 1
                                },
                                "By transforming sum into product": {
                                    "count": 1
                                },
                                "By substitution": {
                                    "count": 1
                                },
                                "With the use of boundedness of function": {
                                    "count": 1
                                },
                                "Using graphs": {
                                    "count": 1
                                }
                            }
                        },
                        "Solution of some special equations": {
                            "count": 5,
                            "content": {
                                "A sinx + B cosx = C": {
                                    "count": 1
                                },
                                "f(x)=√g(x)": {
                                    "count": 1
                                },
                                "Simultaneous T.E in two Variables": {
                                    "count": 1
                                },
                                "Solution in the case of 2 Trigonometric Equations are given": {
                                    "count": 1
                                },
                                "Inequalities": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Binomial Theorem": {
                    "count": 22,
                    "content": {
                        "Factorial": {
                            "count": 4,
                            "content": {
                                "Factorial": {
                                    "count": 1
                                },
                                "Highest power of a prime in n factorial": {
                                    "count": 1
                                },
                                "Number of trailing zeros in n factorial": {
                                    "count": 1
                                },
                                "Formula and properties of nCr": {
                                    "count": 1
                                }
                            }
                        },
                        "Binomial Expansion": {
                            "count": 3,
                            "content": {
                                "Expansion of (x ± a)^n": {
                                    "count": 1
                                },
                                "rth term of binomial expansion": {
                                    "count": 1
                                },
                                "Finding  Coefficient of xʳ": {
                                    "count": 1
                                }
                            }
                        },
                        "Middle term(s) and greatest coefficient(s) in (x + a)^n": {
                            "count": 1,
                            "content": {
                                "If n is even, n is odd": {
                                    "count": 1
                                }
                            }
                        },
                        "Numerically Greatest Term": {
                            "count": 1,
                            "content": {
                                "Numerically Greatest Term": {
                                    "count": 1
                                }
                            }
                        },
                        "Binomial Coefficients": {
                            "count": 6,
                            "content": {
                                "Summation of Binomial coefficients": {
                                    "count": 1
                                },
                                "Use of differentiation": {
                                    "count": 1
                                },
                                "Use of integration": {
                                    "count": 1
                                },
                                "Use of complex number": {
                                    "count": 1
                                },
                                "Multiplication of Binomial coefficients of same series": {
                                    "count": 1
                                },
                                "Multiplication of binomial coefficients of different series": {
                                    "count": 1
                                }
                            }
                        },
                        "Multinomial Theorem": {
                            "count": 2,
                            "content": {
                                "Multinomial Theorem": {
                                    "count": 1
                                },
                                "Number of distinct terms in multinomial expansions": {
                                    "count": 1
                                }
                            }
                        },
                        "Generalised form of binomial theorem": {
                            "count": 2,
                            "content": {
                                "Generalised form ,Binomial theorem for negative index": {
                                    "count": 1
                                },
                                "Binomial expansions for any index": {
                                    "count": 1
                                }
                            }
                        },
                        "Integral and fractional part": {
                            "count": 1,
                            "content": {
                                "Integral and fractional part of (p+√q)^n": {
                                    "count": 1
                                }
                            }
                        },
                        "Application of binomial theorem": {
                            "count": 2,
                            "content": {
                                "Last digits": {
                                    "count": 1
                                },
                                "Double Summation": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Permutation and Combination": {
                    "count": 0,
                    "content": {
                        "Fundamental principle of counting": {
                            "count": 0,
                            "content": {
                                "Addition principle, Multiplication principle": {
                                    "count": 0
                                }
                            }
                        },
                        "Combination": {
                            "count": 0,
                            "content": {
                                "Intro. of combination,Formula of  nCr & nPr": {
                                    "count": 0
                                },
                                "Selection of objects taken all or some objects at a time": {
                                    "count": 0
                                },
                                "Conditional combination": {
                                    "count": 0
                                },
                                "Selection of at least one object from n different articles": {
                                    "count": 0
                                },
                                "Selection of at least one object from n identical articles": {
                                    "count": 0
                                },
                                "Selection of objects (Not all distinct)": {
                                    "count": 0
                                },
                                "Combination with repetition": {
                                    "count": 0
                                }
                            }
                        },
                        "Permutation": {
                            "count": 0,
                            "content": {
                                "Intro of permutation & Difference b/w permutation and combination": {
                                    "count": 0
                                },
                                "Conditional Permutation": {
                                    "count": 0
                                },
                                "Permutation not taking all objects at a time": {
                                    "count": 0
                                },
                                "Repetition of objects are not Allowed": {
                                    "count": 0
                                },
                                "Repetition of objects Allowed": {
                                    "count": 0
                                },
                                "Permutations when all the objects are not distinct": {
                                    "count": 0
                                },
                                "Formation of words": {
                                    "count": 0
                                },
                                "Formation of numbers": {
                                    "count": 0
                                },
                                "Rank of words": {
                                    "count": 0
                                },
                                "Sum of all the numbers formed": {
                                    "count": 0
                                },
                                "Permutation with repetition": {
                                    "count": 0
                                }
                            }
                        },
                        "Circular Permutation": {
                            "count": 0,
                            "content": {
                                "Circular arrangement of distinct objects": {
                                    "count": 0
                                },
                                "Circular Permutation when all the Objects are not Different": {
                                    "count": 0
                                },
                                "Formation of garland": {
                                    "count": 0
                                }
                            }
                        },
                        "Geometrical construction": {
                            "count": 0,
                            "content": {
                                "Number of straight lines & polygon from given n points": {
                                    "count": 0
                                },
                                "Number & intersection points of diagonals of a polygon": {
                                    "count": 0
                                },
                                "Number of intersection points of n straight lines and p circles": {
                                    "count": 0
                                },
                                "Number of squares and rectangle in chess board": {
                                    "count": 0
                                },
                                "Grid based problems": {
                                    "count": 0
                                }
                            }
                        },
                        "Distribution": {
                            "count": 0,
                            "content": {
                                "Distribution of distinct objects when a candidate can receive one, or more objects": {
                                    "count": 0
                                },
                                "Distribution of identical objects in distinguishable groups (Multinomial expansion)": {
                                    "count": 0
                                },
                                "Restricted distributions of distinct objects": {
                                    "count": 0
                                },
                                "No. of integral solutions equation/inequation": {
                                    "count": 0
                                }
                            }
                        },
                        "Division into group": {
                            "count": 0,
                            "content": {
                                "Division into Group when Groups are of Unequal(equal) Size (order not important)": {
                                    "count": 0
                                },
                                "Division into Group when Groups are of Unequal(equal) Size (order important)": {
                                    "count": 0
                                }
                            }
                        },
                        "Divisors": {
                            "count": 0,
                            "content": {
                                "Total  No. of Divisors of a Number": {
                                    "count": 0
                                },
                                "No. of divisors even, odd & of type 4k+2, 4k+1, 4k+3": {
                                    "count": 0
                                },
                                "Sum of all divisors": {
                                    "count": 0
                                },
                                "No. of ways to express a no. as a product of two factors": {
                                    "count": 0
                                },
                                "No. of ways to express a no. as a product of two co-prime factor": {
                                    "count": 0
                                },
                                "Divisibility Conditions for Different Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "De-arrangement": {
                            "count": 0,
                            "content": {
                                "Simple de-arrangement": {
                                    "count": 0
                                },
                                "Partial & conditional de-arrangement": {
                                    "count": 0
                                }
                            }
                        },
                        "Inclusion - Exclusion Principle in Combinatorics": {
                            "count": 0,
                            "content": {
                                "Inclusion - Exclusion Principle in Combinatorics": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Probability": {
                    "count": 0,
                    "content": {
                        "Introduction and basics of probability": {
                            "count": 0,
                            "content": {
                                "Experiment, Statistical Experiment in Probability": {
                                    "count": 0
                                },
                                "Random Experiment, Basic Information about pack of card": {
                                    "count": 0
                                }
                            }
                        },
                        "Different Terms in Experiment in Probability": {
                            "count": 0,
                            "content": {
                                "Sample Space, Event in Probability": {
                                    "count": 0
                                },
                                "Simple Event, Compound Event in Probability": {
                                    "count": 0
                                },
                                "Exhaustive Events, Equally likely Events in Probability": {
                                    "count": 0
                                },
                                "Mutually Exclusive events in Probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Operations in events": {
                            "count": 0,
                            "content": {
                                "Event A or Event B,Event A & Event B in Probability": {
                                    "count": 0
                                },
                                "Event A but not Event B in Probability": {
                                    "count": 0
                                },
                                "Event not A & not event B in Probability": {
                                    "count": 0
                                },
                                "Other set related operations in probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Dependent and independent events": {
                            "count": 0,
                            "content": {
                                "Independent Events, Theorem in Probability": {
                                    "count": 0
                                },
                                "Properties of Independent Event in Probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Definition of probability": {
                            "count": 0,
                            "content": {
                                "Classical Definition, Complement Rule in Probability": {
                                    "count": 0
                                },
                                "Value, Set of Values of Probability of an Event": {
                                    "count": 0
                                }
                            }
                        },
                        "Odds": {
                            "count": 0,
                            "content": {
                                "Odds in Favour & Against of an Event in Probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Conditional probability": {
                            "count": 0,
                            "content": {
                                "Conditional Probability": {
                                    "count": 0
                                },
                                "Multiplication Theorem in Probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Law of total probability and bayes’ theorem": {
                            "count": 0,
                            "content": {
                                "Law of Total Probability": {
                                    "count": 0
                                },
                                "Frequency (Posteriori) Definition": {
                                    "count": 0
                                },
                                "Bayes’ Theorem in Probability": {
                                    "count": 0
                                }
                            }
                        },
                        "Binomial probability": {
                            "count": 0,
                            "content": {
                                "Binomial Distribution in Probability": {
                                    "count": 0
                                },
                                "Binomial probability - Mean & variance": {
                                    "count": 0
                                },
                                "Probability Distribution": {
                                    "count": 0
                                },
                                "Mean & Variance of Probability": {
                                    "count": 0
                                },
                                "Expected value": {
                                    "count": 0
                                }
                            }
                        },
                        "Geometrical probability": {
                            "count": 0,
                            "content": {
                                "Geometrical Application of Probability": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Point and Straight Line": {
                    "count": 44,
                    "content": {
                        "Coordinate system": {
                            "count": 5,
                            "content": {
                                "Cartesian Coordinate System,Polar coordinates": {
                                    "count": 1
                                },
                                "Distance Formula ,Pythagoras Theorem": {
                                    "count": 1
                                },
                                "Section Formula": {
                                    "count": 1
                                },
                                "Area of Triangle in Coordinate Geometry": {
                                    "count": 1
                                },
                                "Collinearity Coordinate Geometry": {
                                    "count": 1
                                }
                            }
                        },
                        "Imp Points of Triangle": {
                            "count": 3,
                            "content": {
                                "Centroid,Incentre": {
                                    "count": 1
                                },
                                "Circumcentre, Orthocentre": {
                                    "count": 1
                                },
                                "Euler Line": {
                                    "count": 1
                                }
                            }
                        },
                        "Imp points of quadrilateral": {
                            "count": 2,
                            "content": {
                                "Parallelogram,Rectangle,Rhombus": {
                                    "count": 1
                                },
                                "Square,Kite,Cyclic Quadrilateral": {
                                    "count": 1
                                }
                            }
                        },
                        "Locus of a Point": {
                            "count": 1,
                            "content": {
                                "Meaning,Equation,Steps to find the locus": {
                                    "count": 1
                                }
                            }
                        },
                        "Change of axes": {
                            "count": 3,
                            "content": {
                                "Shifting of origin": {
                                    "count": 1
                                },
                                "Rotation of axes": {
                                    "count": 1
                                },
                                "Removal of the term xy from ax²+2hxy+by²+2gx+2fy+c": {
                                    "count": 1
                                }
                            }
                        },
                        "Slope": {
                            "count": 4,
                            "content": {
                                "Inclination,Slope of line with given inclination": {
                                    "count": 1
                                },
                                "Slope of line joining two points,Slope of line ax+by+c=0": {
                                    "count": 1
                                },
                                "Collinearity of three points by slope": {
                                    "count": 1
                                },
                                "Angle between two lines,Condition of parallelism and perpendicularity": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of line": {
                            "count": 6,
                            "content": {
                                "Equation of line parallel to x & y-axis": {
                                    "count": 1
                                },
                                "Equation of line in point slope, two point form": {
                                    "count": 1
                                },
                                "Equation of line in intercept form, slope intercept form, general form": {
                                    "count": 1
                                },
                                "Equation of line in normal form": {
                                    "count": 1
                                },
                                "Equation of line in parametric form": {
                                    "count": 1
                                },
                                "Equation of line parallel and perpendicular to a given line": {
                                    "count": 1
                                }
                            }
                        },
                        "Point of intersection of two  Lines": {
                            "count": 1,
                            "content": {
                                "Intersection point of two lines,Concurrence of 3 Lines": {
                                    "count": 1
                                }
                            }
                        },
                        "Distance of a Point from a Line": {
                            "count": 3,
                            "content": {
                                "Distance of a point from a line,Distance between two parallel lines": {
                                    "count": 1
                                },
                                "Image/foot of Perpendicular of a Point in a Line": {
                                    "count": 1
                                },
                                "Concept of reflection of ray": {
                                    "count": 1
                                }
                            }
                        },
                        "Position of Point w.r.t. Line": {
                            "count": 1,
                            "content": {
                                "Position of points w.r.t. Line,Position of point inside a triangle": {
                                    "count": 1
                                }
                            }
                        },
                        "Angle bisectors": {
                            "count": 2,
                            "content": {
                                "Equation of angle acute/obtuse bisectors": {
                                    "count": 1
                                },
                                "Determination of angle bisector containing/not containing (α,β)": {
                                    "count": 1
                                }
                            }
                        },
                        "Family of Straight Lines": {
                            "count": 1,
                            "content": {
                                "Family of straight lines": {
                                    "count": 1
                                }
                            }
                        },
                        "Pair of straight lines": {
                            "count": 7,
                            "content": {
                                "Definition,General equation of 2nd degree": {
                                    "count": 1
                                },
                                "Condition for general equation of 2nd degree to represent  p.o.s.l.": {
                                    "count": 1
                                },
                                "Factorisation of general equation of 2nd degree": {
                                    "count": 1
                                },
                                "Homogeneous Equations in 2nd Degree": {
                                    "count": 1
                                },
                                "Slope quadratic,Angle between p.o.s.l.": {
                                    "count": 1
                                },
                                "Condition for parallel intersecting, perpendicular and coincident lines": {
                                    "count": 1
                                },
                                "Point of intersection of p.o.s.l.": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of Angle Bisector": {
                            "count": 1,
                            "content": {
                                "Equation of Angle Bisector of P.O.S.L.": {
                                    "count": 1
                                }
                            }
                        },
                        "Homogenisation of non Homogeneous Equation": {
                            "count": 2,
                            "content": {
                                "Family of pair of lines": {
                                    "count": 1
                                },
                                "Equation of p.o.s.l. Joining point of intersection of a curve and line": {
                                    "count": 1
                                }
                            }
                        },
                        "Some other important concepts": {
                            "count": 2,
                            "content": {
                                "Product of perpendicular distances from a point to a p.o.s.l.": {
                                    "count": 1
                                },
                                "Distance between parallel p.o.s.l.": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Circle": {
                    "count": 31,
                    "content": {
                        "Definition and Properties of circle": {
                            "count": 2,
                            "content": {
                                "Definition and Properties of circle": {
                                    "count": 2
                                }
                            }
                        },
                        "Equation of a circle in different forms": {
                            "count": 4,
                            "content": {
                                "Center radius form,General equation of circle": {
                                    "count": 1
                                },
                                "Condition for a 2nd degree equation to represent a circle & Diameter form of circle": {
                                    "count": 1
                                },
                                "Parametric equation of a circle Parametric coordinate of any point on circle": {
                                    "count": 1
                                },
                                "Circle Passing Through Three Points,Equation of chord joining A(α) and B(β)": {
                                    "count": 1
                                }
                            }
                        },
                        "Position of point w.r.t. circle": {
                            "count": 1,
                            "content": {
                                "Position of a point w.r.t. circle, Maximum and minimum distance of a point from circle": {
                                    "count": 1
                                }
                            }
                        },
                        "Intercepts of Circle on a line": {
                            "count": 1,
                            "content": {
                                "Intercept on x-axis,y-axis & on y=mx+c": {
                                    "count": 1
                                }
                            }
                        },
                        "Tangent and Normal": {
                            "count": 5,
                            "content": {
                                "Intersection of Line and Circle, Slope form of tangent": {
                                    "count": 1
                                },
                                "Point form of tangent,Parametric form of tangent": {
                                    "count": 1
                                },
                                "Equation of normal to circle, Length of tangent and angle between tangent": {
                                    "count": 1
                                },
                                "Tangent from a point outside the Circle, Equation of pair of tangents": {
                                    "count": 1
                                },
                                "Intersection point of tangents at  A(α) and B(β)": {
                                    "count": 1
                                }
                            }
                        },
                        "Common tangents to two circles": {
                            "count": 3,
                            "content": {
                                "Types of common tangents,No. of common tangents": {
                                    "count": 1
                                },
                                "Length of common tangents": {
                                    "count": 1
                                },
                                "Equation of common tangents": {
                                    "count": 1
                                }
                            }
                        },
                        "Power of a point": {
                            "count": 1,
                            "content": {
                                "Power of a point": {
                                    "count": 1
                                }
                            }
                        },
                        "Intersection of two circles": {
                            "count": 1,
                            "content": {
                                "Angle between 2 circles": {
                                    "count": 1
                                }
                            }
                        },
                        "Important terms and their equations": {
                            "count": 4,
                            "content": {
                                "Director circle to a circle": {
                                    "count": 1
                                },
                                "Chord of contact of circle": {
                                    "count": 1
                                },
                                "Chord of circle with given midpoint": {
                                    "count": 1
                                },
                                "Common chord to two circles,Length of common chord": {
                                    "count": 1
                                }
                            }
                        },
                        "Family of circles": {
                            "count": 2,
                            "content": {
                                "Through intersection of two circles and a circle & a line": {
                                    "count": 1
                                },
                                "Through two fixed points and circle touching a line at a point": {
                                    "count": 1
                                }
                            }
                        },
                        "Pole and Polar": {
                            "count": 2,
                            "content": {
                                "Concept & Properties of Pole and Polar": {
                                    "count": 1
                                },
                                "Conjugate point and Conjugate line": {
                                    "count": 1
                                }
                            }
                        },
                        "Radical axis and radical centre": {
                            "count": 3,
                            "content": {
                                "Definition,Position & Equation of radical axis": {
                                    "count": 1
                                },
                                "Radical center,Position of radical centre": {
                                    "count": 1
                                },
                                "Properties related to radical axis and radical centre": {
                                    "count": 1
                                }
                            }
                        },
                        "Coaxial system of circles": {
                            "count": 2,
                            "content": {
                                "Coaxial system of circles": {
                                    "count": 1
                                },
                                "Limiting points of a coaxial system of circles": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Parabola": {
                    "count": 29,
                    "content": {
                        "Introduction of conic": {
                            "count": 3,
                            "content": {
                                "Conic section definition,Recognition of conics": {
                                    "count": 1
                                },
                                "Equation of conic with given focus ,directrix and eccentricity": {
                                    "count": 1
                                },
                                "How to find centre of conic": {
                                    "count": 1
                                }
                            }
                        },
                        "Fundamentals of Parabola": {
                            "count": 5,
                            "content": {
                                "Definition & Term related to parabola": {
                                    "count": 1
                                },
                                "Standard equation of parabola": {
                                    "count": 1
                                },
                                "Other form of parabola and terms related to them": {
                                    "count": 1
                                },
                                "Parabola with vertex at (h,k) and axis parallel to coordinate axes": {
                                    "count": 1
                                },
                                "Parabola referred to perpendicular lines": {
                                    "count": 1
                                }
                            }
                        },
                        "Chord of Parabola": {
                            "count": 2,
                            "content": {
                                "Equation of chord of parabola": {
                                    "count": 1
                                },
                                "Focal Chord": {
                                    "count": 1
                                }
                            }
                        },
                        "Position of a Point w.r.t Parabola": {
                            "count": 1,
                            "content": {
                                "Position of a Point w.r.t Parabola": {
                                    "count": 1
                                }
                            }
                        },
                        "Intersection of line and Parabola": {
                            "count": 1,
                            "content": {
                                "Intersection of line and parabola,Condition of tangency": {
                                    "count": 1
                                }
                            }
                        },
                        "Tangent to a Parabola": {
                            "count": 3,
                            "content": {
                                "Slope form of Tangent & Point of Tangency": {
                                    "count": 1
                                },
                                "Point form & Parametric form of tangent (parabola)": {
                                    "count": 1
                                },
                                "Intersection point of Tangents, Director Circle": {
                                    "count": 1
                                }
                            }
                        },
                        "Normal to a Parabola": {
                            "count": 8,
                            "content": {
                                "Slope form of Normal to Parabola": {
                                    "count": 1
                                },
                                "Point form ,Parametric form  of Normal to Parabola": {
                                    "count": 1
                                },
                                "Normal Intersecting on the Parabola": {
                                    "count": 1
                                },
                                "Point of Intersection and other Properties": {
                                    "count": 1
                                },
                                "Number of normals": {
                                    "count": 1
                                },
                                "Conormal Points and their properties": {
                                    "count": 1
                                },
                                "Length of Tangent, Subtangent, Normal, Subnormal to Parabola": {
                                    "count": 1
                                },
                                "Reflection Property of Parabola": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of Chord of Contact to a Parabola": {
                            "count": 1,
                            "content": {
                                "Equation of chord of contact to a parabola T= 0": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of Chord of Parabola with given Midpoint": {
                            "count": 1,
                            "content": {
                                "Equation of Chord of Parabola with given Midpoint": {
                                    "count": 1
                                }
                            }
                        },
                        "Pair of Tangents to the Parabola": {
                            "count": 1,
                            "content": {
                                "Pair of tangents equation": {
                                    "count": 1
                                }
                            }
                        },
                        "Diameter of Parabola": {
                            "count": 1,
                            "content": {
                                "Diameter of Parabola": {
                                    "count": 1
                                }
                            }
                        },
                        "Pole and Polar of Parabola": {
                            "count": 1,
                            "content": {
                                "Pole and Polar of Parabola": {
                                    "count": 1
                                }
                            }
                        },
                        "Some standard properties of parabola": {
                            "count": 1,
                            "content": {
                                "Standard properties": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Ellipse": {
                    "count": 28,
                    "content": {
                        "Definition and basics of ellipse": {
                            "count": 4,
                            "content": {
                                "Locus Ellipse Definition,Standard equation of ellipse": {
                                    "count": 1
                                },
                                "Basics of Ellipse when a>b, a<b": {
                                    "count": 1
                                },
                                "Second definition of ellipse": {
                                    "count": 1
                                },
                                "Equation of ellipse referred to two perpendicular lines": {
                                    "count": 1
                                }
                            }
                        },
                        "Auxiliary circle and parametric coordinates (Ellipse)": {
                            "count": 2,
                            "content": {
                                "Auxiliary circle and eccentric angle": {
                                    "count": 1
                                },
                                "Parametric coordinates": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of chord ellipse": {
                            "count": 1,
                            "content": {
                                "Equation of Chord,Condition for Focal Chord of the Ellipse": {
                                    "count": 1
                                }
                            }
                        },
                        "Position of a point w.r.t. ellipse": {
                            "count": 1,
                            "content": {
                                "Position of a point w.r.t. ellipse": {
                                    "count": 1
                                }
                            }
                        },
                        "Line and Ellipse": {
                            "count": 1,
                            "content": {
                                "Intersection of a line and ellipse, condition of tangency": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of tangent ellipse": {
                            "count": 4,
                            "content": {
                                "Slope form of Tangent & Point of Contact": {
                                    "count": 1
                                },
                                "Point form & Parametric form of Tangent to an Ellipse": {
                                    "count": 1
                                },
                                "Intersection point of tangents": {
                                    "count": 1
                                },
                                "Equation of pair of tangents": {
                                    "count": 1
                                }
                            }
                        },
                        "Important terms and their equation ellipse": {
                            "count": 4,
                            "content": {
                                "Director Circle of an Ellipse": {
                                    "count": 1
                                },
                                "Chord of Contact of an Ellipse": {
                                    "count": 1
                                },
                                "Chord to Ellipse with Given midpoint": {
                                    "count": 1
                                },
                                "Pole and polar Ellipse": {
                                    "count": 1
                                }
                            }
                        },
                        "Normal to an Ellipse": {
                            "count": 7,
                            "content": {
                                "Point form of Normal to an Ellipse": {
                                    "count": 1
                                },
                                "Slope form of Normal, Point of Contact": {
                                    "count": 1
                                },
                                "Parametric form of Normal to an Ellipse": {
                                    "count": 1
                                },
                                "Subtangent and Subnormal to an Ellipse": {
                                    "count": 1
                                },
                                "Number of normals from a point": {
                                    "count": 1
                                },
                                "Conormal points Ellipse": {
                                    "count": 1
                                },
                                "Reflection property of ellipse": {
                                    "count": 1
                                }
                            }
                        },
                        "Diameter of an Ellipse": {
                            "count": 3,
                            "content": {
                                "Definition of diameter, Equation of diameter": {
                                    "count": 1
                                },
                                "Conjugate Diameters of Ellipse": {
                                    "count": 1
                                },
                                "Properties of Conjugate Diameters of an Ellipse": {
                                    "count": 1
                                }
                            }
                        },
                        "Standard properties of ellipse": {
                            "count": 1,
                            "content": {
                                "Standard properties Ellipse": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Hyperbola": {
                    "count": 0,
                    "content": {
                        "Introduction of hyperbola": {
                            "count": 0,
                            "content": {
                                "Definition,Standard equation of hyperbola": {
                                    "count": 0
                                },
                                "Basic terms related to hyperbola": {
                                    "count": 0
                                },
                                "Conjugate hyperbola": {
                                    "count": 0
                                },
                                "Auxiliary circle and Parametric Form": {
                                    "count": 0
                                },
                                "Equation of hyperbola referred to two perpendicular lines as axes": {
                                    "count": 0
                                }
                            }
                        },
                        "Position of a point w.r.t hyperbola": {
                            "count": 0,
                            "content": {
                                "Position of a point w.r.t Hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Equation of chord hyperbola": {
                            "count": 0,
                            "content": {
                                "Equation of chord, condition for focal chord of hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Line and hyperbola": {
                            "count": 0,
                            "content": {
                                "Intersection of line and Hyperbola,Condition of tangency": {
                                    "count": 0
                                }
                            }
                        },
                        "Tangent to Hyperbola": {
                            "count": 0,
                            "content": {
                                "Slope form of Tangent & Point of Contact": {
                                    "count": 0
                                },
                                "Point form & Parametric form of Tangent to Hyperbola": {
                                    "count": 0
                                },
                                "Point of intersection of tangents of Hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Normal to Hyperbola": {
                            "count": 0,
                            "content": {
                                "Point form & Parametric form of Normal to Hyperbola": {
                                    "count": 0
                                },
                                "Slope form of Normal & Point of Contact Hyperbola": {
                                    "count": 0
                                },
                                "Conormal points and their properties": {
                                    "count": 0
                                },
                                "Subtangent and subnormal": {
                                    "count": 0
                                }
                            }
                        },
                        "Reflection property of hyperbola": {
                            "count": 0,
                            "content": {
                                "Reflection property of hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Director Circle Hyperbola": {
                            "count": 0,
                            "content": {
                                "Director circle": {
                                    "count": 0
                                }
                            }
                        },
                        "Chord of Contact of Hyperbola": {
                            "count": 0,
                            "content": {
                                "Chord of contact": {
                                    "count": 0
                                }
                            }
                        },
                        "Chord of Hyperbola with given Midpoint": {
                            "count": 0,
                            "content": {
                                "Chord bisected at a given point": {
                                    "count": 0
                                }
                            }
                        },
                        "Pair of Tangents to Hyperbola from an Outside Point": {
                            "count": 0,
                            "content": {
                                "Equation of pair of tangents": {
                                    "count": 0
                                }
                            }
                        },
                        "Diameter of Hyperbola": {
                            "count": 0,
                            "content": {
                                "Definition and equation of diameter": {
                                    "count": 0
                                },
                                "Definition and equation of Conjugate Diameters": {
                                    "count": 0
                                },
                                "Properties of Conjugate Diameters of Hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Pole and Polar of hyperbola": {
                            "count": 0,
                            "content": {
                                "Pole and polar of Hyperbola": {
                                    "count": 0
                                }
                            }
                        },
                        "Asymptotes of hyperbola": {
                            "count": 0,
                            "content": {
                                "Definition & Equation of asymptotes": {
                                    "count": 0
                                },
                                "Properties of asymptotes": {
                                    "count": 0
                                }
                            }
                        },
                        "Highlights and Properties of Hyperbola": {
                            "count": 0,
                            "content": {
                                "Highlights and Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Rectangular Hyperbola": {
                            "count": 0,
                            "content": {
                                "General equation of Rectangular Hyperbola": {
                                    "count": 0
                                },
                                "Parametric Coordinates of Rectangular Hyperbola": {
                                    "count": 0
                                },
                                "Equation of chord": {
                                    "count": 0
                                },
                                "Equation of Tangent to Rectangular Hyperbola": {
                                    "count": 0
                                },
                                "Equation of Normal Rectangular Hyperbola": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Complex Numbers": {
                    "count": 0,
                    "content": {
                        "Inroduction to complex numbers": {
                            "count": 0,
                            "content": {
                                "Properties of iota,Definition of Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebra of Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Addition,subtraction of Complex Numbers": {
                                    "count": 0
                                },
                                "Multiplication,division of Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Equality of Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Equality of Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Square Roots of Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Square Roots of Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Argand plane": {
                            "count": 0,
                            "content": {
                                "Meaning of Argand Plane Geometrical representation of Complex Numbers": {
                                    "count": 0
                                },
                                "Parallelogram Law for Complex Numbers": {
                                    "count": 0
                                },
                                "Geometrical representation of algebra of complex number": {
                                    "count": 0
                                }
                            }
                        },
                        "Conjugate of a Complex Number": {
                            "count": 0,
                            "content": {
                                "Conjugate,Geometrical position of conjugate": {
                                    "count": 0
                                },
                                "Properties of conjugate": {
                                    "count": 0
                                }
                            }
                        },
                        "Modulus of a complex number": {
                            "count": 0,
                            "content": {
                                "Modulus,Geometrical meaning of modulus": {
                                    "count": 0
                                },
                                "Properties of Modulus": {
                                    "count": 0
                                },
                                "Triangle Inequality in Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Amplitude and argument": {
                            "count": 0,
                            "content": {
                                "Argument ,Geometrical meaning,Rules for finding argument": {
                                    "count": 0
                                },
                                "Properties of argument": {
                                    "count": 0
                                }
                            }
                        },
                        "Other forms of complex number": {
                            "count": 0,
                            "content": {
                                "Polar form ,Euler form": {
                                    "count": 0
                                }
                            }
                        },
                        "De- moivre's Theorem": {
                            "count": 0,
                            "content": {
                                "De-moivre’s theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "Concept of Rotation in Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Concept of Rotation in Complex Numbers": {
                                    "count": 0
                                },
                                "Relation between vertices of equilateral triangle": {
                                    "count": 0
                                }
                            }
                        },
                        "Roots of unity": {
                            "count": 0,
                            "content": {
                                "Cube roots of unity,Properties,Geometrical representation of cube roots of unity": {
                                    "count": 0
                                },
                                "Nth roots of unity,Properties,Geometrical representation of nth roots of unity": {
                                    "count": 0
                                }
                            }
                        },
                        "Geometry of complex number": {
                            "count": 0,
                            "content": {
                                "Distance and Section formula in Complex Number": {
                                    "count": 0
                                },
                                "Straight line in Complex Numbers": {
                                    "count": 0
                                },
                                "Parametric form of straight line": {
                                    "count": 0
                                },
                                "General equation of a straight line & Complex slope": {
                                    "count": 0
                                },
                                "Equation of line parallel and perpendicular to a given line": {
                                    "count": 0
                                },
                                "Equation of perpendicular bisector": {
                                    "count": 0
                                },
                                "Distance of a given point from a given line": {
                                    "count": 0
                                },
                                "Centroid ,incentre , orthocentre ,circumcentre": {
                                    "count": 0
                                },
                                "Area of a triangle": {
                                    "count": 0
                                },
                                "Equation of Circle,Centre and radius": {
                                    "count": 0
                                },
                                "Condition for four points to be concyclic": {
                                    "count": 0
                                },
                                "Equation of Parabolas, Ellipse,Hyperbola in Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Log of Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Log of Complex Numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Trigonometric series by complex number": {
                            "count": 0,
                            "content": {
                                "Sum of Trigonometric Series using de Moivre's Theorem": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Properties of Triangle": {
                    "count": 0,
                    "content": {
                        "Basic rules in trigonometry": {
                            "count": 0,
                            "content": {
                                "Sine Rule": {
                                    "count": 0
                                },
                                "Cosine Rule": {
                                    "count": 0
                                },
                                "Napier's Analogy": {
                                    "count": 0
                                },
                                "Projection Formula": {
                                    "count": 0
                                }
                            }
                        },
                        "Formula for Half angle of Triangles": {
                            "count": 0,
                            "content": {
                                "sin A/2, sin B/2, sin C/2": {
                                    "count": 0
                                },
                                "cos A/2, cos B/2, cos C/2": {
                                    "count": 0
                                },
                                "tan A/2, tan B/2, tan C/2": {
                                    "count": 0
                                }
                            }
                        },
                        "Area of Traingle & Heron's Formula": {
                            "count": 0,
                            "content": {
                                "∆=½ab sinC=½bc sinA=½ca sinB½ base. height": {
                                    "count": 0
                                },
                                "∆=abc/4R": {
                                    "count": 0
                                },
                                "Heron's formula": {
                                    "count": 0
                                }
                            }
                        },
                        "M-N Theorem": {
                            "count": 0,
                            "content": {
                                "M-N Theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "Circumcentre and Incentre in Trigonometry": {
                            "count": 0,
                            "content": {
                                "Cirucumcentre, Incentre, in-radius r = ∆/s": {
                                    "count": 0
                                },
                                "r=(s-a) tan A/2=(s-b) tanB/2= (s-c) tanC/2": {
                                    "count": 0
                                },
                                "r= 4RsinA/2sinB/2sinC/2": {
                                    "count": 0
                                },
                                "Distance of incenter from vertex": {
                                    "count": 0
                                },
                                "Length of angle bisector": {
                                    "count": 0
                                }
                            }
                        },
                        "Centroid and Medians": {
                            "count": 0,
                            "content": {
                                "Centroid, Length of medians": {
                                    "count": 0
                                },
                                "Apollonius theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "Excentre and Ex-radii of a triangle": {
                            "count": 0,
                            "content": {
                                "Def of excentre, ex-radius, 𝑟₁=StanA/2, 𝑟₂=..., 𝑟₃=..": {
                                    "count": 0
                                },
                                "𝑟₁=∆/s-a, 𝑟₂=∆/s-b, 𝑟₃=∆/s-c": {
                                    "count": 0
                                },
                                "𝑟₁=4RsinA/2cosB/2cosC/2, 𝑟₂=..., 𝑟₃=...": {
                                    "count": 0
                                }
                            }
                        },
                        "Orthocentre and Pedal Triangle": {
                            "count": 0,
                            "content": {
                                "Orthocentre def, Distance from vertices and sides": {
                                    "count": 0
                                },
                                "Distance between special points of traingle": {
                                    "count": 0
                                },
                                "Pedal triangle def, sides, angles": {
                                    "count": 0
                                },
                                "Circumradius & area of pedal triangle": {
                                    "count": 0
                                }
                            }
                        },
                        "Quadrilateral": {
                            "count": 0,
                            "content": {
                                "Area of Quadrilateral, cyclic Quadrilateral": {
                                    "count": 0
                                },
                                "Cosine of angle of cyclic Quadrilateral": {
                                    "count": 0
                                },
                                "Ptolemy's theorem": {
                                    "count": 0
                                },
                                "Regular polygon": {
                                    "count": 0
                                }
                            }
                        },
                        "Solution of Triangles (ambiguous cases)": {
                            "count": 0,
                            "content": {
                                "Solution of Triangles (ambiguous cases)": {
                                    "count": 0
                                }
                            }
                        },
                        "Cosine Rule Complex Numbers": {
                            "count": 0,
                            "content": {
                                "Cosine Rule Complex Numbers": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Functions": {
                    "count": 32,
                    "content": {
                        "Functions": {
                            "count": 3,
                            "content": {
                                "Introduction of Function, Explicit and Implicit Functions": {
                                    "count": 1
                                },
                                "Definition of domain,Rules for the domain of function": {
                                    "count": 1
                                },
                                "Definition of co-domain,range,Rules for range": {
                                    "count": 1
                                }
                            }
                        },
                        "Graphs and Properties of Elementary Functions": {
                            "count": 7,
                            "content": {
                                "Constant, Linear, Quadratic": {
                                    "count": 1
                                },
                                "Exponential Functions Basics": {
                                    "count": 1
                                },
                                "Logarithmic Functions Basics": {
                                    "count": 1
                                },
                                "Inverse Trigonometric Functions Basics": {
                                    "count": 1
                                },
                                "Special Function |x|": {
                                    "count": 1
                                },
                                "Special function  [x]": {
                                    "count": 1
                                },
                                "Special function sgn(x) , {x}": {
                                    "count": 1
                                }
                            }
                        },
                        "Transformation of Graphs": {
                            "count": 4,
                            "content": {
                                "f(x±a), f(x)±a": {
                                    "count": 1
                                },
                                "f(kx), kf(x)": {
                                    "count": 1
                                },
                                "f(|x|), |f(x)|": {
                                    "count": 1
                                },
                                "sgn(f(x)), [f(x)], {f(x)}": {
                                    "count": 1
                                }
                            }
                        },
                        "Kinds of functions": {
                            "count": 2,
                            "content": {
                                "One one, Many one Function": {
                                    "count": 1
                                },
                                "Into, Onto Function": {
                                    "count": 1
                                }
                            }
                        },
                        "PnC based Questions": {
                            "count": 3,
                            "content": {
                                "Total number of functions, one one functions": {
                                    "count": 1
                                },
                                "Total no. of many one, constant functions": {
                                    "count": 1
                                },
                                "Total no. of onto & into functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Even and odd functions": {
                            "count": 3,
                            "content": {
                                "Even & odd Function Definition": {
                                    "count": 1
                                },
                                "Properties of odd and even functions": {
                                    "count": 1
                                },
                                "Extension of domain(even,odd)": {
                                    "count": 1
                                }
                            }
                        },
                        "Periodic functions": {
                            "count": 2,
                            "content": {
                                "Periodic Function Definition": {
                                    "count": 1
                                },
                                "Rules for finding period of functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Composite functions": {
                            "count": 2,
                            "content": {
                                "Composite Function Definition": {
                                    "count": 1
                                },
                                "Properties of composite functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Inverse functions": {
                            "count": 2,
                            "content": {
                                "Inverse Functions definition/rules": {
                                    "count": 1
                                },
                                "Properties of inverse functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Binary operation": {
                            "count": 2,
                            "content": {
                                "Definition and Types of Binary Operations": {
                                    "count": 1
                                },
                                "Existence of Identity and Inverse": {
                                    "count": 1
                                }
                            }
                        },
                        "Equal and identical functions": {
                            "count": 1,
                            "content": {
                                "Definition of identical functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Functional Equations": {
                            "count": 1,
                            "content": {
                                "Functional Equations": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Inverse Trignometric Functions": {
                    "count": 0,
                    "content": {
                        "Defination and basics of I.T.F.": {
                            "count": 0,
                            "content": {
                                "Defination & graphs of inverse functions": {
                                    "count": 0
                                }
                            }
                        },
                        "Domain range and graphs of I.T.F": {
                            "count": 0,
                            "content": {
                                "Domain,range and graph of sin⁻¹x, cos⁻¹x": {
                                    "count": 0
                                },
                                "Domain,range and graph of tan⁻¹x, cot⁻¹x": {
                                    "count": 0
                                },
                                "Domain, range and graph of cosec ⁻¹x,sec⁻¹x": {
                                    "count": 0
                                }
                            }
                        },
                        "Relation of f⁻¹(x) with f⁻¹(-x) and f⁻¹(1/x)": {
                            "count": 0,
                            "content": {
                                "Relation of f⁻¹(x) with f⁻¹(-x)&f⁻¹(1/x)": {
                                    "count": 0
                                }
                            }
                        },
                        "Addition of complementary angles": {
                            "count": 0,
                            "content": {
                                "sin⁻¹x+cos⁻¹x=π/2 ,Tan⁻¹x+cot⁻¹x=π/2,sec⁻¹x+cosec⁻¹x=π/2": {
                                    "count": 0
                                }
                            }
                        },
                        "Relation between different I.T.F.": {
                            "count": 0,
                            "content": {
                                "Convert one I.T.F. into others using triangle (0.5)": {
                                    "count": 0
                                },
                                "Simplify expression using trigo substitution (0.5)": {
                                    "count": 0
                                }
                            }
                        },
                        "Domain, range and graph of f(f⁻¹(x))": {
                            "count": 0,
                            "content": {
                                "Sin( sin⁻¹x), cos( cos⁻¹x),tan( tan⁻¹x)": {
                                    "count": 0
                                },
                                "Sec( sec⁻¹x), cosec( cosec⁻¹x),cot( cot⁻¹x)": {
                                    "count": 0
                                }
                            }
                        },
                        "Domain, range and graph of f⁻¹(f(x))": {
                            "count": 0,
                            "content": {
                                "sin⁻¹(sinx),cos⁻¹(cosx)": {
                                    "count": 0
                                },
                                "tan⁻¹(tanx) ,cot⁻¹(cotx)": {
                                    "count": 0
                                },
                                "cosec⁻¹(cosec x),Sec⁻¹(secx)": {
                                    "count": 0
                                }
                            }
                        },
                        "Sum and difference of angles formulas": {
                            "count": 0,
                            "content": {
                                "sin⁻¹x±sin⁻¹y=": {
                                    "count": 0
                                },
                                "cos⁻¹x±cos⁻¹y=": {
                                    "count": 0
                                },
                                "tan⁻¹x±tan⁻¹y=": {
                                    "count": 0
                                }
                            }
                        },
                        "Multiple angle formula": {
                            "count": 0,
                            "content": {
                                "2sin⁻¹x in terms of sin⁻¹x, cos⁻¹x": {
                                    "count": 0
                                },
                                "3sin⁻¹x in terms of sin⁻¹x": {
                                    "count": 0
                                },
                                "2cos⁻¹x & 3cos⁻¹x  in terms of cos⁻¹x": {
                                    "count": 0
                                },
                                "2tan⁻¹x in terms of sin⁻¹x,cos⁻¹x": {
                                    "count": 0
                                },
                                "2tan⁻¹x & 3tan⁻¹x  in terms of tan⁻¹x": {
                                    "count": 0
                                }
                            }
                        },
                        "Some other important concepts": {
                            "count": 0,
                            "content": {
                                "Telescopic series questions": {
                                    "count": 0
                                },
                                "Boundary value problems": {
                                    "count": 0
                                },
                                "Questions on max. and min.": {
                                    "count": 0
                                }
                            }
                        },
                        "Height and distance": {
                            "count": 0,
                            "content": {
                                "Angle of elevation and depression": {
                                    "count": 0
                                },
                                "Method of solving a problem of height and distance": {
                                    "count": 0
                                },
                                "Geometrical properties and formulae for a triangle": {
                                    "count": 0
                                },
                                "Bearing": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Limits": {
                    "count": 19,
                    "content": {
                        "Introduction and basics of limits": {
                            "count": 2,
                            "content": {
                                "Concept of Limits, Existence of Limits": {
                                    "count": 1
                                },
                                "Indeterminate forms, Algebra of Limits": {
                                    "count": 1
                                }
                            }
                        },
                        "Methods of Evaluating Limits": {
                            "count": 3,
                            "content": {
                                "Direct substitution method": {
                                    "count": 1
                                },
                                "Factorization method": {
                                    "count": 1
                                },
                                "Rationalization method": {
                                    "count": 1
                                }
                            }
                        },
                        "Standard limits": {
                            "count": 5,
                            "content": {
                                "Algebraic limits": {
                                    "count": 1
                                },
                                "Trigonometric limits": {
                                    "count": 1
                                },
                                "Inverse trigonometric limits": {
                                    "count": 1
                                },
                                "Exponential limits": {
                                    "count": 1
                                },
                                "Logarithmic limits": {
                                    "count": 1
                                }
                            }
                        },
                        "L’Hopital’s rule": {
                            "count": 1,
                            "content": {
                                "L’Hopital’s rule": {
                                    "count": 1
                                }
                            }
                        },
                        "0⁰,ꝏ⁰ and 1^ꝏ Form": {
                            "count": 2,
                            "content": {
                                "0⁰,ꝏ⁰ Form": {
                                    "count": 1
                                },
                                "1 power  ꝏ form": {
                                    "count": 1
                                }
                            }
                        },
                        "Limits using expansions": {
                            "count": 2,
                            "content": {
                                "Expansion of log(1+x), eˣ, aˣ": {
                                    "count": 1
                                },
                                "Expansion of sinx, cosx, tanx": {
                                    "count": 1
                                }
                            }
                        },
                        "Sandwich theorem": {
                            "count": 1,
                            "content": {
                                "Sandwich theorem": {
                                    "count": 1
                                }
                            }
                        },
                        "Newton leibniz rule": {
                            "count": 1,
                            "content": {
                                "Newton leibniz Rule": {
                                    "count": 1
                                }
                            }
                        },
                        "Limit of composite functions": {
                            "count": 1,
                            "content": {
                                "Limit of composite functions": {
                                    "count": 1
                                }
                            }
                        },
                        "Geometrical Limits": {
                            "count": 1,
                            "content": {
                                "Geometrical Limits": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Continuity and Differentiability": {
                    "count": 19,
                    "content": {
                        "Continuity": {
                            "count": 2,
                            "content": {
                                "Introduction and Meaning of Continuity at a Point": {
                                    "count": 1
                                },
                                "Continuity in an Interval": {
                                    "count": 1
                                }
                            }
                        },
                        "Types of Discontinuity": {
                            "count": 3,
                            "content": {
                                "Removable discontinuity": {
                                    "count": 1
                                },
                                "Finite, Infinite type non-removable discontinuity": {
                                    "count": 1
                                },
                                "Oscillatory type non removable discontinuity": {
                                    "count": 1
                                }
                            }
                        },
                        "Continuity of standard functions": {
                            "count": 2,
                            "content": {
                                "Continuity of Standard Functions": {
                                    "count": 1
                                },
                                "Properties of Continuous Function": {
                                    "count": 1
                                }
                            }
                        },
                        "Continuity of special types of functions": {
                            "count": 4,
                            "content": {
                                "Continuity of Composite Functions": {
                                    "count": 1
                                },
                                "Continuity of functions involving [x], {x}, sgn(x)": {
                                    "count": 1
                                },
                                "Continuity of functions involving limit": {
                                    "count": 1
                                },
                                "Continuity of functions defined differently for rational and irrational": {
                                    "count": 1
                                }
                            }
                        },
                        "Intermediate Value Theorem": {
                            "count": 1,
                            "content": {
                                "Intermediate Value Theorem": {
                                    "count": 1
                                }
                            }
                        },
                        "Differentiability": {
                            "count": 2,
                            "content": {
                                "Introduction and Meaning of Differentiability at a Point": {
                                    "count": 1
                                },
                                "Differentiability in an Interval": {
                                    "count": 1
                                }
                            }
                        },
                        "Reasons for non-differentiability": {
                            "count": 2,
                            "content": {
                                "Corner": {
                                    "count": 1
                                },
                                "Discontinuity, Vertical Tangent": {
                                    "count": 1
                                }
                            }
                        },
                        "Differentiability of standard functions": {
                            "count": 3,
                            "content": {
                                "Differentiability of Standard Functions": {
                                    "count": 1
                                },
                                "Properties of Differentiable Functions": {
                                    "count": 1
                                },
                                "Differentiability by differentiation": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Method of Differentiation": {
                    "count": 0,
                    "content": {
                        "Introduction of derivative": {
                            "count": 0,
                            "content": {
                                "Definition,physical & geometric interpretation": {
                                    "count": 0
                                },
                                "First Principle of derivative dy/dx": {
                                    "count": 0
                                }
                            }
                        },
                        "Differential Coefficients of Standard Function": {
                            "count": 0,
                            "content": {
                                "Differential Coefficient of Algebraic Functions": {
                                    "count": 0
                                },
                                "Differential Coefficient of Trigonometric Functions": {
                                    "count": 0
                                },
                                "Differential Coefficient of Logarithmic Functions": {
                                    "count": 0
                                },
                                "Differential Coefficient of Exponential Functions": {
                                    "count": 0
                                },
                                "Differential Coefficients of Inverse Functions": {
                                    "count": 0
                                },
                                "Differentiation by Substitution": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebra of Differentiation": {
                            "count": 0,
                            "content": {
                                "Addition/Subtraction Rule in Differentiation": {
                                    "count": 0
                                },
                                "Product Rule in Differentiation": {
                                    "count": 0
                                },
                                "Quotient Rule in Differentiation": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation of Composite Functions": {
                            "count": 0,
                            "content": {
                                "Differentiation of Composite Functions,Chain Rule": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation of implicit function": {
                            "count": 0,
                            "content": {
                                "Differentiation of implicit function": {
                                    "count": 0
                                },
                                "Direct formula for implicit functions": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation of Parametric Equations": {
                            "count": 0,
                            "content": {
                                "Differentiation of Parametric Equations": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation using logarithm": {
                            "count": 0,
                            "content": {
                                "Differentiation using logarithm": {
                                    "count": 0
                                }
                            }
                        },
                        "Derivative of Infinite Series": {
                            "count": 0,
                            "content": {
                                "Derivative of Infinite Series": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation of a Function w.r.t Another Function": {
                            "count": 0,
                            "content": {
                                "Differentiation of a Function w.r.t Another Function": {
                                    "count": 0
                                }
                            }
                        },
                        "Differentiation of determinant": {
                            "count": 0,
                            "content": {
                                "Differentiation of Determinant": {
                                    "count": 0
                                }
                            }
                        },
                        "Higher order derivatives": {
                            "count": 0,
                            "content": {
                                "Higher order derivatives": {
                                    "count": 0
                                }
                            }
                        },
                        "Determining a function using first principle": {
                            "count": 0,
                            "content": {
                                "Determining a function using first principle": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Application of Derivatives": {
                    "count": 0,
                    "content": {
                        "Derivative": {
                            "count": 0,
                            "content": {
                                "Definition & Geometrical Meaning of Derivatives": {
                                    "count": 0
                                }
                            }
                        },
                        "Rate Measured in Calculus": {
                            "count": 0,
                            "content": {
                                "Interpretation of dy/dx as a rate measure": {
                                    "count": 0
                                }
                            }
                        },
                        "Error and approximations": {
                            "count": 0,
                            "content": {
                                "Errors and Approximation,Approximate change": {
                                    "count": 0
                                },
                                "Relative change,Percentage change": {
                                    "count": 0
                                }
                            }
                        },
                        "Tangents and normals": {
                            "count": 0,
                            "content": {
                                "Slope of Tangent and Normal line": {
                                    "count": 0
                                },
                                "Equation of tangent & normal": {
                                    "count": 0
                                },
                                "Important points about slope of tangent": {
                                    "count": 0
                                },
                                "Tangent from an external point": {
                                    "count": 0
                                },
                                "Length of tangent,normal": {
                                    "count": 0
                                },
                                "Length of subtangent,subnormal": {
                                    "count": 0
                                },
                                "Angle between curves": {
                                    "count": 0
                                },
                                "Shortest distance between curves": {
                                    "count": 0
                                },
                                "Asymptotes in Calculus": {
                                    "count": 0
                                }
                            }
                        },
                        "Mean value theorem": {
                            "count": 0,
                            "content": {
                                "Rolle's theorem,Geometrical meaning": {
                                    "count": 0
                                },
                                "Lagrange’s mean value theorem,Geometrical meaning": {
                                    "count": 0
                                },
                                "Another form of lagrange’s mean value theorem": {
                                    "count": 0
                                },
                                "Cauchy’s mean value theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "Monotonicity": {
                            "count": 0,
                            "content": {
                                "Introduction of monotonicity": {
                                    "count": 0
                                },
                                "Monotonicity of a function at a point": {
                                    "count": 0
                                },
                                "Monotonicity in an interval": {
                                    "count": 0
                                },
                                "Concavity and Convexity": {
                                    "count": 0
                                },
                                "Point of inflection": {
                                    "count": 0
                                },
                                "Inequalities using monotonicity": {
                                    "count": 0
                                }
                            }
                        },
                        "Maxima and Minima": {
                            "count": 0,
                            "content": {
                                "Critical points": {
                                    "count": 0
                                },
                                "Basic theorem of maxima and minima": {
                                    "count": 0
                                },
                                "Turning points of a function": {
                                    "count": 0
                                },
                                "First Derivative Test": {
                                    "count": 0
                                },
                                "Second Derivative Test": {
                                    "count": 0
                                },
                                "Nth derivative test": {
                                    "count": 0
                                },
                                "Maxima and minima for non differentiable functions": {
                                    "count": 0
                                },
                                "Max and Min Values of Function in Domain": {
                                    "count": 0
                                },
                                "Max and Min Values of Function in an Interval": {
                                    "count": 0
                                },
                                "Concept of global maxima/minima": {
                                    "count": 0
                                },
                                "Application of extremum": {
                                    "count": 0
                                },
                                "Graph of rational function": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Indefinite Integration": {
                    "count": 0,
                    "content": {
                        "Antiderivative Integral": {
                            "count": 0,
                            "content": {
                                "Integration Meaning,Geometrical interpretation": {
                                    "count": 0
                                }
                            }
                        },
                        "Elementary integration": {
                            "count": 0,
                            "content": {
                                "Fundamental integration formula": {
                                    "count": 0
                                },
                                "Properties and algebra of indefinite integration": {
                                    "count": 0
                                }
                            }
                        },
                        "Integration by substitution": {
                            "count": 0,
                            "content": {
                                "Integration of type f(ax+b)": {
                                    "count": 0
                                },
                                "Integration of type f’(x)/f(x)": {
                                    "count": 0
                                },
                                "Integration of typef(g(x)).g’(x)": {
                                    "count": 0
                                },
                                "Integration of type sinᵐx.cosⁿx": {
                                    "count": 0
                                },
                                "Some standard trigonometric substitutions": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebraic integration": {
                            "count": 0,
                            "content": {
                                "Integration of type 1/quadratic": {
                                    "count": 0
                                },
                                "Integration of type linear/quadratic": {
                                    "count": 0
                                },
                                "Integration of type 1/√quadratic": {
                                    "count": 0
                                },
                                "Integration of type linear/√quadratic": {
                                    "count": 0
                                },
                                "Integration of type quadratic/√quadratic": {
                                    "count": 0
                                },
                                "Integration of type √quadratic": {
                                    "count": 0
                                },
                                "Integration of type linear.√quadratic": {
                                    "count": 0
                                },
                                "Integration of type quadratic√quadratic": {
                                    "count": 0
                                },
                                "Integration of type quadratic /ax⁴+b": {
                                    "count": 0
                                },
                                "Integration of type 1/linear√linear": {
                                    "count": 0
                                },
                                "Integration of type 1/linear√quadratic": {
                                    "count": 0
                                },
                                "Integration of type 1/quadratic√linear": {
                                    "count": 0
                                },
                                "Integration of type 1/quadratic√quadratic": {
                                    "count": 0
                                },
                                "Integrals involving Exponentials": {
                                    "count": 0
                                }
                            }
                        },
                        "Some special trigonometric forms": {
                            "count": 0,
                            "content": {
                                "Integration of type 1/(asin²x+bcos²x+c)": {
                                    "count": 0
                                },
                                "Integration of type 1/(asinx+bcosx)²": {
                                    "count": 0
                                },
                                "Integration of type 1/(asinx+bcosx+c)": {
                                    "count": 0
                                },
                                "Integration of type (psinx+qcosx+r)/(asinx+bcosx+c)": {
                                    "count": 0
                                }
                            }
                        },
                        "Miscellaneous Methods of Integration": {
                            "count": 0,
                            "content": {
                                "Integration by parts": {
                                    "count": 0
                                },
                                "Integration by cancellation": {
                                    "count": 0
                                },
                                "Integration by partial fractions": {
                                    "count": 0
                                }
                            }
                        },
                        "Some important concepts": {
                            "count": 0,
                            "content": {
                                "Reduction formula integration": {
                                    "count": 0
                                },
                                "Euler substitution integration": {
                                    "count": 0
                                },
                                "Integrals that cannot be evaluated": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Definite Integration": {
                    "count": 0,
                    "content": {
                        "Introduction of definite integrals": {
                            "count": 0,
                            "content": {
                                "Definition & Geometrical Interpretation of Definite Integrals": {
                                    "count": 0
                                },
                                "Finite Integration - Sign Convention": {
                                    "count": 0
                                },
                                "Improper Definite Integrals": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of Definite Integrals": {
                            "count": 0,
                            "content": {
                                "Changing dummy variable": {
                                    "count": 0
                                },
                                "Interchanging limits": {
                                    "count": 0
                                },
                                "Splitting limits": {
                                    "count": 0
                                },
                                "Definite integration of odd and even functions": {
                                    "count": 0
                                },
                                "King property [ f(x)→ f(a+b-x)]": {
                                    "count": 0
                                },
                                "Splitting limit 0 to 2a into o to a": {
                                    "count": 0
                                },
                                "Properties related to periodic functions": {
                                    "count": 0
                                }
                            }
                        },
                        "Newton leibnitz’s rule": {
                            "count": 0,
                            "content": {
                                "Newton Leibniz Theorem": {
                                    "count": 0
                                },
                                "Functional Equation Involving Definite Integral": {
                                    "count": 0
                                }
                            }
                        },
                        "Inequalities": {
                            "count": 0,
                            "content": {
                                "Properties related to inequalities of definite integral": {
                                    "count": 0
                                },
                                "Estimation of integral": {
                                    "count": 0
                                },
                                "Mean Value Theorem for Definite Integrals": {
                                    "count": 0
                                }
                            }
                        },
                        "Other important concepts": {
                            "count": 0,
                            "content": {
                                "Definite Integral as Limit of Sum": {
                                    "count": 0
                                },
                                "Beta and Gamma Function": {
                                    "count": 0
                                },
                                "Reduction formula": {
                                    "count": 0
                                },
                                "Wallis formula": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Area Under Curves": {
                    "count": 0,
                    "content": {
                        "Curve Sketching for Calculus": {
                            "count": 0,
                            "content": {
                                "Symmetry about x-axis ,y-axis, y=x": {
                                    "count": 0
                                },
                                "Points where curve crosses axes,intervals of monotonicity": {
                                    "count": 0
                                },
                                "Examine when x and y tends to infinity": {
                                    "count": 0
                                }
                            }
                        },
                        "Different cases of bounded area": {
                            "count": 0,
                            "content": {
                                "Area Bounded by Curves and x axis": {
                                    "count": 0
                                },
                                "Area Bounded by Curves and y axis": {
                                    "count": 0
                                },
                                "Area of Between Curves": {
                                    "count": 0
                                },
                                "Area of Standard Curves": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Differential Equation": {
                    "count": 0,
                    "content": {
                        "Introduction of D.E.": {
                            "count": 0,
                            "content": {
                                "Definition ,Order of Differential Equation": {
                                    "count": 0
                                },
                                "Degree of Differential Equation": {
                                    "count": 0
                                },
                                "Formation of D. E. by Eliminating Arbitrary Constants": {
                                    "count": 0
                                }
                            }
                        },
                        "Solution of differential equations": {
                            "count": 0,
                            "content": {
                                "Variable Separable form of Differential Equations": {
                                    "count": 0
                                },
                                "Reducible to Variable separable form of differential equations": {
                                    "count": 0
                                },
                                "Homogeneous form of Differential Equations": {
                                    "count": 0
                                },
                                "Reducible to homogeneous form of differential equations": {
                                    "count": 0
                                },
                                "Linear Differentiation of Equation": {
                                    "count": 0
                                },
                                "Differential Equations Reducible to Linear Form": {
                                    "count": 0
                                },
                                "Bernoulli Differential Equations": {
                                    "count": 0
                                },
                                "Exact Differential Equation": {
                                    "count": 0
                                }
                            }
                        },
                        "Application of Differential Equations": {
                            "count": 0,
                            "content": {
                                "Physical Application of Differential Equations": {
                                    "count": 0
                                },
                                "Geometrical Application of Differential Equations": {
                                    "count": 0
                                },
                                "Differential Equation Growth and Decay Problems": {
                                    "count": 0
                                },
                                "Differential equation temperature problem": {
                                    "count": 0
                                },
                                "Differential equation dilution problems": {
                                    "count": 0
                                },
                                "Differential Equation in Circuits": {
                                    "count": 0
                                }
                            }
                        },
                        "Trajectory in Differential Equations": {
                            "count": 0,
                            "content": {
                                "Isogonal & Orthogonal trajectories": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Matrices and Determinants": {
                    "count": 0,
                    "content": {
                        "Matrices": {
                            "count": 0,
                            "content": {
                                "Definition,Terms Related to matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of Matrix": {
                            "count": 0,
                            "content": {
                                "Row ,Column, Null Matrix": {
                                    "count": 0
                                },
                                "Horizontal, Vertical, Square Matrix": {
                                    "count": 0
                                },
                                "Triangular Matrix, Upper & lower Triangular Matrix": {
                                    "count": 0
                                },
                                "Diagonal, Scalar Matrix, Unit Matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebra of matrices": {
                            "count": 0,
                            "content": {
                                "Equality Addition and Subtraction of Two Matrices": {
                                    "count": 0
                                },
                                "Multiplication of Scalar to a Matrix": {
                                    "count": 0
                                },
                                "Multiplication of Matrices, Integer Powers of Matrices": {
                                    "count": 0
                                },
                                "Matrix Polynomials, Trace of matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Transpose of a matrix": {
                            "count": 0,
                            "content": {
                                "Transpose,Properties of Transpose of a Matrix": {
                                    "count": 0
                                },
                                "Symmetric,Skew Symmetric Matrix": {
                                    "count": 0
                                },
                                "Properties of Symmetric and Skew Symmetric Matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Adjoint of a Matrix": {
                            "count": 0,
                            "content": {
                                "Adjoint,Properties of Adjoint of a Matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Inverse of Matrix": {
                            "count": 0,
                            "content": {
                                "Inverse,Properties of Inverse of Matrix": {
                                    "count": 0
                                },
                                "Finding Inverse of a Matrix Using Adjoint": {
                                    "count": 0
                                },
                                "Matrix Inverse Using Row Transformation": {
                                    "count": 0
                                }
                            }
                        },
                        "Linear equations": {
                            "count": 0,
                            "content": {
                                "Solution of Linear Equations Using Matrices": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification of matrices": {
                            "count": 0,
                            "content": {
                                "Idempotent Matrix, Periodic Matrix, Nilpotent Matrix": {
                                    "count": 0
                                },
                                "Involutory Matrix, Orthogonal Matrix, Unitary Matrix": {
                                    "count": 0
                                },
                                "Complex Conjugate, Hermitian, Skew Hermitian Matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Determinant": {
                            "count": 0,
                            "content": {
                                "Definition, Minor, Cofactor & Value of Determinant": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of Determinant": {
                            "count": 0,
                            "content": {
                                "Transpose, All zero,Repetition property": {
                                    "count": 0
                                },
                                "Scalar multiplication, Switching property": {
                                    "count": 0
                                },
                                "Sum property, Property of invariance": {
                                    "count": 0
                                },
                                "Factor property": {
                                    "count": 0
                                }
                            }
                        },
                        "Determinant Multiplication": {
                            "count": 0,
                            "content": {
                                "Determinant Multiplication": {
                                    "count": 0
                                }
                            }
                        },
                        "System of Linear Equation": {
                            "count": 0,
                            "content": {
                                "System of Linear Equation, Homogeneous Linear Equations, Cramer's Rule": {
                                    "count": 0
                                }
                            }
                        },
                        "Calculus of determinant": {
                            "count": 0,
                            "content": {
                                "Differentiation of Determinant": {
                                    "count": 0
                                },
                                "Integration of Determinant": {
                                    "count": 0
                                }
                            }
                        },
                        "Cayley-hamilton theorem": {
                            "count": 0,
                            "content": {
                                "Cayley-hamilton theorem, Characteristic equation of a matrix": {
                                    "count": 0
                                }
                            }
                        },
                        "Some other points": {
                            "count": 0,
                            "content": {
                                "Rank of a Matrix": {
                                    "count": 0
                                },
                                "Area of Triangle in Determinant Form": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Vector": {
                    "count": 0,
                    "content": {
                        "Introduction of vectors": {
                            "count": 0,
                            "content": {
                                "Scalar and vector quantities,Representation of vectors": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of vectors": {
                            "count": 0,
                            "content": {
                                "Zero vector, unit vector,Like and unlike vector": {
                                    "count": 0
                                },
                                "Collinear or parallel vector,Coinitial and coterminous vector": {
                                    "count": 0
                                },
                                "Negative of a vector,Equality of vectors,Localised and free vectors": {
                                    "count": 0
                                }
                            }
                        },
                        "Position vector and direction cosines": {
                            "count": 0,
                            "content": {
                                "Definition of position vector,Direction cosines": {
                                    "count": 0
                                },
                                "Direction ratios and magnitude of a vector": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebra of vectors": {
                            "count": 0,
                            "content": {
                                "Triangle law,Parallelogram law  of addition": {
                                    "count": 0
                                },
                                "Properties of vector addition,Subtraction of vectors": {
                                    "count": 0
                                },
                                "Multiplication of a vector by a scalar,Section formula": {
                                    "count": 0
                                }
                            }
                        },
                        "Collinearity and coplanarity of vectors": {
                            "count": 0,
                            "content": {
                                "Relation between two collinear vectors": {
                                    "count": 0
                                },
                                "Test of collinearity of three points": {
                                    "count": 0
                                },
                                "Theorem on coplanar vectors": {
                                    "count": 0
                                },
                                "Test of coplanarity of three vectors": {
                                    "count": 0
                                },
                                "Test of coplanarity of four points": {
                                    "count": 0
                                },
                                "Linear dependence and independence of vectors": {
                                    "count": 0
                                }
                            }
                        },
                        "Product of two vectors": {
                            "count": 0,
                            "content": {
                                "Dot Product of Vectors": {
                                    "count": 0
                                },
                                "Angle between two vectors": {
                                    "count": 0
                                },
                                "Properties of dot product": {
                                    "count": 0
                                },
                                "Projection of Vector a on b": {
                                    "count": 0
                                },
                                "Component of vector along and perpendicular to another vector": {
                                    "count": 0
                                },
                                "Cross product of two vectors": {
                                    "count": 0
                                },
                                "Properties of cross product": {
                                    "count": 0
                                },
                                "Area of a Triangle and parallelogram": {
                                    "count": 0
                                }
                            }
                        },
                        "Product of three vector": {
                            "count": 0,
                            "content": {
                                "Scalar Triple Product,Geometrical interpretation of scalar triple product": {
                                    "count": 0
                                },
                                "Properties of scalar triple product": {
                                    "count": 0
                                },
                                "Volume of tetrahedron": {
                                    "count": 0
                                },
                                "Important points about tetrahedron": {
                                    "count": 0
                                },
                                "Vector Triple Product": {
                                    "count": 0
                                }
                            }
                        },
                        "Product of four vectors": {
                            "count": 0,
                            "content": {
                                "Scalar product of four vectors": {
                                    "count": 0
                                },
                                "Vector product of four vectors": {
                                    "count": 0
                                }
                            }
                        },
                        "Reciprocal System of Vectors": {
                            "count": 0,
                            "content": {
                                "Definition,Properties of reciprocal system of vectors": {
                                    "count": 0
                                }
                            }
                        },
                        "Solution of vector equation": {
                            "count": 0,
                            "content": {
                                "Solution of vector equation": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Three Dimensional Geometrical System": {
                    "count": 0,
                    "content": {
                        "Introduction of 3D": {
                            "count": 0,
                            "content": {
                                "Coordinate of a Point in 3D,Vector Joining Two Points": {
                                    "count": 0
                                },
                                "Distance Formula 3D": {
                                    "count": 0
                                },
                                "Section Formula in 3D Geometry": {
                                    "count": 0
                                }
                            }
                        },
                        "Important Points in a Triangle 3 Dimensional Geometry": {
                            "count": 0,
                            "content": {
                                "Centroid,Incentre of a Triangle in 3D": {
                                    "count": 0
                                },
                                "Orthocentre,Circumcenter of a Triangle in 3D": {
                                    "count": 0
                                }
                            }
                        },
                        "Direction cosine and direction ratios": {
                            "count": 0,
                            "content": {
                                "Direction cosines & Direction Ratios of a Line Joining Two Points": {
                                    "count": 0
                                }
                            }
                        },
                        "Line in 3D and Vectors": {
                            "count": 0,
                            "content": {
                                "Line Passes through a point Parallel to given Vector": {
                                    "count": 0
                                },
                                "Line Passing through one point, given Direction Ratio Parallel to line": {
                                    "count": 0
                                },
                                "Line Joining two Position Vectors( two points) in 3D": {
                                    "count": 0
                                },
                                "Equation of Line through one Point Perpendicular to Two given vectors": {
                                    "count": 0
                                },
                                "Angle between two lines in 3D": {
                                    "count": 0
                                },
                                "Angle bisector of two lines (3D)": {
                                    "count": 0
                                },
                                "Parallel & Skew Lines in Vectors": {
                                    "count": 0
                                },
                                "Shortest Distance Between Lines (3D)": {
                                    "count": 0
                                },
                                "Equation of line of Shortest Distance between two Skew Lines": {
                                    "count": 0
                                },
                                "Length of Perpendicular from given Point to Other Line (3D)": {
                                    "count": 0
                                },
                                "Foot of Perpendicular & Image of a Point on a Line/Plane in 3D": {
                                    "count": 0
                                },
                                "Point of intersection of two lines in 3D": {
                                    "count": 0
                                }
                            }
                        },
                        "Plane in 3D and vectors": {
                            "count": 0,
                            "content": {
                                "General equation & Normal form of plane": {
                                    "count": 0
                                },
                                "Equation of a Plane through one Point and one perpendicular vector": {
                                    "count": 0
                                },
                                "Plane Passing through a point and Containing given Line (3D)": {
                                    "count": 0
                                },
                                "Intercept form of a Plane (3D)": {
                                    "count": 0
                                },
                                "Equation of plane through three given points": {
                                    "count": 0
                                },
                                "Equation of plane through given point and parallel to two given vectors": {
                                    "count": 0
                                },
                                "Equation of plane in parametric form": {
                                    "count": 0
                                },
                                "Equation of plane parallel to a given plane": {
                                    "count": 0
                                }
                            }
                        },
                        "Foot of perpendicular and image of a point in plane": {
                            "count": 0,
                            "content": {
                                "Length of Perpendicular from a Point on a Plane in 3D": {
                                    "count": 0
                                },
                                "Foot of Perpendicular from a Point on a Plane (3D)": {
                                    "count": 0
                                },
                                "Distance between two parallel planes": {
                                    "count": 0
                                }
                            }
                        },
                        "Angle between two planes": {
                            "count": 0,
                            "content": {
                                "Angle between two Planes,Condition for parallel planes": {
                                    "count": 0
                                }
                            }
                        },
                        "Angle Bisector between two Planes (3D)": {
                            "count": 0,
                            "content": {
                                "Equation of angle bisector plane": {
                                    "count": 0
                                },
                                "Angle bisector containing/not containing a point": {
                                    "count": 0
                                },
                                "Bisector of acute/obtuse angle": {
                                    "count": 0
                                }
                            }
                        },
                        "Family of planes": {
                            "count": 0,
                            "content": {
                                "Equation of line of Intersection of two Plane (3D)": {
                                    "count": 0
                                },
                                "Planes Passing through line of Intersection of two Planes": {
                                    "count": 0
                                }
                            }
                        },
                        "Plane and Line": {
                            "count": 0,
                            "content": {
                                "Intersection Point of Line and Plane (3D)": {
                                    "count": 0
                                },
                                "Angle between Line and Plane (3D)": {
                                    "count": 0
                                },
                                "Condition for a line to lie in given plane": {
                                    "count": 0
                                },
                                "Condition for two lines to be coplanar": {
                                    "count": 0
                                },
                                "Reflection of a Line by Plane (3D)": {
                                    "count": 0
                                },
                                "Projection of a Line on Plane (3D)": {
                                    "count": 0
                                },
                                "Line of Greatest Slope (3D)": {
                                    "count": 0
                                }
                            }
                        },
                        "Sphere": {
                            "count": 0,
                            "content": {
                                "Equation of sphere with centre and radius": {
                                    "count": 0
                                },
                                "General equation,diametric form of sphere": {
                                    "count": 0
                                },
                                "Section of a sphere by a plane": {
                                    "count": 0
                                },
                                "Condition of tangency of a plane to a sphere": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Set Theory and Relations": {
                    "count": 0,
                    "content": {
                        "Sets and their representation": {
                            "count": 0,
                            "content": {
                                "Definition of Set": {
                                    "count": 0
                                },
                                "Roster Method, Set-Builder Method": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of Sets": {
                            "count": 0,
                            "content": {
                                "Empty set, Singleton set, Finite set, Infinite set": {
                                    "count": 0
                                },
                                "Equal sets, Equivalent sets": {
                                    "count": 0
                                },
                                "Universal set, Cardinal number of a finite set": {
                                    "count": 0
                                }
                            }
                        },
                        "SubSet and power set": {
                            "count": 0,
                            "content": {
                                "Definition & Number of subset": {
                                    "count": 0
                                },
                                "Proper, Improper subsets": {
                                    "count": 0
                                },
                                "Power set": {
                                    "count": 0
                                }
                            }
                        },
                        "Operations on Sets": {
                            "count": 0,
                            "content": {
                                "Union of sets, Intersection of sets": {
                                    "count": 0
                                },
                                "Difference, Symmetric difference of two sets": {
                                    "count": 0
                                },
                                "Complement of sets": {
                                    "count": 0
                                },
                                "Venn Diagram": {
                                    "count": 0
                                },
                                "Properties of Set Operation, De morgan’s law": {
                                    "count": 0
                                }
                            }
                        },
                        "Formulas on operation of  sets": {
                            "count": 0,
                            "content": {
                                "Formula for union of two/three sets": {
                                    "count": 0
                                },
                                "Formula for difference of two sets": {
                                    "count": 0
                                },
                                "Some other formulas": {
                                    "count": 0
                                }
                            }
                        },
                        "Cartesian Product of Sets": {
                            "count": 0,
                            "content": {
                                "Ordered pairs": {
                                    "count": 0
                                },
                                "Cartesian product of two sets": {
                                    "count": 0
                                },
                                "Theorem in Cartesian Product of Sets": {
                                    "count": 0
                                }
                            }
                        },
                        "Relations": {
                            "count": 0,
                            "content": {
                                "Definition & Representation of Relations": {
                                    "count": 0
                                },
                                "Domain and Range and co-domain  of Relation": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of Relations": {
                            "count": 0,
                            "content": {
                                "Identity, Void, Universal Relation": {
                                    "count": 0
                                },
                                "Reflexive Relation": {
                                    "count": 0
                                },
                                "Symmetric, Antisymmetric Relation": {
                                    "count": 0
                                },
                                "Transitive Relation": {
                                    "count": 0
                                },
                                "Equivalence Relation": {
                                    "count": 0
                                },
                                "Inverse Relation in Set Theory": {
                                    "count": 0
                                }
                            }
                        },
                        "Other important points": {
                            "count": 0,
                            "content": {
                                "Order of a Relation, Partial Order Relation": {
                                    "count": 0
                                },
                                "Composition of two Relations": {
                                    "count": 0
                                },
                                "Number of Relations": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Mathematical Induction": {
                    "count": 0,
                    "content": {
                        "Mathematical Statement Logic & principle": {
                            "count": 0,
                            "content": {
                                "Mathematical statement logic, First Principle of mathematical induction": {
                                    "count": 0
                                },
                                "Second principle of mathematical induction": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Statistics": {
                    "count": 0,
                    "content": {
                        "Fundamentel of Statistics": {
                            "count": 0,
                            "content": {
                                "Mean of individual,discrete & continuous frequency distribution": {
                                    "count": 0
                                },
                                "Median of individual,discrete & continuous frequency distribution": {
                                    "count": 0
                                },
                                "Mode of individual,discrete & continuous frequency distribution": {
                                    "count": 0
                                },
                                "Empirical Relations in Statistics": {
                                    "count": 0
                                },
                                "Combined mean of two series": {
                                    "count": 0
                                },
                                "Weighted mean": {
                                    "count": 0
                                }
                            }
                        },
                        "Range in Dispersion": {
                            "count": 0,
                            "content": {
                                "Range in Dispersion in Statistics": {
                                    "count": 0
                                }
                            }
                        },
                        "Mean deviation": {
                            "count": 0,
                            "content": {
                                "Mean Deviation about Mean and Median Individual Observation": {
                                    "count": 0
                                },
                                "Mean Deviation for Discrete Frequency Distribution": {
                                    "count": 0
                                },
                                "Mean Deviation for Continuous Frequency Distribution": {
                                    "count": 0
                                },
                                "Merits and Limitations of Mean Deviation": {
                                    "count": 0
                                },
                                "Coefficient Mean Deviation in Statistics": {
                                    "count": 0
                                }
                            }
                        },
                        "Variance and standard deviation": {
                            "count": 0,
                            "content": {
                                "Variance definition Variance of Individual Observation in Statistics": {
                                    "count": 0
                                },
                                "Variance of Discrete Frequency Distribution in Statistics": {
                                    "count": 0
                                },
                                "Variance of Continuous Frequency Distribution in Statistics": {
                                    "count": 0
                                },
                                "Standard Deviation in Statistics": {
                                    "count": 0
                                },
                                "Coefficient of Variation in Statistics": {
                                    "count": 0
                                },
                                "Coefficient Standard Deviation in Statistics": {
                                    "count": 0
                                }
                            }
                        },
                        "Quartile deviation": {
                            "count": 0,
                            "content": {
                                "Quartile & Quartile Deviation in Statistics": {
                                    "count": 0
                                }
                            }
                        },
                        "Skewness": {
                            "count": 0,
                            "content": {
                                "Skewed distribution and skewness": {
                                    "count": 0
                                },
                                "Positively & Negatively skewed distribution": {
                                    "count": 0
                                },
                                "Karl pearson’s coefficient of skewness": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Mathematical Reasoning": {
                    "count": 0,
                    "content": {
                        "Statement": {
                            "count": 0,
                            "content": {
                                "Statements in mathematical reasoning": {
                                    "count": 0
                                },
                                "Simple & Compound statements": {
                                    "count": 0
                                },
                                "Negation of a statement": {
                                    "count": 0
                                }
                            }
                        },
                        "Logical connectives": {
                            "count": 0,
                            "content": {
                                "Connective ‘and’ ,Truth Value & Truth Table": {
                                    "count": 0
                                },
                                "Connective ‘or’ ,Truth Value & Truth Table": {
                                    "count": 0
                                }
                            }
                        },
                        "Quantifiers": {
                            "count": 0,
                            "content": {
                                "Quantifiers (there exixts, for all)": {
                                    "count": 0
                                }
                            }
                        },
                        "Implications": {
                            "count": 0,
                            "content": {
                                "Implication'if-then'": {
                                    "count": 0
                                },
                                "Implication 'if and only if'": {
                                    "count": 0
                                },
                                "Truth table for implications": {
                                    "count": 0
                                }
                            }
                        },
                        "Logical Equivalence in mathematical reasoning": {
                            "count": 0,
                            "content": {
                                "Meaning of Logical equivalence": {
                                    "count": 0
                                }
                            }
                        },
                        "Contrapositive, Converse and Contradiction of a statement": {
                            "count": 0,
                            "content": {
                                "Contrapositive, Converse & Contradiction of a statement": {
                                    "count": 0
                                }
                            }
                        },
                        "Tautology and fallacy": {
                            "count": 0,
                            "content": {
                                "tautology & fallacy in mathematical reasoning": {
                                    "count": 0
                                }
                            }
                        },
                        "Validity of a statement": {
                            "count": 0,
                            "content": {
                                "Validity of statement’p and q’,’p or q’": {
                                    "count": 0
                                },
                                "Validity of statement’if p then q',’p if and only if q'": {
                                    "count": 0
                                }
                            }
                        },
                        "Algebra of statements": {
                            "count": 0,
                            "content": {
                                "Idempotent Law,Commutative Law": {
                                    "count": 0
                                },
                                "Distributive Law,De morgan's Law": {
                                    "count": 0
                                },
                                "Identity law,Complement Law ": {
                                    "count": 0
                                }
                            }
                        }
                    }
                }
            }
        },
        "Physics": {
            "count": 454,
            "content": {
                "Mathematics in physics": {
                    "count": 17,
                    "content": {
                        "Basic functions used in physics": {
                            "count": 2,
                            "content": {
                                "Linear function": {
                                    "count": 1
                                },
                                "Logarithm and exponential": {
                                    "count": 1
                                }
                            }
                        },
                        "Graph plotting": {
                            "count": 2,
                            "content": {
                                "Straight line and parabola": {
                                    "count": 1
                                },
                                "Shifting basics in graphs": {
                                    "count": 1
                                }
                            }
                        },
                        "Introduction to calculus": {
                            "count": 13,
                            "content": {
                                "Derivative of basic functions": {
                                    "count": 1
                                },
                                "dy/dx as rate measurer": {
                                    "count": 1
                                },
                                "Graphical interpretation(slope)": {
                                    "count": 1
                                },
                                "Maxima and minima": {
                                    "count": 1
                                },
                                "Integrals of Basic functions": {
                                    "count": 1
                                },
                                "Area under a curve": {
                                    "count": 1
                                },
                                "Basic usage in Physics": {
                                    "count": 1
                                },
                                "Product rule in differentiation": {
                                    "count": 1
                                },
                                "Quotent rule in differentiation": {
                                    "count": 1
                                },
                                "Chain rule in differentiation": {
                                    "count": 1
                                },
                                "Differentiation of implicit function": {
                                    "count": 1
                                },
                                "Integration by substitution": {
                                    "count": 1
                                },
                                "Integration by product rule": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Units and dimensions": {
                    "count": 0,
                    "content": {
                        "Physical quantities": {
                            "count": 0,
                            "content": {
                                "Fundamental and derived quantities": {
                                    "count": 0
                                }
                            }
                        },
                        "Units": {
                            "count": 0,
                            "content": {
                                "Characteristics of standard units": {
                                    "count": 0
                                },
                                "SI system of units & SI prefixes": {
                                    "count": 0
                                }
                            }
                        },
                        "Dimensions": {
                            "count": 0,
                            "content": {
                                "Dimensions of fundamental and derived quantities": {
                                    "count": 0
                                },
                                "Dimensional Analysis": {
                                    "count": 0
                                },
                                "Deriving New Formula using Dimensional Analysis": {
                                    "count": 0
                                },
                                "Converting from One Unit to Another using Dimensional Analysis": {
                                    "count": 0
                                },
                                "Writting dimensional formula in a new system": {
                                    "count": 0
                                }
                            }
                        },
                        "Errors": {
                            "count": 0,
                            "content": {
                                "Mean absolute error": {
                                    "count": 0
                                },
                                "Relative or Fractional Error": {
                                    "count": 0
                                },
                                "Accuracy and Precision": {
                                    "count": 0
                                },
                                "Uncertainties and Error Propagation": {
                                    "count": 0
                                }
                            }
                        },
                        "Propogation of errors": {
                            "count": 0,
                            "content": {
                                "Error Propagation Subtraction and Addition": {
                                    "count": 0
                                },
                                "Error Propagation Multiplication and Division": {
                                    "count": 0
                                },
                                "Error Propagation in case of Exponential": {
                                    "count": 0
                                }
                            }
                        },
                        "Rounding off & significant figures": {
                            "count": 0,
                            "content": {
                                "Rounding off": {
                                    "count": 0
                                },
                                "Significant figures": {
                                    "count": 0
                                },
                                "Significant figures in +/- and product/division": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Vectors": {
                    "count": 17,
                    "content": {
                        "Scalars and vectors": {
                            "count": 3,
                            "content": {
                                "Types of quantities.": {
                                    "count": 1
                                },
                                "Definition of vectors": {
                                    "count": 1
                                },
                                "General, Graphical and Algebraic Representation": {
                                    "count": 1
                                }
                            }
                        },
                        "Types and properties of vectors": {
                            "count": 3,
                            "content": {
                                "Equal Vectors.": {
                                    "count": 1
                                },
                                "Null & unit vector": {
                                    "count": 1
                                },
                                "Collinear and coplanar vectors": {
                                    "count": 1
                                }
                            }
                        },
                        "Vector addition and subtraction": {
                            "count": 6,
                            "content": {
                                "Triangle law of addition": {
                                    "count": 1
                                },
                                "Parallelogram law": {
                                    "count": 1
                                },
                                "Magnitude and direction of the resultant (Cosine law).": {
                                    "count": 1
                                },
                                "Triangle inequality and its application.": {
                                    "count": 1
                                },
                                "Lami’s Theorem.": {
                                    "count": 1
                                },
                                "Polygon law": {
                                    "count": 1
                                }
                            }
                        },
                        "Components of vector": {
                            "count": 3,
                            "content": {
                                "Component along and perpendicular to a direction.": {
                                    "count": 1
                                },
                                "X and Y component.": {
                                    "count": 1
                                },
                                "Introduction to basic vectors ( i, j ,k)": {
                                    "count": 1
                                }
                            }
                        },
                        "Product of vectors": {
                            "count": 2,
                            "content": {
                                "Scalar Product and application": {
                                    "count": 1
                                },
                                "Vector Product and application": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Motion in one dimension": {
                    "count": 26,
                    "content": {
                        "Parameters used in particle kinematics": {
                            "count": 4,
                            "content": {
                                "Position, Displacement and distance": {
                                    "count": 1
                                },
                                "Average and Instantaneous speed": {
                                    "count": 1
                                },
                                "Average and Instantaneous velocity": {
                                    "count": 1
                                },
                                "Average and Instantaneous acceleration": {
                                    "count": 1
                                }
                            }
                        },
                        "Equation of motion": {
                            "count": 10,
                            "content": {
                                "Uniformly accelerated motion": {
                                    "count": 1
                                },
                                "Velocity - time relation": {
                                    "count": 1
                                },
                                "Displacement - time relation": {
                                    "count": 1
                                },
                                "Displacement - velocity relation": {
                                    "count": 1
                                },
                                "Displacement in Nth second": {
                                    "count": 1
                                },
                                "Non uniform motion": {
                                    "count": 1
                                },
                                "Reaction time": {
                                    "count": 1
                                },
                                "Crossing/overtaking of two trains": {
                                    "count": 1
                                },
                                "Use of multiple equation of motion": {
                                    "count": 1
                                },
                                "Misclleneous based on Dist=speed*time": {
                                    "count": 1
                                }
                            }
                        },
                        "Non uniform motion": {
                            "count": 1,
                            "content": {
                                "Application of calculus in one - dimensional motion": {
                                    "count": 1
                                }
                            }
                        },
                        "Motion under gravity in 1D": {
                            "count": 3,
                            "content": {
                                "Time of Ascent and descent.": {
                                    "count": 1
                                },
                                "Max height": {
                                    "count": 1
                                },
                                "Particle projected from top of building": {
                                    "count": 1
                                }
                            }
                        },
                        "Graphs": {
                            "count": 6,
                            "content": {
                                "Position - time graph": {
                                    "count": 1
                                },
                                "Velocity - time graph": {
                                    "count": 1
                                },
                                "Acceleration – time graph.": {
                                    "count": 1
                                },
                                "Drawing Graph from Equation of Motion": {
                                    "count": 1
                                },
                                "Inter-conversion between graphs (V, S, T, A)": {
                                    "count": 1
                                },
                                "General graphs in kinematics": {
                                    "count": 1
                                }
                            }
                        },
                        "Relative Motion": {
                            "count": 2,
                            "content": {
                                "Motion in Same direction": {
                                    "count": 1
                                },
                                "Motion in opposite direction": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Motion in two dimensions": {
                    "count": 16,
                    "content": {
                        "Basics of 2D": {
                            "count": 1,
                            "content": {
                                "Gallelio statement and use of vectors": {
                                    "count": 1
                                }
                            }
                        },
                        "Projectile motion": {
                            "count": 7,
                            "content": {
                                "Equation of motion for projectile": {
                                    "count": 1
                                },
                                "Horizontal range": {
                                    "count": 1
                                },
                                "Maximum height of projectile": {
                                    "count": 1
                                },
                                "Time of flight": {
                                    "count": 1
                                },
                                "Horizontal Projection": {
                                    "count": 1
                                },
                                "Equation of trajectory": {
                                    "count": 1
                                },
                                "Collision of two projectile in mid air": {
                                    "count": 1
                                }
                            }
                        },
                        "Projectile on inclined plane": {
                            "count": 2,
                            "content": {
                                "Projectile fired up the plane": {
                                    "count": 1
                                },
                                "Projectile fired down the plane": {
                                    "count": 1
                                }
                            }
                        },
                        "Relative motion in 2D": {
                            "count": 6,
                            "content": {
                                "Relative Motion between Two Projectiles": {
                                    "count": 1
                                },
                                "Minimum distance between two bodies in motion": {
                                    "count": 1
                                },
                                "River boat problems": {
                                    "count": 1
                                },
                                "Aircraft wind problems": {
                                    "count": 1
                                },
                                "Rain - Man Problems": {
                                    "count": 1
                                },
                                "Velocity of seperation and approch": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Newton's law of motion": {
                    "count": 42,
                    "content": {
                        "Introduction to forces": {
                            "count": 2,
                            "content": {
                                "Fundamental forces": {
                                    "count": 1
                                },
                                "Normal, Tension and friction": {
                                    "count": 1
                                }
                            }
                        },
                        "Newton's law of motion": {
                            "count": 4,
                            "content": {
                                "First law (Law of inertia)": {
                                    "count": 1
                                },
                                "Momentum and its significance": {
                                    "count": 1
                                },
                                "Second law": {
                                    "count": 1
                                },
                                "Third law": {
                                    "count": 1
                                }
                            }
                        },
                        "Equilibrium of forces": {
                            "count": 2,
                            "content": {
                                "Concurrent and Coplanar Forces": {
                                    "count": 1
                                },
                                "Equilibrium of forces": {
                                    "count": 1
                                }
                            }
                        },
                        "Frame of reference": {
                            "count": 2,
                            "content": {
                                "Inertial and non-inertial F.O.R.": {
                                    "count": 1
                                },
                                "Pseudo Forces": {
                                    "count": 1
                                }
                            }
                        },
                        "Free body diagram": {
                            "count": 4,
                            "content": {
                                "Fundamentals of drawing F.B.D.": {
                                    "count": 1
                                },
                                "Train problems(Tension)": {
                                    "count": 1
                                },
                                "Lift Problems(Normal)": {
                                    "count": 1
                                },
                                "Wedge problems(Resolving Forces)": {
                                    "count": 1
                                }
                            }
                        },
                        "Constraint motion": {
                            "count": 3,
                            "content": {
                                "String and pulley constraint(Tension)": {
                                    "count": 1
                                },
                                "Wedge constraint(Normal)": {
                                    "count": 1
                                },
                                "Rigid Body Constraint(Introduction)": {
                                    "count": 1
                                }
                            }
                        },
                        "Pulley problems": {
                            "count": 3,
                            "content": {
                                "Simple pulley system": {
                                    "count": 1
                                },
                                "Complex Pulley system": {
                                    "count": 1
                                },
                                "Mechanical advantage": {
                                    "count": 1
                                }
                            }
                        },
                        "Spring": {
                            "count": 5,
                            "content": {
                                "Spring constant": {
                                    "count": 1
                                },
                                "Combination of Spring(parallel and series)": {
                                    "count": 1
                                },
                                "Spring pulley system": {
                                    "count": 1
                                },
                                "Equivalent Spring constant": {
                                    "count": 1
                                },
                                "Cutting of spring and string.": {
                                    "count": 1
                                }
                            }
                        },
                        "Pseudo force": {
                            "count": 1,
                            "content": {
                                "Problems involving pseudo force": {
                                    "count": 1
                                }
                            }
                        },
                        "Friction": {
                            "count": 10,
                            "content": {
                                "Types of Friction": {
                                    "count": 1
                                },
                                "Direction of static and kinetic friction": {
                                    "count": 1
                                },
                                "Coefficient of friction": {
                                    "count": 1
                                },
                                "Limiting value of friction": {
                                    "count": 1
                                },
                                "Angle of friction": {
                                    "count": 1
                                },
                                "Transition of friction from static to kinetic": {
                                    "count": 1
                                },
                                "Contact Force": {
                                    "count": 1
                                },
                                "Angle of repose": {
                                    "count": 1
                                },
                                "Two block problem": {
                                    "count": 1
                                },
                                "Three or more block problem": {
                                    "count": 1
                                }
                            }
                        },
                        "Angle of repose": {
                            "count": 3,
                            "content": {
                                "Motion on incline with angle more than angle of repose": {
                                    "count": 1
                                },
                                "Motion on incline with less than the angle of repose": {
                                    "count": 1
                                },
                                "Motion on vertical surface": {
                                    "count": 1
                                }
                            }
                        },
                        "Multiple block system": {
                            "count": 3,
                            "content": {
                                "Concept of driving force": {
                                    "count": 1
                                },
                                "Method to find which surface slips": {
                                    "count": 1
                                },
                                "Method to find acceleration of blocks": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Work, power and energy": {
                    "count": 27,
                    "content": {
                        "Work done": {
                            "count": 2,
                            "content": {
                                "Definition of work": {
                                    "count": 1
                                },
                                "Work done dependence on reference of frames": {
                                    "count": 1
                                }
                            }
                        },
                        "Work done by different forces": {
                            "count": 5,
                            "content": {
                                "Work done by a constant force": {
                                    "count": 1
                                },
                                "Work done by variable force": {
                                    "count": 1
                                },
                                "Work Done by force along curved path": {
                                    "count": 1
                                },
                                "Work done by Gravity, Spring Force and friction": {
                                    "count": 1
                                },
                                "Work done due to Pseudo and central force": {
                                    "count": 1
                                }
                            }
                        },
                        "Energy": {
                            "count": 2,
                            "content": {
                                "Kinetic energy of a particle": {
                                    "count": 1
                                },
                                "Relation between momentum and kinetic energy of a particle": {
                                    "count": 1
                                }
                            }
                        },
                        "Work energy theorem": {
                            "count": 4,
                            "content": {
                                "Derivation from second law of motion": {
                                    "count": 1
                                },
                                "Work energy theorem for particles": {
                                    "count": 1
                                },
                                "Work energy theorem for a system": {
                                    "count": 1
                                },
                                "Work done by external and internal forces": {
                                    "count": 1
                                }
                            }
                        },
                        "Conservative and non-conservative forces": {
                            "count": 3,
                            "content": {
                                "Definition of conservative forces and work done by it": {
                                    "count": 1
                                },
                                "Definition of non-conservative force and work done by it": {
                                    "count": 1
                                },
                                "Identification of conservative and non-conservative forces": {
                                    "count": 1
                                }
                            }
                        },
                        "Potential energy": {
                            "count": 6,
                            "content": {
                                "Concept of potential energy": {
                                    "count": 1
                                },
                                "Relation between conservative force and potential energy": {
                                    "count": 1
                                },
                                "Spring potential energy": {
                                    "count": 1
                                },
                                "Gravitational Potential Energy": {
                                    "count": 1
                                },
                                "Modified Work-energy Theorem": {
                                    "count": 2
                                }
                            }
                        },
                        "Conservation of mechanical energy": {
                            "count": 1,
                            "content": {
                                "How and when to apply Conservation of energy?": {
                                    "count": 1
                                }
                            }
                        },
                        "Power": {
                            "count": 2,
                            "content": {
                                "Instantaneous & average Power": {
                                    "count": 1
                                },
                                "Efficiency of mechanical systems": {
                                    "count": 1
                                }
                            }
                        },
                        "Virtual work and constraint forces": {
                            "count": 2,
                            "content": {
                                "Virtual Work due to Constraint force.": {
                                    "count": 1
                                },
                                "Finding constraint equation using work.": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Circular Motion": {
                    "count": 19,
                    "content": {
                        "Introduction to circular motion": {
                            "count": 3,
                            "content": {
                                "Angular displacement": {
                                    "count": 1
                                },
                                "Angular velocity": {
                                    "count": 1
                                },
                                "Angular acceleration": {
                                    "count": 1
                                }
                            }
                        },
                        "Kinematics of circular motion": {
                            "count": 6,
                            "content": {
                                "Uniform Circular Motion": {
                                    "count": 1
                                },
                                "Centripetal acceleration.": {
                                    "count": 1
                                },
                                "Non- uniform circular motion": {
                                    "count": 1
                                },
                                "Tangential acceleration": {
                                    "count": 1
                                },
                                "Motion along arbitrary curve": {
                                    "count": 1
                                },
                                "Radius of curvature": {
                                    "count": 1
                                }
                            }
                        },
                        "Dynamics of uniform circular motion": {
                            "count": 5,
                            "content": {
                                "Centripetal force.": {
                                    "count": 1
                                },
                                "Uniform circular motion in a horizontal plane.": {
                                    "count": 1
                                },
                                "Banking of roads.": {
                                    "count": 1
                                },
                                "Death well & conical pendulum.": {
                                    "count": 1
                                },
                                "Effect of earth’s rotation": {
                                    "count": 1
                                }
                            }
                        },
                        "Vertical circular motion": {
                            "count": 3,
                            "content": {
                                "Vertical circular motion of a pendulum bob.": {
                                    "count": 1
                                },
                                "Vertical circular motion of a pendulum connected to a light rigid rod.": {
                                    "count": 1
                                },
                                "Circular motion on a vertical circular track.": {
                                    "count": 1
                                }
                            }
                        },
                        "Rotating frame of reference": {
                            "count": 2,
                            "content": {
                                "Centrifugal force": {
                                    "count": 1
                                },
                                "Relative motion in circular motion": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Centre of mass and momentum conservation (collision)": {
                    "count": 23,
                    "content": {
                        "Centre of mass (COM)": {
                            "count": 5,
                            "content": {
                                "COM of a system of point particles.": {
                                    "count": 1
                                },
                                "COM of continuous mass distribution.": {
                                    "count": 1
                                },
                                "COM of bodies having variable density": {
                                    "count": 1
                                },
                                "COM of composite shape.": {
                                    "count": 1
                                },
                                "COM of body with cavities.": {
                                    "count": 1
                                }
                            }
                        },
                        "Motion of centre of mass": {
                            "count": 5,
                            "content": {
                                "Displacement of Centre of mass": {
                                    "count": 1
                                },
                                "Velocity of Centre of mass": {
                                    "count": 1
                                },
                                "Acceleration of Centre of mass": {
                                    "count": 1
                                },
                                "Centre of mass moving with uniform velocity": {
                                    "count": 1
                                },
                                "Centre of mass moving with Acceleration": {
                                    "count": 1
                                }
                            }
                        },
                        "Relation between momentum and kinetic energy": {
                            "count": 1,
                            "content": {
                                "C-frame and concept of reduced mass": {
                                    "count": 1
                                }
                            }
                        },
                        "Impulse in collisions": {
                            "count": 1,
                            "content": {
                                "The Impulse-Momentum Theorem, Impulsive and Non-Impulsive force": {
                                    "count": 1
                                }
                            }
                        },
                        "Conservation of linear momentum": {
                            "count": 2,
                            "content": {
                                "Conservation of momentum": {
                                    "count": 1
                                },
                                "Spring & Gravity with Energy and Momentum": {
                                    "count": 1
                                }
                            }
                        },
                        "Collision or impact": {
                            "count": 1,
                            "content": {
                                "Line of impact, Types of collision and \"e\"": {
                                    "count": 1
                                }
                            }
                        },
                        "Colision of one direction": {
                            "count": 3,
                            "content": {
                                "Elastic collision in 1D": {
                                    "count": 1
                                },
                                "Inelastic collision in 1D": {
                                    "count": 1
                                },
                                "Graphical analysis of Force, and velocites in collision": {
                                    "count": 1
                                }
                            }
                        },
                        "Oblique 2D collision": {
                            "count": 2,
                            "content": {
                                "Elastic Collisions in 2D": {
                                    "count": 1
                                },
                                "Inelastic Collisions in 2D": {
                                    "count": 1
                                }
                            }
                        },
                        "Collisions in COM frame": {
                            "count": 1,
                            "content": {
                                "Collisions in COM frame": {
                                    "count": 1
                                }
                            }
                        },
                        "Variable mass system": {
                            "count": 2,
                            "content": {
                                "Rocket propulsion.": {
                                    "count": 1
                                },
                                "Forces due to falling ropes, chains and stream of water": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Rotational Motion": {
                    "count": 44,
                    "content": {
                        "Kinematics of rotational motion": {
                            "count": 6,
                            "content": {
                                "Analogy between rotational and translational quantities": {
                                    "count": 1
                                },
                                "Kinematics of rotation about fixed axis.": {
                                    "count": 1
                                },
                                "Constraint relations in rotation motion": {
                                    "count": 1
                                },
                                "Combined Translational and rotational motion": {
                                    "count": 1
                                },
                                "Instantaneous Centre of Rotation in Plane Motion": {
                                    "count": 2
                                }
                            }
                        },
                        "Moment of inertia (MOI)": {
                            "count": 12,
                            "content": {
                                "Perpendicular Axis Theorems and Applications.": {
                                    "count": 1
                                },
                                "Physical significance of moment of inertia": {
                                    "count": 1
                                },
                                "Moment of inertia of a system of point masses.": {
                                    "count": 1
                                },
                                "MOI of continuous bodies.": {
                                    "count": 1
                                },
                                "Uniform Thin Ring and Disc about its axis": {
                                    "count": 1
                                },
                                "Uniform Solid and hollow Right Circular Cone about Its Axis": {
                                    "count": 1
                                },
                                "Uniform Solid and hollow Cylinder About Its Axis": {
                                    "count": 1
                                },
                                "Uniform Solid and hollow Sphere through its Centre": {
                                    "count": 1
                                },
                                "Uniform Thin Rectangular and triangular Lamina": {
                                    "count": 1
                                },
                                "Bodies with Cavities.": {
                                    "count": 1
                                },
                                "Radius of Gyration": {
                                    "count": 1
                                },
                                "Parallel Axis Theorems and Applications": {
                                    "count": 1
                                }
                            }
                        },
                        "Torque": {
                            "count": 4,
                            "content": {
                                "Torque about a point and Axis.": {
                                    "count": 1
                                },
                                "Force couple": {
                                    "count": 1
                                },
                                "Rotational Equilibrium": {
                                    "count": 1
                                },
                                "Conditions for toppling and sliding": {
                                    "count": 1
                                }
                            }
                        },
                        "Second law of rotational motion": {
                            "count": 5,
                            "content": {
                                "Newton`s 2nd Law in Rotation": {
                                    "count": 1
                                },
                                "Application about fixed Axis": {
                                    "count": 1
                                },
                                "Forces due to and on hinge": {
                                    "count": 1
                                },
                                "Pulley System.": {
                                    "count": 1
                                },
                                "Application about moving axis (About COM)": {
                                    "count": 1
                                }
                            }
                        },
                        "Work and kinetic energy in rotational motion": {
                            "count": 4,
                            "content": {
                                "Total Kinetic Energy of body in combined motion": {
                                    "count": 2
                                },
                                "Work Done due to Torque": {
                                    "count": 1
                                },
                                "Application of Work Energy theorem in Rotational Motion": {
                                    "count": 1
                                }
                            }
                        },
                        "Angular momentum (L) and its conservation": {
                            "count": 4,
                            "content": {
                                "Definition": {
                                    "count": 1
                                },
                                "L of a rigid body rotating about fixed point or axis": {
                                    "count": 1
                                },
                                "L in combined rotational and translational motion": {
                                    "count": 1
                                },
                                "Angular momentum conservation": {
                                    "count": 1
                                }
                            }
                        },
                        "Rolling motion": {
                            "count": 6,
                            "content": {
                                "Rolling on fixed horizontal surface": {
                                    "count": 1
                                },
                                "Rolling on a Fixed Incline": {
                                    "count": 1
                                },
                                "Rolling on Moving Surface": {
                                    "count": 1
                                },
                                "Distance travelled by a point on rolling body": {
                                    "count": 1
                                },
                                "Instantaneous Centre of Rotation": {
                                    "count": 1
                                },
                                "Direction of friction in Rolling": {
                                    "count": 1
                                }
                            }
                        },
                        "Collision in rotation": {
                            "count": 2,
                            "content": {
                                "Rotational Collision and Angular Momentum": {
                                    "count": 1
                                },
                                "Effect on rolling motion after a collision": {
                                    "count": 1
                                }
                            }
                        },
                        "Miscellaneous(optional)": {
                            "count": 1,
                            "content": {
                                "Rotation of a Body About a Rotating Axis": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Gravitation": {
                    "count": 41,
                    "content": {
                        "Newton's law of gravitation": {
                            "count": 4,
                            "content": {
                                "Law of Gravitation": {
                                    "count": 1
                                },
                                "Gravitation and the Principle of Superposition": {
                                    "count": 1
                                },
                                "Gravitation Field Intensity": {
                                    "count": 1
                                },
                                "Gravitational Field Due to system of particles": {
                                    "count": 1
                                }
                            }
                        },
                        "Gravitational field due to continous distribution of mass": {
                            "count": 5,
                            "content": {
                                "Gravitational Field Strength Due to Combination of Mass": {
                                    "count": 1
                                },
                                "Gravitational field due to a Shell.": {
                                    "count": 1
                                },
                                "Gravitational field due to a Solid Sphere": {
                                    "count": 1
                                },
                                "Gravitational field due to a rod.": {
                                    "count": 1
                                },
                                "Gravitational field due to a Ring": {
                                    "count": 1
                                }
                            }
                        },
                        "Acceleration due to gravity": {
                            "count": 6,
                            "content": {
                                "g at surface of earth/planet": {
                                    "count": 1
                                },
                                "Acceleration due to gravity at height": {
                                    "count": 1
                                },
                                "Acceleration due to gravity at depth": {
                                    "count": 1
                                },
                                "Examples on Jumping into a tunnel through the Earth": {
                                    "count": 1
                                },
                                "Effect of Earth`s axial motion": {
                                    "count": 1
                                },
                                "Effect of shape of earth on acceleration due to gravity": {
                                    "count": 1
                                }
                            }
                        },
                        "Gravitational potential and energy": {
                            "count": 10,
                            "content": {
                                "Gravitational Potential Energy": {
                                    "count": 1
                                },
                                "Gravitational potential Energy for a System of Particles": {
                                    "count": 1
                                },
                                "Gravitational potential": {
                                    "count": 1
                                },
                                "Gravitational Potential difference near Earth.": {
                                    "count": 1
                                },
                                "Gravitational potential due to shell": {
                                    "count": 1
                                },
                                "Gravitational potential due to solid sphere": {
                                    "count": 1
                                },
                                "Gravitational self-energy": {
                                    "count": 1
                                },
                                "Gravitational Potential due to Continuous Mass Distributions": {
                                    "count": 1
                                },
                                "Relation between Field Intensity and Potential": {
                                    "count": 1
                                },
                                "Escape Velocity": {
                                    "count": 1
                                }
                            }
                        },
                        "circular motion of particles under mutual gravitational force": {
                            "count": 2,
                            "content": {
                                "Binary star system": {
                                    "count": 1
                                },
                                "Multi-star system": {
                                    "count": 1
                                }
                            }
                        },
                        "Motion of satellite": {
                            "count": 6,
                            "content": {
                                "Orbital Velocity": {
                                    "count": 1
                                },
                                "Orbital Time Period": {
                                    "count": 1
                                },
                                "Energy of satellite in orbit": {
                                    "count": 1
                                },
                                "Geostationary Satellite and polar satellite": {
                                    "count": 1
                                },
                                "Wieghtlessness": {
                                    "count": 1
                                },
                                "Gravitational binding energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Planetary motion": {
                            "count": 6,
                            "content": {
                                "Kepler's First Law": {
                                    "count": 1
                                },
                                "Kepler's Second law (Areal Velocity).": {
                                    "count": 1
                                },
                                "Kepler's Third law.": {
                                    "count": 1
                                },
                                "Angular momentum and energy conservation": {
                                    "count": 1
                                },
                                "Orbital velocity in elliptical orbit": {
                                    "count": 1
                                },
                                "Projectile Motion under Gravity.": {
                                    "count": 1
                                }
                            }
                        },
                        "Gauss theorem for gravitational field (optional)": {
                            "count": 2,
                            "content": {
                                "Analogy with electrostatics": {
                                    "count": 1
                                },
                                "Examples on Gauss theorem for gravitational field": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Oscillations (SHM)": {
                    "count": 0,
                    "content": {
                        "Periodic and Oscillatory Motion": {
                            "count": 0,
                            "content": {
                                "Introduction": {
                                    "count": 0
                                }
                            }
                        },
                        "Simple harmonic motion": {
                            "count": 0,
                            "content": {
                                "Introduction and General equation of SHM": {
                                    "count": 0
                                },
                                "Velocity and acceleration in SHM": {
                                    "count": 0
                                },
                                "Graphs in SHM": {
                                    "count": 0
                                },
                                "Energy of a particle in SHM": {
                                    "count": 0
                                },
                                "SHM as the projection of uniform circular motion": {
                                    "count": 0
                                },
                                "Application of phasor in SHM": {
                                    "count": 0
                                },
                                "Superposition of SHM": {
                                    "count": 0
                                }
                            }
                        },
                        "Time period in SHM": {
                            "count": 0,
                            "content": {
                                "Force method": {
                                    "count": 0
                                },
                                "Energy method": {
                                    "count": 0
                                },
                                "Combination of springs in SHM": {
                                    "count": 0
                                },
                                "Reduced mass method": {
                                    "count": 0
                                },
                                "Oscillations of springs pulley block systems": {
                                    "count": 0
                                },
                                "Oscillations of Fluid Column": {
                                    "count": 0
                                }
                            }
                        },
                        "Problem based on Amplitude": {
                            "count": 0,
                            "content": {
                                "Problem based on Amplitude": {
                                    "count": 0
                                }
                            }
                        },
                        "Angular SHM": {
                            "count": 0,
                            "content": {
                                "Torque method and Simple pendulum": {
                                    "count": 0
                                },
                                "Oscillations of a Rolling Body on Horizontal and curved surface": {
                                    "count": 0
                                },
                                "Compound pendulum": {
                                    "count": 0
                                },
                                "Torsional pendulum": {
                                    "count": 0
                                }
                            }
                        },
                        "Free, damped and forced oscillation": {
                            "count": 0,
                            "content": {
                                "Damped Oscillation": {
                                    "count": 0
                                },
                                "Forced oscillations.": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Properties of matter and fluid mechanics": {
                    "count": 67,
                    "content": {
                        "Stress and strain": {
                            "count": 9,
                            "content": {
                                "Tensile Stress and Longitudinal Strain": {
                                    "count": 1
                                },
                                "Shear Stress and Strain": {
                                    "count": 1
                                },
                                "Hydraulic Stress and  Volumetric Strain": {
                                    "count": 1
                                },
                                "Hooke's Law and Modulus of Elasticity": {
                                    "count": 1
                                },
                                "Application of Hooke’s law": {
                                    "count": 1
                                },
                                "Bulk Modulus and compressibility": {
                                    "count": 1
                                },
                                "Young Modulus": {
                                    "count": 1
                                },
                                "Rigidity or Shear Modulus": {
                                    "count": 1
                                },
                                "Relation among Modulus": {
                                    "count": 1
                                }
                            }
                        },
                        "Stress-Strain Curve": {
                            "count": 1,
                            "content": {
                                "Behaviour of Solids.": {
                                    "count": 1
                                }
                            }
                        },
                        "Elastic potential energy": {
                            "count": 1,
                            "content": {
                                "Energy per unit volume of wire": {
                                    "count": 1
                                }
                            }
                        },
                        "Poisson's ratio": {
                            "count": 1,
                            "content": {
                                "Poisson's ratio": {
                                    "count": 1
                                }
                            }
                        },
                        "Young Modulus": {
                            "count": 1,
                            "content": {
                                "Depression in Beam": {
                                    "count": 1
                                }
                            }
                        },
                        "Rigidity or Shear Modulus": {
                            "count": 1,
                            "content": {
                                "Twisting of Cylinder/Rod": {
                                    "count": 1
                                }
                            }
                        },
                        "Fluids (Hydrostatics)": {
                            "count": 7,
                            "content": {
                                "Pressure": {
                                    "count": 1
                                },
                                "Properteis of Ideal Fluids": {
                                    "count": 1
                                },
                                "Variation of Pressure with Depth": {
                                    "count": 1
                                },
                                "Barometer and atmoshpheric pressure": {
                                    "count": 1
                                },
                                "Manometer and Gauge pressure": {
                                    "count": 1
                                },
                                "Pascal's Law": {
                                    "count": 1
                                },
                                "Hydrostatic Paradox": {
                                    "count": 1
                                }
                            }
                        },
                        "Pascal's law": {
                            "count": 1,
                            "content": {
                                "Hydraulic lift": {
                                    "count": 1
                                }
                            }
                        },
                        "Force on a surface immersed in a liquid": {
                            "count": 4,
                            "content": {
                                "Hydrostatic Force at the Bottom of Container": {
                                    "count": 1
                                },
                                "Average Pressure on The Side Walls of The Vessel": {
                                    "count": 1
                                },
                                "Torque on The Side Walls of The Vessel": {
                                    "count": 1
                                },
                                "Forces Acted on Objects Immersed in Liquid": {
                                    "count": 1
                                }
                            }
                        },
                        "Accelerated fluid": {
                            "count": 3,
                            "content": {
                                "Pressure Variation in Rotating Liquid Container": {
                                    "count": 1
                                },
                                "Pressure Distribution in a Closed Accelerated Container": {
                                    "count": 1
                                },
                                "Shape of liquid surface in accelerated container": {
                                    "count": 1
                                }
                            }
                        },
                        "Archimedes' principle": {
                            "count": 6,
                            "content": {
                                "Buoyant Force": {
                                    "count": 1
                                },
                                "Buoyant Force on a Body at the Interface of Two Liquids": {
                                    "count": 1
                                },
                                "Buoyant Force in Accelerating Fluid": {
                                    "count": 1
                                },
                                "Law of Floatation": {
                                    "count": 1
                                },
                                "Apparent Weight inside Liquids": {
                                    "count": 1
                                },
                                "Centre of Buoyancy": {
                                    "count": 1
                                }
                            }
                        },
                        "Fluids (Hydrodynamics)": {
                            "count": 8,
                            "content": {
                                "Type of Fluid flow": {
                                    "count": 1
                                },
                                "Equation of Continuity": {
                                    "count": 1
                                },
                                "Bernoulli's Theorem": {
                                    "count": 1
                                },
                                "Velocity of Efflux": {
                                    "count": 1
                                },
                                "Reaction Due to Ejection of a Liquid": {
                                    "count": 1
                                },
                                "Venturi-meter Pitot tube": {
                                    "count": 1
                                },
                                "Spray pump": {
                                    "count": 1
                                },
                                "Magnus effect": {
                                    "count": 1
                                }
                            }
                        },
                        "Velocity of Efflux": {
                            "count": 1,
                            "content": {
                                "Time taken to empty a tank": {
                                    "count": 1
                                }
                            }
                        },
                        "Surface tension": {
                            "count": 4,
                            "content": {
                                "Surface Tension": {
                                    "count": 1
                                },
                                "Surface Film Analysis": {
                                    "count": 1
                                },
                                "Factors affecting Surface Tension": {
                                    "count": 1
                                },
                                "Length of liquid in verticle tube and its meniscus": {
                                    "count": 1
                                }
                            }
                        },
                        "Surface energy": {
                            "count": 2,
                            "content": {
                                "Relation Between Surface Tension and Surface Energy": {
                                    "count": 1
                                },
                                "Drop of radius R splits into n smaller drops": {
                                    "count": 1
                                }
                            }
                        },
                        "Cohessive & Adhessive forces": {
                            "count": 2,
                            "content": {
                                "Definition": {
                                    "count": 1
                                },
                                "Angle of contact & wetting": {
                                    "count": 1
                                }
                            }
                        },
                        "Excess pressure across a curved surface": {
                            "count": 4,
                            "content": {
                                "Excess Pressure Inside a Liquid Drop and bubble": {
                                    "count": 1
                                },
                                "Excess Pressure Inside a Surface Having Two Curvatures": {
                                    "count": 1
                                },
                                "Force between Parallel Plates Due to Liquid Between them": {
                                    "count": 1
                                },
                                "Two Coalescing Soap Bubbles": {
                                    "count": 1
                                }
                            }
                        },
                        "Capillary action": {
                            "count": 4,
                            "content": {
                                "Capillary action (Balance of Forces Method)": {
                                    "count": 1
                                },
                                "Capillary of Insufficient Height (Jurin's Law)": {
                                    "count": 1
                                },
                                "Capillary action (Excess Pressure Method)": {
                                    "count": 1
                                },
                                "Capillary action between two very close surfaces": {
                                    "count": 1
                                }
                            }
                        },
                        "Viscosity": {
                            "count": 6,
                            "content": {
                                "Newton's Law of Viscous Flow": {
                                    "count": 1
                                },
                                "Velocity Profile of a Flowing Fluid": {
                                    "count": 1
                                },
                                "Critical Velocity and Reynolds Number": {
                                    "count": 1
                                },
                                "Stoke's Law": {
                                    "count": 1
                                },
                                "Poiseuille's Equation": {
                                    "count": 1
                                },
                                "Effect of Temperature": {
                                    "count": 1
                                }
                            }
                        },
                        "Stoke's Law": {
                            "count": 1,
                            "content": {
                                "Terminal Velocity": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Waves": {
                    "count": 0,
                    "content": {
                        "Wave motion": {
                            "count": 0,
                            "content": {
                                "Classification of waves": {
                                    "count": 0
                                },
                                "Characteristics of a Mechanical Wave": {
                                    "count": 0
                                },
                                "Propagation of a Progressive Wave Pulse": {
                                    "count": 0
                                }
                            }
                        },
                        "Equation of a single harmonic wave": {
                            "count": 0,
                            "content": {
                                "Differential form of wave equation": {
                                    "count": 0
                                },
                                "Variation of phase with distance": {
                                    "count": 0
                                },
                                "Variation of phase with time in a medium": {
                                    "count": 0
                                },
                                "Relation between Particle Velocity and Slope of equation": {
                                    "count": 0
                                },
                                "Sinusoidal wave traveling in the + and - x-direction": {
                                    "count": 0
                                },
                                "Energy, Power and Intensity of waves": {
                                    "count": 0
                                },
                                "Wave Equations for Cylindrical and Spherical Waves": {
                                    "count": 0
                                }
                            }
                        },
                        "Principle of superposition of waves": {
                            "count": 0,
                            "content": {
                                "Application of Superposition of Waves": {
                                    "count": 0
                                },
                                "Interference of Waves with Same Frequency and Direction": {
                                    "count": 0
                                },
                                "Conditions for Constructive and Destructive Interference": {
                                    "count": 0
                                },
                                "Intensity at the point of Interference": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Wave on string": {
                    "count": 0,
                    "content": {
                        "Transverse waves": {
                            "count": 0,
                            "content": {
                                "String carrying travelling sinusoidal wave": {
                                    "count": 0
                                },
                                "Wave speed of transverse wave in a stretched string": {
                                    "count": 0
                                },
                                "Energy in a transverse wave": {
                                    "count": 0
                                },
                                "Energy density of a Transverse Wave": {
                                    "count": 0
                                },
                                "Instantaneous power and intensity of a wave pulse": {
                                    "count": 0
                                },
                                "Average Intensity and Power of Sinusoidal Wave": {
                                    "count": 0
                                }
                            }
                        },
                        "Relection and transmission of waves": {
                            "count": 0,
                            "content": {
                                "Boundary conditions for wave equation": {
                                    "count": 0
                                },
                                "Amplitudes of the reflected and Transmitted Waves": {
                                    "count": 0
                                },
                                "Phase change in reflection of waves": {
                                    "count": 0
                                }
                            }
                        },
                        "Stationary waves": {
                            "count": 0,
                            "content": {
                                "Equation of stationary wave": {
                                    "count": 0
                                },
                                "Stationary Waves In A Clamped String": {
                                    "count": 0
                                },
                                "Stationary waves in a string clamped at one end": {
                                    "count": 0
                                },
                                "Resonance Phenomenon": {
                                    "count": 0
                                },
                                "Laws of Transverse Vibrations of a String: Sonometer": {
                                    "count": 0
                                },
                                "Stationary wave in composite string": {
                                    "count": 0
                                },
                                "Stationary waves in a clamped rod": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Sound waves": {
                    "count": 0,
                    "content": {
                        "Sound wave propogation": {
                            "count": 0,
                            "content": {
                                "Sound wave as density and pressure wave": {
                                    "count": 0
                                },
                                "Velocity of sound waves": {
                                    "count": 0
                                },
                                "Effect of temperature on velocity of sound": {
                                    "count": 0
                                },
                                "Frequency And Pitch Of Sound Waves": {
                                    "count": 0
                                }
                            }
                        },
                        "Energy transferred by longitudinal wave": {
                            "count": 0,
                            "content": {
                                "Energy Distribution in Longitudinal Wave": {
                                    "count": 0
                                },
                                "Relation between intensity and pressure amplitude of sound waves": {
                                    "count": 0
                                },
                                "Loudness of sound.": {
                                    "count": 0
                                }
                            }
                        },
                        "Interference of sound": {
                            "count": 0,
                            "content": {
                                "Sound Interference in Quinck's tube": {
                                    "count": 0
                                },
                                "Sound Interference in Seebeck's Tube": {
                                    "count": 0
                                },
                                "Interference of Sound from two Closely Spaced Sources": {
                                    "count": 0
                                },
                                "Kundt’s Tube": {
                                    "count": 0
                                }
                            }
                        },
                        "Stationary waves": {
                            "count": 0,
                            "content": {
                                "Reflection and Transmission of Wave": {
                                    "count": 0
                                },
                                "Boundary conditions for wave equation": {
                                    "count": 0
                                },
                                "Phase change in reflection of waves": {
                                    "count": 0
                                },
                                "Stationary waves in organ pipes": {
                                    "count": 0
                                },
                                "Resonance tube": {
                                    "count": 0
                                }
                            }
                        },
                        "Beats": {
                            "count": 0,
                            "content": {
                                "Beat frequency": {
                                    "count": 0
                                },
                                "Factors affecting beat frequency": {
                                    "count": 0
                                }
                            }
                        },
                        "Doppler's effect": {
                            "count": 0,
                            "content": {
                                "Observed Frequency of Sound": {
                                    "count": 0
                                },
                                "Doppler's effect when source is at rest and observer is moving": {
                                    "count": 0
                                },
                                "Doppler's effect-moving source and observer at rest": {
                                    "count": 0
                                },
                                "Doppler Effect Of Source And Observer Moving": {
                                    "count": 0
                                },
                                "Doppler’s Effect Both Source And Observer Are Accelerating": {
                                    "count": 0
                                },
                                "Doppler's effect-Reflection of sound": {
                                    "count": 0
                                },
                                "Doppler’s Effect In Light": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Temperature and thermal properties of matter": {
                    "count": 17,
                    "content": {
                        "Temperature": {
                            "count": 2,
                            "content": {
                                "Temperature Scales & Fixed Points": {
                                    "count": 1
                                },
                                "Types of thermometers": {
                                    "count": 1
                                }
                            }
                        },
                        "Thermal expansion": {
                            "count": 10,
                            "content": {
                                "Thermal expansion: microscopic view": {
                                    "count": 1
                                },
                                "Linear Expansion": {
                                    "count": 1
                                },
                                "Superficial (Areal) Expansion": {
                                    "count": 1
                                },
                                "Volume Expansion": {
                                    "count": 1
                                },
                                "Expansion of Liquids": {
                                    "count": 1
                                },
                                "Variation of upthrust in liquid": {
                                    "count": 1
                                },
                                "Change in Pendulum's Period with temperature": {
                                    "count": 1
                                },
                                "Error in scale reading for linear expansion": {
                                    "count": 1
                                },
                                "Bimetallic Strip": {
                                    "count": 1
                                },
                                "Thermal Stress & Strain": {
                                    "count": 1
                                }
                            }
                        },
                        "Calorimetry": {
                            "count": 5,
                            "content": {
                                "Principle of Calorimetry": {
                                    "count": 1
                                },
                                "Specific Heat and Heat Capacity": {
                                    "count": 1
                                },
                                "Water Equivalent Of A Substance": {
                                    "count": 1
                                },
                                "States of matter and Phase Transformation": {
                                    "count": 1
                                },
                                "Latent heat": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Kinetic theory of gases": {
                    "count": 9,
                    "content": {
                        "Gas laws": {
                            "count": 1,
                            "content": {
                                "Ideal gas equation": {
                                    "count": 1
                                }
                            }
                        },
                        "Average pressure exerted by an ideal gas": {
                            "count": 3,
                            "content": {
                                "Basic postulates of kinetic theory of Ideal gases": {
                                    "count": 1
                                },
                                "Pressure on the walls of container.": {
                                    "count": 1
                                },
                                "Path of Molecules and Mean Free Path": {
                                    "count": 1
                                }
                            }
                        },
                        "Maxwell distribution of molecular speeds": {
                            "count": 2,
                            "content": {
                                "Most Probable and Average Speed": {
                                    "count": 1
                                },
                                "Root Mean Square Speed": {
                                    "count": 1
                                }
                            }
                        },
                        "Degrees of freedom and internal energy of a gas": {
                            "count": 3,
                            "content": {
                                "Monoatomic, Diatomic and Polyatomic Gases": {
                                    "count": 1
                                },
                                "Law of Equi-partition of Energy": {
                                    "count": 1
                                },
                                "Rotational, Vibrational and Translational KE": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Laws of thermodynamics": {
                    "count": 27,
                    "content": {
                        "Thermodynamics system and variables": {
                            "count": 4,
                            "content": {
                                "Open, Closed and isolated System": {
                                    "count": 1
                                },
                                "Thermodynamics of Thermal Equilibrium": {
                                    "count": 1
                                },
                                "State and Path Variables": {
                                    "count": 1
                                },
                                "Intensive and Extensive Properties": {
                                    "count": 1
                                }
                            }
                        },
                        "Thermodynamics process": {
                            "count": 7,
                            "content": {
                                "Isochoric Process": {
                                    "count": 1
                                },
                                "Isobaric Process": {
                                    "count": 1
                                },
                                "Isothermal Process": {
                                    "count": 1
                                },
                                "Adiabatic process": {
                                    "count": 1
                                },
                                "Slope of Isothermal & Adiabatic Curve": {
                                    "count": 1
                                },
                                "Isothermal & Adiabatic Bulk Modulus": {
                                    "count": 1
                                },
                                "Free Expansion of a Gas": {
                                    "count": 1
                                }
                            }
                        },
                        "Polytropic process": {
                            "count": 1,
                            "content": {
                                "Work Done and heat supplied in Polytropic Process": {
                                    "count": 1
                                }
                            }
                        },
                        "Graphs": {
                            "count": 5,
                            "content": {
                                "P-V, P-T, and V-T graphs in different process.": {
                                    "count": 1
                                },
                                "Drawing one graph from another": {
                                    "count": 1
                                },
                                "Work Done From P-V Graph": {
                                    "count": 1
                                },
                                "Cyclic process": {
                                    "count": 1
                                },
                                "Reversible and Irreversible Process": {
                                    "count": 1
                                }
                            }
                        },
                        "First law of thermodynamics": {
                            "count": 5,
                            "content": {
                                "Statement and significance": {
                                    "count": 1
                                },
                                "Work Done by a Gas": {
                                    "count": 1
                                },
                                "Mechanics based problems": {
                                    "count": 1
                                },
                                "Molar heat capacity of ideal gas": {
                                    "count": 1
                                },
                                "Equivalent molar heat capacity for a Gaseous Mixture": {
                                    "count": 1
                                }
                            }
                        },
                        "Second law of thermodynamics": {
                            "count": 5,
                            "content": {
                                "Second Law of Thermodynamics & entropy.": {
                                    "count": 1
                                },
                                "Carnot Cycle": {
                                    "count": 1
                                },
                                "Heat Engine": {
                                    "count": 1
                                },
                                "Refrigerators": {
                                    "count": 1
                                },
                                "Entropy": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Heat transfer": {
                    "count": 14,
                    "content": {
                        "Thermal conduction": {
                            "count": 4,
                            "content": {
                                "Steady state heat conduction": {
                                    "count": 1
                                },
                                "Thermal conductivity and Resistivity": {
                                    "count": 1
                                },
                                "Heat Conduction in One, Two and Three Dimensions": {
                                    "count": 1
                                },
                                "Rate of Growth of Ice": {
                                    "count": 1
                                }
                            }
                        },
                        "Radiation": {
                            "count": 7,
                            "content": {
                                "Prevost theory": {
                                    "count": 1
                                },
                                "Absorption, Reflection and Emission of Radiation": {
                                    "count": 1
                                },
                                "Blackbody Radiation & Planck Curve": {
                                    "count": 1
                                },
                                "Emissive & absorptive power of a body": {
                                    "count": 1
                                },
                                "Kirchhoff's law": {
                                    "count": 1
                                },
                                "Stefan's law": {
                                    "count": 1
                                },
                                "Wien's displacement Law": {
                                    "count": 1
                                }
                            }
                        },
                        "Newton's law of cooling": {
                            "count": 2,
                            "content": {
                                "Newton's Law of Cooling.": {
                                    "count": 1
                                },
                                "Continuous Model and Euler's Method": {
                                    "count": 1
                                }
                            }
                        },
                        "Convection": {
                            "count": 1,
                            "content": {
                                "Convection": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Electrostatistics": {
                    "count": 0,
                    "content": {
                        "Charge and its properties": {
                            "count": 0,
                            "content": {
                                "Charge Theory and Properties": {
                                    "count": 0
                                },
                                "Modes of Emission of Electron": {
                                    "count": 0
                                },
                                "Methods of Charging": {
                                    "count": 0
                                }
                            }
                        },
                        "Coulomb's law": {
                            "count": 0,
                            "content": {
                                "Vector form of Coulomb's Law": {
                                    "count": 0
                                },
                                "Force between the charges when dielectric is present": {
                                    "count": 0
                                },
                                "Principle of superposition": {
                                    "count": 0
                                },
                                "Equilibrium of three charges": {
                                    "count": 0
                                }
                            }
                        },
                        "Electric field": {
                            "count": 0,
                            "content": {
                                "Superposition of Electric Field": {
                                    "count": 0
                                },
                                "Electric Field Lines and its properties": {
                                    "count": 0
                                },
                                "Electric Field due to Continuous Charge": {
                                    "count": 0
                                },
                                "Cylindrical Charge Distribution": {
                                    "count": 0
                                },
                                "Uniformly Charged Sphere": {
                                    "count": 0
                                },
                                "Solid Charged Sphere with Cavity": {
                                    "count": 0
                                },
                                "Conducting Shell and Sphere": {
                                    "count": 0
                                },
                                "Non-conducting thin and thick sheet": {
                                    "count": 0
                                }
                            }
                        },
                        "Electric flux and Gauss law": {
                            "count": 0,
                            "content": {
                                "Electric Flux": {
                                    "count": 0
                                },
                                "Gauss Law": {
                                    "count": 0
                                },
                                "Spherical, cylindrical and planar symmetry": {
                                    "count": 0
                                },
                                "Electric flux using solid angle.": {
                                    "count": 0
                                },
                                "Calculation of Electric Field using Gauss Law": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrostatic potential and energy": {
                            "count": 0,
                            "content": {
                                "Electric Potential due to Point Charge and system of Charges": {
                                    "count": 0
                                },
                                "Electric Potential due to Continuous Charge distribution": {
                                    "count": 0
                                },
                                "Electric potential difference and work done": {
                                    "count": 0
                                },
                                "Work done in an electric field": {
                                    "count": 0
                                },
                                "Relation between Electric Potential and Electric Field": {
                                    "count": 0
                                },
                                "Equipotential Surfaces": {
                                    "count": 0
                                },
                                "Electrostatic potential energy": {
                                    "count": 0
                                },
                                "Self-energy.": {
                                    "count": 0
                                }
                            }
                        },
                        "Electric dipole": {
                            "count": 0,
                            "content": {
                                "Electrical Dipole Moment": {
                                    "count": 0
                                },
                                "Electric Field due to Dipole": {
                                    "count": 0
                                },
                                "Electric Potential due to Electric Dipole": {
                                    "count": 0
                                },
                                "Electric Dipole in Uniform Electric Field": {
                                    "count": 0
                                },
                                "Potential Energy of Electrical Dipole": {
                                    "count": 0
                                },
                                "Electric dipole in a non- uniform electric field": {
                                    "count": 0
                                },
                                "Distributed dipole": {
                                    "count": 0
                                }
                            }
                        },
                        "Conductors and their properties": {
                            "count": 0,
                            "content": {
                                "Electrostatic pressure": {
                                    "count": 0
                                },
                                "Properties of Conductors using Gauss Law": {
                                    "count": 0
                                },
                                "Electrostatic shielding": {
                                    "count": 0
                                },
                                "Conducting parallel and spherical sheets.": {
                                    "count": 0
                                },
                                "Earthing of a conductor.": {
                                    "count": 0
                                }
                            }
                        },
                        "Capacity and capacitance": {
                            "count": 0,
                            "content": {
                                "Parallel plate capacitors.": {
                                    "count": 0
                                },
                                "Isolated Capacitors": {
                                    "count": 0
                                },
                                "Spherical and cylindrical Capacitors.": {
                                    "count": 0
                                },
                                "Force between the plates": {
                                    "count": 0
                                },
                                "Redistribution of charge": {
                                    "count": 0
                                },
                                "System of parallel plates and concentric shells": {
                                    "count": 0
                                },
                                "Work done in charging the capacitor": {
                                    "count": 0
                                },
                                "Energy Loss in capacitor": {
                                    "count": 0
                                },
                                "Combination of Capacitor": {
                                    "count": 0
                                }
                            }
                        },
                        "Introduction of dielectric": {
                            "count": 0,
                            "content": {
                                "Effect of dielectric on Capacitance, charge and potential": {
                                    "count": 0
                                },
                                "Dielectric with and without Battery": {
                                    "count": 0
                                },
                                "Charge induced on dielectric": {
                                    "count": 0
                                },
                                "Force between the plates with dielectric": {
                                    "count": 0
                                },
                                "Force on the dielectric": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Current electricity": {
                    "count": 0,
                    "content": {
                        "Current and drift speed": {
                            "count": 0,
                            "content": {
                                "Drift speed of Electrons": {
                                    "count": 0
                                },
                                "Current Density": {
                                    "count": 0
                                }
                            }
                        },
                        "Resistivity and conductivity": {
                            "count": 0,
                            "content": {
                                "Dependence of Resistivity with Temperature": {
                                    "count": 0
                                },
                                "Different shape & size of resistor & it's expressions.": {
                                    "count": 0
                                },
                                "Colour code.": {
                                    "count": 0
                                }
                            }
                        },
                        "Ohm's law": {
                            "count": 0,
                            "content": {
                                "Ohm law": {
                                    "count": 0
                                },
                                "Grouping of Resistors (Series & Parallel)": {
                                    "count": 0
                                }
                            }
                        },
                        "Battery and cells": {
                            "count": 0,
                            "content": {
                                "EMF & Potential Difference": {
                                    "count": 0
                                },
                                "Grouping of Batteries (Series & Parallel)": {
                                    "count": 0
                                },
                                "Short Circuit": {
                                    "count": 0
                                }
                            }
                        },
                        "Circuit analysis and Kirchhoff's circuit law": {
                            "count": 0,
                            "content": {
                                "Kirchhoff's Current Law": {
                                    "count": 0
                                },
                                "Kirchhoff's Voltage Law": {
                                    "count": 0
                                },
                                "Nodal analysis.": {
                                    "count": 0
                                },
                                "Wheatstone Bridge": {
                                    "count": 0
                                },
                                "Symmetrical Circuits.": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrical instrument": {
                            "count": 0,
                            "content": {
                                "Voltmeter, Ammeter & Galvanometer": {
                                    "count": 0
                                },
                                "Meter Bridge": {
                                    "count": 0
                                },
                                "Post-office Box": {
                                    "count": 0
                                },
                                "Potentiometer": {
                                    "count": 0
                                }
                            }
                        },
                        "Heating effect": {
                            "count": 0,
                            "content": {
                                "Joule's Law": {
                                    "count": 0
                                },
                                "Maximum power theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "RC circuit": {
                            "count": 0,
                            "content": {
                                "Charging of a capacitor": {
                                    "count": 0
                                },
                                "Discharging of a capacitor": {
                                    "count": 0
                                },
                                "Steady state in RC circuit": {
                                    "count": 0
                                },
                                "Complex RC circuit": {
                                    "count": 0
                                },
                                "Flow of Charge and loss of energy.": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Magnetism": {
                    "count": 0,
                    "content": {
                        "Biot-savart law": {
                            "count": 0,
                            "content": {
                                "Magnetic field of moving charge": {
                                    "count": 0
                                },
                                "Magnetic field due to current carrying conductor": {
                                    "count": 0
                                },
                                "Magnetic field of a circular current loop at the Centre": {
                                    "count": 0
                                },
                                "Magnetic field at the Centre of a current carrying arc": {
                                    "count": 0
                                }
                            }
                        },
                        "Ampere's circuital law": {
                            "count": 0,
                            "content": {
                                "Applications of ampere's circuital law": {
                                    "count": 0
                                },
                                "Magnetic field due to infinite sheet": {
                                    "count": 0
                                },
                                "Magnetic field due to long straight conductor with a cavity": {
                                    "count": 0
                                },
                                "Magnetic field outside and inside a cylindrical wire": {
                                    "count": 0
                                },
                                "Magnetic field of a long solenoid & toroid": {
                                    "count": 0
                                }
                            }
                        },
                        "Lorentz force": {
                            "count": 0,
                            "content": {
                                "Force on a moving charge in a magnetic field": {
                                    "count": 0
                                },
                                "Motion of a charge particle in a uniform magnetic field": {
                                    "count": 0
                                },
                                "Particle velocity at an angle to Magnetic Field": {
                                    "count": 0
                                },
                                "Helical path of charge particle": {
                                    "count": 0
                                },
                                "Motion of a charge particle in both electric and magnetic field": {
                                    "count": 0
                                },
                                "Charge is released from Rest in perpendicular Electric and Magnetic Field": {
                                    "count": 0
                                }
                            }
                        },
                        "Magnetic force on a current carrying conductor": {
                            "count": 0,
                            "content": {
                                "Direction of force on a current carrying wire in magnetic field": {
                                    "count": 0
                                },
                                "Force on a curved conductor": {
                                    "count": 0
                                },
                                "Force between two infinite parallel current carrying wires": {
                                    "count": 0
                                },
                                "Force on a current carrying loop in a uniform magnetic field": {
                                    "count": 0
                                },
                                "Cyclotron": {
                                    "count": 0
                                }
                            }
                        },
                        "Magnetic dipole and dipole moment": {
                            "count": 0,
                            "content": {
                                "Current loop as a bar magnet": {
                                    "count": 0
                                },
                                "Torque on a current loop in magnetic field": {
                                    "count": 0
                                },
                                "Work done in rotating a coil in uniform magnetic field": {
                                    "count": 0
                                },
                                "Gyromagnetic ratio": {
                                    "count": 0
                                }
                            }
                        },
                        "Permanent magnets": {
                            "count": 0,
                            "content": {
                                "Magnetic field & potential due to bar magnet": {
                                    "count": 0
                                },
                                "Magnetic dipole moment": {
                                    "count": 0
                                },
                                "Torque on a bar magnet placed in magnetic field": {
                                    "count": 0
                                },
                                "Coulomb's law for Magnetic poles (Optional)": {
                                    "count": 0
                                }
                            }
                        },
                        "Terrestrial magnestism": {
                            "count": 0,
                            "content": {
                                "Angle of declination": {
                                    "count": 0
                                },
                                "Horizontal & Vertical component of earth's magnetic field": {
                                    "count": 0
                                },
                                "Angle of dip, Dip circle and apparent angle of dip": {
                                    "count": 0
                                }
                            }
                        },
                        "Magnetic properties of matter": {
                            "count": 0,
                            "content": {
                                "Intensity of magnetization": {
                                    "count": 0
                                },
                                "Magnetizing field intensity": {
                                    "count": 0
                                },
                                "Magnetic Susceptibility": {
                                    "count": 0
                                },
                                "Type of magnetic materials": {
                                    "count": 0
                                },
                                "Hysteresis.": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Electromagnetic induction and alternating current": {
                    "count": 0,
                    "content": {
                        "Faraday's law and Lenz's law": {
                            "count": 0,
                            "content": {
                                "Direction of induced E.M.F. and current": {
                                    "count": 0
                                },
                                "Induced E.M.F.": {
                                    "count": 0
                                }
                            }
                        },
                        "Motional E.M.F.": {
                            "count": 0,
                            "content": {
                                "Rod in uniform and non-uniform magnetic field": {
                                    "count": 0
                                },
                                "Loop in uniform and non-uniform magnetic field": {
                                    "count": 0
                                }
                            }
                        },
                        "Induced electric field": {
                            "count": 0,
                            "content": {
                                "Induced EMF due to time varying magnetic field": {
                                    "count": 0
                                },
                                "Circular, Square/Rectangular Coil": {
                                    "count": 0
                                },
                                "Induced Electric Field": {
                                    "count": 0
                                },
                                "Concept of eddy currents and electromagnetic damping": {
                                    "count": 0
                                }
                            }
                        },
                        "Self-induction and mutual inductance": {
                            "count": 0,
                            "content": {
                                "LR circuit": {
                                    "count": 0
                                },
                                "Growth and decay of current in LR circuit": {
                                    "count": 0
                                },
                                "Series & Parallel Grouping of inductors": {
                                    "count": 0
                                },
                                "Energy stored in an inductor": {
                                    "count": 0
                                },
                                "Mutual Inductance": {
                                    "count": 0
                                }
                            }
                        },
                        "Alternating current": {
                            "count": 0,
                            "content": {
                                "Average, Instantaneous and RMS value of A.C.": {
                                    "count": 0
                                },
                                "Pure Resistor, capacitor and inductor": {
                                    "count": 0
                                },
                                "Phase relation between voltage and current": {
                                    "count": 0
                                }
                            }
                        },
                        "A.C. circuits": {
                            "count": 0,
                            "content": {
                                "RC Circuits": {
                                    "count": 0
                                },
                                "LR Circuits": {
                                    "count": 0
                                },
                                "LCR Circuits": {
                                    "count": 0
                                },
                                "LC oscillations": {
                                    "count": 0
                                },
                                "Resonance and quality factor in LCR circuit": {
                                    "count": 0
                                }
                            }
                        },
                        "Energy and power in LCR circuit": {
                            "count": 0,
                            "content": {
                                "Power in LCR circuit": {
                                    "count": 0
                                },
                                "Power Factor": {
                                    "count": 0
                                },
                                "Use of j operator in A.C. circuits": {
                                    "count": 0
                                },
                                "Energy density in magnetic field": {
                                    "count": 0
                                }
                            }
                        },
                        "A.C. equipment": {
                            "count": 0,
                            "content": {
                                "AC generator": {
                                    "count": 0
                                },
                                "Transformer": {
                                    "count": 0
                                },
                                "DC motor": {
                                    "count": 0
                                }
                            }
                        },
                        "Electromagnetic waves": {
                            "count": 0,
                            "content": {
                                "Properties of Electromagnetic Waves": {
                                    "count": 0
                                },
                                "Displacement Current": {
                                    "count": 0
                                },
                                "Energy & Momentum of EM Wave": {
                                    "count": 0
                                },
                                "Maxwell's Electromagnetic Wave Equations": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Geometrical optics and optical instruments": {
                    "count": 48,
                    "content": {
                        "Introduction to ray model of light": {
                            "count": 3,
                            "content": {
                                "Rectilinear propagation of light": {
                                    "count": 1
                                },
                                "Fermat's principle": {
                                    "count": 1
                                },
                                "Real and Virtual Images and objects": {
                                    "count": 1
                                }
                            }
                        },
                        "Laws of reflection and plane mirrors": {
                            "count": 6,
                            "content": {
                                "Laws of Reflection.": {
                                    "count": 1
                                },
                                "Angle of deviation by reflection": {
                                    "count": 1
                                },
                                "Rotation of ray and mirror": {
                                    "count": 1
                                },
                                "Field of view": {
                                    "count": 1
                                },
                                "Relative motion in point object and plane mirror": {
                                    "count": 1
                                },
                                "Combination of plane mirrors": {
                                    "count": 1
                                }
                            }
                        },
                        "Spherical mirror": {
                            "count": 8,
                            "content": {
                                "Sign convention in Spherical Mirror": {
                                    "count": 1
                                },
                                "Ray diagram for Spherical mirror": {
                                    "count": 1
                                },
                                "Image formed by Spherical mirror": {
                                    "count": 1
                                },
                                "Mirror Formula & magnification": {
                                    "count": 1
                                },
                                "Power of mirror": {
                                    "count": 1
                                },
                                "Graph for mirror": {
                                    "count": 1
                                },
                                "Velocity of image": {
                                    "count": 1
                                },
                                "Cutting and obstructing mirrors": {
                                    "count": 1
                                }
                            }
                        },
                        "Refraction through plane surface": {
                            "count": 7,
                            "content": {
                                "Laws of refraction and refractive index": {
                                    "count": 1
                                },
                                "Vector form of Snell's law": {
                                    "count": 1
                                },
                                "Real and Apparent Depth": {
                                    "count": 1
                                },
                                "Object inside two or more optical mediums": {
                                    "count": 1
                                },
                                "Refraction through glass slab": {
                                    "count": 1
                                },
                                "Total Internal Reflection": {
                                    "count": 1
                                },
                                "Application of TIR": {
                                    "count": 1
                                }
                            }
                        },
                        "Prism and its characteristics": {
                            "count": 7,
                            "content": {
                                "Angle of Deviation by Prism": {
                                    "count": 1
                                },
                                "Graph of angle of deviation.": {
                                    "count": 1
                                },
                                "Min. & max. deviation": {
                                    "count": 1
                                },
                                "Total internally reflecting prism": {
                                    "count": 1
                                },
                                "Angular Dispersion and Mean Deviation": {
                                    "count": 1
                                },
                                "Dispersive power": {
                                    "count": 1
                                },
                                "Combination of Prisms": {
                                    "count": 1
                                }
                            }
                        },
                        "Refraction at spherical surfaces": {
                            "count": 3,
                            "content": {
                                "Formulae and sign convention.": {
                                    "count": 1
                                },
                                "Velocity of images": {
                                    "count": 1
                                },
                                "Ball lens(glass sphere)": {
                                    "count": 1
                                }
                            }
                        },
                        "Thin lens": {
                            "count": 9,
                            "content": {
                                "Ray diagram for lens": {
                                    "count": 1
                                },
                                "Lens-makers Formula & Lens formula": {
                                    "count": 1
                                },
                                "Nature of lenses depending upon their surroundings": {
                                    "count": 1
                                },
                                "Lens and different cases of images formation": {
                                    "count": 1
                                },
                                "Velocity of object and image formed by lens": {
                                    "count": 1
                                },
                                "Power of a lens": {
                                    "count": 1
                                },
                                "Painting and cutting of lens": {
                                    "count": 1
                                },
                                "Combination of lenses & Mirrors": {
                                    "count": 1
                                },
                                "Displacement method": {
                                    "count": 1
                                }
                            }
                        },
                        "Optical instruments": {
                            "count": 5,
                            "content": {
                                "Human Eye & its defects.": {
                                    "count": 1
                                },
                                "Simple microscope": {
                                    "count": 1
                                },
                                "Compound microscope": {
                                    "count": 1
                                },
                                "Telescope (Refracting Type)": {
                                    "count": 1
                                },
                                "Resolving Limit and Resolving Power": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Wave optics": {
                    "count": 0,
                    "content": {
                        "Introduction to wave optics": {
                            "count": 0,
                            "content": {
                                "Huygens’ Principle and wave front.": {
                                    "count": 0
                                },
                                "Construction of Wave fronts in Optical Phenomenon": {
                                    "count": 0
                                }
                            }
                        },
                        "Interference of light": {
                            "count": 0,
                            "content": {
                                "Superposition Principle": {
                                    "count": 0
                                },
                                "Coherent and Incoherent Sources": {
                                    "count": 0
                                },
                                "Methods of obtaining coherent sources": {
                                    "count": 0
                                },
                                "Relation Between Path Difference and Phase Difference": {
                                    "count": 0
                                },
                                "Constructive interference": {
                                    "count": 0
                                },
                                "Destructive interference": {
                                    "count": 0
                                },
                                "Resultant amplitude and intensity": {
                                    "count": 0
                                },
                                "Incoherent Source and intensity": {
                                    "count": 0
                                }
                            }
                        },
                        "Young double slit experiment setup": {
                            "count": 0,
                            "content": {
                                "Path Difference and Phase Difference in YDSE": {
                                    "count": 0
                                },
                                "Position of Bright and Dark Fringes in YDSE": {
                                    "count": 0
                                },
                                "Fringe width": {
                                    "count": 0
                                },
                                "Changing Direction of Incidence of Light in YDSE": {
                                    "count": 0
                                },
                                "Submerging YDSE in a Transparent Media": {
                                    "count": 0
                                },
                                "YDSE for white light": {
                                    "count": 0
                                },
                                "Intensity graph of YDSE": {
                                    "count": 0
                                },
                                "Thin film in front of slit": {
                                    "count": 0
                                },
                                "YDSE as limiting case in other setups.": {
                                    "count": 0
                                }
                            }
                        },
                        "Thin film interference": {
                            "count": 0,
                            "content": {
                                "Thin film interference": {
                                    "count": 0
                                },
                                "Colours of Thin Film": {
                                    "count": 0
                                },
                                "Interference Due to Thin Wedge Shaped Film": {
                                    "count": 0
                                },
                                "Interference by Air Wedge": {
                                    "count": 0
                                },
                                "Newton's Ring": {
                                    "count": 0
                                }
                            }
                        },
                        "Diffraction": {
                            "count": 0,
                            "content": {
                                "Diffraction by a Single Slit": {
                                    "count": 0
                                },
                                "Single Slit Diffraction Pattern on Screen": {
                                    "count": 0
                                },
                                "Intensity distribution": {
                                    "count": 0
                                },
                                "Diffraction by Small Circular Aperture": {
                                    "count": 0
                                },
                                "Fresnel distance and validity of ray optics": {
                                    "count": 0
                                },
                                "Diffraction Grating": {
                                    "count": 0
                                },
                                "Rayleigh's Criteria for Resolution": {
                                    "count": 0
                                }
                            }
                        },
                        "Polarization": {
                            "count": 0,
                            "content": {
                                "Malus’ Law": {
                                    "count": 0
                                },
                                "Methods of Polarization": {
                                    "count": 0
                                },
                                "Polarization by Reflection (Brewster's Law)": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Experimental physics": {
                    "count": 0,
                    "content": {
                        "Experimental physics": {
                            "count": 0,
                            "content": {
                                "Screw Gauge": {
                                    "count": 0
                                },
                                "Vernier Callipers": {
                                    "count": 0
                                },
                                "Simple Pendulum": {
                                    "count": 0
                                },
                                "Meter Scale": {
                                    "count": 0
                                },
                                "Spherometer for radius of sphere": {
                                    "count": 0
                                },
                                "Young’s modulus by Searle’s method": {
                                    "count": 0
                                },
                                "Surface Tension of Water": {
                                    "count": 0
                                },
                                "Coefficient of Viscosity of given viscous liquid": {
                                    "count": 0
                                },
                                "Weight of a body by parallelogram law of vectors": {
                                    "count": 0
                                },
                                "Plotting Cooling Curve": {
                                    "count": 0
                                },
                                "Resonance tube experiment": {
                                    "count": 0
                                },
                                "Specific Heat Capacity of Solid and Liquid": {
                                    "count": 0
                                },
                                "Calorimeter": {
                                    "count": 0
                                },
                                "Sonometer": {
                                    "count": 0
                                },
                                "Quinck's tube": {
                                    "count": 0
                                },
                                "Experiments on Kundt's tube": {
                                    "count": 0
                                },
                                "Melde's experiment in experimental physics": {
                                    "count": 0
                                },
                                "Meter Bridge": {
                                    "count": 0
                                },
                                "Focal Length of Concave, Convex Mirrors": {
                                    "count": 0
                                },
                                "Focal Length of Concave, Convex Lens": {
                                    "count": 0
                                },
                                "Angle of minimum deviation for a given prism": {
                                    "count": 0
                                },
                                "Refractive index of a glass slab by using travelling microscope": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Photoelectric effect and matter waves": {
                    "count": 0,
                    "content": {
                        "Photoelectric effect": {
                            "count": 0,
                            "content": {
                                "Einstein’s equation.": {
                                    "count": 0
                                },
                                "Distribution of Kinetic Energy of Photoelectrons Under Accelerated Voltage": {
                                    "count": 0
                                },
                                "Work Function In Photoelectric Effect": {
                                    "count": 0
                                },
                                "Effect of Variation in Frequency of Light at Constant Intensity": {
                                    "count": 0
                                },
                                "Relation Between Photocurrent and Intensity": {
                                    "count": 0
                                },
                                "Stopping Potential.": {
                                    "count": 0
                                },
                                "Effect of frequency of Light on Stopping Potential.": {
                                    "count": 0
                                },
                                "Graph between Stopping Potential and Frequency of Light.": {
                                    "count": 0
                                },
                                "Photoelectric effect due to radiation of mixture of wavelength.": {
                                    "count": 0
                                }
                            }
                        },
                        "De Broglie's hypothesis of a matter wave": {
                            "count": 0,
                            "content": {
                                "De Broglie' Wavelength of an Accelerated Charge.": {
                                    "count": 0
                                },
                                "Momentum of a Photon.": {
                                    "count": 0
                                },
                                "Recoiling Of an Atom during De-Excitation.": {
                                    "count": 0
                                },
                                "Heisenberg Uncertainty Principle.": {
                                    "count": 0
                                }
                            }
                        },
                        "Radiation pressure": {
                            "count": 0,
                            "content": {
                                "Power and intensity": {
                                    "count": 0
                                },
                                "Photon Flux and density in a Light Beam": {
                                    "count": 0
                                },
                                "Force Exerted By Radiation On A Surface": {
                                    "count": 0
                                },
                                "Oblique Incidence In Radiation": {
                                    "count": 0
                                },
                                "Partial Reflection & Absorption of Radiation in Oblique Incident": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Atomic structure": {
                    "count": 0,
                    "content": {
                        "Atomic models": {
                            "count": 0,
                            "content": {
                                "Dalton's Theory and Thomson model": {
                                    "count": 0
                                },
                                "Rutherford Experiment discovery of nucleus": {
                                    "count": 0
                                },
                                "Nuclear size(approx.) in Rutherford experiment": {
                                    "count": 0
                                },
                                "Bohr's Model": {
                                    "count": 0
                                }
                            }
                        },
                        "Bohr's Atomic model": {
                            "count": 0,
                            "content": {
                                "Revolution of electron in nth orbit": {
                                    "count": 0
                                },
                                "Time period and frequency of electron": {
                                    "count": 0
                                },
                                "Magnetic dipole moment due to revolution of electron": {
                                    "count": 0
                                },
                                "Electric current due to revolution of electron in nth orbit": {
                                    "count": 0
                                },
                                "Energy of electron": {
                                    "count": 0
                                },
                                "Effect of nuclear mass on various parameters (r, v, E)": {
                                    "count": 0
                                },
                                "De-Broglie's Explanation of Bohr's Second Postulate of Quantization": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of spectrum": {
                            "count": 0,
                            "content": {
                                "Absorption and Emission Spectrum": {
                                    "count": 0
                                },
                                "Hydrogen Spectrum": {
                                    "count": 0
                                },
                                "Rydberg formula for energy and wavelength of emitted radiation": {
                                    "count": 0
                                },
                                "Spectral Series of hydrogen atom": {
                                    "count": 0
                                }
                            }
                        },
                        "Excitation of atom": {
                            "count": 0,
                            "content": {
                                "Excitation by photon": {
                                    "count": 0
                                },
                                "Collision of Hydrogen like atom by an electron or other atom or particle": {
                                    "count": 0
                                },
                                "Binding Energy & Ionization of an atom": {
                                    "count": 0
                                },
                                "Excitation Energy & Ionization energy": {
                                    "count": 0
                                },
                                "De Excitation of an excited atom": {
                                    "count": 0
                                }
                            }
                        },
                        "X-rays": {
                            "count": 0,
                            "content": {
                                "Production of Continuous X rays": {
                                    "count": 0
                                },
                                "Production of Characteristic X rays": {
                                    "count": 0
                                },
                                "Moseley's Law": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Nuclear physics and radioactivity": {
                    "count": 0,
                    "content": {
                        "Concept of nucleus and nucleons": {
                            "count": 0,
                            "content": {
                                "Rutherford's Nuclear Model of The Atom": {
                                    "count": 0
                                },
                                "Protons & Neutrons": {
                                    "count": 0
                                },
                                "Composition and Structure of Nucleus": {
                                    "count": 0
                                },
                                "Isotopes, Isobars, Isotones and Isodiapheres": {
                                    "count": 0
                                },
                                "Size of A Nucleus and density": {
                                    "count": 0
                                }
                            }
                        },
                        "Nuclear force and stability of a nucleus": {
                            "count": 0,
                            "content": {
                                "Nuclear Binding Energy": {
                                    "count": 0
                                },
                                "Binding Energy Per Nucleon": {
                                    "count": 0
                                },
                                "Mass Defect in The Nucleus": {
                                    "count": 0
                                },
                                "Einstein's Mass Energy Equivalence": {
                                    "count": 0
                                }
                            }
                        },
                        "Nuclear reactions": {
                            "count": 0,
                            "content": {
                                "Alpha Decay": {
                                    "count": 0
                                },
                                "Positive and Negative Beta Decay": {
                                    "count": 0
                                },
                                "Kinetic Energy Particles in various decays.": {
                                    "count": 0
                                },
                                "K-electron Capture": {
                                    "count": 0
                                },
                                "Gamma decay": {
                                    "count": 0
                                },
                                "Group Displacement Law for Alpha, Beta and Gamma Decay": {
                                    "count": 0
                                },
                                "Q-Value of a Nuclear Reaction": {
                                    "count": 0
                                },
                                "Exothermic Nuclear Reaction": {
                                    "count": 0
                                },
                                "Nuclear Fission and Nuclear Fusion": {
                                    "count": 0
                                },
                                "Fission Of Uranium Isotopes": {
                                    "count": 0
                                }
                            }
                        },
                        "Radiactivity": {
                            "count": 0,
                            "content": {
                                "Radioactive Decay Law": {
                                    "count": 0
                                },
                                "Mean Life, Half-life and Decay Constant": {
                                    "count": 0
                                },
                                "Simultaneous Decay Modes of Radioactive Element": {
                                    "count": 0
                                },
                                "Successive Disintegration": {
                                    "count": 0
                                },
                                "Secular and Transient Radioactive Equilibrium": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Semiconductos and communication system": {
                    "count": 0,
                    "content": {
                        "Band theory": {
                            "count": 0,
                            "content": {
                                "Band theory and Fermi energy level": {
                                    "count": 0
                                },
                                "Mechanism of conduction in semiconductors": {
                                    "count": 0
                                },
                                "Intrinsic and extrinsic semiconductors.": {
                                    "count": 0
                                }
                            }
                        },
                        "P-N junction diode": {
                            "count": 0,
                            "content": {
                                "Mechanism of p-n junction formation": {
                                    "count": 0
                                },
                                "Forward & reverse biasing": {
                                    "count": 0
                                },
                                "Breakdown mechanisms": {
                                    "count": 0
                                },
                                "Forward and reverse characteristics of a p-n junction diode": {
                                    "count": 0
                                },
                                "Circuit based problem of diode": {
                                    "count": 0
                                }
                            }
                        },
                        "Rectifier": {
                            "count": 0,
                            "content": {
                                "P-n junction diode as a rectifier": {
                                    "count": 0
                                },
                                "Average and r.m.s. value of current in rectifiers": {
                                    "count": 0
                                }
                            }
                        },
                        "Sepcial diodes": {
                            "count": 0,
                            "content": {
                                "Zener diode as a voltage regulator": {
                                    "count": 0
                                },
                                "Photodiode, Solar cells and LED.": {
                                    "count": 0
                                }
                            }
                        },
                        "Transistor": {
                            "count": 0,
                            "content": {
                                "Working of a transistor": {
                                    "count": 0
                                },
                                "Use of a transistor as an amplifier": {
                                    "count": 0
                                },
                                "Study of common base connections of a transistor": {
                                    "count": 0
                                },
                                "Current amplification factor (α) in transistor": {
                                    "count": 0
                                },
                                "Input and output characteristics of common emitter connection In Transistor": {
                                    "count": 0
                                },
                                "Current amplification factor beta in transistor": {
                                    "count": 0
                                },
                                "Relation between alpha and beta in Transistor": {
                                    "count": 0
                                },
                                "Use of a transistor as a switch": {
                                    "count": 0
                                },
                                "Transistor as an oscillator": {
                                    "count": 0
                                }
                            }
                        },
                        "Logic gates and truth tables": {
                            "count": 0,
                            "content": {
                                "Logic gates": {
                                    "count": 0
                                },
                                "Combination of gates": {
                                    "count": 0
                                },
                                "Realization in circuits": {
                                    "count": 0
                                },
                                "NAND gates as universal gate": {
                                    "count": 0
                                }
                            }
                        },
                        "Introduction to communication systems": {
                            "count": 0,
                            "content": {
                                "Elements of communication system": {
                                    "count": 0
                                },
                                "Information/signal in communication system": {
                                    "count": 0
                                },
                                "Digital and analogue signal in communication System": {
                                    "count": 0
                                }
                            }
                        },
                        "Modes of communication": {
                            "count": 0,
                            "content": {
                                "Wave propagation.": {
                                    "count": 0
                                },
                                "Radio horizon of the transmitting antenna": {
                                    "count": 0
                                }
                            }
                        },
                        "Modulation": {
                            "count": 0,
                            "content": {
                                "Frequency modulation": {
                                    "count": 0
                                },
                                "Phase modulation": {
                                    "count": 0
                                },
                                "Amplitude modulation": {
                                    "count": 0
                                },
                                "Production of amplitude modulated wave": {
                                    "count": 0
                                },
                                "Demodulation of an amplitude modulated wave": {
                                    "count": 0
                                }
                            }
                        }
                    }
                }
            }
        },
        "Chemistry": {
            "count": 0,
            "content": {}
        },
        "Inorganic Chemistry": {
            "count": 49,
            "content": {
                "Chemical bonding and molecular structure": {
                    "count": 49,
                    "content": {
                        "Cause of chemical combination": {
                            "count": 1,
                            "content": {
                                "Limitations of lewis thoery": {
                                    "count": 1
                                }
                            }
                        },
                        "Lewis dot formula": {
                            "count": 1,
                            "content": {
                                "Writing Lewis structure and examples": {
                                    "count": 1
                                }
                            }
                        },
                        "Chemical bonds and their type": {
                            "count": 1,
                            "content": {
                                "Definition and different type of chemical bonds": {
                                    "count": 1
                                }
                            }
                        },
                        "Ionic or electrovalent bonds": {
                            "count": 3,
                            "content": {
                                "Ionic Bond definition": {
                                    "count": 1
                                },
                                "Favourable factors for cation & anion formation": {
                                    "count": 1
                                },
                                "Born haber cycle": {
                                    "count": 1
                                }
                            }
                        },
                        "Fajan's rule": {
                            "count": 1,
                            "content": {
                                "definition ,Polarizability of anion  and cation ": {
                                    "count": 1
                                }
                            }
                        },
                        "Application of Fajan's rule": {
                            "count": 4,
                            "content": {
                                "Covalent and ionic character": {
                                    "count": 1
                                },
                                "Melting point of ionic compounds": {
                                    "count": 1
                                },
                                "Solubility of compounds of p and d block": {
                                    "count": 1
                                },
                                "Acidic nature of oxides": {
                                    "count": 1
                                }
                            }
                        },
                        "Water solubility of compounds of S block": {
                            "count": 3,
                            "content": {
                                "Condition for water solubility": {
                                    "count": 2
                                },
                                "Important orders water solubility": {
                                    "count": 1
                                }
                            }
                        },
                        "Covlent bond": {
                            "count": 1,
                            "content": {
                                "Definition and different types of covalent bonds": {
                                    "count": 1
                                }
                            }
                        },
                        "Valence bond theory-1": {
                            "count": 10,
                            "content": {
                                "Bonding by overlapping of atomic orbitals(VBT)": {
                                    "count": 1
                                },
                                "Sigma and pi bond and their relative strength": {
                                    "count": 1
                                },
                                "Demerits of atomic orbital approach": {
                                    "count": 1
                                },
                                "Hybridization and Mathematical relationship with bond angle": {
                                    "count": 1
                                },
                                "Atomic orbital participation in different hybrid orbitals and Electronic geometry associated": {
                                    "count": 1
                                },
                                "Method to determine hybridization": {
                                    "count": 1
                                },
                                "Valence shell electron pair repulsion theory": {
                                    "count": 1
                                },
                                "Effect of lone pair in different geometries": {
                                    "count": 1
                                },
                                "Bent's rule": {
                                    "count": 1
                                },
                                "Variation of bond angle": {
                                    "count": 1
                                }
                            }
                        },
                        "Back bonding": {
                            "count": 2,
                            "content": {
                                "Definition and conditions for back bonding": {
                                    "count": 1
                                },
                                "Applications of back bonding": {
                                    "count": 1
                                }
                            }
                        },
                        "Bridge bonding": {
                            "count": 2,
                            "content": {
                                "Bonding of diboranes": {
                                    "count": 1
                                },
                                "Bridge bonding in other compounds": {
                                    "count": 1
                                }
                            }
                        },
                        "Molecular orbital (MO)": {
                            "count": 5,
                            "content": {
                                "Types of molecular orbitals and LCAO (Linear combination of atomic orbitals) method": {
                                    "count": 1
                                },
                                "Rules for combination of atomic orbitals": {
                                    "count": 1
                                },
                                "Combination of atomic orbitals to give MO by LCAO and Energy order of Molecular orbitals": {
                                    "count": 1
                                },
                                "Calculation of bond order, variation of bond length and bond energy": {
                                    "count": 1
                                },
                                "MO configuration of different molecules": {
                                    "count": 1
                                }
                            }
                        },
                        "Polarity": {
                            "count": 5,
                            "content": {
                                "Polarity of bonds": {
                                    "count": 1
                                },
                                "Dipole moment": {
                                    "count": 1
                                },
                                "Variation of dipole moment": {
                                    "count": 1
                                },
                                "Comparison of dipole in important examples": {
                                    "count": 1
                                },
                                "Dipole moment in geometrical isomers": {
                                    "count": 1
                                }
                            }
                        },
                        "Metallic bond": {
                            "count": 2,
                            "content": {
                                "Definition, Factors affecting strength of metallic bonds": {
                                    "count": 1
                                },
                                "Effect on conductivity and metallic lustre": {
                                    "count": 1
                                }
                            }
                        },
                        "Intermolecular forces": {
                            "count": 2,
                            "content": {
                                "Dipole dipole attractions and Dipole induced dipole attraction": {
                                    "count": 1
                                },
                                "Van Der Waals forces and Factors affecting strength of van der Waals attractions": {
                                    "count": 1
                                }
                            }
                        },
                        "Hydrogen bonding": {
                            "count": 6,
                            "content": {
                                "Definition, types of H bonding": {
                                    "count": 1
                                },
                                "Strength of H bond,condition for intramolecular H bonding": {
                                    "count": 1
                                },
                                "Applications of H bonding on B.P, M.P and water solubility": {
                                    "count": 1
                                },
                                "Effect of H bond on acidic strength": {
                                    "count": 1
                                },
                                "Effect of H bond on basic strength": {
                                    "count": 1
                                },
                                "Effect of H bond on other properties": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Hydrogen and its compounds": {
                    "count": 0,
                    "content": {
                        "Introduction": {
                            "count": 0,
                            "content": {
                                "Electronic configuration, Occurence and isotopes of hydrogen": {
                                    "count": 0
                                },
                                "Resemblance with alkali metals and differences": {
                                    "count": 0
                                },
                                "Resemblance with halogens and differences": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of hydrogen": {
                            "count": 0,
                            "content": {
                                "Atomic hydrogen, nascent hydrogen": {
                                    "count": 0
                                },
                                "Ortho and para hydrogen, Comparison of properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and physical properties of hydrogen": {
                            "count": 0,
                            "content": {
                                "Laboratory preparation of hydrogen": {
                                    "count": 0
                                },
                                "Commercial preparation of hydrogen and its physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties": {
                            "count": 0,
                            "content": {
                                "Reaction with metals and non-metals": {
                                    "count": 0
                                },
                                "Reducing properties and Uses of hydrogen": {
                                    "count": 0
                                }
                            }
                        },
                        "Hydrides": {
                            "count": 0,
                            "content": {
                                "Ionic hydrides and their properties": {
                                    "count": 0
                                },
                                "Covalent hydrides and their properties": {
                                    "count": 0
                                },
                                "Metallic or non stoichiometric hydrides and Polymeric hydrides": {
                                    "count": 0
                                }
                            }
                        },
                        "Water": {
                            "count": 0,
                            "content": {
                                "Physical properties of water and heavy water": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of water": {
                            "count": 0,
                            "content": {
                                "Reaction with metals and non-metals": {
                                    "count": 0
                                },
                                "Reaction with compounds": {
                                    "count": 0
                                }
                            }
                        },
                        "Hard water": {
                            "count": 0,
                            "content": {
                                "Hardness of water and causes": {
                                    "count": 0
                                },
                                "Removal of temporary hardness": {
                                    "count": 0
                                },
                                "Removal of permanent hardness": {
                                    "count": 0
                                }
                            }
                        },
                        "Heavy water": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of heavy water and uses of heavy water": {
                                    "count": 0
                                }
                            }
                        },
                        "Hydrogen peroxide": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Physical properties and structure": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of hydrogen peroxie": {
                            "count": 0,
                            "content": {
                                "Acidic nature and reactions as an acid": {
                                    "count": 0
                                },
                                "As an oxidizing agent, bleaching action": {
                                    "count": 0
                                },
                                "As a reducing agent": {
                                    "count": 0
                                },
                                "Detection of H₂O₂": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "S-block elements (Alkali and alkaline earth metals) and Hydrogen": {
                    "count": 0,
                    "content": {
                        "Alkali metals": {
                            "count": 0,
                            "content": {
                                "General electronic configuration and group members": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties-1": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                },
                                "Bond type": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties-2": {
                            "count": 0,
                            "content": {
                                "Oxidation state, Photoelectric emission and Hydration energies": {
                                    "count": 0
                                },
                                "Reducing ability in gaseous and aqueous state": {
                                    "count": 0
                                },
                                "Ionic mobility and flame colours": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-1": {
                            "count": 0,
                            "content": {
                                "Action of air and water": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-2": {
                            "count": 0,
                            "content": {
                                "Peroxides and superoxides of alkali metals": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-3": {
                            "count": 0,
                            "content": {
                                "Ionic hydrides and carbides": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-4": {
                            "count": 0,
                            "content": {
                                "Sulphides and polysulphides": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-5": {
                            "count": 0,
                            "content": {
                                "Solution in liquid ammonia": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical chemical properties-6": {
                            "count": 0,
                            "content": {
                                "Abnormalities of lithium": {
                                    "count": 0
                                }
                            }
                        },
                        "Salts of alkali metals": {
                            "count": 0,
                            "content": {
                                "Carbonates,Bicarbonates and nitrates": {
                                    "count": 0
                                },
                                "Halides of alkali metals": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of NaOH": {
                            "count": 0,
                            "content": {
                                "Preparation of NaOH": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of NaOH": {
                            "count": 0,
                            "content": {
                                "Physical properties and action of air": {
                                    "count": 0
                                },
                                "Reaction with protic acids, acidic and amphoteric oxides": {
                                    "count": 0
                                },
                                "Reaction with metals": {
                                    "count": 0
                                },
                                "Reaction with non metals": {
                                    "count": 0
                                },
                                "Reaction with ammonium salts and with CO": {
                                    "count": 0
                                },
                                "Reaction with salts": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparations of Na₂CO₃": {
                            "count": 0,
                            "content": {
                                "Le Blanc process": {
                                    "count": 0
                                },
                                "Solvay’s process": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties": {
                            "count": 0,
                            "content": {
                                "Physical properties and formation of bicarbonate": {
                                    "count": 0
                                },
                                "Action of protic acids and acidic oxides": {
                                    "count": 0
                                },
                                "Reaction with salts": {
                                    "count": 0
                                },
                                "Reaction with sulphur and sulphur dioxide, uses of sodium carbonate": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparations of KI": {
                            "count": 0,
                            "content": {
                                "Laboratory and other preparations": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of KI": {
                            "count": 0,
                            "content": {
                                "Physical properties": {
                                    "count": 0
                                },
                                "Chemical properties": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Alkaline earth metals": {
                    "count": 0,
                    "content": {
                        "Alkaline earth metals": {
                            "count": 0,
                            "content": {
                                "General electronic configuration and group members": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                },
                                "Flame colours": {
                                    "count": 0
                                },
                                "General chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Compounds of alkaline earth metals": {
                            "count": 0,
                            "content": {
                                "Hydrides, nitrides, oxides and halides": {
                                    "count": 0
                                },
                                "Hydroxides and oxo salts": {
                                    "count": 0
                                }
                            }
                        },
                        "General trends": {
                            "count": 0,
                            "content": {
                                "Solubility and thermal stability": {
                                    "count": 0
                                }
                            }
                        },
                        "Abnormalities of beryllium": {
                            "count": 0,
                            "content": {
                                "Abnormal physical and chemical properties": {
                                    "count": 0
                                },
                                "Diagonal relationship with Al": {
                                    "count": 0
                                }
                            }
                        },
                        "Important compounds": {
                            "count": 0,
                            "content": {
                                "Comopunds of magnesium and calcium": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "P Block elements": {
                    "count": 0,
                    "content": {
                        "Boron family": {
                            "count": 0,
                            "content": {
                                "General electronic configuration and group members": {
                                    "count": 0
                                },
                                "Metallic and non metallic nature": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties": {
                            "count": 0,
                            "content": {
                                "Allotropy": {
                                    "count": 0
                                },
                                "General physical properties": {
                                    "count": 0
                                },
                                "General chemical properties": {
                                    "count": 0
                                },
                                "Compounds of gp 13 elements": {
                                    "count": 0
                                },
                                "Abnormalities of boron": {
                                    "count": 0
                                }
                            }
                        },
                        "Boron": {
                            "count": 0,
                            "content": {
                                "Ores and extraction of boron": {
                                    "count": 0
                                },
                                "Properties of boron": {
                                    "count": 0
                                }
                            }
                        },
                        "Boron trioxide": {
                            "count": 0,
                            "content": {
                                "Preparation of boron trioxide": {
                                    "count": 0
                                },
                                "Physical and chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of boric acid": {
                            "count": 0,
                            "content": {
                                "Preparation of boric acid": {
                                    "count": 0
                                },
                                "Properties of boric acid": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of borax": {
                            "count": 0,
                            "content": {
                                "Preparation of borax": {
                                    "count": 0
                                },
                                "Properties of borax": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of boron trihalides": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Boron hydrides": {
                            "count": 0,
                            "content": {
                                "Classification in Nido and arachno boranes": {
                                    "count": 0
                                },
                                "Preparation of diborane": {
                                    "count": 0
                                },
                                "Properties of diborane": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of AlCl₃": {
                            "count": 0,
                            "content": {
                                "Preparation of AlCl₃": {
                                    "count": 0
                                },
                                "Properties of AlCl₃": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Carbon family": {
                    "count": 0,
                    "content": {
                        "Carbon family": {
                            "count": 0,
                            "content": {
                                "General electronic configuration": {
                                    "count": 0
                                },
                                "Group members, metallic and non metallic nature": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                },
                                "Allotropy of carbon and tin": {
                                    "count": 0
                                },
                                "General chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Compounds of group- 14": {
                            "count": 0,
                            "content": {
                                "Hydrides": {
                                    "count": 0
                                },
                                "Halides and Oxides": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of carbon monoxide": {
                            "count": 0,
                            "content": {
                                "Preparation of carbon monoxide": {
                                    "count": 0
                                },
                                "Properties of carbon monoxide": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of CO₂": {
                            "count": 0,
                            "content": {
                                "Preparation of CO₂": {
                                    "count": 0
                                },
                                "Properties of CO₂": {
                                    "count": 0
                                }
                            }
                        },
                        "Carbides": {
                            "count": 0,
                            "content": {
                                "Type of Carbides": {
                                    "count": 0
                                },
                                "Chemical reactions of Carbides": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of silica": {
                            "count": 0,
                            "content": {
                                "Preparation of silica": {
                                    "count": 0
                                },
                                "Properties of silica": {
                                    "count": 0
                                }
                            }
                        },
                        "Silicones and silicates": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of silicone": {
                                    "count": 0
                                },
                                "Type of silicates": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of SnCI₂": {
                            "count": 0,
                            "content": {
                                "Preparation of SnCI₂": {
                                    "count": 0
                                },
                                "Properties of SnCI₂": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of stannic chloride": {
                            "count": 0,
                            "content": {
                                "Preparation of stannic chloride": {
                                    "count": 0
                                },
                                "Properties of stannic chloride": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of lead dioxide": {
                            "count": 0,
                            "content": {
                                "Preparation of lead dioxide": {
                                    "count": 0
                                },
                                "Properties of lead dioxide": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Nitrogen family": {
                    "count": 0,
                    "content": {
                        "Group members": {
                            "count": 0,
                            "content": {
                                "General electronic configuration, Metallic and non metallic nature of group members": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                },
                                "Allotropy": {
                                    "count": 0
                                },
                                "General chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Compounds of group": {
                            "count": 0,
                            "content": {
                                "Hydrides": {
                                    "count": 0
                                },
                                "Halides": {
                                    "count": 0
                                },
                                "Oxides and oxyacids": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of nitrogen": {
                            "count": 0,
                            "content": {
                                "Preparation of nitrogen": {
                                    "count": 0
                                },
                                "Properties of nitrogen": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of ammonia": {
                            "count": 0,
                            "content": {
                                "Preparation of ammonia": {
                                    "count": 0
                                },
                                "Physical properties of ammonia": {
                                    "count": 0
                                },
                                "Chemical properties of ammonia": {
                                    "count": 0
                                },
                                "Uses of ammonia": {
                                    "count": 0
                                },
                                "Qualitative estimation of ammonia": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxides of nitrigen": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of nitrous oxide": {
                                    "count": 0
                                },
                                "Preparation and properties of nitric oxide": {
                                    "count": 0
                                },
                                "Preparation and properties of nitrogen dioxide": {
                                    "count": 0
                                },
                                "Preparation and properties of dinitrogen pentoxide": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxyacids of nitrogen": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of nitrous acid": {
                                    "count": 0
                                },
                                "Preparation of nitric acid": {
                                    "count": 0
                                },
                                "Physical properties of nitric acid and reactions as an acid": {
                                    "count": 0
                                },
                                "Nitric acid as an oxidizing agent with compounds": {
                                    "count": 0
                                },
                                "Action of nitric acid on metals, non metals and metalloids": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of phosphorus": {
                            "count": 0,
                            "content": {
                                "Allotropy of phosphorus": {
                                    "count": 0
                                },
                                "Preparation of phosphorus": {
                                    "count": 0
                                },
                                "Properties of phosphorus": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of phosphine": {
                            "count": 0,
                            "content": {
                                "Preparation of phosphine": {
                                    "count": 0
                                },
                                "Properties of phosphine": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxides of phosphorus": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of phosphorus trioxides": {
                                    "count": 0
                                },
                                "Preparation and properties of phosphorus pentoxide": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxyacids": {
                            "count": 0,
                            "content": {
                                "Structure of oxyacids": {
                                    "count": 0
                                },
                                "Preparation and properties of phosphorus acid": {
                                    "count": 0
                                },
                                "Preparation of phosphoric acid": {
                                    "count": 0
                                },
                                "Properties of phosphoric acid": {
                                    "count": 0
                                }
                            }
                        },
                        "Fertilizers": {
                            "count": 0,
                            "content": {
                                "Types and characteristics of fertilizers": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Oxygen family": {
                    "count": 0,
                    "content": {
                        "Group members (Group 16)": {
                            "count": 0,
                            "content": {
                                "General electronic configuration": {
                                    "count": 0
                                },
                                "Metallic and non metallic nature": {
                                    "count": 0
                                }
                            }
                        },
                        "General properties": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                },
                                "Allotropy": {
                                    "count": 0
                                },
                                "General chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Compounds of oxygen family": {
                            "count": 0,
                            "content": {
                                "Hydrides": {
                                    "count": 0
                                },
                                "Halides": {
                                    "count": 0
                                },
                                "Oxides and oxyacids": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of oxygen": {
                            "count": 0,
                            "content": {
                                "Preparation of oxygen": {
                                    "count": 0
                                },
                                "Properties of oxygen": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of ozone": {
                            "count": 0,
                            "content": {
                                "Preparation of ozone and its physical properties": {
                                    "count": 0
                                },
                                "Ozone as an oxidizing agent with compounds": {
                                    "count": 0
                                },
                                "Ozone as an oxidizing agent with metals and non metals": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of compounds": {
                            "count": 0,
                            "content": {
                                "Preparations of H₂S and its physical properties": {
                                    "count": 0
                                },
                                "Chemical reactions as an acid": {
                                    "count": 0
                                },
                                "Chemical reactions as a precipitating agent": {
                                    "count": 0
                                },
                                "Chemical reactions as a reducing agent": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of sulphur dioxide": {
                            "count": 0,
                            "content": {
                                "Preparation of sulphur dioxide": {
                                    "count": 0
                                },
                                "Physical and chemical properties of sulphur dioxide": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties and prparation of sulphur trioxide": {
                            "count": 0,
                            "content": {
                                "Preparation of sulphur trioxide": {
                                    "count": 0
                                },
                                "Physical and chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties sulphuric acid": {
                            "count": 0,
                            "content": {
                                "Preparation of sulphuric acid": {
                                    "count": 0
                                },
                                "Physical properties of sulphuric acid": {
                                    "count": 0
                                },
                                "Reactions of sulphuric acid as an acid": {
                                    "count": 0
                                },
                                "Reactions of sulphuric acid with metals and non metals": {
                                    "count": 0
                                },
                                "Reactions of sulphuric acid as an oxidizing agent": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of sodium thiosulphate": {
                            "count": 0,
                            "content": {
                                "Preparation of sodium thiosulphate": {
                                    "count": 0
                                },
                                "Physical properties of sodium thiosulphate": {
                                    "count": 0
                                },
                                "Chemical properties of sodium thiosulphate": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Group 17 (Halogens)": {
                    "count": 0,
                    "content": {
                        "Preparation and properties of sodium thiosulphate": {
                            "count": 0,
                            "content": {
                                "Group members and General electronic configuration": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of group": {
                            "count": 0,
                            "content": {
                                "Physical properties": {
                                    "count": 0
                                },
                                "Chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and propeties of flourine": {
                            "count": 0,
                            "content": {
                                "Preparation of fluorine": {
                                    "count": 0
                                },
                                "Properties of fluorine": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and propeties of chlorine": {
                            "count": 0,
                            "content": {
                                "Preparation of chlorine": {
                                    "count": 0
                                },
                                "Properties of chlorine": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of bromine": {
                            "count": 0,
                            "content": {
                                "Preparation of bromine": {
                                    "count": 0
                                },
                                "Properties of bromine": {
                                    "count": 0
                                }
                            }
                        },
                        "Prepration and properties of iodine": {
                            "count": 0,
                            "content": {
                                "Preparation of iodine": {
                                    "count": 0
                                },
                                "Properties of iodine": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of halocids": {
                            "count": 0,
                            "content": {
                                "Preparation of haloacids": {
                                    "count": 0
                                },
                                "Properties of haloacids": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxides of CI and Br": {
                            "count": 0,
                            "content": {
                                "Mono oxides of chlorine and bromine": {
                                    "count": 0
                                },
                                "Chlorine dioxide": {
                                    "count": 0
                                },
                                "Chlorine heptoxide": {
                                    "count": 0
                                },
                                "Dichloro hexo oxide": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxyacids of halogens": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of hypohalous acids": {
                                    "count": 0
                                },
                                "Preparation and properties of halic acids": {
                                    "count": 0
                                },
                                "Preparation and properties of perhalic acids": {
                                    "count": 0
                                }
                            }
                        },
                        "Bleaching powder": {
                            "count": 0,
                            "content": {
                                "Preparation of bleaching powder": {
                                    "count": 0
                                },
                                "Properties of bleaching Powder": {
                                    "count": 0
                                }
                            }
                        },
                        "Interhalogen compounds": {
                            "count": 0,
                            "content": {
                                "Preparation of interhalogen compounds": {
                                    "count": 0
                                },
                                "Properties of interhalogen compounds": {
                                    "count": 0
                                }
                            }
                        },
                        "Pseudohalides": {
                            "count": 0,
                            "content": {
                                "List of pseudohalides": {
                                    "count": 0
                                },
                                "Similarities with halogens": {
                                    "count": 0
                                },
                                "Preparation and properties of cyanogen": {
                                    "count": 0
                                }
                            }
                        },
                        "Polyhalides": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Group 18 (noble gases)": {
                    "count": 0,
                    "content": {
                        "Group members": {
                            "count": 0,
                            "content": {
                                "General electronic configuration": {
                                    "count": 0
                                },
                                "Physical properties": {
                                    "count": 0
                                },
                                "Chemical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Xenon diflouride": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Xenon tetraflouride": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Xenon hexaflouride": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Coordination Compounds": {
                    "count": 0,
                    "content": {
                        "Addition compound": {
                            "count": 0,
                            "content": {
                                "Definition and Double salts": {
                                    "count": 0
                                }
                            }
                        },
                        "Coordination or complex compounds": {
                            "count": 0,
                            "content": {
                                "Definition of Complex compounds, Nature of central metal atom/ion": {
                                    "count": 0
                                },
                                "Definition and Denticity of ligands": {
                                    "count": 0
                                },
                                "Coordination and ionization sphere": {
                                    "count": 0
                                },
                                "Primary/ionic and secondary valency(coordination number)": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification": {
                            "count": 0,
                            "content": {
                                "Classification of complexes": {
                                    "count": 0
                                },
                                "Classification of ligands based on denticity": {
                                    "count": 0
                                },
                                "Classification of ligands based on bonding": {
                                    "count": 0
                                }
                            }
                        },
                        "Metal carbonyls": {
                            "count": 0,
                            "content": {
                                "Bonding in metal carbonyls ( synergic bonding )": {
                                    "count": 0
                                },
                                "Effect of back bonding on different bonds": {
                                    "count": 0
                                },
                                "Classification of carbonyls": {
                                    "count": 0
                                },
                                "Important carbonyls and their structure": {
                                    "count": 0
                                }
                            }
                        },
                        "Zeise salt": {
                            "count": 0,
                            "content": {
                                "Structure": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Effective atomic numner (EAN)": {
                            "count": 0,
                            "content": {
                                "Effective atomic number and method of calculation": {
                                    "count": 0
                                },
                                "Sidgwick EAN rule, application and limitations": {
                                    "count": 0
                                }
                            }
                        },
                        "Nomenclature of complex compounds": {
                            "count": 0,
                            "content": {
                                "Rules": {
                                    "count": 0
                                },
                                "Examples": {
                                    "count": 0
                                }
                            }
                        },
                        "Werner theory": {
                            "count": 0,
                            "content": {
                                "Primary and Secondary valency": {
                                    "count": 0
                                },
                                "Shape based on secondary valency and Limitations of Werner theory": {
                                    "count": 0
                                }
                            }
                        },
                        "Structural isomerism in complexes": {
                            "count": 0,
                            "content": {
                                "Ionisation isomerism and Hydrate/solvate isomerism": {
                                    "count": 0
                                },
                                "Linkage isomerism and Ligand isomerism": {
                                    "count": 0
                                },
                                "Coordination isomerism, Position coordination isomerism and Coordination polymerization isomerism": {
                                    "count": 0
                                }
                            }
                        },
                        "Stereoisomerism": {
                            "count": 0,
                            "content": {
                                "Tetrahedral complexes": {
                                    "count": 0
                                },
                                "Square planar complexes": {
                                    "count": 0
                                },
                                "Stereoisomerism in octahedral complexes with monodentate ligands": {
                                    "count": 0
                                },
                                "Stereoisomerism in octahedral complexes with bidentate ligands": {
                                    "count": 0
                                }
                            }
                        },
                        "Valence bond theory (VBT)": {
                            "count": 0,
                            "content": {
                                "Postulates and Hybridization states": {
                                    "count": 0
                                },
                                "Inner, outer orbital complexes and Limitations of VBT": {
                                    "count": 0
                                }
                            }
                        },
                        "Crystal field theory": {
                            "count": 0,
                            "content": {
                                "Shape of d orbitals and Postulates": {
                                    "count": 0
                                },
                                "Weak and strong field ligands, Spectrochemical series": {
                                    "count": 0
                                },
                                "Low and high spin complexes": {
                                    "count": 0
                                },
                                "Crystal field splitting in octahedral complexes": {
                                    "count": 0
                                },
                                "Pairing energy and Conditions for pairing": {
                                    "count": 0
                                },
                                "Crystal field stabilization energy (CFSE)": {
                                    "count": 0
                                },
                                "Calculation of CFSE in octahedral complexes": {
                                    "count": 0
                                },
                                "Factors affecting delta": {
                                    "count": 0
                                },
                                "Splitting in tetrahedral complexes and CFSE calculation": {
                                    "count": 0
                                },
                                "Splitting in square planar complexes": {
                                    "count": 0
                                }
                            }
                        },
                        "Jahn teller distortion theorem": {
                            "count": 0,
                            "content": {
                                "Tetragonal distortion": {
                                    "count": 0
                                },
                                "Application of Jahn teller distortion theorem": {
                                    "count": 0
                                }
                            }
                        },
                        "Color analysis in ocmplexes": {
                            "count": 0,
                            "content": {
                                "D-D transition": {
                                    "count": 0
                                },
                                "Ligand to metal charge transfer (LMCT)": {
                                    "count": 0
                                },
                                "Metal to ligand charge transfer (MLCT)": {
                                    "count": 0
                                },
                                "Metal to metal charge transfer(MMCT)": {
                                    "count": 0
                                }
                            }
                        },
                        "Stability of complexes": {
                            "count": 0,
                            "content": {
                                "Formation constant and Factors affecting stability of complexes": {
                                    "count": 0
                                }
                            }
                        },
                        "Application of complexes": {
                            "count": 0,
                            "content": {
                                "In qualitative and quantitative analysis": {
                                    "count": 0
                                },
                                "In metallurgy and photography": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "D Block elements": {
                    "count": 0,
                    "content": {
                        "Electronic configurations of d-block elements": {
                            "count": 0,
                            "content": {
                                "Series wise distribution and abnormal electronic configurations": {
                                    "count": 0
                                }
                            }
                        },
                        "General characterictics- 1": {
                            "count": 0,
                            "content": {
                                "Metallic bonding in transition metal lattices": {
                                    "count": 0
                                },
                                "Atomic and ionic radii of transition elements": {
                                    "count": 0
                                },
                                "Variable oxidation state and relative stability of various oxidation states": {
                                    "count": 0
                                },
                                "Complexation and reactivity of metals": {
                                    "count": 0
                                },
                                "Density, melting and boiling points": {
                                    "count": 0
                                }
                            }
                        },
                        "General characterictics- 2": {
                            "count": 0,
                            "content": {
                                "Ionisation energies and lattice structure": {
                                    "count": 0
                                },
                                "Standard electrode potential": {
                                    "count": 0
                                },
                                "Nature of oxides, color and polarisation": {
                                    "count": 0
                                },
                                "Incompletely filled d or f subshell": {
                                    "count": 0
                                },
                                "Magnetic properties and Catalytic properties": {
                                    "count": 0
                                },
                                "Electrical conductivity and metallic lustre": {
                                    "count": 0
                                },
                                "Nonstoichiometric compounds": {
                                    "count": 0
                                },
                                "Alloy formation and Abundance": {
                                    "count": 0
                                }
                            }
                        },
                        "Potassium dichromate": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Potassium permanganate": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Properties": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "F block elements": {
                    "count": 0,
                    "content": {
                        "Classification of f-block elements": {
                            "count": 0,
                            "content": {
                                "Lanthanoid": {
                                    "count": 0
                                },
                                "Actinoids": {
                                    "count": 0
                                }
                            }
                        },
                        "General characteristics": {
                            "count": 0,
                            "content": {
                                "Electronic configuration, Physical state and Oxidation states": {
                                    "count": 0
                                },
                                "Magnetic properties and Complexation": {
                                    "count": 0
                                },
                                "Atomic and ionic radii": {
                                    "count": 0
                                },
                                "Lanthanoid contraction": {
                                    "count": 0
                                },
                                "Basic strength of hydroxides": {
                                    "count": 0
                                }
                            }
                        },
                        "Comparison b/w 3d and 4f block elements": {
                            "count": 0,
                            "content": {
                                "Magnetic moment": {
                                    "count": 0
                                },
                                "Oxidation state": {
                                    "count": 0
                                }
                            }
                        },
                        "General characteristics of actinoid": {
                            "count": 0,
                            "content": {
                                "Electronic configuration, Oxidation states and Electropositive character": {
                                    "count": 0
                                },
                                "Atomic and ionic radii": {
                                    "count": 0
                                },
                                "Magnetic behaviour": {
                                    "count": 0
                                },
                                "Ionisation energies and Reducing action": {
                                    "count": 0
                                },
                                "Radioactivity and Colour of ions": {
                                    "count": 0
                                },
                                "Similarities with lanthanoids": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "General principles and processes of isolation of metals (Metallurgy)": {
                    "count": 0,
                    "content": {
                        "Minerals & Ores": {
                            "count": 0,
                            "content": {
                                "Definitions and Type of ores": {
                                    "count": 0
                                }
                            }
                        },
                        "Concentration of ores by physical separation": {
                            "count": 0,
                            "content": {
                                "Gravimetric separation or levigation": {
                                    "count": 0
                                },
                                "Magnetic separation": {
                                    "count": 0
                                },
                                "Froth floatation": {
                                    "count": 0
                                }
                            }
                        },
                        "Concentration of ores by chemical separation": {
                            "count": 0,
                            "content": {
                                "Leaching of alumina, gold ores and argentite": {
                                    "count": 0
                                }
                            }
                        },
                        "Conversion of concentrated ore into its oxide": {
                            "count": 0,
                            "content": {
                                "Calcination": {
                                    "count": 0
                                },
                                "Roasting": {
                                    "count": 0
                                }
                            }
                        },
                        "Reduction of oxide to crude metal": {
                            "count": 0,
                            "content": {
                                "Carbon reduction": {
                                    "count": 0
                                },
                                "Hydrogen reduction": {
                                    "count": 0
                                },
                                "Thermite reduction/aluminothermy and Hydrometallurgy": {
                                    "count": 0
                                },
                                "Electrolytic reduction and Thermal decomposition": {
                                    "count": 0
                                },
                                "Electrolytic vs carbon reduction": {
                                    "count": 0
                                }
                            }
                        },
                        "Refining or purification of crude metal": {
                            "count": 0,
                            "content": {
                                "Thermal refining and Fractional distillation": {
                                    "count": 0
                                },
                                "Liquation and Zone refining": {
                                    "count": 0
                                },
                                "Vapour phase refining and Electro-refining": {
                                    "count": 0
                                }
                            }
                        },
                        "Thermodynamics of reduction process": {
                            "count": 0,
                            "content": {
                                "Ellingham diagram": {
                                    "count": 0
                                },
                                "Explanation based on free energy change and curve for carbon": {
                                    "count": 0
                                }
                            }
                        },
                        "Alloys and amalgam": {
                            "count": 0,
                            "content": {
                                "Definition, classification and preparation": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of furnaces": {
                            "count": 0,
                            "content": {
                                "Blast and reverberatory furnace": {
                                    "count": 0
                                },
                                "Muffle and Electric furnace": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of silver": {
                            "count": 0,
                            "content": {
                                "Ores and extraction of silver": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of gold": {
                            "count": 0,
                            "content": {
                                "Ores and extraction of gold": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of tin": {
                            "count": 0,
                            "content": {
                                "Ores of tin and concentration": {
                                    "count": 0
                                },
                                "Carbon reduction and Electro-refining": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of magnesium": {
                            "count": 0,
                            "content": {
                                "Ores of Mg and preparation of MgCl₂hydrated and anhydrous": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of aluminium": {
                            "count": 0,
                            "content": {
                                "Ores of aluminium, concentration and role of cryolite": {
                                    "count": 0
                                },
                                "Electrolytic reduction and Electro refining of Al": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of lead": {
                            "count": 0,
                            "content": {
                                "Ores of lead, concentration": {
                                    "count": 0
                                },
                                "Carbon reduction, Self reduction and electrolytic refining": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of copper": {
                            "count": 0,
                            "content": {
                                "Ores of copper, concentration": {
                                    "count": 0
                                },
                                "Smelting, Bessemerization and refining": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of zinc": {
                            "count": 0,
                            "content": {
                                "Ores, concentration": {
                                    "count": 0
                                },
                                "Carbon reduction and Electrorefining": {
                                    "count": 0
                                }
                            }
                        },
                        "Extraction of iron": {
                            "count": 0,
                            "content": {
                                "Ores of iron, concentration": {
                                    "count": 0
                                },
                                "Refining and types of iron": {
                                    "count": 0
                                }
                            }
                        },
                        "Steel formation": {
                            "count": 0,
                            "content": {
                                "Types of steel, preparation, annealing and quenching": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Qualitative inorganic analysis": {
                    "count": 0,
                    "content": {
                        "Classification of acid radicals": {
                            "count": 0,
                            "content": {
                                "Dilute H₂SO₄/ dil HCl group radicals and reactions involved": {
                                    "count": 0
                                },
                                "Conc H₂SO₄ group radicals and reactions involved": {
                                    "count": 0
                                },
                                "Independent group": {
                                    "count": 0
                                }
                            }
                        },
                        "Carbonate and bicarbonate radicals": {
                            "count": 0,
                            "content": {
                                "Action of dil H₂SO₄ carbonate/ bicarbonate and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Sulphite radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Thiosulphate radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Sulphide radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Nitrite radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Acetate radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Formate radical": {
                            "count": 0,
                            "content": {
                                "Action of dil  H₂SO₄ or dil HCl and confirmatory tests": {
                                    "count": 0
                                }
                            }
                        },
                        "Chloride radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, test with precipitating agents": {
                                    "count": 0
                                },
                                "Test with MnO₂ and conc H₂SO₄ ,chromyl chloride test": {
                                    "count": 0
                                }
                            }
                        },
                        "Bromide radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                }
                            }
                        },
                        "Iodide radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                }
                            }
                        },
                        "Flourides": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                }
                            }
                        },
                        "Nitrate radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                },
                                "Brown ring test": {
                                    "count": 0
                                }
                            }
                        },
                        "Borate radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxalate radical": {
                            "count": 0,
                            "content": {
                                "Action of conc H₂SO₄, H₃PO₄ and confirmatory tests\n": {
                                    "count": 0
                                }
                            }
                        },
                        "Sulphate radical": {
                            "count": 0,
                            "content": {
                                "Confirmatory tests and reactions": {
                                    "count": 0
                                }
                            }
                        },
                        "Phosphate radical": {
                            "count": 0,
                            "content": {
                                "Confirmatory tests and reactions": {
                                    "count": 0
                                }
                            }
                        },
                        "Heating effects": {
                            "count": 0,
                            "content": {
                                "On carbonates and bicarbonates": {
                                    "count": 0
                                },
                                "On nitrates": {
                                    "count": 0
                                },
                                "On ammonium salts": {
                                    "count": 0
                                },
                                "On sulphates": {
                                    "count": 0
                                },
                                "On hydrated chlorides": {
                                    "count": 0
                                }
                            }
                        },
                        "Flame test": {
                            "count": 0,
                            "content": {
                                "Alkali and alkaline metals": {
                                    "count": 0
                                }
                            }
                        },
                        "Borax bead test": {
                            "count": 0,
                            "content": {
                                "Procedure and colors observed": {
                                    "count": 0
                                }
                            }
                        },
                        "Charcoal cavity test": {
                            "count": 0,
                            "content": {
                                "Procedure, Metals detected and limitations": {
                                    "count": 0
                                }
                            }
                        },
                        "Cobalt nitrate test": {
                            "count": 0,
                            "content": {
                                "Procedure and Cations detected": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification of basic radicals": {
                            "count": 0,
                            "content": {
                                "Classification, group members and colour and composition of precipitates": {
                                    "count": 0
                                },
                                "Explanation for group reagents": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 1": {
                            "count": 0,
                            "content": {
                                "Tests of lead cation": {
                                    "count": 0
                                },
                                "Tests of mercurous cation": {
                                    "count": 0
                                },
                                "Tests of silver cation": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 2": {
                            "count": 0,
                            "content": {
                                "Distinction between group 2A and 2B": {
                                    "count": 0
                                },
                                "Tests of mercuric cation": {
                                    "count": 0
                                },
                                "Tests of bismuth cation": {
                                    "count": 0
                                },
                                "Tests of cupric cation": {
                                    "count": 0
                                },
                                "Tests of lead cation": {
                                    "count": 0
                                },
                                "Tests of cadmium cation": {
                                    "count": 0
                                },
                                "Tests of arsenic cation": {
                                    "count": 0
                                },
                                "Tests of antimony cation": {
                                    "count": 0
                                },
                                "Tests of stannous and stannic cation": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 3": {
                            "count": 0,
                            "content": {
                                "Tests of ferric ion": {
                                    "count": 0
                                },
                                "Tests of aluminium cation": {
                                    "count": 0
                                },
                                "Tests of chromium cation": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 4": {
                            "count": 0,
                            "content": {
                                "Tests of Cobalt cation": {
                                    "count": 0
                                },
                                "Tests of nickel cation": {
                                    "count": 0
                                },
                                "Tests of Manganese cation": {
                                    "count": 0
                                },
                                "Tests of zinc cation": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 5": {
                            "count": 0,
                            "content": {
                                "Tests of barium, strontium and calcium cation": {
                                    "count": 0
                                }
                            }
                        },
                        "Group 6": {
                            "count": 0,
                            "content": {
                                "Tests of magnesium cation": {
                                    "count": 0
                                }
                            }
                        }
                    }
                }
            }
        },
        "Physical Chemistry": {
            "count": 224,
            "content": {
                "Basic concepts of chemistry (Mole concept)": {
                    "count": 27,
                    "content": {
                        "BASIC MOLE": {
                            "count": 11,
                            "content": {
                                "Classification of matter and units of measurement": {
                                    "count": 1
                                },
                                "mole definition , Avogadro's number": {
                                    "count": 1
                                },
                                "mole - number relation": {
                                    "count": 1
                                },
                                "Atomic mass unit , RAM ,GAM": {
                                    "count": 1
                                },
                                "Percentage abundance of isotopes,Average atomic mass": {
                                    "count": 1
                                },
                                "RMM  ,GMM, MOLAR MASS": {
                                    "count": 1
                                },
                                "MASS - MOLE RELATION": {
                                    "count": 1
                                },
                                "Density, specific gravity and specific volume": {
                                    "count": 1
                                },
                                "VOLUME - MOLE RELATION": {
                                    "count": 1
                                },
                                "INTERCONVERSION OF MASS , MOLE VOLUME, NUMBER": {
                                    "count": 1
                                },
                                "SUBENTITIES": {
                                    "count": 1
                                }
                            }
                        },
                        "BASIC STOICHIOMETRY": {
                            "count": 5,
                            "content": {
                                "Empiricial formula, definition and determination": {
                                    "count": 1
                                },
                                "Molecular formula, definition and determination": {
                                    "count": 1
                                },
                                "Principle of Atomic Conservation , BALANCING OF REACTION": {
                                    "count": 1
                                },
                                "Equation-based calculations": {
                                    "count": 1
                                },
                                "Limiting reagent": {
                                    "count": 1
                                }
                            }
                        },
                        "ADVANCED STOICHIOMETRY": {
                            "count": 4,
                            "content": {
                                "Reaction yield": {
                                    "count": 1
                                },
                                "POAC METHOD": {
                                    "count": 1
                                },
                                "SEQUENTIAL AND PARALLEL REACTIONS": {
                                    "count": 1
                                },
                                "MIXTURE PROBLEMS": {
                                    "count": 1
                                }
                            }
                        },
                        "Concentration TERMS": {
                            "count": 7,
                            "content": {
                                "Mole percent and mole fraction": {
                                    "count": 1
                                },
                                "Mass percent and mass fraction": {
                                    "count": 1
                                },
                                "Molarity ": {
                                    "count": 1
                                },
                                "MOLALITY": {
                                    "count": 1
                                },
                                "PPM,PPB": {
                                    "count": 1
                                },
                                "INTERCONVERSION OF CONCENTRATION TERMS": {
                                    "count": 1
                                },
                                "STOICHIOMETRY OF REACTIONS IN SOLUTION": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Atomic structure": {
                    "count": 38,
                    "content": {
                        "EARLY ATOMIC MODELS": {
                            "count": 4,
                            "content": {
                                "CRT experiment": {
                                    "count": 1
                                },
                                "thomson's model": {
                                    "count": 1
                                },
                                "gold foil experiment": {
                                    "count": 1
                                },
                                "rutherford's model": {
                                    "count": 1
                                }
                            }
                        },
                        "Useful concept of Physics": {
                            "count": 1,
                            "content": {
                                " Wave and Wave theory of light": {
                                    "count": 1
                                }
                            }
                        },
                        "useful concept of physics": {
                            "count": 1,
                            "content": {
                                "basic terms of physics": {
                                    "count": 1
                                }
                            }
                        },
                        "DUAL NATURE OF LIGHT": {
                            "count": 2,
                            "content": {
                                "Planck's Quantum theory": {
                                    "count": 1
                                },
                                "Electromagnetic radiations": {
                                    "count": 1
                                }
                            }
                        },
                        "BOHR'S THEORY": {
                            "count": 3,
                            "content": {
                                "Bohr theory ": {
                                    "count": 1
                                },
                                "derivation of r,v,E": {
                                    "count": 1
                                },
                                "time period, frequency": {
                                    "count": 1
                                }
                            }
                        },
                        "ATOMIC SPECTRUM": {
                            "count": 3,
                            "content": {
                                "Emission spectrum,Absorption spectrum": {
                                    "count": 1
                                },
                                "Hydrogen spectrum": {
                                    "count": 1
                                },
                                "Rydberg's equation": {
                                    "count": 1
                                }
                            }
                        },
                        "Wave- particle duality": {
                            "count": 3,
                            "content": {
                                "drawback of bohr , Sommerfeld model": {
                                    "count": 1
                                },
                                "De Broglie’s wavelength": {
                                    "count": 1
                                },
                                "Heisenberg’s uncertainty principle": {
                                    "count": 1
                                }
                            }
                        },
                        "Photoelectric effect": {
                            "count": 3,
                            "content": {
                                "Photoelectric emission, observations and conclusions,Black body radiation,": {
                                    "count": 1
                                },
                                "Einstein's photoelectric equation": {
                                    "count": 1
                                },
                                "photocurrent ,stopping volatge": {
                                    "count": 1
                                }
                            }
                        },
                        "Schrodinger equation": {
                            "count": 18,
                            "content": {
                                "SHRODINGER EQUATION ": {
                                    "count": 1
                                },
                                "QUANTUM NUMBERS": {
                                    "count": 1
                                },
                                "Aufbau rule": {
                                    "count": 1
                                },
                                "Pauli's exclusion principle": {
                                    "count": 1
                                },
                                "Hund's rule": {
                                    "count": 1
                                },
                                "Electronic configuration of atoms": {
                                    "count": 1
                                },
                                "EXCHANGE PAIRS AND SPIN PAIRS": {
                                    "count": 1
                                },
                                "Extra stable configurations": {
                                    "count": 1
                                },
                                "Exceptional configurations": {
                                    "count": 1
                                },
                                "Electronic configuration of ions": {
                                    "count": 1
                                },
                                "Orbitals and shape of orbitals": {
                                    "count": 1
                                },
                                "Wave function": {
                                    "count": 1
                                },
                                "Radial wave function": {
                                    "count": 1
                                },
                                "Angular wave function": {
                                    "count": 1
                                },
                                "Graphs of the radial distribution function": {
                                    "count": 1
                                },
                                "Nodes": {
                                    "count": 1
                                },
                                "FINDING MAX PROBABILITY DISTANCE": {
                                    "count": 1
                                },
                                "Orbital angular momentum and spin angular momentum": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Gaseous state": {
                    "count": 37,
                    "content": {
                        "Introduction": {
                            "count": 1,
                            "content": {
                                "Gas pressure and atmospheric pressure": {
                                    "count": 1
                                }
                            }
                        },
                        "Measurement of gas pressure": {
                            "count": 2,
                            "content": {
                                "Barometer and manometer": {
                                    "count": 1
                                },
                                "Pressure exerted by liquid columns": {
                                    "count": 1
                                }
                            }
                        },
                        "Gas laws": {
                            "count": 3,
                            "content": {
                                "Boyle's law": {
                                    "count": 1
                                },
                                "Charles' law": {
                                    "count": 1
                                },
                                "Avogadro's law": {
                                    "count": 1
                                }
                            }
                        },
                        "Ideal gas equation": {
                            "count": 3,
                            "content": {
                                "Formulation of law": {
                                    "count": 1
                                },
                                "Gas constant": {
                                    "count": 1
                                },
                                "The physical significance of molar gas constant": {
                                    "count": 1
                                }
                            }
                        },
                        "Dalton's law": {
                            "count": 5,
                            "content": {
                                "Partial pressure and partial volume": {
                                    "count": 1
                                },
                                "Mole fraction": {
                                    "count": 1
                                },
                                "Vapour density": {
                                    "count": 1
                                },
                                "Average molar mass": {
                                    "count": 1
                                },
                                "Measurement of gas density": {
                                    "count": 1
                                }
                            }
                        },
                        "Kinetic theory of gases": {
                            "count": 5,
                            "content": {
                                "Postulates": {
                                    "count": 1
                                },
                                "Properties of gases": {
                                    "count": 1
                                },
                                "The kinetic energy of gases": {
                                    "count": 1
                                },
                                "RMS, Average and most probable speed": {
                                    "count": 1
                                },
                                "Plots of Maxwell distribution of speeds": {
                                    "count": 1
                                }
                            }
                        },
                        "Graham's law": {
                            "count": 2,
                            "content": {
                                "Diffusion, effusion and their rates": {
                                    "count": 1
                                },
                                "Graham's law": {
                                    "count": 1
                                }
                            }
                        },
                        "Eudiometry": {
                            "count": 2,
                            "content": {
                                "Methods of collecting gases": {
                                    "count": 1
                                },
                                "Methods of drying gases": {
                                    "count": 1
                                }
                            }
                        },
                        "Non-ideal gases": {
                            "count": 4,
                            "content": {
                                "Limitations of the ideal gas equation": {
                                    "count": 1
                                },
                                "Correction in volume and Intermolecular attraction": {
                                    "count": 1
                                },
                                "Limiting condition for ideality": {
                                    "count": 1
                                },
                                "Real isotherms": {
                                    "count": 1
                                }
                            }
                        },
                        "Critical phenomena": {
                            "count": 3,
                            "content": {
                                "Critical point": {
                                    "count": 1
                                },
                                "Properties near-critical point": {
                                    "count": 1
                                },
                                "Compressibility factor": {
                                    "count": 1
                                }
                            }
                        },
                        "Van der Waals equation": {
                            "count": 4,
                            "content": {
                                "Corrections in van der waals equations": {
                                    "count": 1
                                },
                                "Van der Waals isotherm": {
                                    "count": 1
                                },
                                "Liquification and Van der Waals equation": {
                                    "count": 1
                                },
                                "Critical constants for Van der Waals gas": {
                                    "count": 1
                                }
                            }
                        },
                        "Other equations of state": {
                            "count": 3,
                            "content": {
                                "Virial equation of state and coefficients": {
                                    "count": 1
                                },
                                "Conversion of van der Waals equation into virial": {
                                    "count": 1
                                },
                                "Other equations of state": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Volumetric analysis": {
                    "count": 0,
                    "content": {
                        "Equivalent concept": {
                            "count": 0,
                            "content": {
                                "Equivalent mass": {
                                    "count": 0
                                },
                                "Law of equivalents": {
                                    "count": 0
                                },
                                "Normality": {
                                    "count": 0
                                }
                            }
                        },
                        "Equivalent mass calculation": {
                            "count": 0,
                            "content": {
                                "Calculation of n factor in redox reactions": {
                                    "count": 0
                                },
                                "Calculation of n factor in acid, base and salt": {
                                    "count": 0
                                }
                            }
                        },
                        "Advanced stoichiometry": {
                            "count": 0,
                            "content": {
                                "Acid-base titration": {
                                    "count": 0
                                },
                                "Back titration": {
                                    "count": 0
                                },
                                "Double titrations": {
                                    "count": 0
                                },
                                "Titration of carbonate mixtures": {
                                    "count": 0
                                },
                                "Miscellaneous titrations": {
                                    "count": 0
                                },
                                "Redox stoichiometry": {
                                    "count": 0
                                },
                                "Volume strength of H₂O₂": {
                                    "count": 0
                                }
                            }
                        },
                        "Redox Reactions": {
                            "count": 0,
                            "content": {
                                "Oxidation number/state": {
                                    "count": 0
                                },
                                "Balancing of redox reactions": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Thermodynamics and Thermochemistry": {
                    "count": 44,
                    "content": {
                        "Basic terms used in thermodynamics": {
                            "count": 2,
                            "content": {
                                "Thermodynamic systems and properties": {
                                    "count": 1
                                },
                                "Thermodynamic functions, process and state": {
                                    "count": 1
                                }
                            }
                        },
                        "Heat": {
                            "count": 2,
                            "content": {
                                "Thermal equilibrium and Zeroth law of thermodynamics": {
                                    "count": 1
                                },
                                "Heat transfer, mode of heat transfer and sign convention": {
                                    "count": 1
                                }
                            }
                        },
                        "Work": {
                            "count": 2,
                            "content": {
                                "Definition, sign convention and difference in mechanical and thermodynamic work": {
                                    "count": 1
                                },
                                "P-V work done in reversible and inrreversible process": {
                                    "count": 1
                                }
                            }
                        },
                        "Internal Energy": {
                            "count": 4,
                            "content": {
                                "Definition and characteristics of Internal Energy": {
                                    "count": 1
                                },
                                "Degree of freedom": {
                                    "count": 1
                                },
                                "Heat capacity of matter": {
                                    "count": 1
                                },
                                "Comparison between Cp and Cv": {
                                    "count": 1
                                }
                            }
                        },
                        "Calorimetry": {
                            "count": 2,
                            "content": {
                                "Bomb calorimeter and Dulong-Petit law": {
                                    "count": 1
                                },
                                "Definition and basic derivation of Enthalpy": {
                                    "count": 1
                                }
                            }
                        },
                        "First law of thermodynamics": {
                            "count": 1,
                            "content": {
                                "Statement and its basic applications": {
                                    "count": 1
                                }
                            }
                        },
                        "Application of First law of thermodynamics": {
                            "count": 8,
                            "content": {
                                "Isothermal Process": {
                                    "count": 1
                                },
                                "Isobaric Process": {
                                    "count": 1
                                },
                                "Cyclic Process": {
                                    "count": 1
                                },
                                "Adiabatic Reversible Process": {
                                    "count": 1
                                },
                                "Adiabatic Irreversible Process": {
                                    "count": 1
                                },
                                "Polytropic Process and its Application": {
                                    "count": 1
                                },
                                "Area under the curve and Graph": {
                                    "count": 1
                                },
                                "Isochoric Procses": {
                                    "count": 1
                                }
                            }
                        },
                        "Basic law of thermochemistry": {
                            "count": 2,
                            "content": {
                                "Exothermic, Endothermic process and Laplace and Lavoisier law": {
                                    "count": 1
                                },
                                "Relationship between Enthalpy and Internal Energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Measurement of Enthalpy change": {
                            "count": 6,
                            "content": {
                                "Enthaply of Chemical Reaction Or Heat of Reaction": {
                                    "count": 1
                                },
                                "Enthalpy of Combustion": {
                                    "count": 1
                                },
                                "Enthalpy of fusion, vapourization and sublimation": {
                                    "count": 1
                                },
                                "Enthalpy of atomisation,Bond Energy and Bond Dissociation Energy": {
                                    "count": 1
                                },
                                "Hess Law and Born Haber Cycle and its application": {
                                    "count": 1
                                },
                                "Enthaply of Formation": {
                                    "count": 1
                                }
                            }
                        },
                        "Enthalpy change involving solutions": {
                            "count": 2,
                            "content": {
                                "Enthalpy of neutralization": {
                                    "count": 1
                                },
                                "Enthalpy of Solvation and Enthalpy of Hydration": {
                                    "count": 1
                                }
                            }
                        },
                        "Second law of Thermodynamics": {
                            "count": 7,
                            "content": {
                                "Limitations of First law of thermodynamics and statement of second law of thermodynamics": {
                                    "count": 1
                                },
                                "Heat Engine and Carnot Cycle": {
                                    "count": 1
                                },
                                "Entropy and basic calculation of entropy": {
                                    "count": 1
                                },
                                "Change in Entropy in physical process": {
                                    "count": 1
                                },
                                "Entropy calculation in different thermodynamic process": {
                                    "count": 1
                                },
                                "Change in Entropy calculation during Mixing of Gases and Phase Change": {
                                    "count": 1
                                },
                                "Gibbs Free Energy and Helmholtz Equation": {
                                    "count": 1
                                }
                            }
                        },
                        "Kirchhoff equation": {
                            "count": 3,
                            "content": {
                                "Temperature dependence of ΔH": {
                                    "count": 1
                                },
                                "Temperature dependence of ΔS": {
                                    "count": 1
                                },
                                "Temperature dependence of ΔG": {
                                    "count": 1
                                }
                            }
                        },
                        "Gibbs Free Energy": {
                            "count": 2,
                            "content": {
                                "Relationship between free energy and equilibrium constant": {
                                    "count": 1
                                },
                                "Temperature Derivative Of Gibbs Free Energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Third Law Of Thermodynamics": {
                            "count": 1,
                            "content": {
                                "Statement and its basic applications": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Chemical equilibrium": {
                    "count": 14,
                    "content": {
                        "Types of chemical reaction": {
                            "count": 1,
                            "content": {
                                "Reversible and Irreversible reaction": {
                                    "count": 1
                                }
                            }
                        },
                        "Kinetics and equilibrium": {
                            "count": 1,
                            "content": {
                                "Rate of chemical reaction, Introduction and condition of equilibrium": {
                                    "count": 1
                                }
                            }
                        },
                        "Equilibrium": {
                            "count": 1,
                            "content": {
                                "Active mass, Law of mass action, Reaction Quotient and Equilibrium constant": {
                                    "count": 1
                                }
                            }
                        },
                        "Types of equilibrium": {
                            "count": 3,
                            "content": {
                                "Kp, Kc, Kx and its unit": {
                                    "count": 1
                                },
                                "Homogenous and Heterogeneous equilibrium": {
                                    "count": 1
                                },
                                "Simultaneous equilibrium": {
                                    "count": 1
                                }
                            }
                        },
                        "Relation between degree of dissociation and vapour density": {
                            "count": 1,
                            "content": {
                                "Calculation of degree of dissociation from density measurement": {
                                    "count": 1
                                }
                            }
                        },
                        "Le Chatelier's principle": {
                            "count": 5,
                            "content": {
                                "Le chatelier's principle and Effect of addition of reactant or product at equilibrium state": {
                                    "count": 1
                                },
                                "Effect of volume and pressure and Effect of addition of inert gas": {
                                    "count": 1
                                },
                                "Van't Hoff Equation": {
                                    "count": 1
                                },
                                "Effect of change in temperature and Effect of Catalyst": {
                                    "count": 1
                                },
                                "Application in heterogeneous equilibrium": {
                                    "count": 1
                                }
                            }
                        },
                        "Application of equilibrium constant": {
                            "count": 1,
                            "content": {
                                "Relation between equilibrium constant and Gibbs free energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Calculation of equilibrium constant": {
                            "count": 1,
                            "content": {
                                "Mathematical approach for equilibrium constant": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Ionic equilibrium": {
                    "count": 24,
                    "content": {
                        "Introduction of Electrolytes": {
                            "count": 1,
                            "content": {
                                "Definition and Classification of electrolytes": {
                                    "count": 1
                                }
                            }
                        },
                        "Classification of acids and bases": {
                            "count": 1,
                            "content": {
                                "All theories of acids and bases": {
                                    "count": 1
                                }
                            }
                        },
                        "Ionisation of water": {
                            "count": 1,
                            "content": {
                                "Ionic product of water": {
                                    "count": 1
                                }
                            }
                        },
                        "pH scale": {
                            "count": 1,
                            "content": {
                                "Introduction of pH and pOH in aqueous solution": {
                                    "count": 1
                                }
                            }
                        },
                        "Calculation of pH": {
                            "count": 5,
                            "content": {
                                "Aqueous solution of strong acid or strong base and its combination": {
                                    "count": 1
                                },
                                "pH calculation in case of dilution and mixing of aqueous solution": {
                                    "count": 1
                                },
                                "Ostwald dilution law for weak acid and weak base": {
                                    "count": 1
                                },
                                "Common ion effect": {
                                    "count": 1
                                },
                                "Polyprotic acid and base": {
                                    "count": 1
                                }
                            }
                        },
                        "Salt hydrolysis": {
                            "count": 4,
                            "content": {
                                "Introduction and salt of weak acid and strong base": {
                                    "count": 1
                                },
                                "Salt of strong acid and weak base": {
                                    "count": 1
                                },
                                "Salt of weak acid and weak base": {
                                    "count": 1
                                },
                                "Amphiprotic salt": {
                                    "count": 1
                                }
                            }
                        },
                        "Buffer solution": {
                            "count": 3,
                            "content": {
                                "Introduction of buffer solution and acidic buffer": {
                                    "count": 1
                                },
                                "Basic buffer": {
                                    "count": 1
                                },
                                "Buffer action and buffer capacity": {
                                    "count": 1
                                }
                            }
                        },
                        "Indicators": {
                            "count": 1,
                            "content": {
                                "Acidic and basic indicators and its function": {
                                    "count": 1
                                }
                            }
                        },
                        "Acid-base titrations": {
                            "count": 1,
                            "content": {
                                "Titration curves": {
                                    "count": 1
                                }
                            }
                        },
                        "Solubility product": {
                            "count": 2,
                            "content": {
                                "Introduction and Calculation of Ksp": {
                                    "count": 1
                                },
                                "Effect of adding common ion": {
                                    "count": 1
                                }
                            }
                        },
                        "Precipitation": {
                            "count": 2,
                            "content": {
                                "Simple precipitation and condition of precipitation": {
                                    "count": 1
                                },
                                "Application in qualitative analysis": {
                                    "count": 1
                                }
                            }
                        },
                        "Complex Equilibrium": {
                            "count": 1,
                            "content": {
                                "Formation of complex ion and formation constant": {
                                    "count": 1
                                }
                            }
                        },
                        "Miscellaneous Problems": {
                            "count": 1,
                            "content": {
                                "Solubility Vs Buffer and Solubility Vs Hydrolysis": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Solid state": {
                    "count": 0,
                    "content": {
                        "Solid state": {
                            "count": 0,
                            "content": {
                                "Definition and Properties of solids": {
                                    "count": 0
                                },
                                "Cohesion and cohesive energy": {
                                    "count": 0
                                },
                                "Anisotropy and Types of solids": {
                                    "count": 0
                                }
                            }
                        },
                        "Crystals and crystallography": {
                            "count": 0,
                            "content": {
                                "Geometric crystallography": {
                                    "count": 0
                                },
                                "Bravais lattice": {
                                    "count": 0
                                },
                                "Coordination number": {
                                    "count": 0
                                },
                                "Types of unit cell": {
                                    "count": 0
                                },
                                "Interfacial angles": {
                                    "count": 0
                                }
                            }
                        },
                        "Symmetry": {
                            "count": 0,
                            "content": {
                                "Symmetry operations and Elements of symmetry": {
                                    "count": 0
                                }
                            }
                        },
                        "Crystal systems": {
                            "count": 0,
                            "content": {
                                "2D Lattice and 3D Cubic systems": {
                                    "count": 0
                                },
                                "Simple cubic unit cell": {
                                    "count": 0
                                },
                                "Body-centred unit cell": {
                                    "count": 0
                                },
                                "Face-centred unit cell": {
                                    "count": 0
                                },
                                "Packing efficiency and Ideal density of crystal": {
                                    "count": 0
                                },
                                "Polymorphism": {
                                    "count": 0
                                },
                                "Tetrahedral and Octahedral voids": {
                                    "count": 0
                                }
                            }
                        },
                        "Structure of ionic solids": {
                            "count": 0,
                            "content": {
                                "Rock salt structure": {
                                    "count": 0
                                },
                                "CsCl structure": {
                                    "count": 0
                                },
                                "ZnS structure": {
                                    "count": 0
                                },
                                "Antifluorite structure": {
                                    "count": 0
                                },
                                "Rutile structures and Spinels": {
                                    "count": 0
                                }
                            }
                        },
                        "Radius ratio rule": {
                            "count": 0,
                            "content": {
                                "Calculations of radius ratio": {
                                    "count": 0
                                },
                                "Relation of radius ratio with coordination number": {
                                    "count": 0
                                },
                                "Significance of coordination number": {
                                    "count": 0
                                }
                            }
                        },
                        "Miller indices": {
                            "count": 0,
                            "content": {
                                "Determination of Miller indices": {
                                    "count": 0
                                },
                                "Lattice planes in a cubic system and Interplanar distances": {
                                    "count": 0
                                }
                            }
                        },
                        "Crystal defects": {
                            "count": 0,
                            "content": {
                                "Point defects": {
                                    "count": 0
                                },
                                "Schottky defect and Extrinsic Schottky defect": {
                                    "count": 0
                                },
                                "Frenkel defect and Atom exchange defect": {
                                    "count": 0
                                },
                                "Interstitial defect and F-centres": {
                                    "count": 0
                                },
                                "Defects and non-stoichiometry": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of crystals": {
                            "count": 0,
                            "content": {
                                "Electrical, magnetic and dielectric properties": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Chemical kinetics": {
                    "count": 0,
                    "content": {
                        "Rate of reaction": {
                            "count": 0,
                            "content": {
                                "Differential and instantaneous rate": {
                                    "count": 0
                                },
                                "Rate law and order of chemical reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Integrated rate law": {
                            "count": 0,
                            "content": {
                                "Zero order reaction": {
                                    "count": 0
                                },
                                "First order reaction": {
                                    "count": 0
                                },
                                "Molecularity and Pseudo first-order reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Analysis of some important first order reaction": {
                            "count": 0,
                            "content": {
                                "First order kinetics in terms of pressure and volume": {
                                    "count": 0
                                },
                                "First order kinetics in terms of titration and optical rotation": {
                                    "count": 0
                                }
                            }
                        },
                        "Integrated rate law of higher order": {
                            "count": 0,
                            "content": {
                                "Second order and Third order reactions": {
                                    "count": 0
                                },
                                "nth order reaction and ostwald's equation": {
                                    "count": 0
                                },
                                "Graphs for different order chemical reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Complex kinetics (First order reaction)": {
                            "count": 0,
                            "content": {
                                "Parallel kinetics": {
                                    "count": 0
                                },
                                "Sequential kinetics": {
                                    "count": 0
                                },
                                "Reversible kinetics": {
                                    "count": 0
                                }
                            }
                        },
                        "Effect of temperature on rate of reaction": {
                            "count": 0,
                            "content": {
                                "Arrhenius concept of chemical reactions": {
                                    "count": 0
                                },
                                "Arrhenius equation and temperature cofficient": {
                                    "count": 0
                                },
                                "Effect of Catalyst": {
                                    "count": 0
                                }
                            }
                        },
                        "Mechanism of chemical reaction": {
                            "count": 0,
                            "content": {
                                "Elementary and Non-elementary reaction and its order and molecularity": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Radioactivity": {
                    "count": 0,
                    "content": {
                        "Radioactivity": {
                            "count": 0,
                            "content": {
                                "Alpha(α), Beta(β) and Gamma(𝛾) radiations": {
                                    "count": 0
                                },
                                "Properties of radiations": {
                                    "count": 0
                                }
                            }
                        },
                        "Radioactive decay": {
                            "count": 0,
                            "content": {
                                "Radioactive decay as a first-order process": {
                                    "count": 0
                                },
                                "Decay equation": {
                                    "count": 0
                                },
                                "Half-life period and Mean life": {
                                    "count": 0
                                },
                                "Activity and Relative activity": {
                                    "count": 0
                                },
                                "Application of radioactivity": {
                                    "count": 0
                                }
                            }
                        },
                        "More than one radioactive decay": {
                            "count": 0,
                            "content": {
                                "Mixture of unrelated radionuclides": {
                                    "count": 0
                                },
                                "Parallel decay and Parent-daughter decay growth relationship": {
                                    "count": 0
                                },
                                "Chains of disintegration and Nuclear isomerism": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Nuclear Chemistry": {
                    "count": 0,
                    "content": {
                        "Nucleus": {
                            "count": 0,
                            "content": {
                                "Discovery of nucleus": {
                                    "count": 0
                                },
                                "Nucleons, isotopes, isobars and isotones": {
                                    "count": 0
                                },
                                "Basis of nuclear stability, Magic numbers and Binding energy": {
                                    "count": 0
                                },
                                "Number of stable isotopes and isotones": {
                                    "count": 0
                                },
                                "Alpha(α) decay and Beta(β) decay": {
                                    "count": 0
                                },
                                "Nuclear fission & Fusion": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Electrochemistry": {
                    "count": 0,
                    "content": {
                        "Electrochemical cells": {
                            "count": 0,
                            "content": {
                                "Introduction and difference from electrolytic cells": {
                                    "count": 0
                                },
                                "Daniel cell or Galvanic cells": {
                                    "count": 0
                                },
                                "Working of Daniel cell and Salt bridge": {
                                    "count": 0
                                },
                                "Active and Inactive electrodes": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrodes and electrode potentials": {
                            "count": 0,
                            "content": {
                                "Helmholtz's Double layer": {
                                    "count": 0
                                },
                                "Standard hydrogen electrode": {
                                    "count": 0
                                },
                                "Standard electrode potential": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of electromodes": {
                            "count": 0,
                            "content": {
                                "Metal-metal ion half-cells": {
                                    "count": 0
                                },
                                "Amalgam half-cells": {
                                    "count": 0
                                },
                                "Gas ion half-cells": {
                                    "count": 0
                                },
                                "Metal- insoluble salt- anion half-cells": {
                                    "count": 0
                                },
                                "Calomel electrode": {
                                    "count": 0
                                },
                                "Silver-silver chloride electrode": {
                                    "count": 0
                                }
                            }
                        },
                        "Electromotive force (EMF)": {
                            "count": 0,
                            "content": {
                                "Cell notations, Half-cell potential and EMF of a cell": {
                                    "count": 0
                                },
                                "Thermodynamics of electrochemical cells": {
                                    "count": 0
                                },
                                "Combining half cell reaction to get another half cell reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrochemical series": {
                            "count": 0,
                            "content": {
                                "Electrochemical series": {
                                    "count": 0
                                },
                                "Applications of Electrochemical series": {
                                    "count": 0
                                }
                            }
                        },
                        "Corrosion": {
                            "count": 0,
                            "content": {
                                "Definition, mechanism and protection": {
                                    "count": 0
                                }
                            }
                        },
                        "Cells and batteries": {
                            "count": 0,
                            "content": {
                                "Zinc-carbon battery ( dry cell)": {
                                    "count": 0
                                },
                                "Alkaline battery": {
                                    "count": 0
                                },
                                "Mercury and silver button cells": {
                                    "count": 0
                                },
                                "Lead-acid (rechargeable) battery and its disadvantages": {
                                    "count": 0
                                },
                                "Lithium solid-state battery and Fuel cells": {
                                    "count": 0
                                }
                            }
                        },
                        "Equilibrium constant and cell potential": {
                            "count": 0,
                            "content": {
                                "Mathematical relationship": {
                                    "count": 0
                                },
                                "Temperature coefficient of EMF": {
                                    "count": 0
                                }
                            }
                        },
                        "Nernst equation": {
                            "count": 0,
                            "content": {
                                "Introduction": {
                                    "count": 0
                                },
                                "Expression of Nernst equation": {
                                    "count": 0
                                }
                            }
                        },
                        "Concentration cells": {
                            "count": 0,
                            "content": {
                                "Principle of concentration cells": {
                                    "count": 0
                                },
                                "Electrical measurement of pH": {
                                    "count": 0
                                },
                                "Solubility product determination by Nernst equation": {
                                    "count": 0
                                },
                                "Effect of complex formation on the reduction potential": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrolysis": {
                            "count": 0,
                            "content": {
                                "Introduction": {
                                    "count": 0
                                },
                                "Electrolyte solutions and Electrolytic dissociation": {
                                    "count": 0
                                },
                                "Strong and weak electrolytes": {
                                    "count": 0
                                },
                                "Molten and Solid electrolytes": {
                                    "count": 0
                                },
                                "Products of electrolysis": {
                                    "count": 0
                                },
                                "Different types of electrolysis and application of electrolysis": {
                                    "count": 0
                                }
                            }
                        },
                        "Faraday's law of electrolysis": {
                            "count": 0,
                            "content": {
                                "Faraday's first and second law": {
                                    "count": 0
                                },
                                "Electrode current efficiency": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrolytic conductance": {
                            "count": 0,
                            "content": {
                                "Introduction and definition": {
                                    "count": 0
                                }
                            }
                        },
                        "Flow of electric current through electrolyte solution": {
                            "count": 0,
                            "content": {
                                "Specific resistance and Specific conductance": {
                                    "count": 0
                                },
                                "Conductivity and Measurement of conductivity": {
                                    "count": 0
                                },
                                "Molar conductivities and degree of dissociation": {
                                    "count": 0
                                },
                                "Colligative property and electrolytic dissociation": {
                                    "count": 0
                                }
                            }
                        },
                        "Equivalent conductance at infinite dilution": {
                            "count": 0,
                            "content": {
                                "Kohlrausch's law": {
                                    "count": 0
                                }
                            }
                        },
                        "Applications of conductivity measurements": {
                            "count": 0,
                            "content": {
                                "Conductometric titrations": {
                                    "count": 0
                                },
                                "Solubility product estimation": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Liquid solutions": {
                    "count": 0,
                    "content": {
                        "Colligative property": {
                            "count": 0,
                            "content": {
                                "Introduction": {
                                    "count": 0
                                },
                                "Van't Hoff factor": {
                                    "count": 0
                                },
                                "Degree of ionization for strong and weak electrolytes": {
                                    "count": 0
                                },
                                "Non-electrolytes": {
                                    "count": 0
                                }
                            }
                        },
                        "Lowering of vapour pressure": {
                            "count": 0,
                            "content": {
                                "Vapour pressure": {
                                    "count": 0
                                },
                                "Raoult's law for non-volatile solute": {
                                    "count": 0
                                },
                                "Measurement of relative pressure lowering": {
                                    "count": 0
                                }
                            }
                        },
                        "Boiling and freezing points": {
                            "count": 0,
                            "content": {
                                "Elevation of boiling point": {
                                    "count": 0
                                },
                                "Depression of freezing point (Cryoscopy)": {
                                    "count": 0
                                }
                            }
                        },
                        "Osmotic pressure": {
                            "count": 0,
                            "content": {
                                "Osmosis and Osmotic pressure": {
                                    "count": 0
                                },
                                "Methods of measuring osmotic pressure": {
                                    "count": 0
                                },
                                "Reverse osmosis": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Surface chemistry": {
                    "count": 0,
                    "content": {
                        "Adsorption": {
                            "count": 0,
                            "content": {
                                "Introduction, definition and difference from absorption": {
                                    "count": 0
                                },
                                "Adsorbate and Adsorbent": {
                                    "count": 0
                                },
                                "Desorption and Sorption": {
                                    "count": 0
                                }
                            }
                        },
                        "Driving forces for adsorption": {
                            "count": 0,
                            "content": {
                                "Surface forces and Adsorption of gases on solids": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of adsorption": {
                            "count": 0,
                            "content": {
                                "Physical and Chemical adsorption": {
                                    "count": 0
                                },
                                "Sorption": {
                                    "count": 0
                                },
                                "Factors affecting adsorption of gases on solids": {
                                    "count": 0
                                }
                            }
                        },
                        "Adsorption isotherms": {
                            "count": 0,
                            "content": {
                                "Freundlich and Langmuir isotherm": {
                                    "count": 0
                                },
                                "Adsorption from solutions": {
                                    "count": 0
                                }
                            }
                        },
                        "Applications of adsorption": {
                            "count": 0,
                            "content": {
                                "Adsorption from gases and from solutions": {
                                    "count": 0
                                }
                            }
                        },
                        "Colloidal state": {
                            "count": 0,
                            "content": {
                                "Introduction, crystalloids and colloids": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of colloidal systems": {
                            "count": 0,
                            "content": {
                                "Dispersed phase and Dispersion medium": {
                                    "count": 0
                                },
                                "Multimolecular and Macromolecular colloids": {
                                    "count": 0
                                },
                                "Association colloids or micelles": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification of colloidal systems": {
                            "count": 0,
                            "content": {
                                "Lyophilic and Lyophobic sols": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of sols": {
                            "count": 0,
                            "content": {
                                "Preparation of lyophilic sols": {
                                    "count": 0
                                },
                                "Condensation methods and Dispersion methods": {
                                    "count": 0
                                }
                            }
                        },
                        "Purification of sols": {
                            "count": 0,
                            "content": {
                                "Ultrafiltration, Dialysis and Electrodialysis": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of sols": {
                            "count": 0,
                            "content": {
                                "Heterogeneity, Filtration and Sedimentation": {
                                    "count": 0
                                },
                                "Brownian motion and Tyndall effect": {
                                    "count": 0
                                },
                                "Colour and Colligative properties of sols": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrical properties of sols": {
                            "count": 0,
                            "content": {
                                "Electrophoresis and Electro-osmosis": {
                                    "count": 0
                                }
                            }
                        },
                        "Stability of sols": {
                            "count": 0,
                            "content": {
                                "Repulsive forces between similarly charged particles": {
                                    "count": 0
                                },
                                "Particle solvent interaction": {
                                    "count": 0
                                },
                                "Coagulation or flocculation of sols": {
                                    "count": 0
                                },
                                "Protective colloids and Mechanism of sol protection": {
                                    "count": 0
                                },
                                "Gold number": {
                                    "count": 0
                                }
                            }
                        },
                        "Emulsions": {
                            "count": 0,
                            "content": {
                                "Oil in water and water in oil emulsions": {
                                    "count": 0
                                },
                                "Preparation of emulsions": {
                                    "count": 0
                                },
                                "Structure and Nature of emulsifiers": {
                                    "count": 0
                                },
                                "Identification of emulsion types and Properties of emulsions": {
                                    "count": 0
                                },
                                "Demulsification": {
                                    "count": 0
                                },
                                "Application of emulsions and colloids": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Classification of elements and periodicity in properties": {
                    "count": 40,
                    "content": {
                        "Classification of elements": {
                            "count": 1,
                            "content": {
                                "Older models of periodic table": {
                                    "count": 1
                                }
                            }
                        },
                        "Mendeleev's classification": {
                            "count": 1,
                            "content": {
                                "Salient features, merits and demerits": {
                                    "count": 1
                                }
                            }
                        },
                        "Long form/modern periodic table": {
                            "count": 3,
                            "content": {
                                "Moseley's periodic law and Modern periodic table": {
                                    "count": 1
                                },
                                "Classification of periods and groups": {
                                    "count": 1
                                },
                                "Nomenclature of recently discovered elements": {
                                    "count": 1
                                }
                            }
                        },
                        "Classification in blocks and properties": {
                            "count": 3,
                            "content": {
                                "S block elements and properties": {
                                    "count": 1
                                },
                                "P block elements and properties": {
                                    "count": 1
                                },
                                "D and F block elements and properties": {
                                    "count": 1
                                }
                            }
                        },
                        "Diagonal relationship": {
                            "count": 2,
                            "content": {
                                "Definition, reason for diagonal relationship": {
                                    "count": 1
                                },
                                "Different pairs exhibiting diagonal relationship": {
                                    "count": 1
                                }
                            }
                        },
                        "Screening or shielding effect": {
                            "count": 2,
                            "content": {
                                "Definition, Effective Atomic number (Zeff.) and its variation": {
                                    "count": 1
                                },
                                "Screening ability of electrons in different subshells and application of screening effect": {
                                    "count": 1
                                }
                            }
                        },
                        "Inert pair effect": {
                            "count": 3,
                            "content": {
                                "Definition, explanation of inert pair effect": {
                                    "count": 1
                                },
                                "Application of inert pair effect in group 13,14 and 15": {
                                    "count": 1
                                },
                                "Application of inert pair effect in other groups": {
                                    "count": 1
                                }
                            }
                        },
                        "Atomic radii": {
                            "count": 2,
                            "content": {
                                "Definition, types of atomic radius": {
                                    "count": 1
                                },
                                "Factors affecting atomic radius": {
                                    "count": 1
                                }
                            }
                        },
                        "Variation of atomic radius": {
                            "count": 2,
                            "content": {
                                "In groups and periods": {
                                    "count": 1
                                },
                                "In D and F block elements": {
                                    "count": 1
                                }
                            }
                        },
                        "Ionic radii": {
                            "count": 1,
                            "content": {
                                "Cationic radii, Anionic radii and factors affecting ionic radii": {
                                    "count": 1
                                }
                            }
                        },
                        "Trends of ionic radii": {
                            "count": 1,
                            "content": {
                                "Among isoelectronic species and in periods and groups": {
                                    "count": 1
                                }
                            }
                        },
                        "Ianthaniod contraction": {
                            "count": 1,
                            "content": {
                                "Definitions and applications": {
                                    "count": 1
                                }
                            }
                        },
                        "Ionization energy": {
                            "count": 3,
                            "content": {
                                "Definition and successive ionization energies": {
                                    "count": 1
                                },
                                "Factors affecting ionization energy": {
                                    "count": 1
                                },
                                "Variation of ionization energy in periodic table": {
                                    "count": 1
                                }
                            }
                        },
                        "Applications of ionization energy": {
                            "count": 2,
                            "content": {
                                "Electropositive character and reducing ability": {
                                    "count": 1
                                },
                                "Ionic character of compounds": {
                                    "count": 1
                                }
                            }
                        },
                        "Electron gain enthalpy/electron affinity (EA)": {
                            "count": 4,
                            "content": {
                                "Definition and successive electron gain enthalpies": {
                                    "count": 1
                                },
                                "Factors affecting electron affinity": {
                                    "count": 1
                                },
                                "Variation of electron affinity in groups and periods": {
                                    "count": 1
                                },
                                "Application of electron affinity": {
                                    "count": 1
                                }
                            }
                        },
                        "Electronegativity": {
                            "count": 2,
                            "content": {
                                "Definition and scales of electronegativity": {
                                    "count": 1
                                },
                                "Factors affecting electronegativity": {
                                    "count": 1
                                }
                            }
                        },
                        "Applications of electronegativity": {
                            "count": 2,
                            "content": {
                                "Polarity and strength of bonds": {
                                    "count": 1
                                },
                                "Acidic and basic character": {
                                    "count": 1
                                }
                            }
                        },
                        "Hydration energy": {
                            "count": 2,
                            "content": {
                                "Definition and Variation": {
                                    "count": 1
                                },
                                "Applications of hydration energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Lattice energy": {
                            "count": 2,
                            "content": {
                                "Definition, Variation of lattice energy": {
                                    "count": 1
                                },
                                "Application of Lattice energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Oxidation Number": {
                            "count": 1,
                            "content": {
                                "Oxidation number of S-Block, P-Block, D-Block, & F-Block Elements": {
                                    "count": 1
                                }
                            }
                        }
                    }
                }
            }
        },
        "Organic Chemistry": {
            "count": 85,
            "content": {
                "Classification and nomenclature of organic compounds": {
                    "count": 13,
                    "content": {
                        "Classification and degree of unsaturaion": {
                            "count": 4,
                            "content": {
                                "Classification based on structure": {
                                    "count": 1
                                },
                                "Classification based on functional groups": {
                                    "count": 1
                                },
                                "Classification based on saturation and unsaturation in a carbon chain": {
                                    "count": 1
                                },
                                "Degree of unsaturation calculation": {
                                    "count": 1
                                }
                            }
                        },
                        "Terminology of general IUPAC name": {
                            "count": 2,
                            "content": {
                                "Introduction of secondary and primary prefix": {
                                    "count": 1
                                },
                                "Introduction of the word root and suffix": {
                                    "count": 1
                                }
                            }
                        },
                        "Rules for IUPAC nomenclature": {
                            "count": 2,
                            "content": {
                                "Selection of principal chain": {
                                    "count": 1
                                },
                                "Numbering of the selected parent chain": {
                                    "count": 1
                                }
                            }
                        },
                        "IUPAC nomenclature of important compounds": {
                            "count": 5,
                            "content": {
                                "Nomenclature of Hydrocarbons": {
                                    "count": 1
                                },
                                "Nomenclature of Oxygen and nitrogen derivatives": {
                                    "count": 1
                                },
                                "Nomenclature of Halogen derivatives": {
                                    "count": 1
                                },
                                "Nomenclature of Poly functional and aromatic compounds": {
                                    "count": 1
                                },
                                "Nomenclature of Bicyclo compounds": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "General Organic Chemistry": {
                    "count": 28,
                    "content": {
                        "Bond Cleavage": {
                            "count": 2,
                            "content": {
                                "Homolytic and Heterolytic Cleavage": {
                                    "count": 1
                                },
                                "All Reaction Intermediates": {
                                    "count": 1
                                }
                            }
                        },
                        "Inductive effect": {
                            "count": 3,
                            "content": {
                                "Definition, Types of I effect": {
                                    "count": 1
                                },
                                "Effect on acidic and basic strength": {
                                    "count": 1
                                },
                                "Stability of all reaction intermediates": {
                                    "count": 1
                                }
                            }
                        },
                        "Resonance": {
                            "count": 6,
                            "content": {
                                "Definition, Conditions for resonance": {
                                    "count": 1
                                },
                                "Rules for writing resonating structures and equivalent resonance": {
                                    "count": 1
                                },
                                "Types of conjugations": {
                                    "count": 1
                                },
                                "Mesomeric effect": {
                                    "count": 1
                                },
                                "Steric Inhibition of Resonance [SIR effect]": {
                                    "count": 1
                                },
                                "Stability comparison of resonating structures": {
                                    "count": 1
                                }
                            }
                        },
                        "Heat of hydrogenation and combustion": {
                            "count": 1,
                            "content": {
                                "Definition and Variation and Application": {
                                    "count": 1
                                }
                            }
                        },
                        "Aromaticity": {
                            "count": 4,
                            "content": {
                                "Aromatic, Anti-aromatic, Non-aromatic compounds and Huckel's rule": {
                                    "count": 1
                                },
                                "Quasi-aromatic compounds and Annulenes": {
                                    "count": 2
                                },
                                "Applications of aromaticity": {
                                    "count": 1
                                }
                            }
                        },
                        "Resonance energy": {
                            "count": 1,
                            "content": {
                                "Definition and comparison of resonance energy": {
                                    "count": 1
                                }
                            }
                        },
                        "Hyperconjugation effect [+H effect]": {
                            "count": 2,
                            "content": {
                                "Definition and Conditions for hyperconjugation": {
                                    "count": 1
                                },
                                "Application of hyperconjugation": {
                                    "count": 1
                                }
                            }
                        },
                        "Reverse hyperconjugation[-H effect]": {
                            "count": 1,
                            "content": {
                                "Definition and Conditions for -H effect and Application": {
                                    "count": 1
                                }
                            }
                        },
                        "Electromeric effect": {
                            "count": 1,
                            "content": {
                                "Definition and applications": {
                                    "count": 1
                                }
                            }
                        },
                        "Acid-base theory": {
                            "count": 2,
                            "content": {
                                "General comparison of acidic and basic strength": {
                                    "count": 1
                                },
                                "Tests based on acidic strength": {
                                    "count": 1
                                }
                            }
                        },
                        "Acidic strength comparisons": {
                            "count": 2,
                            "content": {
                                "In organic acids": {
                                    "count": 1
                                },
                                "In inorganic acids": {
                                    "count": 1
                                }
                            }
                        },
                        "Basic strength comparisons": {
                            "count": 3,
                            "content": {
                                "Aliphatic amines in gaseous and aqueous medium": {
                                    "count": 1
                                },
                                "Aromatic amines": {
                                    "count": 1
                                },
                                "Ortho effect": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Isomerism of organic compounds": {
                    "count": 30,
                    "content": {
                        "Structural Isomerism": {
                            "count": 4,
                            "content": {
                                "Definition and types of isomerism, Discussion of chain and position isomerism": {
                                    "count": 1
                                },
                                "Functional, ring chain isomerism and metamerism": {
                                    "count": 1
                                },
                                "Introduction, Mechanism and condition of tautomerism": {
                                    "count": 1
                                },
                                "% enol content and types of tautomers": {
                                    "count": 1
                                }
                            }
                        },
                        "2-D projections of molecules": {
                            "count": 1,
                            "content": {
                                "Sawhorse and newman projection and their interconversions": {
                                    "count": 1
                                }
                            }
                        },
                        "Conformational analysis of open chain compounds": {
                            "count": 2,
                            "content": {
                                "Conformational analysis of alkanes(Ethane, Propane, Butane)": {
                                    "count": 1
                                },
                                "Gauche effect": {
                                    "count": 1
                                }
                            }
                        },
                        "Geometrical isomerism": {
                            "count": 5,
                            "content": {
                                "Introduction, condition and structural units of G.I": {
                                    "count": 1
                                },
                                "cis-trans and syn-anti classifications": {
                                    "count": 1
                                },
                                "E-Z classification and CIP sequence": {
                                    "count": 1
                                },
                                "Calculation of total number of GI in organic compounds": {
                                    "count": 1
                                },
                                "Comparison of properties of geometrical isomers": {
                                    "count": 1
                                }
                            }
                        },
                        "Concept of strain": {
                            "count": 1,
                            "content": {
                                "Definition, strain energy and types of strain": {
                                    "count": 1
                                }
                            }
                        },
                        "Concept of symmetry": {
                            "count": 2,
                            "content": {
                                "Introduction of symmetry and types of symmetry(AOS and COS)": {
                                    "count": 1
                                },
                                "Plane of symmetry and Alternative axis of symmetry": {
                                    "count": 1
                                }
                            }
                        },
                        "Fischer projections": {
                            "count": 1,
                            "content": {
                                "Fischer projections and Interconversion from other projections": {
                                    "count": 1
                                }
                            }
                        },
                        "Optical isomerism": {
                            "count": 5,
                            "content": {
                                "R and S classification": {
                                    "count": 1
                                },
                                "Optically active and inactive compounds and specific rotation": {
                                    "count": 1
                                },
                                "Enantiomers and Diastereomers": {
                                    "count": 1
                                },
                                "Meso compounds": {
                                    "count": 1
                                },
                                "Optical activity in compounds without a chiral center": {
                                    "count": 1
                                }
                            }
                        },
                        "Conformational analysis of closed chain compounds": {
                            "count": 2,
                            "content": {
                                "Conformational analysis of cyclobutane and cyclohexane": {
                                    "count": 1
                                },
                                "Substituted cyclohexane's and their stability": {
                                    "count": 1
                                }
                            }
                        },
                        "Classification of optical isomers": {
                            "count": 1,
                            "content": {
                                "Erythro and threo classification and D, L classifications": {
                                    "count": 1
                                }
                            }
                        },
                        "Racemic mixture": {
                            "count": 1,
                            "content": {
                                "Racemization, racemic mixture and their properties": {
                                    "count": 1
                                }
                            }
                        },
                        "Concept of chirality": {
                            "count": 1,
                            "content": {
                                "Introduction and chiriality of molecules and chiriality of centre": {
                                    "count": 1
                                }
                            }
                        },
                        "Calculation of number of optical isomers": {
                            "count": 2,
                            "content": {
                                "In symmetrical and unsymmetrical molecules": {
                                    "count": 1
                                },
                                "Total number of stereoisomers": {
                                    "count": 1
                                }
                            }
                        },
                        "Amine inversion": {
                            "count": 1,
                            "content": {
                                "Amine inversion and applications": {
                                    "count": 1
                                }
                            }
                        },
                        "Resolution": {
                            "count": 1,
                            "content": {
                                "Definition and resolution schemes in stereoisomers": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Reaction Mechanism": {
                    "count": 14,
                    "content": {
                        "Kinetics of organic reaction": {
                            "count": 0,
                            "content": {
                                "Order, molecularity, energy barrier of chemical reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Thermodynamics of organic reactions": {
                            "count": 0,
                            "content": {
                                "Free energy diagram, thermodynamically and kinetically controlled products": {
                                    "count": 0
                                }
                            }
                        },
                        "Organic Reagents": {
                            "count": 1,
                            "content": {
                                "Nucleophiles and Electrophlies": {
                                    "count": 1
                                }
                            }
                        },
                        "Leaving Groups in organic reactions": {
                            "count": 1,
                            "content": {
                                "Introduction and strength of leaving groups": {
                                    "count": 1
                                }
                            }
                        },
                        "Rearrangement": {
                            "count": 3,
                            "content": {
                                "Rearrangement reactions": {
                                    "count": 0
                                },
                                "Structure and stability order of carbocation and carbanion": {
                                    "count": 0
                                },
                                "Ring expansion and ring contraction in carbocation": {
                                    "count": 0
                                },
                                "Nitrene(Preparation, structure, types and reactions)": {
                                    "count": 1
                                },
                                "Carbenes(Preparation, structure, types and reactions)": {
                                    "count": 1
                                },
                                "Structure and preparation of benzyne and reaction of benzyne": {
                                    "count": 1
                                }
                            }
                        },
                        "Substitution Reaction": {
                            "count": 5,
                            "content": {
                                "Nucleophilic Substitution reaction 1 (SN1)": {
                                    "count": 1
                                },
                                "Nucleophilic Substitution reaction 2 (SN2)": {
                                    "count": 1
                                },
                                "Intramolecular Substitution reaction SNi": {
                                    "count": 1
                                },
                                "Stereochemistry of SN1 and SN2": {
                                    "count": 1
                                },
                                "Comparion between SN1 and SN2": {
                                    "count": 1
                                }
                            }
                        },
                        "Elimination Reaction": {
                            "count": 2,
                            "content": {
                                "Elimination 1 (E1) and Saytzeff's Rule": {
                                    "count": 0
                                },
                                "Elimination 2 (E2) and Hoffman's Elimination": {
                                    "count": 0
                                },
                                "E1CB Reaction and other types of elimination reaction": {
                                    "count": 1
                                },
                                "Stereochemistry of E1 and E2": {
                                    "count": 0
                                },
                                "Comparion between E1 and E2": {
                                    "count": 1
                                }
                            }
                        },
                        "Comparison between Substitution and Elimination": {
                            "count": 1,
                            "content": {
                                "Elimination vs Substitution": {
                                    "count": 1
                                }
                            }
                        },
                        "Addition Reaction": {
                            "count": 1,
                            "content": {
                                "Addition of acid in unsaturated hydrocarbons and Markovnikov rule": {
                                    "count": 0
                                },
                                "Anti Markovnikov rule": {
                                    "count": 0
                                },
                                "Hydroboration, Oxymercuration Demercuration and Hydration of alkenes and alkynes": {
                                    "count": 1
                                }
                            }
                        }
                    }
                },
                "Alkanes, Alkenes and Alkynes (Hydrocarbons)": {
                    "count": 0,
                    "content": {
                        "Introduction: Alkanes, Alkynes and Alkenes": {
                            "count": 0,
                            "content": {
                                "Structure and bonding": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparations-1": {
                            "count": 0,
                            "content": {
                                "From alkenes and alkynes": {
                                    "count": 0
                                },
                                "Preparation from halides": {
                                    "count": 0
                                },
                                "Preparation from oxygen derivatives": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical properties of Alkane": {
                            "count": 0,
                            "content": {
                                "General physical properties, octane and cetane numbers": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of Alkane": {
                            "count": 0,
                            "content": {
                                "Combustion, Cracking, Nitration, Sulphonation reaction": {
                                    "count": 0
                                },
                                "Halogenation, Aromatisation and Isomerisation of alkanes": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of Alkenes": {
                            "count": 0,
                            "content": {
                                "From alkenes and alkynes": {
                                    "count": 0
                                },
                                "Preparation from halogen derivatives": {
                                    "count": 0
                                },
                                "Preparation from oxygen derivatives": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical properties of alkenes": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of alkenes": {
                            "count": 0,
                            "content": {
                                "General electrophilic addition": {
                                    "count": 0
                                },
                                "Stereochemistry of electrophilic addition": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrophilic additions- 1": {
                            "count": 0,
                            "content": {
                                "Addition of halo acids": {
                                    "count": 0
                                },
                                "Addition of halogens": {
                                    "count": 0
                                },
                                "Hydration by mineral acids": {
                                    "count": 0
                                },
                                "Oxymercuration - demercuration": {
                                    "count": 0
                                },
                                "Hydroboration-oxidation reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrophilic additions- 2": {
                            "count": 0,
                            "content": {
                                "Syn Hydroxylations": {
                                    "count": 0
                                },
                                "Epoxidation": {
                                    "count": 0
                                },
                                "Anti-hydroxylation": {
                                    "count": 0
                                },
                                "Addition of carbene and dihalo carbene": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrophilic additions- 3": {
                            "count": 0,
                            "content": {
                                "Prins reaction and Dimerization of alkenes in acidic medium": {
                                    "count": 0
                                },
                                "Addition of X₂ + H₂O": {
                                    "count": 0
                                },
                                "With dry and moist Ag₂O": {
                                    "count": 0
                                }
                            }
                        },
                        "Radical addition": {
                            "count": 0,
                            "content": {
                                "With HBr and CCl₃Br": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxidation of Alkenes": {
                            "count": 0,
                            "content": {
                                "Ozonolysis": {
                                    "count": 0
                                },
                                "Oxidation with strong oxidizing agents": {
                                    "count": 0
                                }
                            }
                        },
                        "Diels-Alder reaction": {
                            "count": 0,
                            "content": {
                                "Mechanism, reactivity": {
                                    "count": 0
                                },
                                "Regioselectivity and Stereochemistry": {
                                    "count": 0
                                }
                            }
                        },
                        "Miscellaneous reactions": {
                            "count": 0,
                            "content": {
                                "Combustion, Polymerization, reaction with Tidden reagent and Wacker process": {
                                    "count": 0
                                },
                                "Alpha halogenation by NBS": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of alkynes": {
                            "count": 0,
                            "content": {
                                "From alkynes": {
                                    "count": 0
                                },
                                "From halogen derivatives": {
                                    "count": 0
                                },
                                "Kolbe's electrolysis and Hydrolysis of C2 carbides": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical properties of alkynes": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of alkynes": {
                            "count": 0,
                            "content": {
                                "Reactivity for electrophilic addition": {
                                    "count": 0
                                },
                                "Electrophilic additions": {
                                    "count": 0
                                },
                                "Reactions of terminal Alkynes": {
                                    "count": 0
                                },
                                "Reduction and Oxidation of alkynes": {
                                    "count": 0
                                },
                                "Polymerization/Dimerization": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Aromatic hydrocarbons": {
                    "count": 0,
                    "content": {
                        "Preparations": {
                            "count": 0,
                            "content": {
                                "From hydrocarbons": {
                                    "count": 0
                                },
                                "From oxygen derivatives": {
                                    "count": 0
                                },
                                "Decarboxylation and Desulphonation": {
                                    "count": 0
                                },
                                "Reduction of benzene diazonium salt": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical properties": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties": {
                            "count": 0,
                            "content": {
                                "Addition vs substitution": {
                                    "count": 0
                                }
                            }
                        },
                        "Electrophilic aromatic substitution [ArSE or EAS]": {
                            "count": 0,
                            "content": {
                                "Mechanism and kinetics": {
                                    "count": 0
                                },
                                "Activating and deactivating groups": {
                                    "count": 0
                                },
                                "Ortho-para and Meta directing groups": {
                                    "count": 0
                                },
                                "Reactivity comparisons": {
                                    "count": 0
                                },
                                "ArSE in naphthalene": {
                                    "count": 0
                                },
                                "ArSE in heteroaromatic compounds": {
                                    "count": 0
                                }
                            }
                        },
                        "Aromatic substitution reaction": {
                            "count": 0,
                            "content": {
                                "Halogenation": {
                                    "count": 0
                                },
                                "Nitration and Nitrosation reaction": {
                                    "count": 0
                                },
                                "Sulphonation reaction": {
                                    "count": 0
                                },
                                "Friedel-Crafts alkylation": {
                                    "count": 0
                                },
                                "Friedel-Crafts acylation reaction": {
                                    "count": 0
                                },
                                "Formylation, halo formylation and Houben-Hoesch reaction": {
                                    "count": 0
                                },
                                "Preparation of phenolphthalein, DDT": {
                                    "count": 0
                                },
                                "Fries rearrangement": {
                                    "count": 0
                                },
                                "Claisen's rearrangement reaction": {
                                    "count": 0
                                },
                                "Kolbe's reaction": {
                                    "count": 0
                                },
                                "Reimer-Tiemann reaction": {
                                    "count": 0
                                },
                                "Diazo coupling reaction and preparation of methyl orange": {
                                    "count": 0
                                }
                            }
                        },
                        "Miscellaneous reactions": {
                            "count": 0,
                            "content": {
                                "Photolytic halogenation, hydrogeneration and Oxidations reactions": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Alkyl and aryl halides or halogen derivatives": {
                    "count": 0,
                    "content": {
                        "Introduction of haloalkanes": {
                            "count": 0,
                            "content": {
                                "Monohalides and Polyhalides": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of haloalkanes": {
                            "count": 0,
                            "content": {
                                "From hydrocarbons": {
                                    "count": 0
                                },
                                "Finkelstein reaction": {
                                    "count": 0
                                },
                                "From alcohols and carbonyl compounds": {
                                    "count": 0
                                },
                                "Borodine Hunsdiecker reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical and chemical properties of haloalkanes": {
                            "count": 0,
                            "content": {
                                "Physical properties of haloalkanes": {
                                    "count": 0
                                },
                                "Elimination reactions of haloalkanes": {
                                    "count": 0
                                },
                                "Nucleophilic substitution of haloalkanes": {
                                    "count": 0
                                },
                                "Ammonolysis reaction": {
                                    "count": 0
                                },
                                "Williamson's reaction": {
                                    "count": 0
                                },
                                "Reduction and oxidation of halides": {
                                    "count": 0
                                },
                                "Wurtz reaction and Reaction with metals": {
                                    "count": 0
                                }
                            }
                        },
                        "Grignard reagent": {
                            "count": 0,
                            "content": {
                                "Preparation": {
                                    "count": 0
                                },
                                "Basic nature of Grignard and Reaction with compounds having protic H": {
                                    "count": 0
                                },
                                "Reaction with oxygen derivatives": {
                                    "count": 0
                                },
                                "Reaction with nitriles": {
                                    "count": 0
                                },
                                "Reaction with CO₂, SO₃, CS₂, oxygen and sulphur": {
                                    "count": 0
                                }
                            }
                        },
                        "Physical properties of haloform": {
                            "count": 0,
                            "content": {
                                "General physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Haloform reaction": {
                            "count": 0,
                            "content": {
                                "Reaction and mechanism ": {
                                    "count": 0
                                },
                                "Examples": {
                                    "count": 0
                                }
                            }
                        },
                        "Use of haloalkanes in CFCs": {
                            "count": 0,
                            "content": {
                                "Freons and applications": {
                                    "count": 0
                                }
                            }
                        },
                        "Aryl halides": {
                            "count": 0,
                            "content": {
                                "Introduction and preparations": {
                                    "count": 0
                                }
                            }
                        },
                        "Reaction of aryl halides": {
                            "count": 0,
                            "content": {
                                "With metals": {
                                    "count": 0
                                },
                                "ArSE of aryl halides": {
                                    "count": 0
                                }
                            }
                        },
                        "Nucleophilic substitution of aryl halides": {
                            "count": 0,
                            "content": {
                                "ArSN2 mechanism and kinetics": {
                                    "count": 0
                                },
                                "Activating and deactivating groups for ArSN2": {
                                    "count": 0
                                },
                                "Dow's process and other examples of ArSN2": {
                                    "count": 0
                                },
                                "ArSN via benzyne mechanism": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Alcohols": {
                    "count": 0,
                    "content": {
                        "Alcohols: Introduction": {
                            "count": 0,
                            "content": {
                                "Classification and nomenclature": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of alcohols": {
                            "count": 0,
                            "content": {
                                "From hydrocarbons": {
                                    "count": 0
                                },
                                "From halides and ethers": {
                                    "count": 0
                                },
                                "By reducing carbonyl compounds": {
                                    "count": 0
                                },
                                "By reducing carboxylic acids and derivatives": {
                                    "count": 0
                                },
                                "By hydrolysis of esters": {
                                    "count": 0
                                },
                                "From amines": {
                                    "count": 0
                                },
                                "Allylic oxidation and Fermentation of Carbohydrates": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of alcohol": {
                            "count": 0,
                            "content": {
                                "Physical Properties of Alcohol": {
                                    "count": 0
                                },
                                "Reactions due to O-H bond cleavage": {
                                    "count": 0
                                },
                                "Reactions due to C-O bond cleavage": {
                                    "count": 0
                                },
                                "Esterification with organic acids and derivatives": {
                                    "count": 0
                                },
                                "Esterification with inorganic acids and derivatives": {
                                    "count": 0
                                },
                                "Oxidation by mild oxidising agents": {
                                    "count": 0
                                },
                                "Oxidation by strong oxidising agents": {
                                    "count": 0
                                },
                                "Distinction tests of alcohol": {
                                    "count": 0
                                }
                            }
                        },
                        "geminal diols": {
                            "count": 0,
                            "content": {
                                "Preration from  Alkenes and geminal dihalides & imp. properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of vicinal diols": {
                            "count": 0,
                            "content": {
                                "Prepration From alkenes and vicinal dihalides": {
                                    "count": 0
                                },
                                "Preparation From carbonyl compounds": {
                                    "count": 0
                                },
                                "Physical properties and Reaction with metals": {
                                    "count": 0
                                },
                                "Halogenation and Dehydration": {
                                    "count": 0
                                },
                                "Esterification, Redox reaction and polymerisation reaction": {
                                    "count": 0
                                },
                                "Pinacol-pinacolone rearrangement reaction": {
                                    "count": 0
                                },
                                "Malaprade oxidation": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of glycerol": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of glycerol": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties": {
                            "count": 0,
                            "content": {
                                "Halogenation, Dehydration and Esterification reaction": {
                                    "count": 0
                                },
                                "Redox reaction and Reaction with oxalic acid": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Phenols": {
                    "count": 0,
                    "content": {
                        "Preparation and physical properties of phenols": {
                            "count": 0,
                            "content": {
                                "Preparation and physical properties of phenols": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of phenol": {
                            "count": 0,
                            "content": {
                                "Reaction due to O-H bond cleavage": {
                                    "count": 0
                                },
                                "Reactions due to C-O bond cleavage": {
                                    "count": 0
                                },
                                "Electrophilic aromatic substitutions": {
                                    "count": 0
                                },
                                "Name reactions": {
                                    "count": 0
                                },
                                "Polymerization reactions": {
                                    "count": 0
                                },
                                "Distinction tests": {
                                    "count": 0
                                },
                                "Oxidation reactions": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Ethers": {
                    "count": 0,
                    "content": {
                        "Preparation and properties of ethers": {
                            "count": 0,
                            "content": {
                                "From alkene": {
                                    "count": 0
                                },
                                "From halides": {
                                    "count": 0
                                },
                                "From alcohols": {
                                    "count": 0
                                },
                                "Physical properties": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of aliphatic ethers": {
                            "count": 0,
                            "content": {
                                "Halogenation and Dehydration": {
                                    "count": 0
                                },
                                "Reduction and Oxidation reactions": {
                                    "count": 0
                                },
                                "Acid and base catalysed ring-opening of epoxides": {
                                    "count": 0
                                },
                                "Nomenclature and properties of crown ethers": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Carbonyl compounds (Aldehydes and ketones)": {
                    "count": 0,
                    "content": {
                        "Introduction to aldehydes and ketones": {
                            "count": 0,
                            "content": {
                                "Structure": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparations": {
                            "count": 0,
                            "content": {
                                "From hydrocarbons and halides": {
                                    "count": 0
                                },
                                "Carbonylation of aromatic compounds and Etard oxidation reaction": {
                                    "count": 0
                                },
                                "By heating allyl-vinyl ethers": {
                                    "count": 0
                                },
                                "By oxidation of alcohols": {
                                    "count": 0
                                },
                                "Friedel-Crafts acylation": {
                                    "count": 0
                                },
                                "Fries rearrangement reaction": {
                                    "count": 0
                                },
                                "Stephen's aldehyde synthesis": {
                                    "count": 0
                                },
                                "Nef's and Rosenmund’s reaction": {
                                    "count": 0
                                },
                                "From nitriles": {
                                    "count": 0
                                },
                                "From carboxylic acids and derivatives": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of carbonyl compounds- 1": {
                            "count": 0,
                            "content": {
                                "Physical properties and General Nucleophilic addition": {
                                    "count": 0
                                },
                                "Nucleophilic addition of water, alcohols": {
                                    "count": 0
                                },
                                "Cyanohydrin formation": {
                                    "count": 0
                                },
                                "Addition of sodium bisulphite": {
                                    "count": 0
                                },
                                "Addition of organometallic compounds": {
                                    "count": 0
                                },
                                "Michael addition reaction": {
                                    "count": 0
                                },
                                "Wittig reaction": {
                                    "count": 0
                                },
                                "Addition elimination with ammonia and derivatives": {
                                    "count": 0
                                },
                                "Enamine formation with secondary amines": {
                                    "count": 0
                                },
                                "Beckmann rearrangement reaction 2": {
                                    "count": 0
                                },
                                "Reduction reactions": {
                                    "count": 0
                                },
                                "Oxidation reactions": {
                                    "count": 0
                                },
                                "Distinction tests of aldehydes and ketones": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of carbonyl compounds- 2": {
                            "count": 0,
                            "content": {
                                "Reaction due to alpha-H": {
                                    "count": 0
                                },
                                "Aldol condensation reaction": {
                                    "count": 0
                                },
                                "Claisen-Schmidt reaction": {
                                    "count": 0
                                },
                                "Knoevenagel condensation reaction": {
                                    "count": 0
                                },
                                "Perkin condensation reaction 2": {
                                    "count": 0
                                },
                                "Reformatsky rearrangement reaction": {
                                    "count": 0
                                },
                                "Benzoin reaction": {
                                    "count": 0
                                },
                                "Mannich reaction": {
                                    "count": 0
                                },
                                "Cannizzaro reaction": {
                                    "count": 0
                                },
                                "Benzil-benzilic acid rearrangement reaction": {
                                    "count": 0
                                },
                                "Tischenko reaction 2": {
                                    "count": 0
                                },
                                "Favorskii rearrangement reaction": {
                                    "count": 0
                                },
                                "Polymerization reactions": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Carboxylic acid and its derivatives": {
                    "count": 0,
                    "content": {
                        "Introduction to carboxylic acids and its derivatives": {
                            "count": 0,
                            "content": {
                                "Structures and resonance stabilization": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of carboxylic acids": {
                            "count": 0,
                            "content": {
                                "Oxidation of hydrocarbons": {
                                    "count": 0
                                },
                                "By Grignard reagents and from halides": {
                                    "count": 0
                                },
                                "Oxidation of alcohols": {
                                    "count": 0
                                },
                                "Oxidation of aldehydes and ketones": {
                                    "count": 0
                                },
                                "Oxidation of aromatic compounds": {
                                    "count": 0
                                },
                                "Hydrolysis of carboxylic acid derivatives": {
                                    "count": 0
                                },
                                "Hydrolysis of nitriles": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties": {
                            "count": 0,
                            "content": {
                                "Physical properties": {
                                    "count": 0
                                },
                                "Reactions due to O-H bond cleavage": {
                                    "count": 0
                                },
                                "Reactions due to C-O bond cleavage": {
                                    "count": 0
                                },
                                "Decarboxylation": {
                                    "count": 0
                                },
                                "Heating effect on carboxylic acids": {
                                    "count": 0
                                },
                                "Oxidation and Reduction reactions": {
                                    "count": 0
                                },
                                "Reaction with organometallic compounds": {
                                    "count": 0
                                },
                                "Borodine Hunsdiecker reaction": {
                                    "count": 0
                                },
                                "Hell-Volhard-Zelinsky reaction": {
                                    "count": 0
                                },
                                "Schmidt reaction": {
                                    "count": 0
                                },
                                "Arndt-Eistert reaction": {
                                    "count": 0
                                },
                                "Acyl nucleophilic substitution": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of Acyl halides": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of Acyl halides": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of acyl halides": {
                            "count": 0,
                            "content": {
                                "Hydrolysis, Esterification and Redox reaction": {
                                    "count": 0
                                },
                                "SNAc and Curtius rearrangement reaction": {
                                    "count": 0
                                },
                                "Reaction with organometallic compounds": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of anhydrides": {
                            "count": 0,
                            "content": {
                                "Preparation and properties of anhydrides": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemical properties of acid anhydride": {
                            "count": 0,
                            "content": {
                                "Hydrolysis, redox reaction and reaction with organometallic compounds": {
                                    "count": 0
                                },
                                "Perkin condensation reaction 1": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of esters": {
                            "count": 0,
                            "content": {
                                "From carboxylic acids and derivatives": {
                                    "count": 0
                                },
                                "Baeyer-Villiger oxidation": {
                                    "count": 0
                                },
                                "Tischenko reaction 1": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of esters": {
                            "count": 0,
                            "content": {
                                "Physical properties of esters, Hydrolysis and Redox reactions": {
                                    "count": 0
                                },
                                "Claisen condensation of esters": {
                                    "count": 0
                                },
                                "With organometallic compounds": {
                                    "count": 0
                                },
                                "Acyloin reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of amides": {
                            "count": 0,
                            "content": {
                                "SNAc of carboxylic acids and derivatives": {
                                    "count": 0
                                },
                                "Partial hydrolysis of nitriles": {
                                    "count": 0
                                },
                                "Beckmann rearrangement reaction 1": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of amides": {
                            "count": 0,
                            "content": {
                                "Physical properties, Hydrolysis and Dehydration": {
                                    "count": 0
                                },
                                "Halogenation and redox reactions.": {
                                    "count": 0
                                },
                                "Acyl nucleophilic substitution": {
                                    "count": 0
                                },
                                "Hoffman bromamide degradation reaction": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Nitrogen derivatives": {
                    "count": 0,
                    "content": {
                        "Introduction to amines": {
                            "count": 0,
                            "content": {
                                "Structure and nomenclature of amines": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation of amines": {
                            "count": 0,
                            "content": {
                                "From halides and alcohols": {
                                    "count": 0
                                },
                                "From carbonyl compounds": {
                                    "count": 0
                                },
                                "Gabriel phthalimide reaction": {
                                    "count": 0
                                },
                                "Reduction of oximes, imines, azides and nitro compounds": {
                                    "count": 0
                                },
                                "Reduction of amides, nitriles and isonitriles": {
                                    "count": 0
                                },
                                "Lossen rearrangement reaction": {
                                    "count": 0
                                }
                            }
                        },
                        "Properties of amines": {
                            "count": 0,
                            "content": {
                                "Physical properties and Basic nature of amines": {
                                    "count": 0
                                },
                                "Hofmann elimination": {
                                    "count": 0
                                },
                                "Acylation and Schotten-Baumann reaction": {
                                    "count": 0
                                },
                                "Condensation with carbonyl compounds": {
                                    "count": 0
                                }
                            }
                        },
                        "Distinction tests": {
                            "count": 0,
                            "content": {
                                "With HNO₂": {
                                    "count": 0
                                },
                                "Hoffman mustard oil test": {
                                    "count": 0
                                },
                                "With Hinsberg’s reagent": {
                                    "count": 0
                                },
                                "Carbylamine test": {
                                    "count": 0
                                },
                                "Reaction with COCl₂": {
                                    "count": 0
                                }
                            }
                        },
                        "Oxidation of amines": {
                            "count": 0,
                            "content": {
                                "Oxidation by KMnO₄ and peroxy acid": {
                                    "count": 0
                                },
                                "Oxidation of aromatic amines": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties of aniline": {
                            "count": 0,
                            "content": {
                                "Reduction of nitrobenzene by Zn/HCl or Sn/HCl": {
                                    "count": 0
                                },
                                "From phenol and chlorobenzene": {
                                    "count": 0
                                },
                                "Electrophilic aromatic substitution": {
                                    "count": 0
                                }
                            }
                        },
                        "Preparation and properties and urea": {
                            "count": 0,
                            "content": {
                                "Wohler synthesis and Heating effect on urea": {
                                    "count": 0
                                },
                                "Reaction with oxalic acid, diethyl malonate and HNO₂": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Biomolecules": {
                    "count": 0,
                    "content": {
                        "Carbohydrates": {
                            "count": 0,
                            "content": {
                                "Definition, classification and examples of saccharides": {
                                    "count": 0
                                }
                            }
                        },
                        "Monosaccharides": {
                            "count": 0,
                            "content": {
                                "Aldoses and Ketoses": {
                                    "count": 0
                                },
                                "D, L classification of monosaccharides": {
                                    "count": 0
                                },
                                "Stereoisomerism, Haworth and Fischer projection of monosaccharides": {
                                    "count": 0
                                },
                                "Ring chain tautomerism in sugars": {
                                    "count": 0
                                },
                                "Mutarotation": {
                                    "count": 0
                                },
                                "Glycosides": {
                                    "count": 0
                                },
                                "Lobry de Vruyn-van Ekenstein transformation": {
                                    "count": 0
                                },
                                "Oxidation and Reduction of sugar": {
                                    "count": 0
                                },
                                "Formation of Osazones": {
                                    "count": 0
                                },
                                "Kiliani Fischer synthesis and Ruff degradation synthesis": {
                                    "count": 0
                                }
                            }
                        },
                        "Sucrose": {
                            "count": 0,
                            "content": {
                                "Structure and Properties of Sucrose": {
                                    "count": 0
                                }
                            }
                        },
                        "Maltose": {
                            "count": 0,
                            "content": {
                                "Structure and Properties of Maltose": {
                                    "count": 0
                                }
                            }
                        },
                        "Polysaccharides": {
                            "count": 0,
                            "content": {
                                "Starch": {
                                    "count": 0
                                },
                                "Amylose and Amylopectin": {
                                    "count": 0
                                }
                            }
                        },
                        "Structure and classification of amino acids": {
                            "count": 0,
                            "content": {
                                "Classification of Amino acids": {
                                    "count": 0
                                },
                                "Zwitter ion character and Isoelectric point": {
                                    "count": 0
                                }
                            }
                        },
                        "Synthesis of amino acids": {
                            "count": 0,
                            "content": {
                                "Ammonolysis of alpha-amino acids, Gabriel phthalimide and Strecker synthesis": {
                                    "count": 0
                                }
                            }
                        },
                        "Polypeptides": {
                            "count": 0,
                            "content": {
                                "Peptide bonds their preparation": {
                                    "count": 0
                                },
                                "Representation of polypeptides and Ninhydrin test": {
                                    "count": 0
                                },
                                "Hydrolysis of polypeptides and Sanger N- terminal analysis": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Synthetic and natural polymers": {
                    "count": 0,
                    "content": {
                        "Polymers-Introduction": {
                            "count": 0,
                            "content": {
                                "Polymers, macromolecules, Homopolymers and Copolymers": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification of polymers": {
                            "count": 0,
                            "content": {
                                "Classification based on source": {
                                    "count": 0
                                },
                                "Classification based on structure": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification based on synthesis": {
                            "count": 0,
                            "content": {
                                "Addition polymers and Condensation polymers": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification based on the mode of polymerization": {
                            "count": 0,
                            "content": {
                                "Chain growth and step growth polymers": {
                                    "count": 0
                                },
                                "Free radical and Vinyl polymerization": {
                                    "count": 0
                                }
                            }
                        },
                        "Classification based on molecular forces": {
                            "count": 0,
                            "content": {
                                "Elastomers and fibres": {
                                    "count": 0
                                },
                                "Thermoplastics and Thermosetting polymers": {
                                    "count": 0
                                }
                            }
                        },
                        "Polymerization": {
                            "count": 0,
                            "content": {
                                "Conjugate diene polymerization": {
                                    "count": 0
                                },
                                "Cationic addition polymerization": {
                                    "count": 0
                                },
                                "Anionic addition polymerization": {
                                    "count": 0
                                },
                                "Co polymerization": {
                                    "count": 0
                                }
                            }
                        },
                        "Commercially important polymers": {
                            "count": 0,
                            "content": {
                                "Polyethylene, Polypropylene and Polystyrene": {
                                    "count": 0
                                },
                                "Polyacrylonitrile (PAN), Polyvinyl chlorides (PVC) and Teflon": {
                                    "count": 0
                                }
                            }
                        },
                        "Homopolymers": {
                            "count": 0,
                            "content": {
                                "Synthetic rubber": {
                                    "count": 0
                                },
                                "Natural rubber (all-cis rubber)": {
                                    "count": 0
                                },
                                "Gutta-percha (all-trans rubber)": {
                                    "count": 0
                                }
                            }
                        },
                        "Copolymers": {
                            "count": 0,
                            "content": {
                                "BUNA-S and BUNA-N": {
                                    "count": 0
                                }
                            }
                        },
                        "Condensation polymers": {
                            "count": 0,
                            "content": {
                                "Terylene or dacron": {
                                    "count": 0
                                }
                            }
                        },
                        "Polyamides": {
                            "count": 0,
                            "content": {
                                "Types of nylon": {
                                    "count": 0
                                }
                            }
                        },
                        "Formaldehyde resin": {
                            "count": 0,
                            "content": {
                                "Resins with formaldehyde, Melamine and Urea": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Theoretical and environmental chemistry": {
                    "count": 0,
                    "content": {
                        "Introduction to environmental chemistry": {
                            "count": 0,
                            "content": {
                                "Regions of the atmosphere and Air pollution reactions": {
                                    "count": 0
                                },
                                "Air pollution and its effects": {
                                    "count": 0
                                },
                                "Greenhouse Effect Global Warming and Ozone layer depletion": {
                                    "count": 0
                                },
                                "Water pollution": {
                                    "count": 0
                                }
                            }
                        }
                    }
                },
                "Chemistry in everyday life": {
                    "count": 0,
                    "content": {
                        "Introduction to drugs and chemotherapy": {
                            "count": 0,
                            "content": {
                                "Chemotherapy": {
                                    "count": 0
                                },
                                "Classification of drugs and important drugs": {
                                    "count": 0
                                },
                                "Drugs according to their action": {
                                    "count": 0
                                },
                                "Neurologically active drugs and Antimicrobials": {
                                    "count": 0
                                }
                            }
                        },
                        "Types of chemical messengers": {
                            "count": 0,
                            "content": {
                                "Types of Hormones and Neurotransmitters": {
                                    "count": 0
                                }
                            }
                        },
                        "Chemicals in food": {
                            "count": 0,
                            "content": {
                                "Artificial sweeteners, Food preservatives and Antioxidants": {
                                    "count": 0
                                }
                            }
                        },
                        "Cleansing agents": {
                            "count": 0,
                            "content": {
                                "Soaps and detergents": {
                                    "count": 0
                                }
                            }
                        },
                        "Rocket propellant": {
                            "count": 0,
                            "content": {
                                "Types of rocket propellants": {
                                    "count": 0
                                }
                            }
                        },
                        "Dyes": {
                            "count": 0,
                            "content": {
                                "Chromophores and important dyes": {
                                    "count": 0
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};