export default {
    "Trignometric Ratios, Functions & Identities": {
        "Fundamentals of Trigo": [
            "Degree, Radians, Minutes and Seconds",
            "Definition of Sin, Cos, Tan",
            "Definition of Cosec, Sec, Cot"
        ],
        "Domain, Range, Graph of Trigo": [
            "Domain, Range, Graph Sin,cos",
            "Domain, Range, Graph Tan,cot",
            "Domain, Range, Graph Cosec, sec"
        ],
        "Signs of Ratios in Quadrants": [
            "Unit circle definition, ASTC (All Silver Tea Cups)"
        ],
        "Allied Angles": [
            "180°± A",
            "360° ± A",
            "90 ± A",
            "270° ± A",
            "n(90°) ± A method"
        ],
        "Basic Trigo Identities": [
            "Sin and Cos",
            "Tan and Sec",
            "Cot and Cosec"
        ],
        "Standard Angles": [
            "0°,30°, 45°, 60°, 90°"
        ],
        "Compound Angles Formula": [
            "sin(A+B) and sin(A-B)",
            "cos(A+B) and cos (A-B)",
            "tan(A+B) and tan(A-B)",
            "Expansion of cot(A±B)"
        ],
        "Factorisation, De Factorisation": [
            "(sinC+sinD) and (sinC-sinD)",
            "(cosC+cosD) and (cosC-cosD)",
            "Formula of 2sinAcosB and 2cosAsinB",
            "Formula of 2cosAcosB and 2sinAsinB"
        ],
        "15°, 75°": [
            "15°, 75°"
        ],
        "Multiple angles Formula": [
            "sin2A, cos2A, tan2A",
            "sin3A, cos3A, tan3A"
        ],
        "18°, 36°, 54°, 72°": [
            "18°, 72°",
            "36°, 54°"
        ],
        "Half Angle Formula": [
            "sin(A/2), cos(A/2) formula",
            "tan(A/2) formula",
            "sin(A/2)± cos(A/2)",
            "cot(A/2)± tan(A/2)"
        ],
        "22.5° , 67.5° and 7.5°": [
            "22.5° , 67.5° and 7.5°"
        ],
        "A, (60°+A), (60°-A)": [
            "sinA.sin(60°-A).sin(60°+A)",
            "cosA.cos(60°-A). cos(60°+A)",
            "tanA.tan(60°-A).tan(60°+A)"
        ],
        "A+B+C= 180°, In Triangle ABC": [
            "∑tanA, ∑tan(A/2)tan(B/2)",
            "∑sin2A, ∑cos2A",
            "∑sinA, ∑cosA"
        ],
        "Max and Min in Trigo": [
            "Whole Square Method",
            "Reciprocal Based (Acosx+ Bsecx)",
            "asinx + bcosx"
        ],
        "Sin and Cos of an AP": [
            "Sin of an AP, Cos of an AP"
        ],
        "Trignonometry and other chapters": [
            "Series (telescoping)",
            "Complex numbers",
            "Theory of Equations"
        ]
    },
    "Equation and Inequation": {
        "Inequalities": [
            "Introduction of >,<,>=,<=,Intervals,Union and Intersection of intervals",
            "Transitive property (inequality), Property related addition, Subtraction and Multiplication, divisio",
            "Properties related to powers and roots",
            "Linear inequalities, system of Linear inequalities (one variable)",
            "Wavy Curve Method",
            "Irrational inequalities and equation"
        ],
        "Modulus Function": [
            "Introduction of modulus function",
            "Graph of Modulus function (y = |x - a|, y = |x - a|+ |x - b| )",
            "Properties of Modulus function",
            "Triangle inequalities of Modulus function",
            "Max f (x), g(x), Min f (x), g(x) Related Properties"
        ],
        "Exponential Function": [
            "Introduction and graph and equation of exponential function",
            "Exponential Inequalities"
        ],
        "Logarithm Function": [
            "Law of Indices",
            "Graph, Domain, Range of logarithm",
            "Logarithm of number or base raised to any power",
            "Change of base, Reciprocal of logarithm",
            "Cancelling of base, a^(log(b,c)) = b^(log(a,c))",
            "Logarithmic inequalities",
            "Common log, Characteristic and mantissa and antilog",
            "log ab / log a/b"
        ]
    },
    "Quadratic Equations": {
        "Introduction of quadratic": [
            "Polynomial and quadratic expression",
            "Difference between equation expression identity formula",
            "Identical equations,Rational expression"
        ],
        "Equation and roots": [
            "Factors & roots of Quadratic Equation",
            "Quadratic formula",
            "Sum,product and difference of roots",
            "Formation of quadratic with given roots"
        ],
        "Nature of Roots of quadratic": [
            "Real and distinct,Real and equal",
            "Imaginary vs Real",
            "Rational/Irrational/Integral roots",
            "Geometrical Interpretation of Nature of Roots"
        ],
        "Descartes Rule of Sign": [
            "In Quadratic & In equation of degree n"
        ],
        "Transformation of equation": [
            "kα ,kβ, -α ,-β",
            "α±k , β±k",
            "αⁿ , βⁿ ,1/α ,1/β"
        ],
        "Common roots": [
            "Condition for at least one common root",
            "Condition for both common roots",
            "Common roots in higher degree equations"
        ],
        "Quadratic/Biquadratic": [
            "Equation Reducible to Quadratic/Biquadratic"
        ],
        "Quadratic function and its graph": [
            "a>0, D>0 & a>0, D=0 & a>0, D<0",
            "a<0, D>0 & a<0, D=0 & a<0, D<0"
        ],
        "Maximum and minimum value of quadratic": [
            "a>0 , a<0",
            "Range of rational expression"
        ],
        "Location of roots": [
            "Both roots greater than a real number",
            "Both roots less than a real number",
            "A real number lie between the roots",
            "Exactly one root lie in open interval",
            "Both roots lie in open interval",
            "One root is less than ‘p’ and other is greater than ‘q’"
        ],
        "Polynomial expression": [
            "Cubic Equation & Relation between roots and coefficient",
            "Condition for 3 real roots,1 real and two imaginary roots",
            "Graph of cubic in different conditions",
            "Polynomial of degree n,Relation between roots and coefficients"
        ],
        "Rolle’s theorem": [
            "Statement of rolle’s theorem"
        ]
    },
    "Sequences and Series (Progressions)": {
        "Definition of Sequence and Series": [
            "Def sequence and series & general term"
        ],
        "Arithmetic Progressions (AP)": [
            "Definition , General term",
            "Sum of n terms",
            "General term from sum of n terms",
            "Properties of Arithmetic Progression",
            "Arithmetic mean,Inserting n A.M. B/w 2 Numbers"
        ],
        "Geometric Progressions(GP)": [
            "Definition,General term",
            "Sum of n term",
            "Sum of infinite terms",
            "Properties of geometric Progression",
            "Geometric mean,Inserting n G.M. B/w 2 Numbers"
        ],
        "Harmonic progression(HP)": [
            "Definition,General term",
            "Properties of Harmonic Progression",
            "Harmonic mean,Inserting n H.M. B/w 2 Numbers"
        ],
        "A.M. G.M and H.M": [
            "A.M, G.M, H.M of n positive numbers",
            "Relation Between A.M. G.M and H.M"
        ],
        "Arithmetic-geometric progression ( AGP)": [
            "Definition,General term",
            "Sum of n term",
            "Sum of infinite terms"
        ],
        "Miscellaneous series": [
            "Sum of squares of first n natural numbers",
            "Sum of cubes of first n natural numbers",
            "Difference of terms in AP",
            "Difference of Difference of terms in AP",
            "Difference of terms in GP",
            "Difference of Difference of terms in GP",
            "Vn Method in Progression"
        ],
        "Various Inequalities": [
            "Cauchy's Inequality"
        ],
        "Special Concepts": [
            "Recursion",
            "Use of Rationalisation in Series",
            "Weighted Mean",
            "Use of Calculus in Series",
            "Exponential Series",
            "Logarithmic Series"
        ]
    },
    "Trigonometric Equations": {
        "Introduction of trigonometric equation": [
            "Principal & general solution of T.E",
            "Definition, solution or root of T.E"
        ],
        "General solution of some elementary equations": [
            "sinx=0,1,-1",
            "cosx=0,1,-1",
            "tanx=0, cotx=0"
        ],
        "Important points to be taken care while solving equations": [
            "Domain checking",
            "Avoid  squaring",
            "Avoid  cancelling terms from both side containing unknown"
        ],
        "General solution of some standard equations": [
            "sinθ=sinα",
            "cosθ=cosα",
            "tanθ=tanα",
            "sin²θ=sin²α, cos²θ=cos²α, tan²θ=tan²α"
        ],
        "Different strategies to solve T.E": [
            "By factorisation",
            "By transforming product into sum",
            "By transforming sum into product",
            "By substitution",
            "With the use of boundedness of function",
            "Using graphs"
        ],
        "Solution of some special equations": [
            "A sinx + B cosx = C",
            "f(x)=√g(x)",
            "Simultaneous T.E in two Variables",
            "Solution in the case of 2 Trigonometric Equations are given",
            "Inequalities"
        ]
    },
    "Binomial Theorem": {
        "Factorial": [
            "Factorial",
            "Highest power of a prime in n factorial",
            "Number of trailing zeros in n factorial",
            "Formula and properties of nCr"
        ],
        "Binomial Expansion": [
            "Expansion of (x ± a)^n",
            "rth term of binomial expansion",
            "Finding  Coefficient of xʳ"
        ],
        "Middle term(s) and greatest coefficient(s) in (x + a)^n": [
            "If n is even, n is odd"
        ],
        "Numerically Greatest Term": [
            "Numerically Greatest Term"
        ],
        "Binomial Coefficients": [
            "Summation of Binomial coefficients",
            "Use of differentiation",
            "Use of integration",
            "Use of complex number",
            "Multiplication of Binomial coefficients of same series",
            "Multiplication of binomial coefficients of different series"
        ],
        "Multinomial Theorem": [
            "Multinomial Theorem",
            "Number of distinct terms in multinomial expansions"
        ],
        "Generalised form of binomial theorem": [
            "Generalised form ,Binomial theorem for negative index",
            "Binomial expansions for any index"
        ],
        "Integral and fractional part": [
            "Integral and fractional part of (p+√q)^n"
        ],
        "Application of binomial theorem": [
            "Last digits",
            "Double Summation"
        ]
    },
    "Permutation and Combination": {
        "Fundamental principle of counting": [
            "Addition principle, Multiplication principle"
        ],
        "Combination": [
            "Introduction of combination, formula of nCr",
            "Conditional combination",
            "Selection of at least one object from n Different/Identical articles",
            "Selection of objects (Not all distinct)"
        ],
        "Permutation": [
            "Introduction of permutation (nPr) & Difference b/w permutation and combination",
            "Conditional Permutation",
            "Permutation with/without repetition",
            "Permutations when all the objects are not distinct",
            "Rank of words",
            "Sum of all the numbers formed",
            "Permutation of alike obejcts taken some at a time"
        ],
        "Circular Permutation": [
            "Circular arrangement of distinct objects",
            "Circular Permutation when all the objects are not different",
            "Formation of garland"
        ],
        "Geometry based problem": [
            "Number of straight lines & polygon from given n points",
            "Number of intersection points",
            "Number of squares and rectangles in chess board",
            "Grid based problems"
        ],
        "Inclusion- Exclusion principle in combinatorics": [
            "Inclusion-Exclusion principle in combinatorics"
        ],
        "Distribution": [
            "Distribution of distinct objects when a candidate can receive one, or more objects",
            "Distribution of identical objects in distinguishable groups (Multinomial expansion)",
            "Number of integral solutions equation/inequation"
        ],
        "Division into group": [
            "Division into Group when Groups are of Unequal(equal) Size (order not important)",
            "Division into Group when Groups are of Unequal(equal) Size (order important)"
        ],
        "Divisors": [
            "Number of Divisors",
            "Sum of all divisors",
            "Factors/co-prime Factors"
        ],
        "De-arrangement": [
            "De-arrangement"
        ]
    },
    "Probability": {
        "Basic terms in Probability": [
            "Experiment, Sample space, Event and Definition",
            "Exhaustive, Equally likely, Mutually exclusive events"
        ],
        "Set Theory in Probability": [
            "Containing two events",
            "Containing more than two events"
        ],
        "Dependent and Independent events": [
            "Dependent and Independent events"
        ],
        "Odds": [
            "Odds in Favor & Against of an Event in Probability"
        ],
        "Conditional probability": [
            "Conditional Probability",
            "Multiplication Theorem in Probability"
        ],
        "Law of total probability and Bayes' theorem": [
            "Law of Total Probability",
            "Bayes' Theorem in Probability"
        ],
        "Probability Distribution": [
            "Probability Distribution",
            "Binomial Distribution in Probability",
            "Mean & Variance of Probability"
        ],
        "Geometrical probability": [
            "Geometrical application of Probability"
        ]
    },
    "Point and Straight Line": {
        "Coordinate system": [
            "Cartesian Coordinate System,Polar coordinates",
            "Distance Formula ,Pythagoras Theorem",
            "Section Formula",
            "Area of Triangle in Coordinate Geometry",
            "Collinearity Coordinate Geometry"
        ],
        "Imp Points of Triangle": [
            "Centroid,Incentre",
            "Circumcentre, Orthocentre",
            "Euler Line"
        ],
        "Imp points of quadrilateral": [
            "Parallelogram,Rectangle,Rhombus",
            "Square,Kite,Cyclic Quadrilateral"
        ],
        "Locus of a Point": [
            "Meaning,Equation,Steps to find the locus"
        ],
        "Change of axes": [
            "Shifting of origin",
            "Rotation of axes",
            "Removal of the term xy from ax²+2hxy+by²+2gx+2fy+c"
        ],
        "Slope": [
            "Inclination,Slope of line with given inclination",
            "Slope of line joining two points,Slope of line ax+by+c=0",
            "Collinearity of three points by slope",
            "Angle between two lines,Condition of parallelism and perpendicularity"
        ],
        "Equation of line": [
            "Equation of line parallel to x & y-axis",
            "Equation of line in point slope, two point form",
            "Equation of line in intercept form, slope intercept form, general form",
            "Equation of line in normal form",
            "Equation of line in parametric form",
            "Equation of line parallel and perpendicular to a given line"
        ],
        "Point of intersection of two  Lines": [
            "Intersection point of two lines,Concurrence of 3 Lines"
        ],
        "Distance of a Point from a Line": [
            "Distance of a point from a line,Distance between two parallel lines",
            "Image/foot of Perpendicular of a Point in a Line",
            "Concept of reflection of ray"
        ],
        "Position of Point w.r.t. Line": [
            "Position of points w.r.t. Line,Position of point inside a triangle"
        ],
        "Angle bisectors": [
            "Equation of angle acute/obtuse bisectors",
            "Determination of angle bisector containing/not containing (α,β)"
        ],
        "Family of Straight Lines": [
            "Family of straight lines"
        ],
        "Pair of straight lines": [
            "Definition,General equation of 2nd degree",
            "Condition for general equation of 2nd degree to represent  p.o.s.l.",
            "Factorisation of general equation of 2nd degree",
            "Homogeneous Equations in 2nd Degree",
            "Slope quadratic,Angle between p.o.s.l.",
            "Condition for parallel intersecting, perpendicular and coincident lines",
            "Point of intersection of p.o.s.l."
        ],
        "Equation of Angle Bisector": [
            "Equation of Angle Bisector of P.O.S.L."
        ],
        "Homogenisation of non Homogeneous Equation": [
            "Family of pair of lines",
            "Equation of p.o.s.l. Joining point of intersection of a curve and line"
        ],
        "Some other important concepts": [
            "Product of perpendicular distances from a point to a p.o.s.l.",
            "Distance between parallel p.o.s.l."
        ]
    },
    "Circle": {
        "Definition and Properties of circle": [
            "Definition and Properties of circle"
        ],
        "Equation of a circle in different forms": [
            "Center radius form,General equation of circle",
            "Condition for a 2nd degree equation to represent a circle & Diameter form of circle",
            "Parametric equation of a circle Parametric coordinate of any point on circle",
            "Circle Passing Through Three Points,Equation of chord joining A(α) and B(β)"
        ],
        "Position of point w.r.t. circle": [
            "Position of a point w.r.t. circle, Maximum and minimum distance of a point from circle"
        ],
        "Intercepts of Circle on a line": [
            "Intercept on x-axis,y-axis & on y=mx+c"
        ],
        "Tangent and Normal": [
            "Intersection of Line and Circle, Slope form of tangent",
            "Point form of tangent,Parametric form of tangent",
            "Equation of normal to circle, Length of tangent and angle between tangent",
            "Tangent from a point outside the Circle, Equation of pair of tangents",
            "Intersection point of tangents at  A(α) and B(β)"
        ],
        "Common tangents to two circles": [
            "Types of common tangents,No. of common tangents",
            "Length of common tangents",
            "Equation of common tangents"
        ],
        "Power of a point": [
            "Power of a point"
        ],
        "Intersection of two circles": [
            "Angle between 2 circles"
        ],
        "Important terms and their equations": [
            "Director circle to a circle",
            "Chord of contact of circle",
            "Chord of circle with given midpoint",
            "Common chord to two circles,Length of common chord"
        ],
        "Family of circles": [
            "Through intersection of two circles and a circle & a line",
            "Through two fixed points and circle touching a line at a point"
        ],
        "Pole and Polar": [
            "Concept & Properties of Pole and Polar",
            "Conjugate point and Conjugate line"
        ],
        "Radical axis and radical centre": [
            "Definition,Position & Equation of radical axis",
            "Radical center,Position of radical centre",
            "Properties related to radical axis and radical centre"
        ],
        "Coaxial system of circles": [
            "Coaxial system of circles",
            "Limiting points of a coaxial system of circles"
        ]
    },
    "Parabola": {
        "Introduction of conic": [
            "Conic section definition,Recognition of conics",
            "Equation of conic with given focus ,directrix and eccentricity",
            "How to find centre of conic"
        ],
        "Fundamentals of Parabola": [
            "Definition & Term related to parabola",
            "Standard equation of parabola",
            "Other form of parabola and terms related to them",
            "Parabola with vertex at (h,k) and axis parallel to coordinate axes",
            "Parabola referred to perpendicular lines"
        ],
        "Chord of Parabola": [
            "Equation of chord of parabola",
            "Focal Chord"
        ],
        "Position of a Point w.r.t Parabola": [
            "Position of a Point w.r.t Parabola"
        ],
        "Intersection of line and Parabola": [
            "Intersection of line and parabola,Condition of tangency"
        ],
        "Tangent to a Parabola": [
            "Slope form of Tangent & Point of Tangency",
            "Point form & Parametric form of tangent (parabola)",
            "Intersection point of Tangents, Director Circle"
        ],
        "Normal to a Parabola": [
            "Slope form of Normal to Parabola",
            "Point form ,Parametric form  of Normal to Parabola",
            "Normal Intersecting on the Parabola",
            "Point of Intersection and other Properties",
            "Number of normals",
            "Conormal Points and their properties",
            "Length of Tangent, Subtangent, Normal, Subnormal to Parabola",
            "Reflection Property of Parabola"
        ],
        "Equation of Chord of Contact to a Parabola": [
            "Equation of chord of contact to a parabola T= 0"
        ],
        "Equation of Chord of Parabola with given Midpoint": [
            "Equation of Chord of Parabola with given Midpoint"
        ],
        "Pair of Tangents to the Parabola": [
            "Pair of tangents equation"
        ],
        "Diameter of Parabola": [
            "Diameter of Parabola"
        ],
        "Pole and Polar of Parabola": [
            "Pole and Polar of Parabola"
        ],
        "Some standard properties of parabola": [
            "Standard properties"
        ]
    },
    "Ellipse": {
        "Definition and basics of ellipse": [
            "Locus Ellipse Definition,Standard equation of ellipse",
            "Basics of Ellipse when a>b, a<b",
            "Second definition of ellipse",
            "Equation of ellipse referred to two perpendicular lines"
        ],
        "Auxiliary circle and parametric coordinates (Ellipse)": [
            "Auxiliary circle and eccentric angle",
            "Parametric coordinates"
        ],
        "Equation of chord ellipse": [
            "Equation of Chord,Condition for Focal Chord of the Ellipse"
        ],
        "Position of a point w.r.t. ellipse": [
            "Position of a point w.r.t. ellipse"
        ],
        "Line and Ellipse": [
            "Intersection of a line and ellipse, condition of tangency"
        ],
        "Equation of tangent ellipse": [
            "Slope form of Tangent & Point of Contact",
            "Point form & Parametric form of Tangent to an Ellipse",
            "Intersection point of tangents",
            "Equation of pair of tangents"
        ],
        "Important terms and their equation ellipse": [
            "Director Circle of an Ellipse",
            "Chord of Contact of an Ellipse",
            "Chord to Ellipse with Given midpoint",
            "Pole and polar Ellipse"
        ],
        "Normal to an Ellipse": [
            "Point form of Normal to an Ellipse",
            "Slope form of Normal, Point of Contact",
            "Parametric form of Normal to an Ellipse",
            "Subtangent and Subnormal to an Ellipse",
            "Number of normals from a point",
            "Conormal points Ellipse",
            "Reflection property of ellipse"
        ],
        "Diameter of an Ellipse": [
            "Definition of diameter, Equation of diameter",
            "Conjugate Diameters of Ellipse",
            "Properties of Conjugate Diameters of an Ellipse"
        ],
        "Standard properties of ellipse": [
            "Standard properties Ellipse"
        ]
    },
    "Hyperbola": {
        "Introduction of hyperbola": [
            "Definition,Standard equation of hyperbola",
            "Basic terms related to hyperbola",
            "Conjugate hyperbola",
            "Auxiliary circle and Parametric Form",
            "Equation of hyperbola referred to two perpendicular lines as axes"
        ],
        "Position of a point w.r.t hyperbola": [
            "Position of a point w.r.t Hyperbola"
        ],
        "Equation of chord hyperbola": [
            "Equation of chord, condition for focal chord of hyperbola"
        ],
        "Line and hyperbola": [
            "Intersection of line and Hyperbola,Condition of tangency"
        ],
        "Tangent to Hyperbola": [
            "Slope form of Tangent & Point of Contact",
            "Point form & Parametric form of Tangent to Hyperbola",
            "Point of intersection of tangents of Hyperbola"
        ],
        "Normal to Hyperbola": [
            "Point form & Parametric form of Normal to Hyperbola",
            "Slope form of Normal & Point of Contact Hyperbola",
            "Conormal points and their properties",
            "Subtangent and subnormal"
        ],
        "Reflection property of hyperbola": [
            "Reflection property of hyperbola"
        ],
        "Director Circle Hyperbola": [
            "Director circle"
        ],
        "Chord of Contact of Hyperbola": [
            "Chord of contact"
        ],
        "Chord of Hyperbola with given Midpoint": [
            "Chord bisected at a given point"
        ],
        "Pair of Tangents to Hyperbola from an Outside Point": [
            "Equation of pair of tangents"
        ],
        "Diameter of Hyperbola": [
            "Definition and equation of diameter",
            "Definition and equation of Conjugate Diameters",
            "Properties of Conjugate Diameters of Hyperbola"
        ],
        "Pole and Polar of hyperbola": [
            "Pole and polar of Hyperbola"
        ],
        "Asymptotes of hyperbola": [
            "Definition & Equation of asymptotes",
            "Properties of asymptotes"
        ],
        "Highlights and Properties of Hyperbola": [
            "Highlights and Properties"
        ],
        "Rectangular Hyperbola": [
            "General equation of Rectangular Hyperbola",
            "Parametric Coordinates of Rectangular Hyperbola",
            "Equation of chord",
            "Equation of Tangent to Rectangular Hyperbola",
            "Equation of Normal Rectangular Hyperbola"
        ]
    },
    "Complex Numbers": {
        "Introduction to complex numbers": [
            "Properties of iota, definition of complex numbers"
        ],
        "Algebra of Complex Numbers": [
            "Addition, subtraction and equality of complex numbers",
            "Multiplication, division of Complex Numbers"
        ],
        "Square Roots of Complex Numbers": [
            "Square Roots of Complex Numbers"
        ],
        "Argand plane": [
            "Meaning of Argand Plane Geometrical representation of Complex Numbers",
            "Parallelogram Law for Complex Numbers"
        ],
        "Conjugate of a Complex Number": [
            "Conjugate, Geometrical position of conjugate",
            "Properties of conjugate"
        ],
        "Modulus of a complex number": [
            "Modulus, Geometrical meaning of modulus",
            "Properties of Modulus",
            "Triangle Inequality in Complex Numbers"
        ],
        "Amplitude and argument": [
            "Argument, Geometrical meaning, Rules for finding argument",
            "Properties of argument"
        ],
        "Other forms of complex number": [
            "Polar form, Euler form"
        ],
        "De Moivre's Theorem": [
            "De Moivre's Theorem"
        ],
        "Concept of Rotation in Complex Numbers": [
            "Concept of Rotation in Complex Numbers",
            "Relation between vertices of polygon"
        ],
        "Roots of unity": [
            "Cube roots of unity, Properties, Geometrical representation of cube roots of unity",
            "nth roots of unity, Geometrical representation of nth roots of unity",
            "Properties of nth roots of unity"
        ],
        "Complex Polynomial": [
            "Complex Polynomial"
        ],
        "Geometry of complex number": [
            "Distance and Section formula in Complex Number",
            "Straight line in Complex Numbers",
            "Parametric form of straight line",
            "General equation of a straight line & Complex slope",
            "Equation of line parallel and perpendicular to a given line",
            "Equation of perpendicular bisector",
            "Distance of a given point from a given line",
            "Image/foot of perpendicular",
            "Centroid, incentre, orthocenter, circumcenter",
            "Area of a triangle",
            "Equation of Circle, Centre-radius, General form",
            "Other forms of equation of circle",
            "Condition for four points to be concyclic",
            "Equation of Parabolas, Ellipse, Hyperbola in Complex Numbers"
        ],
        "Log of Complex Numbers": [
            "Log of Complex Numbers"
        ],
        "Trigonometric series by complex number": [
            "Sum of Trigonometric Series using De Moivre's Theorem"
        ]
    },
    "Properties of Triangle": {
        "Basic rules in trigonometry": [
            "Sine Rule",
            "Cosine Rule",
            "Napier's Analogy",
            "Projection Formula"
        ],
        "Formula for Half angle of Triangles": [
            "sin A/2, sin B/2, sin C/2",
            "cos A/2, cos B/2, cos C/2",
            "tan A/2, tan B/2, tan C/2"
        ],
        "Area of Traingle & Heron's Formula": [
            "∆=½ab sinC=½bc sinA=½ca sinB½ base. height",
            "∆=abc/4R",
            "Heron's formula"
        ],
        "M-N Theorem": [
            "M-N Theorem"
        ],
        "Circumcentre and Incentre in Trigonometry": [
            "Cirucumcentre, Incentre, in-radius r = ∆/s",
            "r=(s-a) tan A/2=(s-b) tanB/2= (s-c) tanC/2",
            "r= 4RsinA/2sinB/2sinC/2",
            "Distance of incenter from vertex",
            "Length of angle bisector"
        ],
        "Centroid and Medians": [
            "Centroid, Length of medians",
            "Apollonius theorem"
        ],
        "Excentre and Ex-radii of a triangle": [
            "Def of excentre, ex-radius, ????₁=StanA/2, ????₂=..., ????₃=..",
            "????₁=∆/s-a, ????₂=∆/s-b, ????₃=∆/s-c",
            "????₁=4RsinA/2cosB/2cosC/2, ????₂=..., ????₃=..."
        ],
        "Orthocentre and Pedal Triangle": [
            "Orthocentre def, Distance from vertices and sides",
            "Distance between special points of traingle",
            "Pedal triangle def, sides, angles",
            "Circumradius & area of pedal triangle"
        ],
        "Quadrilateral": [
            "Area of Quadrilateral, cyclic Quadrilateral",
            "Cosine of angle of cyclic Quadrilateral",
            "Ptolemy's theorem",
            "Regular polygon"
        ],
        "Solution of Triangles (ambiguous cases)": [
            "Solution of Triangles (ambiguous cases)"
        ],
        "Cosine Rule Complex Numbers": [
            "Cosine Rule Complex Numbers"
        ]
    },
    "Functions": {
        "Functions": [
            "Introduction of Function, Explicit and Implicit Functions",
            "Definition of domain,Rules for the domain of function",
            "Definition of co-domain,range,Rules for range"
        ],
        "Graphs and Properties of Elementary Functions": [
            "Constant, Linear, Quadratic",
            "Exponential Functions Basics",
            "Logarithmic Functions Basics",
            "Inverse Trigonometric Functions Basics",
            "Special Function |x|",
            "Special function  [x]",
            "Special function sgn(x) , {x}"
        ],
        "Transformation of Graphs": [
            "f(x±a), f(x)±a",
            "f(kx), kf(x)",
            "f(|x|), |f(x)|",
            "sgn(f(x)), [f(x)], {f(x)}"
        ],
        "Kinds of functions": [
            "One one, Many one Function",
            "Into, Onto Function"
        ],
        "PnC based Questions": [
            "Total number of functions, one one functions",
            "Total no. of many one, constant functions",
            "Total no. of onto & into functions"
        ],
        "Even and odd functions": [
            "Even & odd Function Definition",
            "Properties of odd and even functions",
            "Extension of domain(even,odd)"
        ],
        "Periodic functions": [
            "Periodic Function Definition",
            "Rules for finding period of functions"
        ],
        "Composite functions": [
            "Composite Function Definition",
            "Properties of composite functions"
        ],
        "Inverse functions": [
            "Inverse Functions definition/rules",
            "Properties of inverse functions"
        ],
        "Binary operation": [
            "Definition and Types of Binary Operations",
            "Existence of Identity and Inverse"
        ],
        "Equal and identical functions": [
            "Definition of identical functions"
        ],
        "Functional Equations": [
            "Functional Equations"
        ]
    },
    "Inverse Trignometric Functions": {
        "Defination and basics of I.T.F.": [
            "Defination & graphs of inverse functions"
        ],
        "Domain range and graphs of I.T.F": [
            "Domain,range and graph of sin⁻¹x, cos⁻¹x",
            "Domain,range and graph of tan⁻¹x, cot⁻¹x",
            "Domain, range and graph of cosec ⁻¹x,sec⁻¹x"
        ],
        "Relation of f⁻¹(x) with f⁻¹(-x) and f⁻¹(1/x)": [
            "Relation of f⁻¹(x) with f⁻¹(-x)&f⁻¹(1/x)"
        ],
        "Addition of complementary angles": [
            "sin⁻¹x+cos⁻¹x=π/2 ,Tan⁻¹x+cot⁻¹x=π/2,sec⁻¹x+cosec⁻¹x=π/2"
        ],
        "Relation between different I.T.F.": [
            "Convert one I.T.F. into others using triangle (0.5)",
            "Simplify expression using trigo substitution (0.5)"
        ],
        "Domain, range and graph of f(f⁻¹(x))": [
            "Sin( sin⁻¹x), cos( cos⁻¹x),tan( tan⁻¹x)",
            "Sec( sec⁻¹x), cosec( cosec⁻¹x),cot( cot⁻¹x)"
        ],
        "Domain, range and graph of f⁻¹(f(x))": [
            "sin⁻¹(sinx),cos⁻¹(cosx)",
            "tan⁻¹(tanx) ,cot⁻¹(cotx)",
            "cosec⁻¹(cosec x),Sec⁻¹(secx)"
        ],
        "Sum and difference of angles formulas": [
            "sin⁻¹x±sin⁻¹y=",
            "cos⁻¹x±cos⁻¹y=",
            "tan⁻¹x±tan⁻¹y="
        ],
        "Multiple angle formula": [
            "2sin⁻¹x in terms of sin⁻¹x, cos⁻¹x",
            "3sin⁻¹x in terms of sin⁻¹x",
            "2cos⁻¹x & 3cos⁻¹x  in terms of cos⁻¹x",
            "2tan⁻¹x in terms of sin⁻¹x,cos⁻¹x",
            "2tan⁻¹x & 3tan⁻¹x  in terms of tan⁻¹x"
        ],
        "Some other important concepts": [
            "Telescopic series questions",
            "Boundary value problems",
            "Questions on max. and min."
        ],
        "Height and distance": [
            "Angle of elevation and depression",
            "Method of solving a problem of height and distance",
            "Geometrical properties and formulae for a triangle",
            "Bearing"
        ]
    },
    "Limits": {
        "Introduction and basics of limits": [
            "Concept of Limits, Existence of Limits",
            "Indeterminate forms, Algebra of Limits"
        ],
        "Methods of Evaluating Limits": [
            "Direct substitution method",
            "Factorization method",
            "Rationalization method"
        ],
        "Standard limits": [
            "Algebraic limits",
            "Trigonometric limits",
            "Inverse trigonometric limits",
            "Exponential limits",
            "Logarithmic limits"
        ],
        "L’Hopital’s rule": [
            "L’Hopital’s rule"
        ],
        "0⁰,ꝏ⁰ and 1^ꝏ Form": [
            "0⁰,ꝏ⁰ Form",
            "1 power  ꝏ form"
        ],
        "Limits using expansions": [
            "Expansion of log(1+x), eˣ, aˣ",
            "Expansion of sinx, cosx, tanx"
        ],
        "Sandwich theorem": [
            "Sandwich theorem"
        ],
        "Newton leibniz rule": [
            "Newton leibniz Rule"
        ],
        "Limit of composite functions": [
            "Limit of composite functions"
        ],
        "Geometrical Limits": [
            "Geometrical Limits"
        ]
    },
    "Continuity and Differentiability": {
        "Continuity": [
            "Introduction and Meaning of Continuity at a Point",
            "Continuity in an Interval"
        ],
        "Types of Discontinuity": [
            "Removable discontinuity",
            "Finite, Infinite type non-removable discontinuity",
            "Oscillatory type non removable discontinuity"
        ],
        "Continuity of standard functions": [
            "Continuity of Standard Functions",
            "Properties of Continuous Function"
        ],
        "Continuity of special types of functions": [
            "Continuity of Composite Functions",
            "Continuity of functions involving [x], {x}, sgn(x)",
            "Continuity of functions involving limit",
            "Continuity of functions defined differently for rational and irrational"
        ],
        "Intermediate Value Theorem": [
            "Intermediate Value Theorem"
        ],
        "Differentiability": [
            "Introduction and Meaning of Differentiability at a Point",
            "Differentiability in an Interval"
        ],
        "Reasons for non-differentiability": [
            "Corner",
            "Discontinuity, Vertical Tangent"
        ],
        "Differentiability of standard functions": [
            "Differentiability of Standard Functions",
            "Properties of Differentiable Functions",
            "Differentiability by differentiation"
        ]
    },
    "Method of Differentiation": {
        "Introduction of derivative": [
            "Introduction, First Principle of derivative"
        ],
        "Differential coefficients of standard function": [
            "Algebraic, Logarithmic & Exponential Functions",
            "Trigonometric & Inverse Trigonometric Functions"
        ],
        "Algebra of Differentiation": [
            "Addition/Subtraction Rule in Differentiation",
            "Product Rule in Differentiation",
            "Quotient Rule in Differentiation"
        ],
        "Differentiation by Substitution": [
            "Differentiation by Substitution"
        ],
        "Differentiation of Composite Functions": [
            "Differentiation of Composite Functions, Chain Rule"
        ],
        "Differentiation of implicit function": [
            "Differentiation of implicit function"
        ],
        "Differentiation of Parametric Equations": [
            "Differentiation of Parametric Equations"
        ],
        "Differentiation using logarithm": [
            "Differentiation using logarithm"
        ],
        "Derivative of Infinite Series": [
            "Derivative of infinite series"
        ],
        "Differentiation of a Function w.r.t Another Function": [
            "Differentiation of a Function w.r.t Another Function"
        ],
        "Derivative of inverse function": [
            "Derivative of inverse function"
        ],
        "Differentiation of Determinant": [
            "Differentiation of Determinant"
        ],
        "Higher Order Derivatives": [
            "Higher Order Derivatives"
        ],
        "Determining a function using first principle": [
            "Determining a function using first principle"
        ]
    },
    "Application of Derivatives": {
        "Derivative": [
            "Definition & Geometrical Meaning of Derivatives"
        ],
        "Rate Measured in Calculus": [
            "Interpretation of dy/dx as a rate measure"
        ],
        "Error and approximations": [
            "Errors and Approximation,Approximate change",
            "Relative change,Percentage change"
        ],
        "Tangents and normals": [
            "Slope of Tangent and Normal line",
            "Equation of tangent & normal",
            "Important points about slope of tangent",
            "Tangent from an external point",
            "Length of tangent,normal",
            "Length of subtangent,subnormal",
            "Angle between curves",
            "Shortest distance between curves",
            "Asymptotes in Calculus"
        ],
        "Mean value theorem": [
            "Rolle's theorem,Geometrical meaning",
            "Lagrange’s mean value theorem,Geometrical meaning",
            "Another form of lagrange’s mean value theorem",
            "Cauchy’s mean value theorem"
        ],
        "Monotonicity": [
            "Introduction of monotonicity",
            "Monotonicity of a function at a point",
            "Monotonicity in an interval",
            "Concavity and Convexity",
            "Point of inflection",
            "Inequalities using monotonicity"
        ],
        "Maxima and Minima": [
            "Critical points",
            "Basic theorem of maxima and minima",
            "Turning points of a function",
            "First Derivative Test",
            "Second Derivative Test",
            "Nth derivative test",
            "Maxima and minima for non differentiable functions",
            "Max and Min Values of Function in Domain",
            "Max and Min Values of Function in an Interval",
            "Concept of global maxima/minima",
            "Application of extremum",
            "Graph of rational function"
        ]
    },
    "Indefinite Integration": {
        "Antiderivative Integral": [
            "Integration Meaning,Geometrical interpretation"
        ],
        "Elementary integration": [
            "Fundamental integration formula",
            "Properties and algebra of indefinite integration"
        ],
        "Integration by substitution": [
            "Integration of type f(ax+b)",
            "Integration of type f’(x)/f(x)",
            "Integration of typef(g(x)).g’(x)",
            "Integration of type sinᵐx.cosⁿx",
            "Some standard trigonometric substitutions"
        ],
        "Algebraic integration": [
            "Integration of type 1/quadratic",
            "Integration of type linear/quadratic",
            "Integration of type 1/√quadratic",
            "Integration of type linear/√quadratic",
            "Integration of type quadratic/√quadratic",
            "Integration of type √quadratic",
            "Integration of type linear.√quadratic",
            "Integration of type quadratic√quadratic",
            "Integration of type quadratic /ax⁴+b",
            "Integration of type 1/linear√linear",
            "Integration of type 1/linear√quadratic",
            "Integration of type 1/quadratic√linear",
            "Integration of type 1/quadratic√quadratic",
            "Integrals involving Exponentials"
        ],
        "Some special trigonometric forms": [
            "Integration of type 1/(asin²x+bcos²x+c)",
            "Integration of type 1/(asinx+bcosx)²",
            "Integration of type 1/(asinx+bcosx+c)",
            "Integration of type (psinx+qcosx+r)/(asinx+bcosx+c)"
        ],
        "Miscellaneous Methods of Integration": [
            "Integration by parts",
            "Integration by cancellation",
            "Integration by partial fractions"
        ],
        "Some important concepts": [
            "Reduction formula integration",
            "Euler substitution integration",
            "Integrals that cannot be evaluated"
        ]
    },
    "Definite Integration": {
        "Introduction of definite integrals": [
            "Definition & Geometrical Interpretation of Definite Integrals",
            "Finite Integration - Sign Convention",
            "Improper Definite Integrals"
        ],
        "Properties of Definite Integrals": [
            "Changing dummy variable",
            "Interchanging limits",
            "Splitting limits",
            "Definite integration of odd and even functions",
            "King property [ f(x)→ f(a+b-x)]",
            "Splitting limit 0 to 2a into o to a",
            "Properties related to periodic functions"
        ],
        "Newton leibnitz’s rule": [
            "Newton Leibniz Theorem",
            "Functional Equation Involving Definite Integral"
        ],
        "Inequalities": [
            "Properties related to inequalities of definite integral",
            "Estimation of integral",
            "Mean Value Theorem for Definite Integrals"
        ],
        "Other important concepts": [
            "Definite Integral as Limit of Sum",
            "Beta and Gamma Function",
            "Reduction formula",
            "Wallis formula"
        ]
    },
    "Area Under Curves": {
        "Curve Sketching for Calculus": [
            "Symmetry about x-axis ,y-axis, y=x",
            "Points where curve crosses axes,intervals of monotonicity",
            "Examine when x and y tends to infinity"
        ],
        "Different cases of bounded area": [
            "Area Bounded by Curves and x axis",
            "Area Bounded by Curves and y axis",
            "Area of Between Curves",
            "Area of Standard Curves"
        ]
    },
    "Differential Equation": {
        "Introduction of D.E.": [
            "Definition ,Order of Differential Equation",
            "Degree of Differential Equation",
            "Formation of D. E. by Eliminating Arbitrary Constants"
        ],
        "Solution of differential equations": [
            "Variable Separable form of Differential Equations",
            "Reducible to Variable separable form of differential equations",
            "Homogeneous form of Differential Equations",
            "Reducible to homogeneous form of differential equations",
            "Linear Differentiation of Equation",
            "Differential Equations Reducible to Linear Form",
            "Bernoulli Differential Equations",
            "Exact Differential Equation"
        ],
        "Application of Differential Equations": [
            "Physical Application of Differential Equations",
            "Geometrical Application of Differential Equations",
            "Differential Equation Growth and Decay Problems",
            "Differential equation temperature problem",
            "Differential equation dilution problems",
            "Differential Equation in Circuits"
        ],
        "Trajectory in Differential Equations": [
            "Isogonal & Orthogonal trajectories"
        ]
    },
    "Matrices and Determinants": {
        "Matrices": [
            "Definition,Terms Related to matrix"
        ],
        "Types of Matrix": [
            "Row ,Column, Null Matrix",
            "Horizontal, Vertical, Square Matrix",
            "Triangular Matrix, Upper & lower Triangular Matrix",
            "Diagonal, Scalar Matrix, Unit Matrix"
        ],
        "Algebra of matrices": [
            "Equality Addition and Subtraction of Two Matrices",
            "Multiplication of Scalar to a Matrix",
            "Multiplication of Matrices, Integer Powers of Matrices",
            "Matrix Polynomials, Trace of matrix"
        ],
        "Transpose of a matrix": [
            "Transpose,Properties of Transpose of a Matrix",
            "Symmetric,Skew Symmetric Matrix",
            "Properties of Symmetric and Skew Symmetric Matrix"
        ],
        "Adjoint of a Matrix": [
            "Adjoint,Properties of Adjoint of a Matrix"
        ],
        "Inverse of Matrix": [
            "Inverse,Properties of Inverse of Matrix",
            "Finding Inverse of a Matrix Using Adjoint",
            "Matrix Inverse Using Row Transformation"
        ],
        "Linear equations": [
            "Solution of Linear Equations Using Matrices"
        ],
        "Classification of matrices": [
            "Idempotent Matrix, Periodic Matrix, Nilpotent Matrix",
            "Involutory Matrix, Orthogonal Matrix, Unitary Matrix",
            "Complex Conjugate, Hermitian, Skew Hermitian Matrix"
        ],
        "Determinant": [
            "Definition, Minor, Cofactor & Value of Determinant"
        ],
        "Properties of Determinant": [
            "Transpose, All zero,Repetition property",
            "Scalar multiplication, Switching property",
            "Sum property, Property of invariance",
            "Factor property"
        ],
        "Determinant Multiplication": [
            "Determinant Multiplication"
        ],
        "System of Linear Equation": [
            "System of Linear Equation, Homogeneous Linear Equations, Cramer's Rule"
        ],
        "Calculus of determinant": [
            "Differentiation of Determinant",
            "Integration of Determinant"
        ],
        "Cayley-hamilton theorem": [
            "Cayley-hamilton theorem, Characteristic equation of a matrix"
        ],
        "Some other points": [
            "Rank of a Matrix",
            "Area of Triangle in Determinant Form"
        ]
    },
    "Vector": {
        "Introduction of vectors": [
            "Scalar and vector quantities,Representation of vectors"
        ],
        "Types of vectors": [
            "Zero vector, unit vector,Like and unlike vector",
            "Collinear or parallel vector,Coinitial and coterminous vector",
            "Negative of a vector,Equality of vectors,Localised and free vectors"
        ],
        "Position vector and direction cosines": [
            "Definition of position vector,Direction cosines",
            "Direction ratios and magnitude of a vector"
        ],
        "Algebra of vectors": [
            "Triangle law,Parallelogram law  of addition",
            "Properties of vector addition,Subtraction of vectors",
            "Multiplication of a vector by a scalar,Section formula"
        ],
        "Collinearity and coplanarity of vectors": [
            "Relation between two collinear vectors",
            "Test of collinearity of three points",
            "Theorem on coplanar vectors",
            "Test of coplanarity of three vectors",
            "Test of coplanarity of four points",
            "Linear dependence and independence of vectors"
        ],
        "Product of two vectors": [
            "Dot Product of Vectors",
            "Angle between two vectors",
            "Properties of dot product",
            "Projection of Vector a on b",
            "Component of vector along and perpendicular to another vector",
            "Cross product of two vectors",
            "Properties of cross product",
            "Area of a Triangle and parallelogram"
        ],
        "Product of three vector": [
            "Scalar Triple Product,Geometrical interpretation of scalar triple product",
            "Properties of scalar triple product",
            "Volume of tetrahedron",
            "Important points about tetrahedron",
            "Vector Triple Product"
        ],
        "Product of four vectors": [
            "Scalar product of four vectors",
            "Vector product of four vectors"
        ],
        "Reciprocal System of Vectors": [
            "Definition,Properties of reciprocal system of vectors"
        ],
        "Solution of vector equation": [
            "Solution of vector equation"
        ]
    },
    "Three Dimensional Geometrical System": {
        "Introduction of 3D": [
            "Coordinate of a Point in 3D,Vector Joining Two Points",
            "Distance Formula 3D",
            "Section Formula in 3D Geometry"
        ],
        "Important Points in a Triangle 3 Dimensional Geometry": [
            "Centroid,Incentre of a Triangle in 3D",
            "Orthocentre,Circumcenter of a Triangle in 3D"
        ],
        "Direction cosine and direction ratios": [
            "Direction cosines & Direction Ratios of a Line Joining Two Points"
        ],
        "Line in 3D and Vectors": [
            "Line Passes through a point Parallel to given Vector",
            "Line Passing through one point, given Direction Ratio Parallel to line",
            "Line Joining two Position Vectors( two points) in 3D",
            "Equation of Line through one Point Perpendicular to Two given vectors",
            "Angle between two lines in 3D",
            "Angle bisector of two lines (3D)",
            "Parallel & Skew Lines in Vectors",
            "Shortest Distance Between Lines (3D)",
            "Equation of line of Shortest Distance between two Skew Lines",
            "Length of Perpendicular from given Point to Other Line (3D)",
            "Foot of Perpendicular & Image of a Point on a Line/Plane in 3D",
            "Point of intersection of two lines in 3D"
        ],
        "Plane in 3D and vectors": [
            "General equation & Normal form of plane",
            "Equation of a Plane through one Point and one perpendicular vector",
            "Plane Passing through a point and Containing given Line (3D)",
            "Intercept form of a Plane (3D)",
            "Equation of plane through three given points",
            "Equation of plane through given point and parallel to two given vectors",
            "Equation of plane in parametric form",
            "Equation of plane parallel to a given plane"
        ],
        "Foot of perpendicular and image of a point in plane": [
            "Length of Perpendicular from a Point on a Plane in 3D",
            "Foot of Perpendicular from a Point on a Plane (3D)",
            "Distance between two parallel planes"
        ],
        "Angle between two planes": [
            "Angle between two Planes,Condition for parallel planes"
        ],
        "Angle Bisector between two Planes (3D)": [
            "Equation of angle bisector plane",
            "Angle bisector containing/not containing a point",
            "Bisector of acute/obtuse angle"
        ],
        "Family of planes": [
            "Equation of line of Intersection of two Plane (3D)",
            "Planes Passing through line of Intersection of two Planes"
        ],
        "Plane and Line": [
            "Intersection Point of Line and Plane (3D)",
            "Angle between Line and Plane (3D)",
            "Condition for a line to lie in given plane",
            "Condition for two lines to be coplanar",
            "Reflection of a Line by Plane (3D)",
            "Projection of a Line on Plane (3D)",
            "Line of Greatest Slope (3D)"
        ],
        "Sphere": [
            "Equation of sphere with centre and radius",
            "General equation,diametric form of sphere",
            "Section of a sphere by a plane",
            "Condition of tangency of a plane to a sphere"
        ]
    },
    "Set Theory and Relations": {
        "Sets and their representation": [
            "Definition of Set",
            "Roster Method, Set-Builder Method"
        ],
        "Types of Sets": [
            "Empty set, Singleton set, Finite set, Infinite set",
            "Equal sets, Equivalent sets",
            "Universal set, Cardinal number of a finite set"
        ],
        "SubSet and power set": [
            "Definition & Number of subset",
            "Proper, Improper subsets",
            "Power set"
        ],
        "Operations on Sets": [
            "Union of sets, Intersection of sets",
            "Difference, Symmetric difference of two sets",
            "Complement of sets",
            "Venn Diagram",
            "Properties of Set Operation, De morgan’s law"
        ],
        "Formulas on operation of  sets": [
            "Formula for union of two/three sets",
            "Formula for difference of two sets",
            "Some other formulas"
        ],
        "Cartesian Product of Sets": [
            "Ordered pairs",
            "Cartesian product of two sets",
            "Theorem in Cartesian Product of Sets"
        ],
        "Relations": [
            "Definition & Representation of Relations",
            "Domain and Range and co-domain  of Relation"
        ],
        "Types of Relations": [
            "Identity, Void, Universal Relation",
            "Reflexive Relation",
            "Symmetric, Antisymmetric Relation",
            "Transitive Relation",
            "Equivalence Relation",
            "Inverse Relation in Set Theory"
        ],
        "Other important points": [
            "Order of a Relation, Partial Order Relation",
            "Composition of two Relations",
            "Number of Relations"
        ]
    },
    "Mathematical Induction": {
        "Mathematical Statement Logic & principle": [
            "Mathematical statement logic, First Principle of mathematical induction",
            "Second principle of mathematical induction"
        ]
    },
    "Statistics": {
        "Fundamentel of Statistics": [
            "Mean of individual,discrete & continuous frequency distribution",
            "Median of individual,discrete & continuous frequency distribution",
            "Mode of individual,discrete & continuous frequency distribution",
            "Empirical Relations in Statistics",
            "Combined mean of two series",
            "Weighted mean"
        ],
        "Range in Dispersion": [
            "Range in Dispersion in Statistics"
        ],
        "Mean deviation": [
            "Mean Deviation about Mean and Median Individual Observation",
            "Mean Deviation for Discrete Frequency Distribution",
            "Mean Deviation for Continuous Frequency Distribution",
            "Merits and Limitations of Mean Deviation",
            "Coefficient Mean Deviation in Statistics"
        ],
        "Variance and standard deviation": [
            "Variance definition Variance of Individual Observation in Statistics",
            "Variance of Discrete Frequency Distribution in Statistics",
            "Variance of Continuous Frequency Distribution in Statistics",
            "Standard Deviation in Statistics",
            "Coefficient of Variation in Statistics",
            "Coefficient Standard Deviation in Statistics"
        ],
        "Quartile deviation": [
            "Quartile & Quartile Deviation in Statistics"
        ],
        "Skewness": [
            "Skewed distribution and skewness",
            "Positively & Negatively skewed distribution",
            "Karl pearson’s coefficient of skewness"
        ]
    },
    "Mathematical Reasoning": {
        "Statement": [
            "Statements in mathematical reasoning",
            "Simple & Compound statements",
            "Negation of a statement"
        ],
        "Logical connectives": [
            "Connective ‘and’ ,Truth Value & Truth Table",
            "Connective ‘or’ ,Truth Value & Truth Table"
        ],
        "Quantifiers": [
            "Quantifiers (there exixts, for all)"
        ],
        "Implications": [
            "Implication'if-then'",
            "Implication 'if and only if'",
            "Truth table for implications"
        ],
        "Logical Equivalence in mathematical reasoning": [
            "Meaning of Logical equivalence"
        ],
        "Contrapositive, Converse and Contradiction of a statement": [
            "Contrapositive, Converse & Contradiction of a statement"
        ],
        "Tautology and fallacy": [
            "tautology & fallacy in mathematical reasoning"
        ],
        "Validity of a statement": [
            "Validity of statement’p and q’,’p or q’",
            "Validity of statement’if p then q',’p if and only if q'"
        ],
        "Algebra of statements": [
            "Idempotent Law,Commutative Law",
            "Distributive Law,De morgan's Law",
            "Identity law,Complement Law "
        ]
    }
};